import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from '../../utils/auth';
import User from '../../utils/user';

const ProtectedRouteAuthenticated = (props) => {
  const { path, children } = props;
  const domain = User.getUserDomain();
  return Auth.isAuthenticated()
    ? (<Redirect to={`/${domain}`} />)
    : (
      <Route path={path}>
        { children}
      </Route>
    );
};

ProtectedRouteAuthenticated.defaultProps = {
  children: <></>,
  path: '',
};

ProtectedRouteAuthenticated.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.instanceOf(React.Component),
  ]),
  path: PropTypes.string,
};

export default ProtectedRouteAuthenticated;
