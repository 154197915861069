import React from "react";
import Tooltip from "../../../tooltip/Tooltip";

import { TableCol, TableColDivCenter, TableEditIcon } from "../../Styles";

function ActionsCol({
  withActions,
  obj,
  getDisabledObject,
  isDisabledObject,
  handleOnClickEdit,
}) {
  return (
    <>
      {withActions ? (
        <TableCol colActions>
          <TableColDivCenter>
            {isDisabledObject(obj) ? (
              <Tooltip message={getDisabledObject(obj).tooltip} position="left">
                <TableEditIcon
                  isdisabled={(
                    isDisabledObject(obj) &&
                    getDisabledObject(obj).action === "edit"
                  ).toString()}
                  onClick={() => handleOnClickEdit(obj)}
                />
              </Tooltip>
            ) : (
              <TableEditIcon
                isdisabled={isDisabledObject(obj).toString()}
                onClick={() => handleOnClickEdit(obj)}
              />
            )}
          </TableColDivCenter>
        </TableCol>
      ) : null}
    </>
  );
}

export default ActionsCol;
