import React from "react";

import { TableCol } from "../../Styles";

import formatters from "../../../../utils/formatters";

function IDCol({ obj, isCoupon }) {
  return isCoupon ? <TableCol>{formatters.formatId(obj?.id)}</TableCol> : null;
}

export default IDCol;
