export default {
  translations: {
    language: {
      message: "ko",
    },
    LoginFormTitle: {
      message: "로그인"
    },
    LoginFormSubtitle: {
      message: "안녕하세요, 로그인하려면 이메일과 비밀번호를 입력하세요:"
    },
    LoginFormErroMessage: {
      message: "로그인할 수 없습니다. 다시 시도하십시오."
    },
    LoginFormButton: {
      message: "로그인"
    },
    LoginFormButtonForgotPassword: {
      message: "비밀번호를 잊어버렸어요"
    },
    password: {
      message: "비밀번호"
    },
    confirmPassword: {
      message: "비밀번호 확인"
    },
    mail: {
      message: "이메일"
    },
    myEstablishment: {
      message: "내 설립"
    },
    parameterization: {
      message: "매개변수화"
    },
    itemRegistration: {
      message: "레지스트라지오네 델로게토"
    },
    orders: {
      message: "요청"
    },
    discountCoupons: {
      message: "할인쿠폰"
    },
    logout: {
      message: "나가"
    },
    establishmentDetails: {
      message: "속성 세부 정보"
    },
    subscriptions: {
      message: "서명"
    },
    aboutTheEstablishment: {
      message: "설립에 대해"
    },
    mandatoryFilling: {
      message: "필수 채우기"
    },
    tradeName: {
      message: "상호(귀하의 시설이 알려진 이름)"
    },
    companyCorporateName: {
      message: "사회적 이유"
    },
    informTheCompanyCorporateName: {
      message: "회사명 알려줘"
    },
    pixel: {
      message: "픽셀"
    },
    informPixel: {
      message: "픽셀 보고"
    },
    invalidCnpj: {
      message: "잘못된 CNPJ"
    },
    invalidCep: {
      message: "잘못된 우편번호"
    },
    state: {
      message: "상태"
    },
    city: {
      message: "도시"
    },
    cityUpper: {
      message: "도시"
    },
    neighborhoods: {
      message: "이웃"
    },
    street: {
      message: "도로"
    },
    number: {
      message: "숫자"
    },
    informTheNumber: {
      message: "번호를 입력하세요."
    },
    complement: {
      message: "보어"
    },
    complementUpper: {
      message: "보어"
    },
    URLToYourMenu: {
      message: "메뉴 URL"
    },
    emailToLoginToTheManager: {
      message: "관리자 로그인 이메일"
    },
    name: {
      message: "이름"
    },
    nameUpper: {
      message: "이름"
    },
    lastName: {
      message: "성"
    },
    contactEmail: {
      message: "이메일 연락처"
    },
    cellphoneNumberWhatsApp: {
      message: "모바일/왓츠앱"
    },
    thisFieldIsRequired: {
      message: "이 필드는 필수입니다."
    },
    enterYourCreditCardDetails: {
      message: "신용카드 정보를 입력하세요:"
    },
    thisIsTheCurrentPaymentMethod: {
      message: "현재 결제 방법은 다음과 같습니다."
    },
    paymentMethod: {
      message: "지불 방법:"
    },
    paymentMethod2: {
      message: "지불 방법"
    },
    paymentMethod3: {
      message: "지불 방법"
    },
    cardNumber: {
      message: "카드 번호:"
    },
    enterAValidCardNumber: {
      message: "유효한 카드 번호를 입력하십시오."
    },
    enterAValidDate: {
      message: "유효한 날짜를 입력하십시오."
    },
    cardholderName: {
      message: "카드 소지자 이름."
    },
    typeAsWrittenOnCard: {
      message: "카드에 적힌 대로 입력하세요."
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: "카드에 적힌 그대로 이름을 입력하세요."
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: "지불 세부 정보를 저장하면 구독을 취소할 때까지 MyTripMenu에서 계획 금액을 매월 자동으로 청구하도록 승인하는 것입니다. 이용약관이 있습니다"
    },
    inThisLink: {
      message: "이 링크에서."
    },
    savePaymentData: {
      message: "결제 데이터 저장"
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: "요금제 정보를 보고 구독을 확인하려면 신용카드를 추가하세요."
    },
    deliveryConfiguration: {
      message: "배달 구성"
    },
    schedules: {
      message: "일정"
    },
    Schedules: {
      message: "일정"
    },
    add: {
      message: "추가하다"
    },
    onSiteServiceVisualization: {
      message: "현장 서비스 | 심상"
    },
    deliveryWhatsappOrders: {
      message: "배달 | WhatsApp의 요청"
    },
    takeAway: {
      message: "철수"
    },
    catalog: {
      message: "목록"
    },
    typesOfService: {
      message: "서비스 유형"
    },
    mandatoryDataForDelivery: {
      message: "배달을 위한 필수 데이터"
    },
    phone: {
      message: "전화"
    },
    phoneUpper: {
      message: "전화"
    },
    address: {
      message: "주소"
    },
    generalSettings: {
      message: "일반 설정"
    },
    freeShippingForPurchasesAbove: {
      message: "이상 구매시 무료배송"
    },
    noFreeShipping: {
      message: "무료배송 불가"
    },
    minimumValueForDeliveryOrders: {
      message: "배달 주문을 위한 최소 금액"
    },
    minimumDeliveryTime: {
      message: "최소 배달 시간"
    },
    noForecast: {
      message: "예측 불가"
    },
    maximumDeliveryTime: {
      message: "최대 배달 시간"
    },
    orderResponseTimeInMinutes: {
      message: "몇 분 안에 주문 응답 시간"
    },
    serviceNumberViaWhats: {
      message: "WHATSAPP을 통한 서비스 번호"
    },
    servicePlaces: {
      message: "서비스 장소"
    },
    action: {
      message: "행위"
    },
    addEditCities: {
      message: "도시 추가/편집"
    },
    newCity: {
      message: "새로운 도시"
    },
    search: {
      message: "검색"
    },
    addNew: {
      message: "새로운 걸 더하다"
    },
    saveEditions: {
      message: "에디션 저장"
    },
    exitWithoutSaving: {
      message: '저장하지 않고 종료'
    },
    saveEditionsAndExit: {
      message: '변경 사항을 저장하고 종료'
    },
    back: {
      message: "돌아와"
    },
    status: {
      message: "상태"
    },
    active: {
      message: "활동적인"
    },
    inactive: {
      message: "비활성"
    },
    category: {
      message: "범주"
    },
    subcategory: {
      message: "하위 카테고리"
    },
    categories: {
      message: "카테고리"
    },
    subcategories: {
      message: "하위 카테고리"
    },
    categoriesUpper: {
      message: "카테고리"
    },
    subcategoriesUpper: {
      message: "하위 카테고리"
    },
    complements: {
      message: "애드온"
    },
    complementsGroup: {
      message: "보완 그룹"
    },
    complementsGroupUpper: {
      message: "보완 그룹"
    },
    products: {
      message: "제품"
    },
    image: {
      message: "영상"
    },
    yes: {
      message: "응"
    },
    no: {
      message: "아니"
    },
    theHighlightsCategoryCannotBeEdited: {
      message: "하이라이트 카테고리는 편집할 수 없습니다"
    },
    sortFromAZ: {
      message: "A-Z 순으로 정렬"
    },
    sortFromZA: {
      message: "Z-A에서 정렬"
    },
    sortByActive: {
      message: "활성순으로 정렬"
    },
    sortByInactive: {
      message: "비활성순으로 정렬"
    },
    listActiveOnly: {
      message: "자산만 목록"
    },
    addEditCategory: {
      message: "카테고리 추가/편집"
    },
    addEditSubcategory: {
      message: "하위 카테고리 추가/편집"
    },
    imageOptional: {
      message: "이미지(선택 사항)"
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: "카테고리에 일러스트 이미지를 추가하는 것은 선택 사항이지만 메뉴를 더 매력적으로 만드는 데 도움이 됩니다."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: "이 범주를 구성하는 항목을 설명하고 제품의 실제 프레젠테이션과 관련하여 조명이 좋고 사실적인 사진을 선택하십시오. 일반 사진, 스톡 사진 또는 낮은 품질의 사진을 사용하지 마십시오."
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: "이상적인 해상도는 800x600픽셀이고 이미지는 1MB를 초과할 수 없습니다."
    },
    selectPhoto: {
      message: "사진 선택"
    },
    selectCategory: {
      message: "카테고리 선택"
    },
    saveAndAddAnother: {
      message: "저장 및 추가"
    },
    highlightsSubcategoryCannotBeEdited: {
      message: "하이라이트 하위 카테고리는 편집할 수 없습니다"
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: "하위 범주가 표시되어야 하는 범주"
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: "하위 카테고리에 예시 이미지를 추가하는 것은 선택 사항이지만 메뉴를 더 매력적으로 만드는 데 도움이 됩니다."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "이 하위 범주를 구성하는 항목을 보여주고 조명이 좋고 제품의 실제 표현과 관련하여 사실적인 사진을 선택하십시오. 일반 사진, 스톡 사진 또는 낮은 품질의 사진을 사용하지 마십시오."
    },
    enterANameForTheSubcategory: {
      message: "하위 카테고리의 이름을 입력하세요"
    },
    enterANameForTheCategory: {
      message: '카테고리 이름을 입력하세요.'
    },
    descriptionOfComplement: {
      message: "추가 설명"
    },
    descriptionOfComplementUpper: {
      message: "보완 설명"
    },
    addComplementGroup: {
      message: "보완 그룹 추가"
    },
    complementGroupName: {
      message: "추가 기능 그룹 이름"
    },
    complementName: {
      message: "추가 기능 이름"
    },
    checkAllFields: {
      message: "모든 필드를 확인하십시오."
    },
    complementGroupIsInactive: {
      message: "추가 기능 그룹이 비활성화되었습니다."
    },
    value: {
      message: "값"
    },
    valueUpper: {
      message: "값"
    },
    addProduct: {
      message: "제품 추가"
    },
    images: {
      message: "이미지"
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "이 제품을 구성하는 항목을 보여주고 조명이 좋고 실제 프레젠테이션과 관련하여 사실적인 사진을 선택하십시오. 일반 사진, 스톡 사진 또는 낮은 품질의 사진을 사용하지 마십시오."
    },
    enableOn: {
      message: "사용 설정"
    },
    categorysInWhichTheProductShouldAppear: {
      message: "제품이 표시되어야 하는 범주"
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: "제품이 표시되어야 하는 하위 범주"
    },
    categorization: {
      message: "분류"
    },
    shortDescriptionShownInProductDetail: {
      message: "간략한 설명(제품 세부정보에 표시됨)"
    },
    longDescriptionShownInProductDetail: {
      message: "자세한 설명(제품 세부정보에 표시됨)"
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: "이 제품을 구성하는 성분, 그 반주 및 부분 크기를 입력하십시오"
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: "반찬이나 분량 등을 입력하세요."
    },
    principalInformation: {
      message: "주요 정보"
    },
    id: {
      message: "ID"
    },
    type: {
      message: "유형"
    },
    validity: {
      message: "타당성"
    },
    validityUpper: {
      message: "타당성"
    },
    useAvailable: {
      message: "사용/사용 가능"
    },
    filter: {
      message: "필터"
    },
    freeDelivery: {
      message: "무료 배달"
    },
    discount: {
      message: "할인"
    },
    aCouponWithThisCodeAlreadyExists: {
      message: "해당 코드가 있는 쿠폰이 이미 있습니다."
    },
    dateTime: {
      message: "날짜 시간"
    },
    change: {
      message: "물건"
    },
    formOfPayment: {
      message: "지불 형태"
    },
    customer: {
      message: "고객"
    },
    uninformed: {
      message: "정보 없음"
    },
    recordsFound: {
      message: "기록 발견"
    },
    QRCodeOfYourDigitalMenu: {
      message: "당신의 디지털 메뉴의 QR코드"
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: "QR 코드를 다운로드하여 고객이 디지털 메뉴에 쉽게 액세스할 수 있도록 시설 또는 판촉 자료에서 사용할 수 있도록 하십시오."
    },
    areYouSureYouWantToDoThis: {
      message: "정말 하시겠습니까?"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: "추가 기능을 비활성화하면 이전에 연결된 모든 추가 기능 그룹이 제품에서 연결 해제됩니다."
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: "제품 카테고리를 가져오는 동안 오류가 발생했습니다"
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: "제품 작업을 가져오는 동안 오류가 발생했습니다"
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: "선택한 카테고리의 하위 카테고리를 가져오는 동안 오류가 발생했습니다"
    },
    productImage: {
      message: "제품 이미지"
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: "제품을 비활성화하면 더 이상 온라인 메뉴에 나타나지 않습니다."
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: "필요한 경우 다른 시간에 다시 켤 수 있음을 기억하십시오."
    },
    inactivateTheProduct: {
      message: "제품 비활성화"
    },
    keepActive: {
      message: "활성 유지"
    },
    simpleEasyFastDigital: {
      message: "단순. 간편. 빠름. 디지털."
    },
    errorIdentification: {
      message: "오류 식별"
    },
    tryAgain: {
      message: "다시 시도하십시오"
    },
    huhLooksLikeSomethingWentWrong: {
      message: "와! 뭔가 잘못 된 것 같군..."
    },
    unableToSaveCreditCardDetails: {
      message: "신용카드 정보를 저장할 수 없습니다."
    },
    mmyy: {
      message: "MM/YY"
    },
    removePhoto: {
      message: "사진 제거"
    },
    addEditCoupon: {
      message: "쿠폰 추가/수정"
    },
    couponType: {
      message: "쿠폰 종류"
    },
    freeShipping: {
      message: "무료 배송"
    },
    discountByValue: {
      message: "가치별 할인"
    },
    percentageDiscount: {
      message: "백분율 할인"
    },
    generateCoupon: {
      message: "쿠폰 생성"
    },
    limitOfUse: {
      message: "사용 제한"
    },
    couponCode: {
      message: "쿠폰 코드"
    },
    validFrom: {
      message: "유효"
    },
    validUntil: {
      message: "까지 유효합니다"
    },
    highlightsSubcategoryCannotBeCopied: {
      message: "하이라이트 하위 카테고리는 복사할 수 없습니다"
    },
    nothingHere: {
      message: "아직 아무것도 없는 것 같습니다."
    },
    from: {
      message: "에"
    },
    to: {
      message: "~까지"
    },
    sunday: {
      message: "일요일"
    },
    monday: {
      message: "초"
    },
    tuesday: {
      message: "화요일"
    },
    wesnesday: {
      message: "네번째"
    },
    thursday: {
      message: "다섯 번째"
    },
    friday: {
      message: "금요일"
    },
    saturday: {
      message: "토요일"
    },
    accessData: {
      message: "데이터 액세스"
    },
    responsibleEstablishment: {
      message: "설립 책임"
    },
    saveEditions2: {
      message: "에디션 저장"
    },
    zeit: {
      message: "시간"
    },
    cardsAccepted: {
      message: "시설에서 허용하는 카드를 표시하십시오."
    },
    pixKey: {
      message: "여기에서 PIX 키를 등록하세요."
    },
    noOptions: {
      message: "옵션 없음"
    },
    neighborhoods2: {
      message: "이웃"
    },
    addEditNeighborhoods: {
      message: "이웃 추가/수정"
    },
    newNeighborhoods: {
      message: "새로운 동네"
    },
    qtyMinimum: {
      message: "수량 최저한의"
    },
    qtymaximum: {
      message: "수량 최고"
    },
    linkGroup: {
      message: "링크 공급 그룹"
    },
    importantGroupComplements: {
      message: "보체 수입 그룹"
    },
    people: {
      message: "사람들"
    },
    house: {
      message: "주택"
    },
    subcategoryProducts: {
      message: "이 하위 범주에는 관련 제품이 없습니다."
    },
    productNotAdd: {
      message: "이 제품에는 추가된 추가 기능이 없습니다."
    },
    linkSupplements: {
      message: "링크 보완"
    },
    PRICE: {
      message: "가격"
    },
    COMPLEMENTS: {
      message: "보완"
    },
    DESCRIPTION: {
      message: "설명"
    },
    action2: {
      message: "행위"
    },
    pixKey2: {
      message: "귀하의 PIX 키"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "변경 사항을 저장하기 전에 속성 세부 정보 페이지를 나가면 이미 입력한 모든 정보를 잃게 됩니다."
    },
    couldNotLoadUserData: {
      message: "사용자 데이터를 로드할 수 없습니다."
    },
    termsOfUseAanServices: {
      message: "이용약관 및 서비스"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou: {
      message: "안녕! 관심을 가져 주셔서 기쁩니다! 당사 서비스를 사용하기 전에 시간을 내어 이용 약관 및 서비스를 읽고 귀하와의 관계에 적용되는 규칙에 대해 알아보십시오."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom: {
      message: "아래에서 우리가 중요하다고 생각하는 몇 가지 사항을 명확히 할 것입니다. 이 문서에서 논의된 사항이나 논의되지 않은 사항에 대해 의문 사항이 있으면 주저하지 말고 hello@mytripmenu.com으로 문의하십시오."
    },
    definitions: {
      message: "1. 정의:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "이 도구에서 우리는 다음 정의에 따라 아래 표현을 이해합니다."
    },
    plataform: {
      message: "플랫폼"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "mytripmenu.com.br을 통해 제공되는 웹 시스템"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " 의 소유권, 운영 및 책임 "
    },
    users: {
      message: "사용자"
    },
    user: {
      message: "사용자"
    },
    where: {
      message: ", 어디에"
    },
    allThe: {
      message: "모든"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "에서 제공하는 서비스를 보고 사용할 수 있습니다."
    },
    establishment: {
      message: "설립"
    },
    establishments: {
      message: "시설"
    },
    legalEntityRegisteredInThe: {
      message: "에 등록된 법인"
    },
    whichContractsTheServicesOfferedBy: {
      message: "에서 제공하는 서비스를 계약하는"
    },
    client: {
      message: "고객"
    },
    customers: {
      message: "고객"
    },
    naturalPersonAccessingThe: {
      message: "액세스하는 자연인"
    },
    toViewTheMenu: {
      message: "메뉴를 보려면 "
    },
    dataProcessing: {
      message: "데이터 처리"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " 2018년 법률 13,709의 5조 X 항목에 따라 "
    },
    withPersonalDataOf: {
      message: " 의 개인 데이터로 "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " , 예를 들어 정보의 수집, 생산, 수신, 분류, 사용, 액세스, 복제, 전송, 배포, 처리, 보관, 저장, 제거, 평가 또는 제어, 수정, 통신, 전송, 보급 또는 추출을 참조합니다. "
    },
    admission: {
      message: " 2. 입학: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " 이 도구는 사용 조건을 규정합니다. "
    },
    being: {
      message: "존재"
    },
    aContractBetweenThe: {
      message: " 사이의 계약 "
    },
    andThe: {
      message: " 그리고 "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " 통해 제공되는 서비스 이용 "
    },
    fromThe: {
      message: "~로부터 "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " 귀하가 이 문서에 포함된 모든 조건과 해당 종에 적용되는 법적 조항에 동의함을 명시적으로 나타냅니다. "
    },
    the: {
      message: "그만큼 "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " 서비스 계약 모델은 "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "웹 사이트의 계획 영역에서 사용할 수 있는 정보에 따라: "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "맞춤형 요금제 계약의 경우, "
    },
    shouldContactThe: {
      message: "연락해야 "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "특정 예산의 정교화를 위해 채널을 통해. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "귀하는 다음 사항을 이해하고 동의합니다. "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " 제공된 서비스를 사용하면 종과 관련된 본 약관 및 기타 모든 법적 조항에 동의하는 것으로 간주됩니다."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "이 도구의 조건을 수락함으로써 사용자는 플랫폼 기능의 유지 관리 및 우수한 성능을 보장하기 위해 자신의 데이터 처리를 명시적으로 승인합니다."
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "이 법에서 사용자는 수집 및 처리된 데이터의 공유에 대한 완전한 동의를 나타냅니다. "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: ", 이 문서에 따라 귀하의 경제 그룹에 속하거나 귀하의 서비스 제공업체인 다른 내 여행 메뉴와 함께."
    },

    caseYou: {
      message: "당신을 케이스 "
    },
    doNotAgree: {
      message: "동의하지 않음"
    },
    withTheProvisionsOfThisInstrument: {
      message: " 이 문서의 조항과 함께, "
    },
    dont: {
      message: '하지 않다'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " 의 페이지, 콘텐츠, 정보 또는 서비스에 액세스, 보기, 다운로드 또는 사용 "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "이 약관은 언제든지 다음 사이트에서 읽을 수 있습니다. "
    },
    inMessage: {
      message: "안에 "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. 우리가 누구이며 우리가 하는 일:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "SaaS(Software as a Service) 형태의 시스템을 개발하고 라이선스를 부여한 서비스 제공업체로, 특정 기능을 지원합니다."
    },
    and: {
      message: "그리고"
    },
    soThe: {
      message: "그래서"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "디지털 메뉴를 제공하여 두 사람 모두에게 효율성과 경험을 향상시킬 수 있습니다."
    },
    asForThe: {
      message: "에 관해서는"
    },
    generalConditionsOfUse: {
      message: "4. 일반 사용 조건:"
    },
    onlyProvidesThe: {
      message: " 만 제공합니다 "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " 디지털 메뉴 서비스를 제공하는 "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " 의 올바른 기능에만 책임이 제한됨 "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "그리고 그 기능은 이 문서와 에 의해 계약된 계획에 따라 "
    },
    notStoppingThe: {
      message: ", 멈추지 않는"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "디지털 메뉴를 통한 콘텐츠의 생성, 사용자 정의 및 배포에 대해 책임을 지지 않습니다."
    },
    justLicenseThe: {
      message: " 그냥 라이센스 "
    },
    toThe: {
      message: " ~로 "
    },
    toThe2: {
      message: " 로 "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ", 그리고 이들 당사자 사이에는 다른 관계가 없으므로"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " 타인에게 발생한 손해에 대한 책임 "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " 또는 제3자에게 "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " 사용 가능한 기능을 사용하는 동안 "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "을 이해하고 수락한다고 선언합니다. "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "의 상업적 단위에서 사용할 수 있는 물리적 메뉴를 대체할 의도가 없습니다. "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "상호간의 의사소통을 용이하게 하기 위한 보완적 솔루션일 뿐 "
    },
    atTheTimeOfRegistrationThe: {
      message: "등록 당시,"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " 에서 제공하는 모든 서비스를 사용할 수 있습니다. "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: ", 본 이용 약관에 포함된 모든 조항을 읽고, 이해하고, 수락했음을 선언합니다."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "이 문서를 통해 "
    },
    theGarantingOfALicenseToUseThe: {
      message: " 사용할 수 있는 라이선스 부여 "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", 양도할 수 없으며, 이 조건에 대한 준수가 지속되는 한 국내 또는 해외 영토에서 사용하기 위한 서브라이선싱이 금지됩니다. "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " 이 문서의 조항에 동의하지 않습니다."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " 귀하의 비밀번호 보안 및 귀하의 등록 사용에 대한 책임은 전적으로 귀하에게 있습니다. "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: ", 따라서 이러한 정보를 제3자와 공유하지 않는 것이 좋습니다. 이 정보가 어떤 이유로든 손실되거나 "
    },
    hacked: {
      message: "해킹"
    },
    mustImmediatelyInformThe: {
      message: " 즉시 알려야 한다 "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", hello@mytripmenu.com을 통해 문제를 해결하십시오."
    },
    itIsOnlyUpToThe: {
      message: "그것은 까지만 "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " 제3자에게 발생한 모든 손해에 대해 책임을 집니다. "
    },
    orToItself: {
      message: " 또는 자신에게 "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", 의 기능을 사용한 결과 "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " 에서 제공되는 서비스를 사용해서는 안 됩니다. "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " 불법적, 중상적, 차별적, 모욕적, 공격적, 외설적, 외설적, 공격적, 해를 끼치는 것, 성가시게 하는 것, 기만적, 중상하는 것, 폭력적, 저속한 것, 괴롭히는 것, 위협하는 것, 또는 거짓 신분 사용, 즉 해를 끼칠 수 있다 "
    },
    others: {
      message: " 기타"
    },
    orThirdParties: {
      message: " 또는 제3자"
    },
    UnderNoCircumstancesWillThe: {
      message: "어떠한 상황에서도 "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "에 의해 발생한 모든 손해에 대해 책임을 집니다. "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " 일시적으로 사용할 수 없는 경우 "
    },
    orOnyContentMadeAvailableByThe: {
      message: "또는 "
    },
    onTheDigitalMenu: {
      message: "디지털 메뉴에서"
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " 액세스하는 데 필요한 모든 소프트웨어와 하드웨어가 있어야 합니다. "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", 인터넷에 액세스할 수 있는 컴퓨터 또는 모바일 장치를 포함하되 이에 국한되지 않고 "
    },
    onlyToProvideThe: {
      message: ", 제공하기 위해 "
    },
    to2: {
      message: " 에게 "
    },
    underThisInstrumentToThe: {
      message: "이 악기 아래. 로 "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "디지털 메뉴에 액세스하려면 QR 코드를 읽을 수 있는 장치가 필수적입니다."
    },
    byThe: {
      message: " 에 의해 "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "사전 등록과 이 문서에 포함된 조항의 준수를 조건으로 합니다."
    },
    registration: {
      message: "5. 등록"
    },
    soThatThe: {
      message: "그래서 "
    },
    registerInThe: {
      message: " 에 등록하다 "
    },
    mustProvideTo: {
      message: "에 제공해야 합니다 "
    },
    theFollowingData: {
      message: " 다음 데이터 "
    },
    corporateName: {
      message: "회사 이름;"
    },
    unitAddressOf: {
      message: " 단위 주소 "
    },
    contactNumberInTheEventThatThe: {
      message: "연락처. 경우에"
    },
    hireThe: {
      message: "고용하다"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "더 많은 장치의 경우 관심 있는 다른 주소를 등록해야 합니다."
    },
    forRegularUseOf: {
      message: "정기적인 사용을 위해 "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " 에서 요청한 모든 데이터를 작성하여 등록해야 합니다. "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " 등록 시 제공되는 서비스 계획 선택뿐만 아니라 "
    },
    itIsTheResponsibilityOf: {
      message: " 의 책임입니다 "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "계약 계획에서 계약에서 고려된 단위 수를 식별합니다."
    },
    itIsTheSoleResponsibilityOf: {
      message: "의 단독 책임입니다."
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "등록 데이터의 정확성을 제공, 업데이트 및 보장합니다."
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " 에서 제공한 사실이 아니거나 부정확하거나 불완전한 데이터로 인해 발생하는 모든 유형의 민형사상 책임 "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " 식별하기 위해 모든 유효하고 가능한 수단을 사용할 권리가 있습니다. "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " 보고된 데이터를 확인하기 위해 관련이 있다고 판단되는 추가 데이터 및 문서를 요청합니다. 이 경우 사용 "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " 최종적으로 요청된 문서의 전송을 조건으로 합니다."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "등록이 잘못되었거나 사실이 아닌 데이터를 포함하는 것으로 의심되는 경우, "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " 사전 통지 없이 일시적 또는 영구적으로 중단할 권리가 있습니다. "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " 등록을 담당합니다. 정학 시에는 참석하지 않습니다. "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " 손실 및 손해, 이익 손실 또는 도덕적 손해에 대한 모든 유형의 배상 또는 보상에 대한 권리."
    },
    inTheEventThatThe: {
      message: "경우에"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "에서 개인 데이터 수집을 수행 "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "수집된 정보 및 관련 정보에 대한 액세스를 허용합니다. "
    },
    carriedOutBy: {
      message: " 에 의해 수행 "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", hello@mytripmenu.com을 요청하여 무료"
    },
    orByRegisteringAt: {
      message: "또는 에 등록하여 "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " 언제든지 편집하거나 삭제할 수 있습니다."
    },
    theCollectionOfDataFrom: {
      message: "에서 데이터 수집 "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", 발생 시 귀하를 식별하고 귀하가 올바르게 사용할 수 있도록 하는 것을 목표로 합니다. "
    },
    andWithThatThe: {
      message: ", 그리고 그것으로, "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " 라이센스 서비스의 좋은 품질을 보장할 수 있습니다."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "이 도구의 조건에 동의함으로써,"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " 귀하의 데이터 수집이 시스템의 적절한 기능을 위해 필수적임을 인지하고 있음을 명시적으로 선언합니다. "
    },
    authorizingFromNowOnThe: {
      message: ", 권한 부여, 지금부터 "
    },
    forThe: {
      message: " 위해 "
    },
    offersThePossibilityOf: {
      message: " 의 가능성을 제공합니다 "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "QR 코드를 통해 디지털 메뉴를 귀하의 "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "그러나 등록이 필요하지 않다고 해서 이 문서의 규칙을 위반하는 것은 아닙니다. "
    },
    mustEnsureCompliance: {
      message: "준수를 보장해야 합니다."
    },
    willAccessYourRegistrationIn: {
      message: " 귀하의 등록에 액세스할 것입니다 "
    },
    through: {
      message: " ~을 통해 "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " 및 암호, 이 데이터를 제3자에게 알리지 않을 것을 서약하고 해당 데이터의 사용에 대해 전적인 책임을 집니다."
    },
    undertakesToNotifyThe: {
      message: " 통지할 것을 약속한다 "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " 에 의해 유지 관리되는 연락 채널을 통해 즉시 "
    },
    at: {
      message: " ~에 "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ", 귀하의 계정의 무단 사용과 관련하여. 그만큼"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " 귀하에게만 알려진 비밀번호를 통해서만 액세스가 가능하기 때문에 귀하의 계정에서 수행되는 작업에 대한 책임은 전적으로 귀하에게 있습니다."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", 다른 사람의 불법 행위를 알고 있는 경우"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " 자신에게 피해를 줄 수 있는 "
    },
    ofThe: {
      message: " 의 "
    },
    toThisOnTo: {
      message: " 이것에, "
    },
    orToThirdParties: {
      message: " 또는 제3자에게."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "의 기능을 사용할 것을 약속합니다."
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " 유효한 법률, 도덕 및 선량한 관습에 따라 성실하게."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "어떠한 경우에도 양도, 판매, 임대 또는 기타 형태의 양도는 허용되지 않습니다."
    },
    atItsSoleDiscretion: {
      message: "단독 재량으로 "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " 사전 통지 또는 보상 없이 서비스 사용을 배제, 비활성화, 제한 생성, 정지, 차단, 무기한, 등록 o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " 불쾌감을 주는 것으로 간주되거나 이 문서 또는 시행 중인 법률의 조건을 위반하는 것."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " 신규 등록을 허용하지 않을 권리가 있습니다. "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " 이미 취소, 비활성화, 차단, 삭제 또는 일시 중단된 "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " 의 정책 위반으로 인해 원래 등록이 취소, 차단, 비활성화, 제외 또는 일시 중지된 사람들에 의한 새 등록 생성 "
    },
    orCurrentLegislation: {
      message: " 또는 현행법."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " 사전 통지, 동의 또는 보상 없이 일방적으로 등록 요청을 거부할 수 있는 권리를 보유합니다. "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", 이전에 승인된 등록의 사용을 취소, 비활성화, 차단, 삭제 또는 일시 중단합니다."
    },
    byAgreeingToThisInstrumentThe: {
      message: "이 도구에 동의함으로써, "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " 오래되거나 정확하지 않거나 사실이 아닌 정보의 삽입으로 인해 발생하는 모든 손해는 "
    },
    orThe: {
      message: "아니면 그"
    },
    functionalities: {
      message: "6. 기능:"
    },
    offersTheFollowingFeatures: {
      message: " 다음과 같은 기능을 제공합니다."
    },
    aOnlineDigitalMenu: {
      message: "(a) 온라인 디지털 메뉴"
    },
    bViewingProductsWithPphotos: {
      message: "(b) 사진이 있는 제품 보기"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) 이용 가능한 조건에 따라 다국어 메뉴를 제공합니다."
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) 실시간 항목 활성화 및 비활성화"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) QR 코드를 스캔하여 메뉴에 액세스"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) 판촉 활동을 위한 하이라이트 생성."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " 회사에서 제공하는 서비스를 이용할 때 "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " 의 디지털 메뉴를 찾을 전자 주소를 결정하기 위해 URL(Uniform Resource Locator)을 선택할 수 있습니다. "
    },
    ToDefineTheURLThe: {
      message: "URL을 정의하려면 "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "제3자의 지적 재산을 존중해야 하므로 혼동을 일으키거나 제3자 서비스를 암시할 수 있는 표현을 포함해서는 안 됩니다."
    },
    understandThatThe: {
      message: " 이해 "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " 자원을 사용하여 사람들 사이에 혼란을 야기했습니다. "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "서비스를 제대로 식별하기 어렵고 오도할 수 있으므로 서비스의 URL을 제외할 수 있습니다. "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "사건과 관련된 다른 조치를 취하는 것뿐만 아니라"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " 에 제공된 대로 계약 계획에 따라 사용 가능한 기능의 범위를 변경할 수 있습니다. "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " 보상 없이 사전 통지를 통해 언제든지 기존 기능을 편집 및/또는 삭제할 수 있을 뿐만 아니라 새로운 기능을 "
    },
    translations: {
      message: "7. 번역:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "메뉴를 선택한 언어로 제공하기 위해 번역 서비스를 사용할 수 있습니다. 이를 위해 서비스에 대한 요금이 청구됩니다. "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "번역 서비스의 실행을 위해,"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "최소 72시간(72시간)의 기간을 요청하십시오. 번역할 텍스트의 길이에 따라"
    },
    withPriorNoticeTo: {
      message: "에 대한 사전 통지와 함께"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "회사는 재량에 따라 서비스 실행 기간을 연장할 수 있습니다."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "특정 문화나 특정 지역을 반영하는 특정 표현을 번역할 수 없음을 이해하고 수용한다고 선언합니다."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " 디지털 메뉴의 부정확한 번역으로 인한 피해는 책임지지 않습니다. "
    },
    howeverInTheEventThatThe: {
      message: ". 다만, 다음과 같은 경우에는"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "전통에서 오류나 오해를 식별하면 "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "요청에 가능한 한 빨리 응답해야 합니다."
    },
    suppertService: {
      message: "8. 지원 서비스"
    },
    willSupportThe: {
      message: "지원합니다 "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " 이메일 hello@mytripmenu.com을 통해 제공되는 연락 채널을 통해."
    },
    inSupportRequestsViaEmailTthe: {
      message: "이메일을 통한 지원 요청에서, "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "응답하는 데 최대 72(72) 영업시간이 소요됩니다."
    },
    paymentMethod4: {
      message: "9. 지불 방법:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "요금이 청구되기 전에 "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "도구의 무료 평가판을 위해 07(칠)일의 평가판 기간이 제공됩니다. "
    },
    ifThe: {
      message: "만약 "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " 서비스 계약을 진행하기로 선택하고, "
    },
    makesAvailableTo: {
      message: "사용할 수 있게 해준다 "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "다음 지불 방법 중 하나: "
    },
    VISACreditCardOr: {
      message: "비자 신용 카드; 또는,"
    },
    MASTERCARDCreditCardOr: {
      message: "마스터카드 신용카드 또는,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "AMERICAN EXPRESS 신용 카드; 또는,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "DINNERS CLUB 신용카드 또는,"
    },
    ELOCreditCardOr: {
      message: "ELO 신용 카드, 또는"
    },
    DISCOVERCreditCard: {
      message: "디스커버 신용카드."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "신용 카드 결제를 처리하려면 다음이 필요합니다."
    },
    registerOn: {
      message: "에 등록하다 "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "선택한 신용 카드의 다음 세부 정보:"
    },
    NameOfTheCreditCardHolder: {
      message: "신용 카드 소지자의 이름"
    },
    CreditCardNumber: {
      message: "신용 카드 번호;"
    },
    CreditCardFlag: {
      message: "신용 카드 깃발;"
    },
    CreditCardExpirationMonthandYear: {
      message: "신용 카드 만료(월 및 연도);"
    },
    CreditCardsecuritynumber: {
      message: "신용 카드 보안 번호."
    },
    Paymentsforservicescontractedinthe: {
      message: "에서 계약한 서비스에 대한 지불 "
    },
    willBe: {
      message: " 될거야"
    },
    processedthroughtheGETNETplatform: {
      message: " GETNET 플랫폼을 통해 처리"
    },
    beingnecessarythatall: {
      message: "모든 것이 필요하다 "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " 또한 GETNET 플랫폼의 사용 약관 및 서비스를 읽고 동의합니다."
    },
    viaemailaddress: {
      message: "이메일 주소를 통해: "
    },
    Whenhiringthe: {
      message: "채용할 때 "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", with payment by credit card or bank slip, the"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "귀하가 이 도구와 GETNET 플랫폼의 사용 조건 및 특정 서비스에 있는 모든 조건을 읽고 수락했음을 명시적으로 나타냅니다."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "GETNET 플랫폼을 통해 이루어진 모든 지불 분쟁은 다음 사이에서만 해결되어야 합니다."
    },
    andGETNETnotfallingto: {
      message: " 및 GETNET, "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " GETNET 플랫폼에서 이루어진 지불 처리에 대한 모든 책임."
    },
    TheGETNETplatform: {
      message: "GETNET 플랫폼"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "사용료를 부과할 수 있으며, "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " GETNET에서 부과하는 값에 대한 정보입니다. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " 에서 제공한 결제 데이터 삭제에 대해서만 책임이 있습니다. "
    },
    fromitsowndatabasedeclaringthe: {
      message: "자체 데이터베이스에서 다음을 선언합니다."
    },
    beawarethatitisnotupto: {
      message: " 까지가 아님을 알아두십시오. "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " GETNET 데이터베이스에서 해당 데이터의 제외를 촉진합니다."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. 개인 정보 및 데이터 처리 정책:"
    },
    Duringtheuseof: {
      message: "사용하는 동안 "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "에서 제공한 정보를 수집 및 저장합니다."
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", 본 기기의 조건에 따라 액세스 장치, 브라우저, 애플리케이션 액세스 로그(IP, 날짜 및 시간 포함), 액세스한 정보, 액세스한 화면, 지리적 위치 데이터, 애플리케이션 기록, 무엇보다도"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", 데이터베이스와 브라우저 쿠키에도 저장됩니다."
    },
    Thedatacollectedfrom: {
      message: "에서 수집한 데이터 "
    },
    throughtheuseof: {
      message: ", 사용을 통해"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", 적절한 서비스 제공, 항법 개선 목적으로 사용됩니다."
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: ", 광고 및 통계 목적으로 사용됩니다."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "개인 데이터를 보내는 경우 이해, 수락 및 동의합니다."
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "2018년 법률 13,709의 5조 X 항목의 조건에 따라 그렇게 해야 합니다."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "회사에서 개인정보를 제공하는 경우 "
    },
    willbeconsideredconfidentialbythe: {
      message: ", 기밀로 간주됩니다."
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ", 그리고 다음과 같이 시행령 No. 8,771/2016에 설정된 보안 표준을 충족하면서 해당 데이터 저장 시 시스템의 보안을 유지하기 위해 모든 노력을 기울일 것을 약속합니다."
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) 불가침성을 보장하기 위해 다른 표준 암호화 형식과 함께 수집된 데이터를 암호화하는 산업 표준 방법의 사용"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) 통제된 보안 환경으로 간주되는 시스템에 대한 무단 액세스로부터 보호하기 위한 하이테크 소프트웨어의 사용"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) 기밀 유지 계약 서명을 포함하여 해당 데이터의 비밀을 약속하는 이전에 승인되고 인증된 사람만 개인 데이터 저장 위치에 대한 통제된 액세스를 사용할 수 있습니다."
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) 처리 책임자를 개별화할 수 있는 기록에 대한 액세스에 대한 인증 메커니즘의 적용 및 사용 결과 수집된 데이터에 대한 액세스"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) 데이터의 익명화"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "파트너가 아닌 제3자와 공유할 때"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) 법령 No. 8,771/2016의 13조에서 결정된 바와 같이, 연결 및 애플리케이션 접근 기록을 기반으로 하는 접근 및 목적 파일의 시간, 기간, 신원, 접근 책임자의 신원을 나타내는 목록의 유지."
    },
    Ifdatacollectionfromthe: {
      message: "에서 데이터를 수집하는 경우"
    },
    throughtheuse: {
      message: ", 사용을 통해"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "다음과 같은 경우에만 제3자와 공유할 수 있습니다."
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) 이익 보호를 위해"
    },
    incaseofconflictincludinglegalclaims: {
      message: "법적 청구를 포함하여 충돌이 있는 경우"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) 관련 거래 및 법인 변경의 경우"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", 이 경우 데이터 전송은 를 통해 제공되는 서비스의 연속성을 위해 필요합니다."
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) 법원 명령 또는 요청에 대한 법적 권한이 있는 행정 당국의 요청에 의해."
    },
    guaranteesto: {
      message: "보장"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", 개인 데이터 처리와 관련하여 다음 권리:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) 귀하의 개인 데이터 처리 존재 확인"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) 다음에 의해 수집된 귀하의 데이터에 대한 액세스"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ", 자신의 로그인을 통해 또는 hello@mytripmenu.com을 요청하여;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) 데이터가 불완전하거나 부정확하거나 오래된 경우 수정"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) 해당 브라질 법률에 따라 불필요하거나 과도하거나 일치하지 않는 데이터를 차단하거나 삭제합니다."
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) 귀하 또는 제3자를 위한 개인 데이터의 이식성, 귀하의 명시적 요청 시"
    },
    viahellomytripmenucom: {
      message: ", hello@mytripmenu.com을 통해;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) 귀하의 동의 하에 처리된 개인 데이터의 삭제(단, 등록된 상태를 유지할 법적 결정이 없는 경우"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) 공공 또는 민간 기관에 대한 정보 획득"
    },
    sharedyourdataand: {
      message: "귀하의 데이터를 공유했습니다. 그리고,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) 동의를 제공하지 않을 가능성 및 결과에 대한 정보"
    },
    youcansendemailto: {
      message: "당신은 이메일을 보낼 수 있습니다"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, 귀하의 개인 데이터와 관련된 의심 및/또는 요구 사항을 지적합니다."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "에서 수집한 개인 데이터를 삭제할 수 있습니다."
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) 수집 목적이 달성되었을 때 또는 본 이용 약관 및 개인 정보 보호 정책에 설명된 목적에 따라 목적 범위에 대해 더 이상 필요하지 않거나 관련성이 없는 경우"
    },
    iiWhenthe: {
      message: "(ii) 때"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "동의 철회, 필요한 경우 제외를 요청하는 경우"
    },
    viahellomytripmenucomor: {
      message: "hello@mytripmenu.com을 통해; 또는,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) 권한 있는 당국이 결정하는 경우."
    },
    GENERALPROVISIONS: {
      message: "11. 일반 조항:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "어떤 이유로든 법원이나 법원에 의해 무효이거나 무효인 것으로 간주되는 이 문서의 조항이나 조건은 완전히 유효하고 구속력이 있는 상태로 남아 있는 본 약관의 다른 조항의 유효성에 영향을 미치지 않으며 최대 범위."
    },
    Thefailureof: {
      message: "의 실패"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "본 약관의 권리 또는 조항을 주장함에 있어 권리 포기를 구성하지 않으며 후자는 법적 기한 내에 정기적으로 권리를 행사할 수 있습니다."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "모든 자료, 특허, 상표, 등록, 도메인, 이름, 특권, 창작물, 이미지 및 관련된 모든 권리"
    },
    anddevelopedby: {
      message: "에 의해 개발"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", 의 독점적이고 독점적인 자산이며 앞으로도 계속 유지될 것입니다."
    },
    agreeingwiththe: {
      message: ", 에 동의"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "어떤 식으로든 여기에 명시된 권리에 해를 끼치는 행위나 사실을 수행하거나 이에 대한 권리나 특권을 주장하지 않습니다."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "당사 웹사이트에 수정된 버전을 게시함으로써 언제든지 이 도구를 변경할 수 있습니다. 이러한 이유로 항상 당사 사이트의 이 섹션을 방문하여 주기적으로 읽을 것을 강력히 권장합니다. 그러나 좋은 관계에 기여하기 위해 이러한 변경 사항을 알리는 이메일도 보내드립니다."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "이 도구는 다음 사이의 완전한 이해를 구성합니다."
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "그리고 브라질 법률의 적용을 받으며 상 레오폴도/RS 시의 관할권이 이 문서에서 발생하는 문제를 해결할 수 있는 유일한 관할 법원으로 선출되며, 특권이 있을지라도 다른 관할권은 명시적으로 포기합니다."
    },
    recoverPassword: {
      message: "비밀번호 복구"
    },
    receiveNewPassword: {
      message: "이메일로 새 비밀번호를 받게 되며 스팸 폴더도 확인하십시오."
    },
    dontWorry: {
      message: "걱정하지 마세요. 되찾을 수 있도록 도와드리겠습니다. 로그인 이메일 제공"
    },
    enterEmail: {
      message: "이메일 입력"
    },
    notBeSent: {
      message: "이메일을 보낼 수 없습니다. 다시 시도해 주세요."
    },
    pleasureToHaveYou: {
      message: "우리와 함께 하게 되어 기쁩니다!"
    },
    beforeStarting: {
      message: "제품을 포함하기 시작하고 메뉴가 구체화되는 것을 보기 전에 일정 금액의 유지 관리 계획에 가입해야 합니다."
    },
    BRL99: {
      message: "BRL 99.90"
    },
    month: {
      message: "한 달."
    },
    includesFacilities: {
      message: "다음과 같은 시설이 포함되어 있습니다."
    },
    menuWithPhotos: {
      message: "사진이 있는 전체 메뉴;"
    },
    priceUpdate: {
      message: "실시간 가격 업데이트;"
    },
    activateDeactivate: {
      message: "제품을 빠르게 활성화 및 비활성화합니다."
    },
    categorizeFilteReorder: {
      message: "제품을 분류, 필터링 및 재정렬합니다."
    },
    newFeatures: {
      message: "매주 새로운 기능이 추가됩니다!"
    },
    thePlan: {
      message: "계획에 서명하다"
    },
    company: {
      message: "申込日"
    },
    fantasyName: {
      message: "판타지 이름"
    },
    numberAccesses: {
      message: "액세스 수"
    },
    entryDate: {
      message: "입장 날짜"
    },
    wantLeave:{
      message: "여기까지 왔으니 떠나시겠습니까?"
    },
    cancelYourSubscription:{
      message: "MyTripMenu 플랜 구독을 취소하려고 해서 죄송합니다."
    },
    beforeConfirming:{
      message: "확인하기 전에 퇴사하면 고객이 더 이상 시설 메뉴에 액세스할 수 없으며 플랜에 다시 가입할 때까지 URL 및 QRCode에 액세스할 수 없음을 알려드립니다."
    },
    goAhead:{
      message: "자신있으면 가세요..."
    },
    unsubscribePlan:{
      message: "계획에서 탈퇴하다"
    },
    dontCancel:{
      message: "더 이상 취소하고 싶지 않습니다"
    },
    noChange:{
      message: "변경 없음"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, Limited Company, No. 34.177.633/0001-91, 본사는 Rua Felipe Dos Santos, nº 77, 1203 torre A, Padre Reus 이웃, São Leopoldo/RS 시, CEP: 93.020-180."
    },
    Delivery: {
      message: "배달"
    },
    withdrawal: {
      message: "철수"
    },
    Pickup: {
      message: "그 자리에서 픽업"
    },
    Catalog: {
      message: "목록"
    },
    Enteremail: {
      message: "이메일을 입력하세요"
    },
    emailinvalid: {
      message: "이 이메일은 유효하지 않습니다"
    },
    Typepassword: {
      message: "비밀번호를 입력하세요"
    },
    least6digits: {
      message: "이 필드는 6자리 이상이어야 합니다."
    },
     Cashpayment: {
      message: "현금결제수단 수정 불가"
    },
    Checkpayment: {
      message: "수표 결제 수단을 수정할 수 없습니다."
    },
    save: {
      message: "저장하려면"
    },
    logIn: {
      message: "로그인"
    },
    logged: {
      message: "당신은 로그인했습니다"
    },
    enterAShortEasilyIdentifiableName: {
      message: "짧고 쉽게 식별할 수 있는 이름을 입력하세요."
    },
    highlightssubcategoryCannotBeCopied: {
      message: "하이라이트 하위 카테고리는 복사할 수 없습니다."
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "카드를 로드할 수 없습니다."
    },
    invalidUser: {
      message: "유효하지 않은 사용자"
    },
    invalidPassword: {
      message: "유효하지 않은 비밀번호"
    },
    inactiveUser: {
      message: "비활성 사용자"
    }
  }
}
