import React, { useState } from 'react';
import ResetPasswordSuccess from './reset-password-success/ResetPasswordSuccess';
import ResetPassword from './reset-password/ResetPassword';
import {
  RecoveryPasswordContent, RecoveryPasswordLogoMyTripMenu,
  RecoveryPasswordOverlay, RecoveryPasswordWrapper,
} from './Styles';

const RecoveryPassword = () => {
  const [viewState, setViewState] = useState('RESET');

  const renderView = () => {
    switch (viewState) {
      case 'SUCCESS':
        return (<ResetPasswordSuccess />);
      default:
        return (<ResetPassword onRecovery={() => setViewState('SUCCESS')} />);
    }
  };

  return (
    <RecoveryPasswordWrapper>
      <RecoveryPasswordOverlay />
      <RecoveryPasswordLogoMyTripMenu />
      <RecoveryPasswordContent>
        {renderView()}
      </RecoveryPasswordContent>
    </RecoveryPasswordWrapper>
  );
};

export default RecoveryPassword;
