import styled from 'styled-components';
import { Description12px300Light, Description12px600Semibold, Paragraph16px300Light } from '../../../../../../styles/style-guide';

const CreditCardListContent = styled.div`
  margin-top: 25px;
`;

const CreditCardListParagraph = styled.div`
  ${Paragraph16px300Light};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`}
`;

const CreditCardListFlagIcon = styled.label`
  position: relative;
  top: 5px;
  margin-right: 8px;
`;

const CreditCardListCardNumberText = styled.p`
  ${Paragraph16px300Light};
  margin-top: 8px;
  margin-bottom: 15px;
`;

const CreditCardListLink = styled.a`
  ${Description12px600Semibold}
  color: ${({ theme }) => theme.colors.mainColor};
  cursor: pointer;
`;

const CreditCardListSmallParagraph = styled.p`
  ${Description12px300Light}
  margin-bottom: 30px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`}
`;

const CreditCardButtonContent = styled.div`
  margin-left: 20px;
`;

export {
  CreditCardListContent,
  CreditCardListParagraph,
  CreditCardListFlagIcon,
  CreditCardListLink,
  CreditCardListSmallParagraph,
  CreditCardButtonContent,
  CreditCardListCardNumberText,
};
