import styled from "styled-components";

const OperationContainer = styled.div`
  /* width: 100px; */
  margin-left: 30px;
  margin-right: 50px;
  /* margin-top: 30px; */
`;

const OperationLine = styled.div`
  border-top: 1px;
  border-color: #cccccc;
  border-width: 2px;
  border-top-style: solid;
  margin-right: auto;
  margin-left: auto;
  width: calc(80vw - 100px);
  margin-top: 40px;
`;

const OperationContext = styled.div`
  display: flex;
`;

const OperationCheckBox = styled.div`
  margin-right: 110px;
`;

const OperationLabel = styled.h4`
  margin-bottom: 30px;
  margin-top: 30px;
  color: #000000;
`;

export {
  OperationContainer,
  OperationContext,
  OperationCheckBox,
  OperationLabel,
  OperationLine,
};
