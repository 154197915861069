import React from 'react';
import { LoaderContainer, LoaderCircle } from './Styles';

const Loader = () => (
  <LoaderContainer>
    <LoaderCircle />
  </LoaderContainer>
);

export default Loader;
