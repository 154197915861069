import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import { uniqBy } from "lodash";
import InputSearch from "../../../../../components/inputs/mui-input-search/input-search";

import TableDnD from "../../../../../components/table/TableDnD";

import { ContainerFilter, ListProductsTableWrapper } from "./Styles";
import User from "../../../../../utils/user";
import { removeCurrencyMask } from "../../../../../utils/masks";
import TranslatorFunction from '../../../../../components/I18n/Translator';

const ListProducts = ({
  products,
  categories,
  subcategories,
  handleOnLoad,
  putProductHighlight,
  putProductStatus,
  postProduct,
  putProduct,
  isLoading,
}) => {
  const language = TranslatorFunction({ path: "language.message" });
  const noOptions = TranslatorFunction({ path: "noOptions.message" });
  const yes = TranslatorFunction({ path: "yes.message" });
  const no = TranslatorFunction({ path: "no.message" });
  const image = TranslatorFunction({ path: "image.message" });
  const nameUpper = TranslatorFunction({ path: "nameUpper.message" });
  const valueUpper = TranslatorFunction({ path: "valueUpper.message" });
  const categoriesUpper = TranslatorFunction({ path: "categoriesUpper.message" });
  const search = TranslatorFunction({ path: "search.message" });
  const subcategoriesUpper = TranslatorFunction({ path: "subcategoriesUpper.message" });
  const statuss = TranslatorFunction({ path: "status.message" });
  const category = TranslatorFunction({ path: "category.message" });
  const subcategory = TranslatorFunction({ path: "subcategory.message" });
  const listActiveOnly = TranslatorFunction({ path: "listActiveOnly.message" });
  const action = TranslatorFunction({ path: "action.message" });
  const [data, setData] = useState(products);
  const [isEditable, setIsEditable] = useState([]);
  const [searchs, setSearchs] = useState({
    product: null,
    category: null,
    subcategory: null,
    active: no,
  });

  const history = useHistory();
  const colKeyAligns = { value: "right", name: "left" };
  const colKeysMaxWidth = { name: "250px" };
  const colTitleKeysAlign = [
    "center",
    "left",
    "left",
    "left",
    "left",
    "center",
  ];
  const colKeysCurrency = ["value"];
  const productKeys = ["name", "value"];
  const listTitles = [
    image,
    nameUpper,
    valueUpper,
    categoriesUpper,
    subcategoriesUpper,
    statuss,
    action,
  ];

  const params = new URLSearchParams(window.location.search);
  const highLightSubCat = User.getHighLightSubCat();

  const handleEditClick = (selectedProduct) => {
    history.push("/home/cadastro-itens/produtos/novo", {
      product: selectedProduct,
    });
  };

  const handleCopyClick = (selectedProduct) => {
    const newForm = { ...selectedProduct };
    if (newForm && newForm.subcategories && newForm.subcategories.length > 0) {
      newForm.subcategories = newForm.subcategories.map(
        (subCategory) => subCategory._id
      );
    }

    const operationMapActives = newForm.enableIn.map((x) => x.idEnableIn);
    newForm.enableIn = operationMapActives.toString();

    if (newForm.complementGroup && newForm.complementGroup.length > 0) {
      const complementGroupString = JSON.stringify(newForm.complementGroup);
      newForm.complementGroup = complementGroupString;
    } else {
      newForm.complementGroup = "";
    }

    postProduct(newForm);
  };

  const handleOnClickHighlight = (product) => {
    handleOnLoad(true);
    putProductHighlight(product);
  };

  const handleStarShow = (product) =>
    product.subcategories.filter(
      (subcategory) => subcategory._id === highLightSubCat
    ).length > 0;

  const handleOnChangeActiveToggle = (selectedProduct) => {
    const productIndex = data.findIndex(
      (cat) => cat._id === selectedProduct._id
    );

    setData((oldData) => {
      const newData = [...oldData];
      newData[productIndex].active = !newData[productIndex].active;
      return newData;
    });

    putProductStatus(data[productIndex]);
  };

  const optionsProducts = useMemo(() => uniqBy(products, "name"), [products]);

  useEffect(() => {
    setData(products);
    const editable = products.map(() => false);
    setIsEditable(editable);

    const searchProduct = params.get("product");
    const searchCategory = params.get("category");
    const searchSubcategory = params.get("subcategory");
    const searchActive = params.get("active");

    setSearchs({
      category: searchCategory,
      product: searchProduct,
      subcategory: searchSubcategory,
      active: searchActive ?? no,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const filterProductByName = (product) => {
    if (!searchs.product) return true;
    return product.name
      .toLocaleLowerCase()
      .includes(searchs.product.toLocaleLowerCase());
  };
  const filterProductBySubcategory = (product) => {
    if (!searchs.subcategory) return true;
    if (!!product?.subcategories[0].translatedName[0]) {
      return product?.subcategories?.some(
        (subcat) => { return subcat.translatedName.length  && subcat.translatedName[0].name[0][language] !== undefined> 0 ? subcat.translatedName[0].name[0][language] === searchs.subcategory : false }
      );
    }
    return product?.subcategories?.some(
      (subcat) => subcat.name === searchs.subcategory
    );
  };
  const filterProductByCategory = (product) => {
    if (!searchs.category) return true;
    if (!!product?.subcategories[0].category.translatedName[0]) {

      return product?.subcategories?.some(
        (subcat) => {
          return (subcat.category.translatedName[0] && subcat.category.translatedName[0].name[0][language] !== undefined ? subcat.category.translatedName[0].name[0][language] : subcat.category.name) === searchs.category
        }
      );
    }
    return product?.subcategories?.some(
      (subcat) => subcat.category.name === searchs.category
    );
  };

  const filterProductByActive = (product) => {
    const active = searchs.active === yes;
    if (!active) return true;
    return product.active === active;
  };

  const filteredProducts = useMemo(
    () =>
      data
        ?.filter(filterProductByName)
        .filter(filterProductBySubcategory)
        .filter(filterProductByCategory)
        .filter(filterProductByActive),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, data]
  );

  const handleClickInLine = (index) => {
    const editables = [...isEditable];
    editables[index] = true;
    setIsEditable(editables);
  };

  const handleOnBlurInLine = async (value, index, productId) => {
    const editables = [...isEditable];
    editables[index] = false;
    setIsEditable(editables);

    const findedIndexProduct = data.findIndex((prod) => prod._id === productId);
    const products = [...data];

    products[findedIndexProduct].value = parseFloat(removeCurrencyMask(value));

    setData(products);

    putProduct(data[findedIndexProduct]);
  };

  const filterSubCategoriesByCategories = (subcategory) => {
    let sub = !!subcategory.category.translatedName[0]  && subcategory.category.translatedName[0].name[0][language] !==undefined? subcategory.category.translatedName[0].name[0][language] : subcategory.category.name;
    return searchs.category === sub;
  }


  const filteredSubcategories = useMemo(
    () => subcategories.filter(filterSubCategoriesByCategories),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, subcategories]
  );

  const handleOnBlurSearch = (event) => {
    const { id } = event?.target;

    if (params.get(id)) {
      params.delete(id);
    }

    searchs[id] ? params.append(id, searchs[id]) : params.delete(id);
    const url = params.toString().includes("tab=products")
      ? params.toString()
      : `?tab=products&${params.toString()}`;

    history.push({ search: url });
  };

  return (
    <ListProductsTableWrapper>
      <ContainerFilter>
        {!isLoading && (
          <>
            <MuiAutoComplete
              noOptionsText={noOptions}
              id="product"
              freeSolo
              name="product"
              options={optionsProducts.map((prod) => prod.name)}
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  label={search}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onInputChange={(e, value) => {
                setSearchs((state) => ({
                  ...state,
                  product: value,
                }));
              }}
              onSelect={(e) => {
                setSearchs((state) => ({
                  ...state,
                  product: e.target.value,
                }));
                handleOnBlurSearch(e);
              }}
              defaultValue={searchs.product}
            />

            <MuiAutoComplete
              id="category"
              noOptionsText={noOptions}
              freeSolo
              options={categories.map((cat) => !!cat.translatedName[0]  &&  cat.translatedName[0].name[0][language] !== undefined ? cat.translatedName[0].name[0][language] : cat.name)}
              clearOnEscape
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  label={category}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onInputChange={(e, value) => {
                setSearchs((state) => ({
                  ...state,
                  category: value,
                }));
              }}
              onSelect={(e) => {
                setSearchs((state) => ({
                  ...state,
                  category: e.target.value,
                }));
                handleOnBlurSearch(e);
              }}
              defaultValue={searchs.category}
            />
            <MuiAutoComplete
              id="subcategory"
              noOptionsText={noOptions}
              name="subcategory"
              options={filteredSubcategories.map((sub) => !!sub.translatedName[0] && sub.translatedName[0].name[0][language]  !== undefined ? sub.translatedName[0].name[0][language] : sub.name)}
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  label={subcategory}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onInputChange={(e, value) => {
                setSearchs((state) => ({
                  ...state,
                  subcategory: value,
                }));
              }}
              onSelect={(e) => {
                setSearchs((state) => ({
                  ...state,
                  subcategory: e.target.value,
                }));
                handleOnBlurSearch(e);
              }}
              defaultValue={searchs.subcategory}
            />

            <MuiAutoComplete
              id="active"
              noOptionsText={noOptions}
              name="active"
              options={[yes, no]}
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  label={listActiveOnly}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onInputChange={(e, value) => {
                setSearchs((state) => ({
                  ...state,
                  active: value,
                }));
              }}
              onSelect={(e) => {
                setSearchs((state) => ({
                  ...state,
                  active: e.target.value,
                }));
                handleOnBlurSearch(e);
              }}
              defaultValue={searchs.active}
            />
          </>
        )}
      </ContainerFilter>
      <TableDnD
        data={filteredProducts}
        setData={setData}
        titles={listTitles}
        colKeysAlign={colKeyAligns}
        colKeysCurrency={colKeysCurrency}
        colTitleKeysAlign={colTitleKeysAlign}
        colKeysMaxWidth={colKeysMaxWidth}
        keys={productKeys}
        objectKey="_id"
        withImage
        withStatus
        withCategories
        withSubCategories
        withDup
        statusKey="active"
        imageKey="imageURL"
        categoriesKey="categories"
        subCategoriesKey="subcategories"
        onClickEdit={handleEditClick}
        onClickCopy={handleCopyClick}
        onClickToogle={handleOnChangeActiveToggle}
        onClickStar={handleOnClickHighlight}
        handleStarShow={handleStarShow}
        isProduct
        isEditableProduct={isEditable}
        clickOnLineProduct={handleClickInLine}
        handleOnBlurProduct={handleOnBlurInLine}
      />
    </ListProductsTableWrapper>
  );
};
ListProducts.defaultProps = {
  products: [],
  categories: [],
  changeList: () => { },
};

ListProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
  changeList: PropTypes.func,
};

export default ListProducts;
