const CepOptions = {
  numericOnly: true,
  blocks: [5, 3],
  delimiters: ['-'],
};

const PhoneNumberOptions = {
  numericOnly: true,
  blocks: [0, 2, 0, 5, 4],
  delimiters: ['(', ')', ' '],
};

const CvvOptions = {
  numericOnly: true,
  blocks: [4],
  delimiter: '',
};

const ExpDateOptions = {
  date: true,
  datePattern: ['m', 'y'],
};

const CnpjOptions = {
  numericOnly: true,
  blocks: [2, 3, 3, 4, 2],
  delimiters: ['.', '.', '/', '-'],
};

const defaultOptions = {
  blocks: [99999],
  delimiter: '',
};

const defaultOptionsUpperCase = {
  blocks: [99999],
  delimiter: '',
  uppercase: true,
};

const NumericOptions = {
  numericOnly: true,
  blocks: [99999],
  delimiter: '',
};

const CreditCardOptions = {
  creditCard: true,
};

const CpfOptions = {
  numericOnly: true,
  blocks: [3, 3, 3, 2],
  delimiters: ['.', '.', '-'],
};
export {
  CnpjOptions,
  CepOptions,
  PhoneNumberOptions,
  NumericOptions,
  defaultOptions,
  defaultOptionsUpperCase,
  CreditCardOptions,
  CvvOptions,
  CpfOptions,
  ExpDateOptions,
};
