import React, { useState, useEffect, useMemo } from "react";

import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import InputSearch from "../../../../../components/inputs/mui-input-search/input-search";
import PropTypes from "prop-types";
import TableDnD from "../../../../../components/table/TableDnD";

import { ContainerFilter, ListSubCategoriesTableWrapper } from "./Styles";
import {
  orderAlphabetical,
  orderByStatus,
} from "../../../../../utils/order-functions";
import user from "../../../../../utils/user";
import TranslatorFunction from '../../../../../components/I18n/Translator';

const ListSubCategories = ({
  subCategories,
  onClickEditSubCategory,
  onClickCopySubCategory,
  categories,
  putSubCategory,
}) => {
  const language = TranslatorFunction({ path: 'language.message' });
  const image = TranslatorFunction({ path: 'image.message' });
  const nameUpper = TranslatorFunction({ path: 'nameUpper.message' });
  const category = TranslatorFunction({ path: 'category.message' });
  const statuss = TranslatorFunction({ path: 'status.message' });
  const action = TranslatorFunction({ path: 'action.message' });
  const highlightsSubcategoryCannotBeEdited = TranslatorFunction({ path: 'highlightsSubcategoryCannotBeEdited.message' });
  const sortFromAZ = TranslatorFunction({ path: 'sortFromAZ.message' });
  const sortFromZA = TranslatorFunction({ path: 'sortFromZA.message' });
  const sortByActive = TranslatorFunction({ path: 'sortByActive.message' });
  const sortByInactive = TranslatorFunction({ path: 'sortByInactive.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const listActiveOnly = TranslatorFunction({ path: 'listActiveOnly.message' });
  const yes = TranslatorFunction({ path: "yes.message" });
  const no = TranslatorFunction({ path: "no.message" });

  const [data, setData] = useState(subCategories);
  const [searchs, setSearchs] = useState({
    name: "",
    category: "",
    active: no,
  });
  const colKeyAligns = { name: "left" };
  const colTitleKeysAlign = ["left", "left", "left", "left"];
  const subCategoryKeys = ["name"];
  const listTitles = [image, nameUpper, category, statuss, action];
  const disabledTableKeys = [
    {
      key: user.getHighLightSubCat(),
      tooltip: highlightsSubcategoryCannotBeEdited,
      action: "edit",
    },
  ];
  const orderTitles = [
    {
      colKey: "name",
      titleKey: "NOME",
      titleKeyIndex: 1,
      tooltipUpText: sortFromAZ,
      tooltipDownText: sortFromZA,
    },
    {
      colKey: "name",
      titleKey: "STATUS",
      titleKeyIndex: 2,
      tooltipUpText: sortByActive,
      tooltipDownText: sortByInactive,
    },
  ];



  const orderAlphabeticalData = (order) => {
    setData((oldData) => [...orderAlphabetical(oldData, "name", order)]);
  };

  const orderByActiveStatus = (status) => {
    setData((oldData) => [...orderByStatus(oldData, "active", status)]);
  };

  const handleOnClickOrderStatus = (arrow) => {
    if (arrow === "up") {
      orderByActiveStatus(true);
      return;
    }
    orderByActiveStatus(false);
  };

  const handleOnClickOrderName = (arrow) => {
    if (arrow === "up") {
      orderAlphabeticalData("ASC");
      return;
    }
    orderAlphabeticalData("DSC");
  };

  const handleOnClickTableOrder = (title, arrow) =>
    title === "NOME"
      ? handleOnClickOrderName(arrow)
      : handleOnClickOrderStatus(arrow);

  const handleOnChangeActiveToggle = (selectedSubCategory) => {
    const subCategoryIndex = data.findIndex(
      (cat) => cat._id === selectedSubCategory._id
    );
    setData((oldData) => {
      const newData = [...oldData];
      newData[subCategoryIndex].active = !newData[subCategoryIndex].active;
      return newData;
    });
    putSubCategory(data[subCategoryIndex]);
  };

  const filterSubcategoriesByName = (subcategory) => {
    if (!searchs.name) return true;
    return (subcategory.translatedName[0]?.name && subcategory?.translatedName[0]?.name[0][language]!== undefined? subcategory?.translatedName[0]?.name[0][language] : subcategory?.name) === searchs.name
  };

  const filterSubcategoriesByCategory = (subcategory) => {
    if (!searchs.category) return true;
    return (Array.isArray(subcategory.category.translatedName) && subcategory.category.translatedName.length > 0 && subcategory.category.translatedName[0].name[0][language] !==undefined ? subcategory.category.translatedName[0].name[0][language] : subcategory.category.name) === searchs.category;
  };

  const filterSubcategoriesByActive = (subcategory) => {
    const active = searchs.active === yes;
    if (!active) return true;
    return subcategory.active === active;
  };

  const filteredSubcategories = useMemo(
    () =>
      data
        ?.filter(filterSubcategoriesByName)
        .filter(filterSubcategoriesByCategory)
        .filter(filterSubcategoriesByActive),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, data]
  );

  useEffect(() => {
    setData(subCategories);
  }, [subCategories]);

  return (
    <ListSubCategoriesTableWrapper>
      <ContainerFilter>
        {data && (
          <>
            <MuiAutoComplete
              id="search"
              options={data}
              getOptionLabel={(option) => {
                return (option?.translatedName[0]?.name && option?.translatedName[0]?.name[0][language]  !== undefined? option?.translatedName[0]?.name[0][language] : option?.name) || ""
              }}
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  label={search}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onChange={(e) => {
                setSearchs({ ...searchs, name: e.target.value });
              }}
              onSelect={(e) => {
                setSearchs({ ...searchs, name: e.target.value });
              }}
            />
            <MuiAutoComplete
              options={categories}
              getOptionLabel={(option) => {
                return (Array.isArray(option?.translatedName) && option?.translatedName.length > 0  && option?.translatedName[0]?.name[0][language]  !== undefined?  option?.translatedName[0]?.name[0][language] : option?.name) || ""
              }}
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  label={category}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onChange={(e) => {
                setSearchs({
                  ...searchs,
                  category: e.target.value,
                });
              }}
              onSelect={(e) => {
                setSearchs({
                  ...searchs,
                  category: e.target.value,
                });
              }}
            />
            <MuiAutoComplete
              options={[yes, no]}
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  label={listActiveOnly}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              defaultValue={searchs.active}
              onInputChange={(e, value) => {
                setSearchs((state) => ({
                  ...state,
                  active: value,
                }));
              }}
              onSelect={(e) => {
                setSearchs((state) => ({
                  ...state,
                  active: e.target.value,
                }));
              }}
            />
          </>
        )}
      </ContainerFilter>
      <TableDnD
        data={filteredSubcategories}
        setData={setData}
        titles={listTitles}
        colKeysAlign={colKeyAligns}
        colTitleKeysAlign={colTitleKeysAlign}
        keys={subCategoryKeys}
        withImage
        withDup
        withStatus
        withCategories
        categoriesKey="category"
        withStarCategory={false}
        statusKey="active"
        imageKey="imageURL"
        onClickEdit={onClickEditSubCategory}
        onClickCopy={onClickCopySubCategory}
        onClickToogle={handleOnChangeActiveToggle}
        orderTitles={orderTitles}
        onClickOrder={handleOnClickTableOrder}
        disabledKeys={disabledTableKeys}
        objectKey="_id"
        isSubCategory
      />
    </ListSubCategoriesTableWrapper>
  );
};

ListSubCategories.defaultProps = {
  Subcategories: [],
  onClickEditSubCategory: () => { },
  onClickCopySubCategory: () => { },
};

ListSubCategories.propTypes = {
  Subcategories: PropTypes.arrayOf(PropTypes.object),
  onClickEditSubCategory: PropTypes.func,
  onClickCopySubCategory: PropTypes.func,
};

export default ListSubCategories;
