import styled from 'styled-components';
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui';

const CustomLoader = styled(BlockUi)`
  height: 100%;
  width: 100%;
  .loading-indicator {
    .loading-bullet {
      color: ${({ theme }) => ((theme && theme.colors) ? theme.colors.mainColor : '#0483F5')};
    }
  }
`;

export default CustomLoader;
