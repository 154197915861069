import React, { useEffect, useState } from "react";
import Table from "../../../../../components/table/Table";
import { ListFormPaymentTableWrapper } from "./Styles";
import PropTypes from "prop-types";
import restFormPayment from "../../../../../api/formpayment/rest-formpayment";
import TranslatorFunction from "../../../../../components/I18n/Translator"

const ListFormPayment = ({ formPayments, onClickEditFormPayment }) => {
  const paymentMethod = TranslatorFunction({ path: 'paymentMethod3.message' });
  const status = TranslatorFunction({ path: 'status.message' });
  const action = TranslatorFunction({ path: 'action.message' });
  const Cashpayment = TranslatorFunction({ path: 'Cashpayment.message' });
  const Checkpayment = TranslatorFunction({ path: 'Checkpayment.message' });
  const [data, setData] = useState(formPayments);
  const colKeyAligns = { name: "left" };
  const colTitleKeysAlign = ["left", "center", "center"];
  const formPaymentKeys = ["name"];
  const listTitles = [paymentMethod, status, action];

  const disabledTableKeys =
    data.length === 8
      ? [
          {
            key: data[4]?._id,
            tooltip: Cashpayment,
            action: "edit",
          },
          {
            key: data[3]?._id,
            tooltip: Checkpayment,
            action: "edit",
          },
        ]
      : [
          {
            key: data[3]?._id,
            tooltip: Cashpayment,
            action: "edit",
          },
          {
            key: data[2]?._id,
            tooltip: Checkpayment,
            action: "edit",
          },
        ];
  const putFormPayment = (formPayment) => {
    restFormPayment
      .putFormPayment(formPayment._id, { active: formPayment.active })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  const handleOnChangeActiveToggle = (selectedFormPayment) => {
    const formPaymentIndex = data.findIndex(
      (cat) => cat.name === selectedFormPayment.name
    );
    setData((oldData) => {
      const newData = [...oldData];
      newData[formPaymentIndex].active = !newData[formPaymentIndex].active;
      return newData;
    });
    putFormPayment(data[formPaymentIndex]);
  };

  useEffect(() => {
    setData(formPayments);
  }, [formPayments]);

  return (
    <ListFormPaymentTableWrapper>
      <Table
        data={data}
        setData={setData}
        titles={listTitles}
        colKeysAlign={colKeyAligns}
        keys={formPaymentKeys}
        onClickEdit={onClickEditFormPayment}
        onClickToogle={handleOnChangeActiveToggle}
        colTitleKeysAlign={colTitleKeysAlign}
        statusKey="active"
        withActions
        withStatus
        objectKey="_id"
        disabledKeys={disabledTableKeys}
      />
      <div style={{ height: "100px" }} />
    </ListFormPaymentTableWrapper>
  );
};

ListFormPayment.defaultProps = {
  formPayments: [],
  onClickEditFormPayment: () => {},
};

ListFormPayment.propTypes = {
  formPayments: PropTypes.arrayOf(PropTypes.object),
  onClickEditFormPayment: PropTypes.func,
};

export default ListFormPayment;
