import HttpClient from "../http-client";
import { id } from "../helper-client";

const putMandatoryData = async (mandatoryId, data) => {
  return HttpClient.put(
    `mandatorydata/${mandatoryId}?userId=${id ?? ""}`,
    data
  );
};

const fetchMandatoryData = async () =>
  HttpClient.get(`mandatorydata?userId=${id ?? ""}`);

export default {
  putMandatoryData,
  fetchMandatoryData,
};
