import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Label,
  InputWrapper,
  InputErrorMessage,
  CustomIconError,
  LabelRequired,
  InputSpanCounter,
  LoaderContainer,
  Loader,
  InputCleave,
} from "./Styles";
import generateRandomIdHash from "../../../utils/IdGenerator";
import { defaultOptions } from "../../../utils/input-options";

import { useTranslation } from 'react-i18next';

const InputText = (props) => {
  const {
    label,
    value,
    disabled,
    onChange,
    id,
    errorMessage,
    name,
    error,
    placeholder,
    maxLength,
    maxLengthShow,
    inputType,
    dataTestId,
    readonly,
    options,
    isRequired,
    onBlur,
    isLoading,
    replace
  } = props;

  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(value);
  const [inputMsgError, setInputMsgError] = useState(t(errorMessage).toString());
  const [inputPlaceholder, setInputPlaceHolder] = useState(t(placeholder).toString());
  const [inputId] = useState(id !== "" ? id : generateRandomIdHash());

  const renderMaxLength = () =>
    maxLength !== "" && maxLengthShow === true ? (
      <InputSpanCounter haserror={error.toString()}>
        {inputValue && inputValue !== null
          ? `${inputValue.length}/${maxLength}`
          : 0}
      </InputSpanCounter>
    ) : (
      ""
    );

  const handleOnChange = (event) => {
    replace ? onChange(event.target.name, replace(event.target.value)) : onChange(event.target.name, event.target.value); 
    replace ? setInputValue(replace(event.target.value)) : setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (errorMessage && errorMessage !== null && errorMessage !== "") {
      setInputMsgError(t(errorMessage));
    }
  }, [errorMessage, t]);

  useEffect(() => {
    if (placeholder && placeholder !== null && placeholder !== "") {
      setInputPlaceHolder(t(placeholder));
    }
  }, [placeholder, t]);

  return (
    <>
      <InputWrapper haserror={error.toString()}>
        <CustomIconError haserror={error.toString()} />
        <LoaderContainer haserror={error}>
          <Loader isLoading={isLoading} />
        </LoaderContainer>
        <InputErrorMessage haserror={error.toString()}>
          {inputMsgError}
        </InputErrorMessage>
        <InputCleave
          value={inputValue}
          onChange={handleOnChange}
          id={inputId}
          name={name}
          type={inputType}
          disabled={disabled}
          pattern=".+"
          placeholder={inputPlaceholder}
          required
          haserror={error ? error.toString() : ''}
          formNoValidate
          autoComplete="off"
          maxLength={maxLength}
          data-testid={dataTestId}
          readOnly={readonly}
          options={options}
          onBlur={onBlur}
        />
        <Label htmlFor={inputId}>
          {label}
          {isRequired ? (
            <LabelRequired haserror={error.toString()}> *</LabelRequired>
          ) : null}
        </Label>
        {renderMaxLength()}
      </InputWrapper>
    </>
  );
};

InputText.defaultProps = {
  disabled: false,
  label: "",
  name: "",
  errorMessage: "",
  placeholder: "",
  id: "",
  onChange: () => {},
  value: "",
  error: false,
  maxLength: "",
  maxLengthShow: true,
  inputType: "text",
  dataTestId: "",
  isRequired: false,
  readonly: false,
  options: defaultOptions,
  onBlur: () => {},
  isLoading: false,
};

InputText.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string,
  maxLengthShow: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  dataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
  inputType: PropTypes.oneOf(["text", "password"]),
  readonly: PropTypes.bool,
  options: PropTypes.objectOf(PropTypes.any),
  onBlur: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default InputText;
