/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import restOrder from "../../../api/orders/rest-orders";

import BlockLoader from "../../../components/block-loader/BlockLoader";

import ListOrders from "./list-orders/ListOrders";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Orders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    name: "",
  });
  const [orders, setOrders] = useState();
  const query = useQuery();
  const page = query.get("page");
  const name = query.get("name");

  const handleResponseSuccess = ({ data }) => {
    setOrders(data);
    setIsLoading(false);
  };

  const handleResponseError = () => {
    setIsLoading(false);
  };

  const fetchOrders = () => {
    restOrder
      .fetchOrders(filters.page, filters.name)
      .then(handleResponseSuccess)
      .catch(handleResponseError);
  };

  const onBlur = () => {
    fetchOrders();
  };

  useEffect(() => {
    fetchOrders();
    setFilters({ name: name ?? "", page: page ?? 1 });
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [filters.page]);

  return (
    <BlockLoader blocking={isLoading}>
      <ListOrders
        isLoading={isLoading}
        data={orders}
        setFilters={setFilters}
        filters={filters}
        query={query}
        onBlur={onBlur}
      />
    </BlockLoader>
  );
};

export default Orders;
