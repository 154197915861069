import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EmptyStateImage } from "../../assets/empty-state.svg";
import {
  EmptyScreenWrapper,
  EmptyScreenParagraph,
  EmptyScreenButtonIconAdd,
  EmptyScreenButton,
  EmptyScreenButtonContent,
} from "./Styles";
import TranslatorFunction from "../../components/I18n/Translator"

const EmptyScreen = (props) => {
  const nothingHere = TranslatorFunction({ path: 'nothingHere.message' });
  const { onClickButton } = props;

  const handleOnClickAdd = () => {
    onClickButton();
  };
  return (
    <EmptyScreenWrapper>
      <EmptyStateImage />
      <EmptyScreenParagraph>
        {nothingHere}
      </EmptyScreenParagraph>
      <EmptyScreenButtonContent>
        {onClickButton === {} ? (
          <EmptyScreenButton onClick={handleOnClickAdd}>
            <EmptyScreenButtonIconAdd />
            ADICIONAR
          </EmptyScreenButton>
        ) : (
          ""
        )}
      </EmptyScreenButtonContent>
    </EmptyScreenWrapper>
  );
};

EmptyScreen.defaultProps = {
  onClickButton: () => {},
};

EmptyScreen.propTypes = {
  onClickButton: PropTypes.func,
};

export default EmptyScreen;
