import HttpClient from "../http-client";
import { id } from "../helper-client";

const postCard = async (data) =>
  HttpClient.post(`payment/cards?userId=${id}`, data);

const getCard = async () => HttpClient.get(`payment/cards?userId=${id}`);

export default {
  postCard,
  getCard,
};
