import React from 'react';
import { SignaturesEmptyContent, SignaturesEmptyParagraph, SignaturesEmptyPaymentImage } from './Styles';
import TranslatorFunction from "../../../../../../components/I18n/Translator"

const SignaturesEmpty = () => (
  <SignaturesEmptyContent>
    <SignaturesEmptyPaymentImage />
    <SignaturesEmptyParagraph>
      
      { TranslatorFunction({ path: 'addACreditCardToViewPlanInformationAndConfirmYourSubscription.message' })}
    </SignaturesEmptyParagraph>
  </SignaturesEmptyContent>
);

export default SignaturesEmpty;
