import React from "react";

import ReactPaginate from "react-paginate";

import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

import TranslatorFunction from "../../components/I18n/Translator"

import {
  Container,
  CountResults,
  ContainerInside,
  ContainerPaginate,
} from "./styles";

export default function PaginateBottom({
  count,
  totalPages,
  onChangePage,
  initialPage,
}) {
  const recordsFound = TranslatorFunction({ path: 'recordsFound.message' });
  return (
    <Container>
      <ContainerInside>
        <CountResults>{recordsFound}: {count}</CountResults>
        <ContainerPaginate>
          <ReactPaginate
            previousLabel={<MdNavigateBefore />}
            nextLabel={<MdNavigateNext />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(obj) => onChangePage(obj)}
            containerClassName={"pagination"}
            activeClassName={"active"}
            initialPage={initialPage - 1}
          />
        </ContainerPaginate>
      </ContainerInside>
    </Container>
  );
}
