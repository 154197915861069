import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import restSubCategories from "../../../../api/subcategories/rest-subcategories";
import restCategories from "../../../../api/categories/rest-categories";
import ListSubCategories from "./list-subcategories/ListSubCategories";
import NewSubCategory from "./new-subcategory/NewSubCategory";
import EmptyScreen from "../../../../components/empty-screen/EmptyScreen";
import TranslatorFunction from '../../../../components/I18n/Translator';

const SubCategories = ({
  openNewSubCategory,
  onHideNewSubCategory,
  onLoad,
}) => {
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openNewSubCategoryModal, setOpenNewSubCategoryModal] =
    useState(openNewSubCategory);
  const language = TranslatorFunction({ path: 'language.message' });
  const handleOnClickEditSubCategory = (selectedSubCategory) => {
    setSubCategory(selectedSubCategory);
    setOpenNewSubCategoryModal(true);
  };

  const handleOnClickCopySubCategory = (selectedSubCategory) => {
    const newForm = {
      name: selectedSubCategory.name,
      categoryId: selectedSubCategory.category._id,
      active: selectedSubCategory.active,
      from:language
    };

    postSubCategory(newForm);
  };

  const handleOnLoad = (value) => {
    onLoad(value);
    setIsLoading(value);
  };

  const handleOnClickEmptyScreenButton = () => {
    setOpenNewSubCategoryModal(true);
  };

  const handleResponseSuccess = (response) => {
    setSubCategories(response);
    handleOnLoad(false);
  };

  const handleResponseCategoriesSuccess = (response) => {
    setCategories(response);
    handleOnLoad(false);
  };

  const postSubCategory = (subcategory) => {
    restSubCategories
      .postSubCategory(subcategory)
      .then((res) => fetchSubCategories());
    // .catch((err) => handleResponseError(err, "ERROR"));
  };

  const putSubCategory = (subCategory) => {
    restSubCategories.putSubCategory(subCategory._id, {
      active: subCategory.active,
    });
  };

  const fetchSubCategories = () => {
    handleOnLoad(true);
    restSubCategories
      .fetchSubCategories()
      .then((res) => handleResponseSuccess(res.data.subCategories))
      .catch(() => handleOnLoad(false));
  };
  const fetchCategories = () => {
    handleOnLoad(true);
    restCategories
      .fetchCategories()
      .then((res) => handleResponseCategoriesSuccess(res.data.categories))
      .catch(() => handleOnLoad(false));
  };

  const handleOnModalClose = () => {
    setOpenNewSubCategoryModal(false);
    setSubCategory({});
    onHideNewSubCategory();
    fetchSubCategories();
    fetchCategories();
  };

  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
    return () => {
      setCategories([]);
      setSubCategories([]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOpenNewSubCategoryModal(openNewSubCategory);
  }, [openNewSubCategory]);

  const renderSubCategories = () =>
    !isLoading && subCategories.length === 0 ? (
      <EmptyScreen onClickButton={handleOnClickEmptyScreenButton} />
    ) : (
      <ListSubCategories
        subCategories={subCategories}
        categories={categories}
        onClickEditSubCategory={handleOnClickEditSubCategory}
        onClickCopySubCategory={handleOnClickCopySubCategory}
        putSubCategory={putSubCategory}
      />
    );

  return (
    <>
      {renderSubCategories()}
      <NewSubCategory
        open={openNewSubCategoryModal}
        subCategory={subCategory}
        onClose={handleOnModalClose}
      />
    </>
  );
};

SubCategories.defaultProps = {
  openNewSubCategory: false,
  onHideNewSubCategory: () => { },
  onLoad: () => { },
};

SubCategories.propTypes = {
  onLoad: PropTypes.func,
  openNewSubCategory: PropTypes.bool,
  onHideNewSubCategory: PropTypes.func,
};

export default SubCategories;
