import React from "react";

import InputSearch from "../../../../components/inputs/mui-input-search/input-search";
import PaginateBottom from "../../../../components/paginate-bottom/PaginateBottom";
import TableOrder from "../../../../components/table/table-orders/TableOrders";
import { Container, ContainerFilter, ContainerTable } from "./styles";
import { useHistory } from "react-router-dom";
import user from "../../../../utils/user";
import { formatPriceWithSeparato } from "../../../../utils/form-utils";
import dayjs from "dayjs";
import TranslatorFunction from '../../../../components/I18n/Translator';



const ListOrders = ({
  isLoading,
  data,
  setFilters,
  filters,
  query,
  onBlur,
}) => {
  const search = TranslatorFunction({ path: 'search.message' });
  const headerTitles = [
    TranslatorFunction({ path: "id.message" }),
    TranslatorFunction({ path: "customer.message" }),
    TranslatorFunction({ path: "phoneUpper.message" }),
    TranslatorFunction({ path: "type.message" }),
    TranslatorFunction({ path: "valueUpper.message" }),
    TranslatorFunction({ path: "formOfPayment.message" }),
    TranslatorFunction({ path: "change.message" }),
    TranslatorFunction({ path: "dateTime.message" }),
    TranslatorFunction({ path: "action.message" }),
  ];
  const history = useHistory();

  const onChangeActualPage = (obj) => {
    setFilters({ ...filters, page: parseInt(obj.selected) + 1 });

    if (query.get("page")) {
      query.delete("page");
    }

    query.append("page", parseInt(obj.selected) + 1);

    history.push({ search: query.toString() });
  };

  const onChangeName = (event) => {
    const { value } = event.target;

    setFilters({ ...filters, name: value });

    if (query.get("name")) {
      query.delete("name");
    }

    if (value !== "") query.append("name", value);

    history.push({ search: query.toString() });
  };

  const handleOnClickImp = (orderSelect) => {
    const userFantasyName =
      user.getUser().fantasyName === "null"
        ? ""
        : `${user.getUser().fantasyName}\n\n`;

    const fantasyName = `${userFantasyName}`;

    const textProducts = `${orderSelect.products
      .map((product) => {
        const existComplementGroup = product.complementGroup.length > 0;

        const textComplementGroup = existComplementGroup
          ? `- Complementos:\n${product.complementGroup
            .map((complementGroup) => {
              const existComplement =
                complementGroup.complements.filter(
                  (complement) => complement.quantity > 0
                ).length > 0;

              if (existComplement) {
                return ` - ${complementGroup.name
                  }\n${complementGroup.complements
                    .filter((complement) => complement.quantity > 0)
                    .map(
                      (complement) =>
                        `  - ${complement.quantity}x ${complement.name}${complement.price > 0
                          ? ` (R$ ${formatPriceWithSeparato(
                            complement.price
                          )})`
                          : ""
                        }\n`
                    )
                    .join("")}\n`;
              }
              return "";
            })
            .join("")}\n`
          : "";

        const observation =
          product.observation.length > 0
            ? `Observações:\n${product.observation}\n`
            : "";

        return `Categoria: ${product.productId.subcategories[0].category.name
          }\n${product.productId.name}\nQuantidade: ${product.quantity
          }\nPreço unitário: R$ ${formatPriceWithSeparato(
            product.priceProduct
          )}\nPreço Total: R$ ${formatPriceWithSeparato(
            product.priceProductTotal
          )}\n${observation}${textComplementGroup}\n`;
      })
      .join("")}`;

    let textClient = "";

    textClient += `Nome: ${orderSelect.clientName !== "" ? orderSelect.clientName : "Não Informado"
      }\n`;

    textClient += `CPF: ${orderSelect.cpf !== "" ? orderSelect.cpf : "Não Informado"
      }\n`;

    textClient += `CEP: ${orderSelect.address.cep !== "" ? orderSelect.address.cep : "Não informado"
      }\n`;

    textClient += `Telefone: ${orderSelect.phone !== "" ? orderSelect.phone : "Não informado"
      }\n`;

    const filteredPaymentName = orderSelect.payment.paymentName;

    let textAddress = "";

    if (orderSelect.address) {
      textAddress = `Rua: ${orderSelect.address.street}\nNumero: ${orderSelect.address.street_number}\nComplemento: ${orderSelect.address.street_complement}\nCidade: ${orderSelect.address.city} - Bairro: ${orderSelect.address.neighborhood}`;
    }

    let textPayment = "";
    if (filteredPaymentName.name === "Dinheiro") {
      if (
        orderSelect.payment.payback !== 0 &&
        orderSelect.payment.payback !== null &&
        orderSelect.payment.payback.replace(" ", "") !== "R$" &&
        orderSelect.payment.payback.replace(" ", "") !== ""
      ) {
        textPayment = `Método de Pagamento: ${filteredPaymentName}\nTroco para: R$ ${formatPriceWithSeparato(
          orderSelect.payment.payback
        )}`;
      } else textPayment = `Método de Pagamento: ${filteredPaymentName}\n`;
    }

    if (filteredPaymentName === "Pix") {
      textPayment = `Método de Pagamento: ${filteredPaymentName}`;
    }

    if (
      filteredPaymentName !== "Pix" &&
      filteredPaymentName !== "Dinheiro" &&
      filteredPaymentName !== "Cheque"
    ) {
      textPayment = `Forma de Pagamento: ${filteredPaymentName} (${orderSelect.payment.type || "Não informada"
        })`;
    }

    let textFormatted = `${fantasyName}${dayjs(new Date()).format(
      "DD/MM/YYYY HH:mm"
    )}\n\nOlá, gostaria de fazer um pedido\n\nProdutos:\n${textProducts}Subtotal.: R$ ${formatPriceWithSeparato(
      orderSelect.payment.total
    )}\nEntrega..: ${orderSelect?.address.city !== "Retirar no local"
        ? `R$ ${formatPriceWithSeparato(orderSelect.payment.shippingPrice)}`
        : "Retirar no local"
      }\nTotal.......: R$ ${formatPriceWithSeparato(
        orderSelect.payment.total + orderSelect.payment.shippingPrice
      )}\n\n${textPayment}\n\n
    `;

    if (
      orderSelect.address &&
      orderSelect?.address.city !== "Retirar no local"
    ) {
      textFormatted += `ENTREGA:\nEndereço para entrega:\n${textAddress}\n\n`;
    }

    textFormatted += `${textClient}\n`;

    return <div>{`${textFormatted}`}</div>;
  };

  return (
    !isLoading && (
      <Container>
        <ContainerFilter>
          <InputSearch
            margin="dense"
            label={search}
            variant="outlined"
            value={filters.name}
            onChange={onChangeName}
            onBlur={onBlur}
          />
        </ContainerFilter>
        <ContainerTable>
          <TableOrder
            headerTitles={headerTitles}
            data={data?.orders}
            handleOnClickImp={handleOnClickImp}
          />
        </ContainerTable>
        <PaginateBottom
          count={data?.ordersCount}
          totalPages={data?.totalPages}
          onChangePage={onChangeActualPage}
          initialPage={filters.page}
        />
      </Container>
    )
  );
};

export default ListOrders;
