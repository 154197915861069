import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import restProducts from "../../../../api/products/rest-products";
import restCategories from "../../../../api/categories/rest-categories";
import restSubcategories from "../../../../api/subcategories/rest-subcategories";
import ListProducts from "./list-products/ListProducts";
import EmptyScreen from "../../../../components/empty-screen/EmptyScreen";
import apiErrorHandler from "../../../../utils/api-error-handler";
import { useAPIErrorContext } from "../../../../contexts/APIErrorContext";

const Products = ({ onChange, onLoad }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [canFetchData, setCanFetchData] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const history = useHistory();
  const defaultNewProductRoute = "/home/cadastro-itens/produtos/novo";
  const modalContext = useAPIErrorContext();

  const handleOnLoad = (value) => {
    onLoad(value);
    setIsLoading(value);
  };

  const handleOnErrorClickGoBack = () => {
    modalContext.setOpen(false);
    history.push("/home/cardapio");
  };

  const checkHasProducts = (listOfProducts) => {
    if (listOfProducts.length > 0) {
      onChange({ to: defaultNewProductRoute, enableButton: true });
    }
  };

  const handleFetchProductsSuccess = (response) => {
    const { products: listProducts } = response.data;
    setProducts(listProducts);
    checkHasProducts(listProducts);
    handleOnLoad(false);
  };

  const setApiErrorModal = (error, message) => {
    modalContext.setMessage(message);
    modalContext.setError(apiErrorHandler.handleError(error));
    modalContext.setOnClickGoBackFunc(() => handleOnErrorClickGoBack());
    return modalContext;
  };

  const handleModalOnTryAgain = () => {
    handleOnLoad(true);
    modalContext.setOpen(false);
    restProducts
      .fetchProducts()
      .then(handleFetchProductsSuccess)
      .catch((err) => {
        handleOnLoad(false);
        setApiErrorModal(err, "Ocorreu um erro ao carregar os produtos");
        modalContext.setOnClickTryAgainFunc(() => handleModalOnTryAgain());
        modalContext.setOpen(true);
      });
  };

  const handleFecthProductsError = (err) => {
    handleOnLoad(false);
    setApiErrorModal(err, "Ocorreu um erro ao carregar os produtos");
    modalContext.setOnClickTryAgainFunc(() => handleModalOnTryAgain());
    modalContext.setOpen(true);
  };

  const fetchCategories = () => {
    restCategories
      .fetchCategories()
      .then(({ data }) => setCategories(data.categories))
      .catch((err) => handleFecthProductsError(err));
  };

  const fetchSubcategories = () => {
    restSubcategories
      .fetchSubCategories()
      .then(({ data }) => setSubcategories(data.subCategories))
      .catch((err) => handleFecthProductsError(err));
  };

  const fetchProducts = () => {
    handleOnLoad(true);
    restProducts
      .fetchProducts()
      .then(handleFetchProductsSuccess)
      .catch(handleFecthProductsError);
    setCanFetchData(false);
  };

  const handleMutateProduct = (product) => {
    const oldData = [...products];

    const findedIndexProduct = products.findIndex(
      (prod) => prod._id === product._id
    );
    oldData[findedIndexProduct] = product;
    setProducts(oldData);
    handleOnLoad(false);
  };

  const putProductHighlight = (product) => {
    restProducts
      .putProductHighLight(product._id)
      .then(({ data }) => handleMutateProduct(data))
      .catch((err) => {
        console.log(err);
        handleOnLoad(false);
      });
  };

  const putProductStatus = (product) => {
    restProducts.putProduct(product._id, { active: product.active });
  };

  const putProductValue = (product) => {
    restProducts
      .putProduct(product._id, { value: product.value })
      .then(() => fetchProducts());
  };

  const postProduct = (newForm) => {
    restProducts.postProduct(newForm).then(() => {
      fetchProducts();
    });
  };

  const handleOnClickEmptyScreenButton = () => {
    history.push("/home/cadastro-itens/produtos/novo");
  };

  useEffect(() => {
    if (canFetchData) {
      fetchCategories();
      fetchProducts();
      fetchSubcategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading === false && products.length === 0 ? (
    <EmptyScreen onClickButton={handleOnClickEmptyScreenButton} />
  ) : (
    <ListProducts
      products={products}
      categories={categories}
      subcategories={subcategories}
      handleOnLoad={handleOnLoad}
      putProductHighlight={putProductHighlight}
      putProductStatus={putProductStatus}
      postProduct={postProduct}
      putProduct={putProductValue}
      isLoading={isLoading}
    />
  );
};

Products.defaultProps = {
  onChange: () => {},
  onLoad: () => {},
};

Products.propTypes = {
  onChange: PropTypes.func,
  onLoad: PropTypes.func,
};

export default Products;
