import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Tab from "../../../components/tab/Tab";
import Categories from "./categories/categories";
import SubCategories from "./subcategories/SubCategories";
import Products from "./products/Products";
import ComplementGroup from "./complement-group/complement-group";
import  { Translator } from '../../../components/I18n';

const ItensRegistration = () => {
  const [openModalNewCategory, setOpenModalNewCategory] = useState(false);
  const [openModalNewSubCategory, setOpenModalNewSubCategory] = useState(false);
  const [onLoadTab, setOnLoadTab] = useState(false);
  const [openModalNewComplementGroup, setOpenModalNewComplementGroup] =
    useState(false);
  const history = useHistory();

  const tabsConfig = [
    {
      text: Translator({ path: "categories.message" }),
      path: "categories",
      component: (
        <Categories
          openNewCategory={openModalNewCategory}
          onHideNewCategory={() => setOpenModalNewCategory(false)}
          onLoad={(e) => setOnLoadTab(e)}
        />
      ),
      onClickButton: () => setOpenModalNewCategory(true),
      selectedTabNumber: 0,
    },
    {
      text: Translator({ path: "subcategories.message" }),
      path: "subcategories",
      component: (
        <SubCategories
          openNewSubCategory={openModalNewSubCategory}
          onHideNewSubCategory={() => setOpenModalNewSubCategory(false)}
          onLoad={(e) => setOnLoadTab(e)}
        />
      ),
      onClickButton: () => setOpenModalNewSubCategory(true),
      selectedTabNumber: 1,
    },
    {
      text: Translator({ path: "complementsGroup.message" }),
      path: "add-ons-group",
      component: (
        <ComplementGroup
          openModalNewComplementGroup={openModalNewComplementGroup}
          onHideNewComplementGroup={() => setOpenModalNewComplementGroup(false)}
          onLoad={(e) => setOnLoadTab(e)}
        />
      ),
      onClickButton: () => setOpenModalNewComplementGroup(true),
      selectedTabNumber: 2,
    },
    {
      text: Translator({ path: "products.message" }),
      path: "products",
      component: <Products onLoad={(e) => setOnLoadTab(e)} />,
      onClickButton: () => history.push("/home/cadastro-itens/produtos/novo"),
      selectedTabNumber: 3,
    },
  ];

  return (
    <div>
      <Tab
        buttonText={Translator({ path: "add.message"})}
        isLoadingContent={onLoadTab}
        tabsConfig={tabsConfig}
      />
    </div>
  );
};

export default ItensRegistration;
