import React from "react";
import PropTypes from "prop-types";
import { Container, CheckboxStyle, LabelStyle } from "./Styles";

const Checkbox = (props) => {
  const { value, onChange, children, name, dataTestId, id } = props;

  const hanldeClickContainer = () => {
    onChange({ name, value: !value });
  };

  return (
    <Container
      name={name}
      onClick={hanldeClickContainer}
      data-testid={dataTestId}
      id={id}
    >
      <CheckboxStyle selected={value} />
      <LabelStyle>{children}</LabelStyle>
    </Container>
  );
};

Checkbox.defaultProps = {
  name: "",
  dataTestId: "",
  value: false,
  onChange: () => {},
};

Checkbox.propTypes = {
  name: PropTypes.string,
  dataTestId: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.string.isRequired,
};

export default Checkbox;
