import React, { useState } from "react";

import { TableInput } from "./styles";
import { currencyMask } from "../../../utils/masks";

export default function TableInputs({
  props,
  index,
  isProduct,
  handleOnBlurProduct,
  productId,
  value,
}) {
  const [values, setValues] = useState(value);

  const handleChange = (event) => {
    const { value } = event.target;
    setValues(currencyMask(value));
  };

  return isProduct ? (
    <TableInput
      {...props}
      value={values}
      onBlur={(event) =>
        handleOnBlurProduct(event.target.value, index, productId)
      }
      onChange={(e) => handleChange(e)}
    />
  ) : (
    <TableInput {...props} />
  );
}
