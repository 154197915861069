import formUtils from './form-utils';

const getFormFilledFromUser = (currentForm, userData) => {
  let newForm = { ...currentForm };
  newForm.CEP.value = userData.CEP;
  newForm.CNPJ.value = userData.CNPJ;
  newForm.addressComplement.value = userData.addressComplement;
  newForm.addressNumber.value = userData.addressNumber;
  newForm.street.value = userData.street;
  newForm.city.value = userData.city;
  newForm.companyEmail.value = userData.companyEmail;
  newForm.companyName.value = userData.companyName;
  newForm.fantasyName.value = userData.fantasyName;
  newForm.state.value = userData.state;
  newForm.subDomain.value = userData.subDomain;
  newForm.userEmail.value = userData.userEmail;
  newForm.userFirstName.value = userData.userFirstName || '';
  newForm.userLastName.value = userData.userLastName || '';
  newForm.userPhone.value = userData.userPhone;
  newForm.password.value = 'xxxxxxxx';
  newForm.operation = userData.operation || [];
  newForm = formUtils.updateFormValidationWithValidators(newForm);
  return newForm;
};

const removeCNPJMask = (value) => value.toString().split('.').join('').split('-')
  .join('')
  .split('/')
  .join('');

const removeCEPMask = (value) => value.toString().split('-').join('');

export default {
  getFormFilledFromUser,
  removeCNPJMask,
  removeCEPMask,
};
