import { useLocation } from "react-router-dom";
import { useRef, useEffect, useCallback, useState } from "react";
import debounce from "lodash/debounce";

const useQuery = () => new URLSearchParams(useLocation().search);

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useFunction = (value) => {
  const ref = useRef();
  ref.current = ref.current === undefined ? value || (() => {}) : ref.current;
  return [
    ref.current,
    (callbackFuntion) => {
      ref.current = callbackFuntion;
    },
  ];
};

function useDebounce(callback, delay) {
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay]
  );
  return debouncedFn;
}

function useDeb(func, delay) {
  const timeOut = useRef(null);

  function debouncedFunc(...args) {
    window.clearTimeout(timeOut.current);
    timeOut.current = window.setTimeout(() => {
      func(...args);
    }, delay);
  }

  return debouncedFunc;
}

function usePriorityDeb(func, delay) {
  const timeOut = useRef(null);
  const [p, setP] = useState(0);

  function debouncedFunc(priority, ...args) {
    if (priority < p) return;
    setP(priority);

    window.clearTimeout(timeOut.current);
    timeOut.current = window.setTimeout(() => {
      func(...args);
      setP(0);
    }, delay);
  }

  return debouncedFunc;
}

export {
  useQuery,
  usePrevious,
  useFunction,
  useDebounce,
  useDeb,
  usePriorityDeb,
};
