import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../../components/styled/Forms";
import Button from "../../../../../../components/button/Button";
import {
  Paragraph16px300Light,
  Title24px400Regular,
} from "../../../../../../styles/style-guide";

export const NewNeighborhoodWrapper = styled.div`
  height: 100%;
  padding: 14px;
  border-radius: 30px;
  width: 60vw;
`;

export const NewNeighborhoodTitle = styled.h4`
  ${Title24px400Regular};
  margin-bottom: 22px;
  text-align: center;
  color: #9a9a9a;
`;

export const NewNeighborhoodSubTitle = styled.h4`
  /* margin-bottom: 22px; */
  text-align: center;
  font-weight: 400;
  color: #6a6a6a;
`;

export const NewNeighborhoodInputTitle = styled.label`
  position: absolute;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  top: -7px;
  left: 25px;
  font-size: 11px;
  font-weight: 600;
  color: #ff5533;
`;
export const NewNeighborhoodInput = styled.input`
  border-width: 1px;
  border-radius: 10px;
  border-color: #9a9a9a;
  width: 100%;
  padding-left: 10px;
  /* text-align: center; */
  height: 36px;

  :focus {
    border-radius: 10px;
    outline: 0;
  }
`;

export const NewNeighborhoodInputValor = styled.input`
  border-width: 1px;
  border-radius: 10px;
  border-color: #9a9a9a;
  width: 100%;
  height: 36px;
  padding-left: 10px;
  /* text-align: center; */

  :focus {
    border-radius: 10px;
    outline: 0;
  }
`;

export const NewNeighborhoodSelect = styled.div`
  width: 80%;
`;
export const NewNeighborhoodTableWrapper = styled.div`
  max-height: 300px;
  /* width: 100%; */
  border: 1px solid #aaa;
  border-radius: 15px;
  margin: 10px 26px 10px 26px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
  }
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
    outline: 0px;
  }
`;

export const NewNeighborhoodInputContent = styled.div`
  height: 36px;
  margin: 15px 26px 15px 26px;
  display: flex;
  /* width: 100%; */
  align-items: center;
`;

export const NewNeighborhoodInputContext = styled.div`
  position: relative;
  top: 0px;
  height: 36px;
  margin-right: 15px;
  width: 100%;
`;

export const ContainerFilter = styled.div`
  padding-top: 15px;
  /* width: 100%; */
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0px 26px 16px 26px;
`;

export const NewNeighborhoodCheckBox = styled.div`
  min-width: 100px;
`;

export const NewNeighborhoodGrid = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px;
`;

export const NewNeighborhoodForm = styled.form`
  display: flex;
  /* flex-direction: column; */
  .button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NewNeighborhoodPhotoContent = styled.div`
  margin-top: 40px;
  display: flex;
`;

export const NewNeighborhoodPhotoTextContent = styled.div`
  width: 40%;
  height: 320px;
`;

export const NewNeighborhoodPhotoArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 320px;
`;

export const NewNeighborhoodPhotoAreaContent = styled.div`
  margin-left: 35px;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  max-width: 426px;
`;

export const NewNeighborhoodPhotoParagraph = styled.p`
  margin-top: 16px;
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
`;

export const NewNeighborhoodInputGroupWithToggle = styled(InputGroupWithToggle)`
  margin-bottom: 11px;
`;

export const NewNeighborhoodButtonGoBack = styled(Button)`
  float: right;
`;

export const NewNeighborhoodButtonSave = styled(Button)`
  float: left;
`;

export const NewNeighborhoodFooter = styled.footer`
  bottom: 20px;
  width: 90%;
  margin: 32px auto;
`;
export const NewNeighborhoodInputGroup = styled.div`
  display: flex;
  flex: 0;
`;

export const NewNeighborhoodDivision = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
`;
