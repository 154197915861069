import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import {
  DetailsQrCodeButtonDownload,
  DetailsQrCodeButtonsContent,
  DetailsQrCodeContent,
  DetailsQrCodeParagraph,
  DetailsQrCodeTextContent,
  DetailsQrCodeTitle,
  DetailsQrCodeURL,
  DetailsQrCodeCard,
  DetailsQrCodeSGVContent,
  DetailsQrCodeWrapper,
  DetailsQrCodeContainer,
} from "./Styles";
import { IconDownload } from "../../../../../styles/icons";
import user from "../../../../../utils/user";
import restUser from "../../../../../api/user/rest-user";
import TranslatorFunction from "../../../../../components/I18n/Translator"
const DetailsQrCode = () => {
  const [subDomain, setSubDomain] = useState(user.getUserDomain());
  const downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu = TranslatorFunction({ path: 'downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu.message' });
  const QRCodeOfYourDigitalMenu = TranslatorFunction({ path: 'QRCodeOfYourDigitalMenu.message' });

  const URL = process.env.REACT_APP_URL;
  const userUrl = `${URL}${subDomain}`;

  const renderQRCode = (isSvg) => (
    <DetailsQrCodeWrapper>
      <QRCode
        value={userUrl}
        level="H"
        size={370}
        includeMargin
        renderAs={isSvg ? "svg" : "canvas"}
        id={isSvg ? "qrcode-svg" : "qrcode"}
        name={isSvg ? "qrcode-svg" : "qrcode"}
      />
    </DetailsQrCodeWrapper>
  );

  const handleDownloadPNG = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "my-trip-menu.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDownloadSVG = () => {
    const svgData = document.getElementById("qrcode-svg").outerHTML;
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const svgURL = global.URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgURL;
    downloadLink.download = "my-trip-menu.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleGetUser = async () => {
    try {
      const { data } = await restUser.getUser();
      setSubDomain(data.subDomain);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  return (
    <DetailsQrCodeCard>
      <DetailsQrCodeTextContent>
        <DetailsQrCodeTitle>{QRCodeOfYourDigitalMenu}</DetailsQrCodeTitle>
        <DetailsQrCodeParagraph>
          {downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu}:
        </DetailsQrCodeParagraph>
      </DetailsQrCodeTextContent>
      <DetailsQrCodeContent>
        <DetailsQrCodeContainer>
          {renderQRCode()}
          <DetailsQrCodeSGVContent>
            {renderQRCode(true)}
          </DetailsQrCodeSGVContent>
        </DetailsQrCodeContainer>
        <DetailsQrCodeURL href={userUrl}>{userUrl}</DetailsQrCodeURL>
      </DetailsQrCodeContent>
      <DetailsQrCodeButtonsContent>
        <DetailsQrCodeButtonDownload
          btnType="secondary"
          onClick={handleDownloadPNG}
        >
          <IconDownload />
          PNG
        </DetailsQrCodeButtonDownload>
        <DetailsQrCodeButtonDownload
          btnType="secondary"
          onClick={handleDownloadSVG}
        >
          <IconDownload />
          SVG
        </DetailsQrCodeButtonDownload>
      </DetailsQrCodeButtonsContent>
    </DetailsQrCodeCard>
  );
};

export default DetailsQrCode;
