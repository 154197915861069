import React from "react";

import { TableRow } from "../../Styles";

function TableHead({ children }) {
  return (
    <thead>
      <TableRow>{children}</TableRow>
    </thead>
  );
}

export default TableHead;
