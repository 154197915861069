import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../components/styled/Forms";
import Button from "../../../../../components/button/Button";
import {
  Paragraph16px300Light,
  Title24px400Regular,
} from "../../../../../styles/style-guide";

const NewSchedulesWrapper = styled.div`
  height: 100%;
  padding: 14px;
  border-radius: 30px;
`;

const NewSchedulesTitle = styled.h4`
  ${Title24px400Regular};
  margin-bottom: 22px;
  text-align: center;
  color: #9a9a9a;
`;

const NewSchedulesInputTitle = styled.label`
  position: absolute;
  background: white;
  top: -7px;
  left: 25px;
  font-size: 11px;
  font-weight: 600;
  color: #ff5533;
`;
const NewSchedulesInput = styled.input`
  margin-left: 12px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #9a9a9a;
  width: 78px;
  height: 28px;
  text-align: center;
  :focus {
    border-radius: 10px;
    outline: 0;
  }
`;
const NewSchedulesInputContent = styled.div`
  height: 28px;
  position: relative;
`;

const NewScheduleCheckBox = styled.div`
  width: 120px;
`;

const NewSchedulesForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const NewSchedulesPhotoContent = styled.div`
  margin-top: 40px;
  display: flex;
`;

const NewSchedulesPhotoTextContent = styled.div`
  width: 40%;
  height: 320px;
`;

const NewSchedulesPhotoArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 320px;
`;

const NewSchedulesPhotoAreaContent = styled.div`
  margin-left: 35px;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  max-width: 426px;
`;

const NewSchedulesPhotoParagraph = styled.p`
  margin-top: 16px;
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
`;

const NewSchedulesInputGroupWithToggle = styled(InputGroupWithToggle)`
  margin-bottom: 11px;
  margin-top: 0px;
`;

const NewSchedulesButtonGoBack = styled(Button)`
  float: right;
`;

const NewSchedulesButtonSave = styled(Button)`
  float: left;
`;

const NewSchedulesFooter = styled.footer`
  bottom: 20px;
  width: 90%;
`;
const NewSchedulesInputGroup = styled.div`
  display: flex;
  flex: 0;
`;

export {
  NewSchedulesWrapper,
  NewSchedulesForm,
  NewSchedulesFooter,
  NewSchedulesButtonSave,
  NewSchedulesButtonGoBack,
  NewSchedulesPhotoContent,
  NewSchedulesPhotoTextContent,
  NewSchedulesPhotoParagraph,
  NewSchedulesPhotoArea,
  NewSchedulesPhotoAreaContent,
  NewSchedulesInputGroupWithToggle,
  NewSchedulesInput,
  NewScheduleCheckBox,
  NewSchedulesInputGroup,
  NewSchedulesTitle,
  NewSchedulesInputTitle,
  NewSchedulesInputContent,
};
