import React from "react";
import { Footer, FooterParagraph } from "./Styles";
import { ReactComponent as Signature } from "../../../assets/signature-simple.svg";
import TranslatorFunction from "../../../components/I18n/Translator"

const PageSideMenuFooter = () => (
  
  <Footer>
    <Signature />
    <FooterParagraph>{ TranslatorFunction({ path: 'simpleEasyFastDigital.message' })}</FooterParagraph>
  </Footer>
);

export default PageSideMenuFooter;
