import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalMytm from "../../../../../components/modal-mytm/ModalMytm";
import Neighborhood from "./neighborhood/Neighborhood";
import City from "./city/City";

const EditDeliveryArea = (props) => {
  const { open, onClose, location, cities } = props;
  const [openModal, setOpenModal] = useState(open);
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const renderEditMode = () => {
    const isCity = Object.keys(location).indexOf("city");
    const isNeighborhood = Object.keys(location).indexOf("neighborhood");

    return (
      <>
        {isCity === 1 ? (
          <City
            city={location?.city}
            setOpen={setOpenModal}
            onClose={onClose}
          />
        ) : (
          ""
        )}
        {isNeighborhood === 1 ? (
          <Neighborhood
            neighborhood={location?.neighborhood}
            cities={cities}
            setOpen={setOpenModal}
            onClose={onClose}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  useEffect(() => {
    setOpenModal(open);
    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <ModalMytm open={openModal} isLoading={isLoading}>
        {renderEditMode()}
      </ModalMytm>
    </>
  );
};

EditDeliveryArea.defaultProps = {
  location: {},
  cities: [],
  onClose: () => {},
  open: false,
};

EditDeliveryArea.propTypes = {
  location: PropTypes.object,
  cities: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default EditDeliveryArea;
