import styled from 'styled-components';
import ModalMytm from '../modal-mytm/ModalMytm';

const ModalGenericErrorWrapper = styled(ModalMytm)`
  .modal_mytm {
    width: 736px;
    height: 376px;
    padding: 24px;
  }
`;

const ModalGenericErrorContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ModalGenericErrorButtonsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalGenericErrorImageContent = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
  height: 100%;
`;

const ModalGenericErrorDataContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ModalGenericErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export {
  ModalGenericErrorWrapper,
  ModalGenericErrorContainer,
  ModalGenericErrorContent,
  ModalGenericErrorButtonsContent,
  ModalGenericErrorImageContent,
  ModalGenericErrorDataContent,
};
