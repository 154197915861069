import React from "react";
import styled from "styled-components";
import "date-fns";
import { MuiTextFieldStyled } from "../mui-inputs/Styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import TranslatorFunction from "../../I18n/Translator"
import { ptBR } from 'date-fns/esm/locale'
import { pt } from 'date-fns/esm/locale'
import { enUS } from 'date-fns/esm/locale'
import { es } from 'date-fns/esm/locale'
import { ru } from 'date-fns/esm/locale'
import { de } from 'date-fns/esm/locale'
import { it } from 'date-fns/esm/locale'
import { ja } from 'date-fns/esm/locale'
import { ko } from 'date-fns/esm/locale'
import { zhTW } from 'date-fns/esm/locale'
import { ar } from 'date-fns/esm/locale'

const Container = styled.div``;

function InputDate({
  label,
  onChange,
  value,
  id,
  name,
  onError = () => { },
  minDate = new Date("1900-01-01"),
}) {

  const language = TranslatorFunction({ path: "language.message" });


  const locales = {
    pt: ptBR,
    en: enUS,
    es: es,
    ru: ru,
    de: de,
    "zh-Hant": zhTW,
    ar: ar,
    it: it,
    ja: ja,
    ko: ko,
    "pt-PT": pt
  }


  return (
    <Container>
      <MuiPickersUtilsProvider locale={locales[language]} utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          margin="dense"
          format="dd/MM/yyyy"
          id={id}
          label={label ?? ""}
          onChange={onChange}
          name={name}
          KeyboardButtonProps={{
            "aria-label": label ?? "",
          }}
          value={value}
          TextFieldComponent={MuiTextFieldStyled}
          invalidDateMessage="Data inválida."
          invalidLabel="Formato inválido."
          minDate={minDate}
          minDateMessage="Data inválida"
          onError={onError}
          maxDateMessage="Data inválida"
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
}

export default InputDate;
