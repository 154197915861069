import styled, { css } from "styled-components";
import { Paragraph16px300Light } from "../../../styles/style-guide";
import OrderButtons from "../../order-buttons/OrderButtons";
import imgArrowDown from "../../../assets/icons/icon-arrow-down.svg";

const skewDeg = -16;

const TableFoodImage = styled.div`
  position: relative;
  height: 70px;
  width: 140px;
  border-top-left-radius: 16px;
  transform: skew(${skewDeg}deg);
  overflow: hidden;
  right: -12px;

  &:after {
    content: "";
    position: absolute;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0px;
    left: -24px;
    min-width: 153px;
    width: 100%;
    height: 100%;
    transform: skew(${-skewDeg}deg);
  }
`;

const TableCustom = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

  ::-webkit-scrollbar {
    width: 100px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 0px;
  }
`;

const TableButtonStarContent = styled.div`
  margin-top: 24px;
  margin-left: 16px;
`;

const TableOrderButtons = styled(OrderButtons)`
  float: right;
`;

const colImageCss = css`
  width: ${({ withStar }) => (withStar ? "197px" : "132px")};
  min-width: ${({ withStar }) => (withStar ? "197px" : "132px")};
  padding: 0;

  ${TableButtonStarContent} {
    float: left;
  }

  ${TableFoodImage} {
    float: right;
  }
`;

const TableDrag = styled.div`
  display: flex;
  padding: 15px;
`;

const Empty = styled.div`
  width: 15px;
`;

const TableCol = styled.td`
  flex-direction: column;
  text-align: ${({ textAlign }) => textAlign || "center"};
  vertical-align: middle;
  padding: 0 15px;
  min-width: 150px;
  white-space: pre-wrap;
  ${Paragraph16px300Light};

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  width: ${({ Column }) => (Column === "c-0" ? "45%" : "")};
  width: ${({ Column }) => (Column === "c-1" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-2" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-3" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-4" ? "20%" : "")};
`;

const TableColContentButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableButtonOpenShow = styled.button`
  background-image: url(${imgArrowDown});
  background-color: transparent;
  border: 0px;
  width: 44px;
  height: 35px;
  background-size: 35px 35px;
  fill: #ff5533;
`;

const TableColTitle = styled.th`
  text-align: ${({ textAlign }) => textAlign || "center"};
  padding-left: ${({ textAlign }) => (textAlign === "left" ? "18px" : "0px")};
  padding-right: 15px;
  vertical-align: middle;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textColorDisabled};
  ${({ colImage }) => colImage && colImageCss};

  width: ${({ Column }) => (Column === "c-0" ? "45%" : "")};
  width: ${({ Column }) => (Column === "c-1" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-2" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-3" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-4" ? "25%" : "")};
`;

const TableRow = styled.tr`
  height: 80px;
  /* display: block; */
  background-color: ${({ theme }) => theme.colors.mainColorContrast};

  &:nth-child(even) {
    background: rgba(230, 230, 230, 0.3);
  }

  ${TableCol} {
    height: 80px;
  }

  ${TableCol}, ${TableColTitle} {
    border-right: 1px solid ${({ theme }) => theme.colors.textColorDisabled};
  }

  &:last-of-type(${TableCol}),
  &:last-of-type(${TableColTitle}) {
    border-right: none;
  }
`;

const TableInput = styled.input`
  width: 100%;
  padding-left: 5px;
  border: 1px solid #ff5533;
  border-radius: 5px;
  outline: #ff5533;
  text-align: ${({ textAlign }) => textAlign || "center"};
  ${Paragraph16px300Light};

  background-color: transparent;

  &:nth-child(even) {
    background-color: rgba(230, 230, 230, 0.3);
  }
`;

const DoubleRow = styled.tr`
  height: 0px;
  /* display: block; */
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
`;

const DoubleRowCol = styled(TableCol)`
  min-width: 0px;
  height: 1px;
  height: 1px;
`;

///########Estruturas da Tabela Interna

const InsideTableCol = styled(TableCol)`
  background-color: #f6f6f6;

  border-left: 3px solid #e2e2e2;
  border-right: 3px solid #e2e2e2;
  border-top: 3px solid #e2e2e2;
  border-bottom: 3px solid #e2e2e2;

  width: ${({ Column }) => (Column === "c-0" ? "30%" : "")};
  width: ${({ Column }) => (Column === "c-1" ? "50%" : "")};
  width: ${({ Column }) => (Column === "c-2" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-3" ? "10%" : "")};
`;

const InsideTableColTitle = styled(TableColTitle)`
  background-color: #f6f6f6;

  /* border: 1px solid #ff5533; */
  border-left: 3px solid #e2e2e2;
  border-right: 3px solid #e2e2e2;
  border-top: 3px solid #e2e2e2;
  border-bottom: 3px solid #e2e2e2;

  width: ${({ Column }) => (Column === "c-0" ? "30%" : "")};
  width: ${({ Column }) => (Column === "c-1" ? "50%" : "")};
  width: ${({ Column }) => (Column === "c-2" ? "10%" : "")};
  width: ${({ Column }) => (Column === "c-3" ? "10%" : "")};
`;

export {
  TableColTitle,
  TableRow,
  TableCol,
  TableCustom,
  TableOrderButtons,
  TableInput,
  TableDrag,
  Empty,
  DoubleRow,
  DoubleRowCol,
  InsideTableCol,
  InsideTableColTitle,
  TableColContentButton,
  TableButtonOpenShow,
};
