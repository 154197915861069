import styled from "styled-components";

const ComplementTableWrapper = styled.div`
  top: 40px;
  height: calc(100vh - 155px);
  width: 100%;
  overflow: auto;
`;

export const FilterLine = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerFilter = styled.div`
  padding-top: 5px;
  width: 40%;
  display: grid;
  gap: 2%;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
`;

export const EmptySpace = styled.div`
  width: 30%;
`;

export const ContainerButtons = styled.div`
  padding-top: 6px;
  padding-right: 20px;
  width: 30%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  button {
    height: 50px;
  }

  button:nth-child(2) {
    margin-left: 12px;
  }
`;

export default ComplementTableWrapper;
