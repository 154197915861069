import React from "react";
import { Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "../protected-route/ProtectedRoute";
import MyCompany from "../../pages/domain/my-company/MyCompany";
import NewProducts from "../../pages/domain/itens-registration/products/new-products/NewProducts";
import ItensRegistration from "../../pages/domain/itens-registration/ItensRegistration";
import Parameterization from "../../pages/domain/parameterization/Parameterization";
import Orders from "../../pages/domain/orders/Orders";
import Admin from "../../pages/domain/admim/Admim";
import Storage from "../../utils/storage";
import Coupons from "../../pages/domain/coupons/Coupons";

const DomainRoutes = () => (
  <Switch>
    {Storage.getAdmin() && (
      <ProtectedRoute path="/home/clientes">
        <Admin />
      </ProtectedRoute>
    )}
    <ProtectedRoute path="/home/meu-estabelecimento">
      <MyCompany />
    </ProtectedRoute>
    <ProtectedRoute path="/home/cadastro-itens/produtos/novo">
      <NewProducts />
    </ProtectedRoute>
    <ProtectedRoute path="/home/cadastro-itens">
      <ItensRegistration />
    </ProtectedRoute>
    <ProtectedRoute path="/home/pedidos">
      <Orders />
    </ProtectedRoute>
    <ProtectedRoute path="/home/parametrizacao">
      <Parameterization />
    </ProtectedRoute>
    <ProtectedRoute path="/home/cupom">
      <Coupons />
    </ProtectedRoute>
    <Redirect to="/home/cadastro-itens" />
  </Switch>
);

export default DomainRoutes;
