import React from "react";
import PaginateBottom from "../../../../components/paginate-bottom/PaginateBottom";
import Table from "../../../../components/table/Table";

import { Container, ContainerTable } from "./styles";
import TranslatorFunction from "../../../../components/I18n/Translator"


function ListCoupons({
  data,
  setData,
  putCoupons,
  handleOnClickEdit,
  page,
  onChangePage,
}) {
  const id = TranslatorFunction({ path: 'id.message' });
  const nameUpper = TranslatorFunction({ path: 'nameUpper.message' });
  const type = TranslatorFunction({ path: 'type.message' });
  const validityUpper = TranslatorFunction({ path: 'validityUpper.message' });
  const status = TranslatorFunction({ path: 'status.message' });
  const useAvailable = TranslatorFunction({ path: 'useAvailable.message' });
  const action = TranslatorFunction({ path: 'action.message' });
  const headerTitles = [
    id,
    nameUpper,
    type,
    validityUpper,
    useAvailable,
    status,
    action,
  ];

  const couponsKeys = ["couponCod"];
  const statusKey = "active";

  const handleOnChangeActiveToggle = (selectedCoupon) => {
    const couponIndex = data.coupons.findIndex(
      (coupons) => coupons._id === selectedCoupon._id
    );

    setData((oldData) => {
      const newData = { ...oldData };
      newData.coupons[couponIndex].active =
        !newData.coupons[couponIndex].active;
      return newData;
    });

    putCoupons(selectedCoupon._id, {
      active: data.coupons[couponIndex].active,
    });
  };

  data.coupons.map(coupon => {
    coupon.couponCod = coupon.couponCod.toUpperCase();
    return coupon;
  });

  return (
    <Container>
      <ContainerTable>
        <Table
          withActions
          withStatus
          data={data.coupons}
          titles={headerTitles}
          keys={couponsKeys}
          onClickToogle={handleOnChangeActiveToggle}
          statusKey={statusKey}
          onClickEdit={handleOnClickEdit}
          isCoupon={true}
        />
      </ContainerTable>
      <PaginateBottom
        count={data.couponsCount}
        initialPage={page}
        onChangePage={onChangePage}
        totalPages={data.totalPages}
      />
    </Container>
  );
}

export default ListCoupons;
