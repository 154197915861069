import { css } from 'styled-components';

const SourceSansProFont = css`
  font-family: "Source Sans Pro", sans-serif;
`;

/* CTA-16px-1rem-600semibold-uppercase */
const CTA16px600SemiboldUppercase = css`
  ${() => SourceSansProFont};
  font-style: normal;
  font-weight: 600;
    font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
`;

/* paragraph-16px-1rem-400regular */
const Paragraph16px400Regular = css`
  ${() => SourceSansProFont};
  font-weight: 400;
    font-size: 16px;
  line-height: 120%;
`;
/* paragraph-16px-1rem-300light */
const Paragraph16px300Light = css`
  ${() => SourceSansProFont};
  font-weight: 300;
    font-size: 16px;
  line-height: 100%;
`;

// paragraph-16px-1rem-600medium
const Paragraph16px600Medium = css`
  ${() => SourceSansProFont};
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
`;

/* title-24px-1.5rem-400regular */
const Title24px400Regular = css`
  ${() => SourceSansProFont};
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
`;

// TITLE-24px-1.5rem-700bold-uppercase
const Title24px700BoldUppercase = css`
  ${() => SourceSansProFont};
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
`;

/* SUBTITLE-16px-1rem-400regular-uppercase */
const Subtitle16px400RegularUppercase = css`
  ${() => SourceSansProFont};
  font-weight: 400;
    font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
`;

/* SUBTITLE-16px-1rem-600semibold-uppercase */
const Subtitle16px600SemiboldUppercase = css`
  ${() => SourceSansProFont};
  font-weight: 600;
    font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
`;

/* hint-9px-0.57rem-400regular */
const Hint9px400Regular = css`
  ${() => SourceSansProFont};
  font-weight: 400;
  font-size: 9px;
  line-height: 120%;
`;

/* description-12px-0.75rem-300light */
const Description12px300Light = css`
  ${() => SourceSansProFont};
  font-weight: 300;
    font-size: 16px;
  line-height: 120%;
`;

/* description-12px-0.75rem-400regular */
const Description12px400Regular = css`
  ${() => SourceSansProFont};
  font-weight: 400;
    font-size: 12px;
  line-height: 120%;
`;

/* description-12px-0.75rem-400regular */
const Description12px400RegularArray = {

  "ar": css`
  ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
`,
  "de":css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    `,

  "en": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    `,

  "es": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    `,

  "it": css`
    ${() => SourceSansProFont};
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    `,

  "ja": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    `,

  "ko": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    `,

  "pt": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    `,

  "pt-PT": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    `,

  "ru": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    `,
  "zh-Hant": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;`,
  "fr": css`
    ${() => SourceSansProFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    `
  
  }

    

  ;

/* description-12px-0.75rem-600semibold */
const Description12px600Semibold = css`
  ${() => SourceSansProFont};
  font-weight: 600;
    font-size: 12px;
  line-height: 120%;
`;


const PosistionInactiveButtonLeft = {

  "ar": css`
  top: 10px;
  left: 25px;
`,
  "de":
    css`
    top: 10px;
    left: 20px;
    `,

  "en": css`
  top: 10px;
  left: 15px;
    `,

  "fr": css`
  top: 10px;
  left: 15px;
    `,

  "es": css`
  top: 10px;
  left: 15px;
    `,

  "it": css`
  top: 13px;
  left: 20px;
    `,

  "ja": css`
  top: 13px;
  left: 10px;
    `,

  "ko": css`
  top: 12px;
  left: 10px;
    `,

  "pt": css`
    top: 10px;
    left: 20px;
      `,

  "pt-PT": css`
    top: 10px;
    left: 20px;
      `,

  "ru": css`
  top: 14px;
  left: 15px;
    `,
  "zh-Hant":
    css`
    top: 10px;
    left: 17px;
    `}

  ;


const PosistionActiveButtonLeft = {

  "ar": css`
  top: 10px;
  left: 25px;
`,
  "de":
    css`
    top: 10px;
    left: 20px;
    `,

  "en": css`
  top: 10px;
  left: 15px;
    `,

    "fr": css`
    top: 10px;
    left: 15px;
      `,

  "es": css`
  top: 10px;
  left: 15px;
    `,

  "it": css`
  top: 13px;
  left: 20px;
    `,

  "ja": css`
  top: 13px;
  left: 10px;
    `,

  "ko": css`
  top: 12px;
  left: 10px;
    `,

  "pt": css`
  top: 10px;
  left: 20px;
    `,

  "pt-PT": css`
  top: 10px;
  left: 20px;
    `,

  "ru": css`
  top: 14px;
  left: 15px;
    `,
  "zh-Hant":
    css`
    top: 10px;
    left: 17px;
    `}

  ;
const PosistionInactiveButtonRight = {

  "ar": css`
  top: 10px;
  right: 16px;
`,
  "de":
    css`
    top: 10px;
    right: 14px;
    `,

  "en": css`
  top: 10px;
  right: 10px;
    `,

    "fr": css`
  top: 10px;
  right: 10px;
    `,

  "es": css`
  top: 10px;
  right: 10px;
    `,

  "it": css`
  top: 13px;
  right: 10px;
    `,

  "ja": css`
  top: 13px;
  right: 22px;
    `,

  "ko": css`
  top: 12px;
  right: 16px;
    `,

  "pt": css`
  top: 10px;
  right: 14px;
    `,

  "pt-PT": css`
  top: 10px;
  right: 14px;
    `,

  "ru": css`
  top: 14px;
  right: 10px;
    `,
  "zh-Hant":
    css`
    top: 10px;
    right: 17px;
    `}

  ;


const PosistionActiveButtonRight = {

  "ar": css`
  top: 10px;
  right: 16px;
`,
  "de":
    css`
    top: 10px;
    right: 14px;
    `,

  "en": css`
  top: 10px;
  right: 10px;
    `,
    
    
  "fr": css`
  top: 10px;
  right: 10px;
    `,

  "es": css`
  top: 10px;
  right: 10px;
    `,

  "it": css`
  top: 13px;
  right: 10px;
    `,

  "ja": css`
  top: 13px;
  right: 22px;
    `,

  "ko": css`
  top: 12px;
  right: 16px;
    `,

  "pt": css`
    top: 10px;
    right: 14px;
      `,

  "pt-PT": css`
    top: 10px;
    right: 14px;
      `,

  "ru": css`
  top: 14px;
  right: 10px;
    `,
  "zh-Hant":
    css`
    top: 10px;
    right: 17px;
    `}

  ;


const inputSizeLabel = {

  "ar": { fontSize: 14, marginTop: 0 },
  "de": { fontSize: 10, marginTop: 4 },
  "en": { fontSize: 14, marginTop: 0 },
  "es": { fontSize: 14, marginTop: 0 },
  "it": { fontSize: 12, marginTop: 2 },
  "ja": { fontSize: 14, marginTop: 0 },
  "ko": { fontSize: 14, marginTop: 0 },
  "pt": { fontSize: 14, marginTop: 0 },
  "pt-PT": { fontSize: 14, marginTop: 0 },
  "ru": { fontSize: 12, marginTop: -2,width:'90%', marginLeft: -4 },
  "zh-Hant": { fontSize: 14, marginTop: 0 }
}

export {
  SourceSansProFont,
  CTA16px600SemiboldUppercase,
  Paragraph16px400Regular,
  Paragraph16px300Light,
  Paragraph16px600Medium,
  Title24px400Regular,
  Title24px700BoldUppercase,
  Subtitle16px400RegularUppercase,
  Subtitle16px600SemiboldUppercase,
  Hint9px400Regular,
  Description12px300Light,
  Description12px400Regular,
  Description12px600Semibold,
  Description12px400RegularArray,
  PosistionInactiveButtonLeft,
  PosistionActiveButtonLeft,
  PosistionInactiveButtonRight,
  PosistionActiveButtonRight,
  inputSizeLabel
};
