import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html,body{
    height:100%;
    min-height:100%; 
    width: 100%;
    min-width: 100%;
  }

  #root {
    height:100%;
    min-height:100%; 
    width: 100%;
    min-width: 100%;
    overflow: hidden;
  }

  * {
    margin: 0px;
    padding: 0px;
    font-family: "Source Sans Pro", sans-serif;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 99px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bbb;
  }
`;

export default GlobalStyle;
