import React, { useState } from "react";
import Tab from "../../../components/tab/Tab";
import Details from "./details/Details";
import Signatures from "./signatures/Signatures";
import  { Translator } from '../../../components/I18n'
const MyCompany = () => {
  const [onLoadTab] = useState(false);

  const tabsConfig = [
    {
      text: <Translator path="establishmentDetails.message" />,
      path: "company",
      component: <Details />,
      selectedTabNumber: 0,
    },
    {
      text: <Translator path="subscriptions.message" />,
      path: "subscriptions",
      component: <Signatures />,
      selectedTabNumber: 1,
    },
  ];
  return (
    <div>
      <Tab isLoadingContent={onLoadTab} tabsConfig={tabsConfig} />
    </div>
  );
};

export default MyCompany;
