import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../components/styled/Forms";
import Button from "../../../../../components/button/Button";
import {
  Paragraph16px300Light,
  Title24px400Regular,
} from "../../../../../styles/style-guide";

export const NewComplementGroupWrapper = styled.div`
  height: 100%;
  width: 60vw;
  /* padding: 14px; */
  border-radius: 30px;
`;

export const NewComplementGroupTitle = styled.h4`
  ${Title24px400Regular};
  margin-bottom: 22px;
  text-align: center;
  color: #9a9a9a;
`;

export const NewComplementGroupTableWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

export const NewComplementGroupTableContainer = styled.div`
  display: inline-block;
  border: 1px solid #aaa;
  border-radius: 15px;
  margin: 0px 26px 0px 26px;
  overflow: hidden;
`;

export const NewComplementGroupSubTitle = styled.h4`
  /* margin-bottom: 22px; */
  text-align: center;
  font-weight: 400;
  color: #6a6a6a;
`;

export const NewComplementGroupInputTitle = styled.label`
  position: absolute;
  background: white;
  top: -7px;
  left: 25px;
  font-size: 11px;
  font-weight: 600;
  color: #ff5533;
  padding-left: 5px;
  padding-right: 5px;
`;
export const NewComplementGroupInput = styled.input`
  /* margin-left: 12px; */
  border-width: 1px;
  border-radius: 10px;
  border-color: #9a9a9a;

  width: 100%;
  height: 36px;
  padding-left: 10px;
  /* text-align: center; */

  :focus {
    border-radius: 10px;
    outline: 0;
  }
`;
export const NewComplementGroupInputContext = styled.div`
  position: relative;
  height: 36px;
  margin-right: 15px;
  width: 100%;
`;
export const NewComplementGroupInputContent = styled.div`
  height: 36px;
  margin: 15px 26px 15px 26px;
  display: flex;
  align-items: center;
`;

export const NewComplementGroupCheckBox = styled.div`
  min-width: 100px;
`;

export const NewComplementGroupGrid = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px;
`;

export const NewComplementGroupForm = styled.form`
  display: flex;
  height: 70px;
  margin: 15px 26px 15px 26px;
  align-items: center;
  /* flex-direction: column; */
  .button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NewComplementGroupPhotoContent = styled.div`
  margin-top: 40px;
  display: flex;
`;

export const NewComplementGroupPhotoTextContent = styled.div`
  width: 40%;
  height: 320px;
`;

export const NewComplementGroupPhotoArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 320px;
`;

export const NewComplementGroupPhotoAreaContent = styled.div`
  margin-left: 35px;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  max-width: 426px;
`;

export const NewComplementGroupPhotoParagraph = styled.p`
  margin-top: 16px;
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
`;

export const NewComplementGroupInputGroupWithToggle = styled(
  InputGroupWithToggle
)`
  margin-bottom: 11px;
`;

export const NewComplementGroupButtonGoBack = styled(Button)`
  float: right;
`;

export const NewComplementGroupButtonSave = styled(Button)`
  float: left;
`;

export const NewComplementGroupFooter = styled.footer`
  bottom: 20px;
  width: 90%;
  margin: 32px auto;
`;
export const NewComplementGroupInputGroup = styled.div`
  display: flex;
  flex: 0;
`;

export const NewComplementGroupDivision = styled.div`
  width: 90%;
  margin: 10px auto 0;
  height: 0.5px;
  background-color: #e6e6e6;
`;
