import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalTitle, ModalParagraph, ModalSubTitle } from '../modal-mytm/Styles';
import { ReactComponent as ImageError } from '../../assets/error.svg';
import Button from '../button/Button';
import {
  ModalGenericErrorWrapper,
  ModalGenericErrorDataContent,
  ModalGenericErrorImageContent,
  ModalGenericErrorContainer,
  ModalGenericErrorContent,
  ModalGenericErrorButtonsContent,
} from './Styles';
import TranslatorFunction from "../../components/I18n/Translator"

const ModalGenericError = (props) => {
  const errorIdentification = TranslatorFunction({ path: 'errorIdentification.message' });
  const translateTitle = TranslatorFunction({ path: 'huhLooksLikeSomethingWentWrong.message' });
  const tryAgain = TranslatorFunction({ path: 'tryAgain.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const {
    open, message, errorMessage,
    onClickGoBack, onClickTryAgain, title,
  } = props;

  const [openModal, setOpenModal] = useState(open);

  const handleOnClickGoBack = () => {
    onClickGoBack();
  };

  const handleOnClickTryAgain = () => {
    onClickTryAgain();
  };

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  return (
    <ModalGenericErrorWrapper open={openModal}>
      <ModalGenericErrorContainer>
        <ModalGenericErrorContent>
          <ModalGenericErrorDataContent>
            <ModalTitle>{ title === 'translateTitle' ? translateTitle : title }</ModalTitle>
            <ModalParagraph>
              { message }
            </ModalParagraph>
            <ModalSubTitle>
              {errorIdentification}:
            </ModalSubTitle>
            <ModalParagraph noMargin>
              { errorMessage }
            </ModalParagraph>
          </ModalGenericErrorDataContent>
          <ModalGenericErrorImageContent>
            <ImageError />
          </ModalGenericErrorImageContent>
        </ModalGenericErrorContent>
        <ModalGenericErrorButtonsContent>
          <Button onClick={handleOnClickTryAgain}>{tryAgain}</Button>
          <Button btnType="tertiary" onClick={handleOnClickGoBack}>{back}</Button>
        </ModalGenericErrorButtonsContent>
      </ModalGenericErrorContainer>
    </ModalGenericErrorWrapper>
  );
};

ModalGenericError.defaultProps = {
  title: 'translateTitle',
  open: false,
  message: '',
  errorMessage: '',
  onClickGoBack: () => {},
  onClickTryAgain: () => {},
};

ModalGenericError.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.string,
  errorMessage: PropTypes.string,
  onClickGoBack: PropTypes.func,
  onClickTryAgain: PropTypes.func,
};

export default ModalGenericError;
