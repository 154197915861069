import styled from "styled-components";
import loginBackground from "../../assets/login-background-min.png";
import { ReactComponent as LogoMytm } from "../../assets/logo_vertical.svg";

const LoginWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${loginBackground});
`;

const LoginOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: #1a1a1a;
  mix-blend-mode: normal;
  opacity: 0.9;
`;

const LoginContent = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 450px;
  border-radius: 8px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
`;

const LoginLogoMyTripMenu = styled(LogoMytm)`
  z-index: 1;
  margin-bottom: 40px;
  width: 118px;
  height: 148px;
`;

export { LoginWrapper, LoginOverlay, LoginContent, LoginLogoMyTripMenu };
