import React from "react";

import { TableCol } from "../../Styles";

function CityCol({ withCities, obj, objectKey }) {
  const renderCities = (obj) => {
    const city = obj[objectKey];
    const name = city.name;
    return name;
  };

  return (
    <>
      {withCities ? (
        <TableCol colSchedules textAlign={"left"}>
          {renderCities(obj)}
        </TableCol>
      ) : null}
    </>
  );
}

export default CityCol;
