import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import ModalGenericConfirmation from '../components/modal-generic-confirmation/ModalGenericConfirmation';
import { useFunction } from '../hooks/custom-hooks';

const ModalConfirmationContext = createContext({
  open: false,
  setOpen: () => {},
  title: '',
  setTitle: () => {},
  message: '',
  setMessage: () => {},
  btnOneText: '',
  setBtnOneText: () => {},
  btnTwoText: '',
  setBtnTwoText: () => {},
  btnThreeText: '',
  setBtnThreeText: () => {},
  setOnClickBtnOneFunc: () => {},
  setOnClickBtnTwoFunc: () => {},
  setOnClickBtnThreeFunc: () => {},
});

const useModalConfirmationContext = () => (
  useContext(ModalConfirmationContext)
);

const ModalConfirmationContextProvider = ({ children }) => {
  const [open, _setOpen] = useState(false);
  const [title, _setTitle] = useState();
  const [message, _setMessage] = useState();
  const [btnOneText, _setBtnOneText] = useState();
  const [btnTwoText, _setBtnTwoText] = useState();
  const [btnThreeText, _setBtnThreeText] = useState();
  const [onClickBtnTwo, _setOnClickBtnTwo] = useFunction(() => {});
  const [onClickBtnThree, _setOnClickBtnThree] = useFunction(() => {});
  const [onClickBtnOne, _setOnClickBtnOne] = useFunction();
  const setOpen = useCallback((e) => _setOpen(e), [_setOpen]);
  const setTitle = useCallback((e) => _setTitle(e), [_setTitle]);
  const setMessage = useCallback((e) => _setMessage(e), [_setMessage]);
  const setBtnOneText = useCallback((e) => _setBtnOneText(e), [_setBtnOneText]);
  const setBtnTwoText = useCallback((e) => _setBtnTwoText(e), [_setBtnTwoText]);
  const setBtnThreeText = useCallback((e) => _setBtnThreeText(e), [_setBtnThreeText]);
  const setOnClickBtnOneFunc = useCallback((e) => _setOnClickBtnOne(e), [_setOnClickBtnOne]);
  const setOnClickBtnTwoFunc = useCallback((e) => _setOnClickBtnTwo(e), [_setOnClickBtnTwo]);
  const setOnClickBtnThreeFunc = useCallback((e) => _setOnClickBtnThree(e), [_setOnClickBtnThree]);

  const contextValue = useMemo(() => (
    {
      open,
      setOpen,
      title,
      setTitle,
      message,
      setMessage,
      btnOneText,
      setBtnOneText,
      btnTwoText,
      setBtnTwoText,
      btnThreeText,
      setBtnThreeText,
      setOnClickBtnOneFunc,
      setOnClickBtnTwoFunc,
      setOnClickBtnThreeFunc,
    }
  ), [
    title, setTitle, message, setMessage,
    btnOneText, setBtnOneText, btnTwoText,
    setBtnTwoText, btnThreeText, setBtnThreeText,
    open, setOpen, setOnClickBtnOneFunc,
    setOnClickBtnTwoFunc, setOnClickBtnThreeFunc,
  ]);

  return (
    <ModalConfirmationContext.Provider value={contextValue}>
      <ModalGenericConfirmation
        open={open}
        title={title}
        message={message}
        btnOneText={btnOneText}
        btnTwoText={btnTwoText}
        btnThreeText={btnThreeText}
        onClickBtnOne={onClickBtnOne}
        onClickBtnTwo={onClickBtnTwo}
        onClickBtnThree={onClickBtnThree}
      />
      {children}
    </ModalConfirmationContext.Provider>
  );
};

ModalConfirmationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any,
  ]).isRequired,
};

export {
  ModalConfirmationContext,
  useModalConfirmationContext,
};

export default ModalConfirmationContextProvider;
