import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../../../components/styled/Forms";
import Button from "../../../../../../../components/button/Button";

const ComplementLoaderWrapper = styled.div`
  width: 945px;
  height: 100%;
  min-width: 40vw;
  min-height: 50vh;
  padding: 4px;
`;

const ComplementLoaderForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ComplementLoaderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;
const ComplementLoaderCell = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ComplementLoaderButtonGoBack = styled(Button)`
  float: right;
`;

const ComplementLoaderButtonSave = styled(Button)`
  float: left;
`;

const ComplementLoaderFooter = styled.footer`
  bottom: 24px;
  position: absolute;
  width: 95%;
`;

const ComplementGroupWithToggle = styled(InputGroupWithToggle)`
  margin-top: 15px;
  margin-bottom: 11px;
`;

const ComplementFilterBox = styled.div`
  padding-top: 5px;
  width: 44%;
  display: grid;
  gap: 2%;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
`;

const ComplementInput = styled.input`
  width: 30%;
`;

export {
  ComplementLoaderWrapper,
  ComplementLoaderForm,
  ComplementLoaderFooter,
  ComplementLoaderButtonSave,
  ComplementLoaderButtonGoBack,
  ComplementLoaderGrid,
  ComplementLoaderCell,
  ComplementGroupWithToggle,
  ComplementFilterBox,
  ComplementInput,
};
