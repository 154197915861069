import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../../../components/styled/Forms";
import Button from "../../../../../../../components/button/Button";

const ComplementGroupLoaderWrapper = styled.div`
  width: 945px;
  height: 100%;
  min-width: 40vw;
  min-height: 50vh;
  padding: 4px;
`;

const ComplementGroupLoaderForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ComplementGroupLoaderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;
const ComplementGroupLoaderCell = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ComplementGroupLoaderButtonGoBack = styled(Button)`
  float: right;
`;

const ComplementGroupLoaderButtonSave = styled(Button)`
  float: left;
`;

const ComplementGroupLoaderFooter = styled.footer`
  bottom: 24px;
  position: absolute;
  width: 95%;
`;

const ComplementGroupWithToggle = styled(InputGroupWithToggle)`
  margin-top: 15px;
  margin-bottom: 11px;
`;

const ComplementGroupFilterBox = styled.div`
  padding-top: 5px;
  width: 44%;
  display: grid;
  gap: 2%;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
`;

const ComplementGroupInput = styled.input`
  width: 30%;
`;

export {
  ComplementGroupLoaderWrapper,
  ComplementGroupLoaderForm,
  ComplementGroupLoaderFooter,
  ComplementGroupLoaderButtonSave,
  ComplementGroupLoaderButtonGoBack,
  ComplementGroupLoaderGrid,
  ComplementGroupLoaderCell,
  ComplementGroupWithToggle,
  ComplementGroupFilterBox,
  ComplementGroupInput,
};
