import React, { useState, useMemo } from "react";

import Table from "../../../../../components/table/Table";
import restComplementGroup from "../../../../../api/complementgroup/rest-complement-group";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import InputSearch from "../../../../../components/inputs/mui-input-search/input-search";
import TranslatorFunction from '../../../../../components/I18n/Translator'
import { ListComplementGroupWrapper, ContainerFilter } from "./styles";

export default function ListComplementGroup({
  complementGroup,
  onClickEditComplementGroup,
}) {
  const [data, setData] = useState(complementGroup);
  const [searchs, setSearchs] = useState("");
  const language = TranslatorFunction({ path: 'language.message' });
  const complementsGroupUpper = TranslatorFunction({ path: 'complementsGroupUpper.message' });
  const action = TranslatorFunction({ path: 'action.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const statusS = TranslatorFunction({ path: 'status.message' });
  const colTitleKeysAlign = ["left", "center", "center"];
  const complementGroupKeys = ["name"];
  const listTitles = [complementsGroupUpper, statusS,action];
  const colKeyAligns = { name: "left" };

  const putComplementGroup = (complementgroup) => {
    restComplementGroup.putComplementGroup(complementgroup._id, {
      active: complementgroup.active,
    });
  };

  const handleOnChangeActiveToggle = (selectedComplementGroup) => {
    const complementGroupIndex = data.findIndex(
      (cp) => cp._id === selectedComplementGroup._id
    );

    setData((oldData) => {
      const newData = [...oldData];
      newData[complementGroupIndex].active =
        !newData[complementGroupIndex].active;
      return newData;
    });

    putComplementGroup(data[complementGroupIndex]);
  };

  const filterComplementGroupByName = (complementGroup_) => {
    if (!searchs) return true;
    console.log('complements', complementGroup_?.translatedComplementGroup && complementGroup_.translatedComplementGroup[0].name[0][language] !== undefined?complementGroup_.translatedComplementGroup[0].name[0][language]:complementGroup_.name)
    return  (complementGroup_?.translatedComplementGroup && complementGroup_.translatedComplementGroup[0].name[0][language] !== undefined?complementGroup_.translatedComplementGroup[0].name[0][language]:complementGroup_.name)
      .toLocaleLowerCase()
      .includes(searchs.toLocaleLowerCase());
  };

  const filteredComplementGroup = useMemo(
    () => data?.filter(filterComplementGroupByName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, data]
  );

  return (
    <ListComplementGroupWrapper>
      {data && (
        <ContainerFilter>
          <MuiAutoComplete
            id="search"
            options={data ?? []}
            getOptionLabel={(option) => (Array.isArray(option.translatedComplementGroup) && option.translatedComplementGroup.length > 0 ? (option.translatedComplementGroup[0].name[0][language] !== undefined ?option.translatedComplementGroup[0].name[0][language]:option.name   ): option.name)  || ""}
            renderInput={(params) => (
              <InputSearch
                {...params}
                margin="dense"
                label={search}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
            onChange={(e) => {
              setSearchs(e.target.value);
            }}
            onSelect={(e) => {
              setSearchs(e.target.value);
            }}
          />
        </ContainerFilter>
      )}
      <Table
        data={filteredComplementGroup ?? []}
        setData={setData}
        titles={listTitles}
        colKeysAlign={colKeyAligns}
        keys={complementGroupKeys}
        onClickEdit={onClickEditComplementGroup}
        onClickToogle={handleOnChangeActiveToggle}
        colTitleKeysAlign={colTitleKeysAlign}
        statusKey="active"
        withActions
        withStatus
        objectKey="_id"
      />
    </ListComplementGroupWrapper>
  );
}
