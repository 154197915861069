import styled from 'styled-components';
import RecoveryPasswordBackground from '../../assets/login-background-min.png';
import { ReactComponent as LogoMytm } from '../../assets/logo-mt-trip.svg';

const RecoveryPasswordWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${RecoveryPasswordBackground});
`;

const RecoveryPasswordOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: #1A1A1A;
  mix-blend-mode: normal;
  opacity: 0.9;
`;

const RecoveryPasswordContent = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 416px;
  border-radius: 8px;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: center;
`;

const RecoveryPasswordLogoMyTripMenu = styled(LogoMytm)`
  z-index: 1;
  margin-bottom: 40px;
`;

export {
  RecoveryPasswordWrapper,
  RecoveryPasswordOverlay,
  RecoveryPasswordContent,
  RecoveryPasswordLogoMyTripMenu,
};
