import styled from "styled-components";

export const Container = styled.div`
  padding: 32px 0 0px 16px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 92%;
  position: relative;
`;

export const ContainerTable = styled.div``;
