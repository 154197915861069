import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
  ModalBodyScrollSection,
} from "../../../../../../components/modal-mytm/Styles";
import { useDeb } from "../../../../../../hooks/custom-hooks";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import { MuiTextFieldStyled } from "../../../../../../components/inputs/mui-inputs/Styles";
import InputSearch from "../../../../../../components/inputs/mui-input-search/input-search";

import {
  NewNeighborhoodInputContent,
  NewNeighborhoodInputContext,
  NewNeighborhoodDivision,
  NewNeighborhoodButtonSave,
  NewNeighborhoodButtonGoBack,
  ContainerFilter,
} from "./Styles";
import CurrencyMask from "react-currency-format";
import Table from "../../../../../../components/table/Table";
import ButtonAdd from "../../../../../../components/button-add/button-add";
import restNeighborhood from "../../../../../../api/neighborhood/rest-neighborhood";

import TranslatorFunction from "../../../../../../components/I18n/Translator"

const Neighborhood = (props) => {
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const addEditNeighborhoods = TranslatorFunction({ path: 'addEditNeighborhoods.message' });
  const neighborhoods = TranslatorFunction({ path: 'neighborhoods.message' });
  const cityUpper = TranslatorFunction({ path: 'cityUpper.message' });
  const valueUpper = TranslatorFunction({ path: 'valueUpper.message' });
  const status = TranslatorFunction({ path: 'status.message' });
  const action = TranslatorFunction({ path: 'action.message' });

  const newNeighborhoods = TranslatorFunction({ path: 'newNeighborhoods.message' });
  const value = TranslatorFunction({ path: 'value.message' });
  const City = TranslatorFunction({ path: 'city.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const noOptions = TranslatorFunction({ path: 'noOptions.message' });


  const { neighborhood, onClose, cities } = props;
  const data = neighborhood;
  const colTitleKeysAlign = ["left", "left", "left", "left"];
  const locationKeys = ["name", "price"];
  const listTitles = [neighborhoods, valueUpper, cityUpper, status, action];
  const colKeyAligns = { name: "left", price: "price" };

  const [searchs, setSearchs] = useState({
    name: "",
    city: "",
  });

  const [form, setForm] = useState(data);
  const [priceTag, setPriceTag] = useState("");
  const debounceFunc = useDeb(setPriceTag, 2000);
  const { register, handleSubmit, reset } = useForm();

  const [neighborhoodArray, setNeighborhoodArray] = useState([]);

  const [oldForm, setOldForm] = useState([]);

  const [values, setValues] = useState({
    name: "",
    price: "",
    city: {
      name: null,
      _id: null,
    },
    id: 0,
    active: true,
    edit: false,
  });

  const handleOnClickGoBack = () => {
    onClose();
  };

  const handleAutoChange = (event, obj) => {
    if (obj !== undefined || obj !== null) {
      setValues((oldValues) => {
        const newValues = { ...oldValues };
        newValues.city._id = obj?._id;
        newValues.city.name = obj?.name;
        return newValues;
      });
    }
  };

  const putAndPostNeighborhoods = async () => {
    try {
      if (oldForm.length > 0) {
        const formattedForm = oldForm.map((ngh) => ({
          ...ngh,
          cityId: ngh.city._id,
        }));

        await restNeighborhood.postNeighborhood({
          neighborhoods: formattedForm,
        });
      }
      if (form.length > 0) {
        const formattedForm = form.map((ngh) => ({ ...ngh, id: ngh._id }));
        await restNeighborhood.putNeighborhood({
          neighborhoods: formattedForm,
        });
      }
    } catch (error) {}
    onClose();
  };

  const handleOnClickSave = () => putAndPostNeighborhoods();

  const handleChangePriceNeighborhood = (event) => {
    debounceFunc(event.floatValue);
  };

  const verify = (finalForm) => {
    if (finalForm.name === "" || values.city.name === null) return false;

    return true;
  };

  const handleAddOnTable = (finalForm) => {
    if (verify(finalForm)) {
      let oldForm_ = [...neighborhoodArray];
      let beforeForm = [...oldForm];

      let newValues = { ...values };
      newValues.name = finalForm.Name;
      newValues.price = priceTag;

      beforeForm.push(newValues);
      oldForm_.push(newValues);
      setNeighborhoodArray(oldForm_);
      setOldForm(beforeForm);
      setValues({
        name: "",
        price: "",
        city: {
          name: null,
          _id: null,
        },
        id: oldForm.length + 1,
        active: true,
      });
      reset({
        Name: "",
        Value: "",
      });
      setPriceTag("");
    }
  };

  const handleChangeTable = (data, event) => {
    const isNew = data._id ? false : true;
    if (
      event.floatValue !== undefined ||
      (event.floatValue && isNaN(event.floatValue))
    ) {
      const floatValue = isNaN(event.floatValue) ? 0 : event.floatValue;
      const valuesInArray = [...neighborhoodArray];
      const findedIndexInArray = neighborhoodArray.findIndex(
        (neighborhood) => neighborhood === data
      );

      const findedIndexComplement = form.findIndex(
        (compl) => compl._id === data._id
      );

      const values = [...form];
      values[findedIndexComplement]["price"] = floatValue;
      valuesInArray[findedIndexInArray] = values[findedIndexComplement];

      return;
    }

    const { value, name } = event.target;
    const valuesInArray = [...neighborhoodArray];

    const findedIndexInArray = neighborhoodArray.findIndex(
      (neighborhood) => neighborhood === data
    );

    if (isNew) {
      const values = [...oldForm];

      const findedIndexComplement = oldForm.findIndex(
        (compl) => compl.id === data.id
      );

      values[findedIndexComplement][name] = value;

      valuesInArray[findedIndexInArray] = values[findedIndexComplement];

      setOldForm(values);
      setNeighborhoodArray(valuesInArray);
      return;
    }

    const findedIndexComplement = form.findIndex(
      (compl) => compl._id === data._id
    );

    const values = [...form];
    values[findedIndexComplement][name] = value;
    valuesInArray[findedIndexInArray] = values[findedIndexComplement];

    setForm(values);
    setNeighborhoodArray(valuesInArray);

    return;
  };

  const handleOnChangeActiveToggle = (selectedNeighborhood) => {
    const neighborhoodIndex = neighborhoodArray.findIndex(
      (neighborhood) => neighborhood._id === selectedNeighborhood._id
    );
    setNeighborhoodArray((oldData) => {
      const newData = [...oldData];
      newData[neighborhoodIndex].active = !newData[neighborhoodIndex].active;
      return newData;
    });
  };

  const handleOnClickEdit = (selectedNeighborhood) => {
    const neighborhoodIndex = neighborhoodArray.findIndex(
      (neighborhood) => neighborhood._id === selectedNeighborhood._id
    );

    setNeighborhoodArray((oldData) => {
      const newData = [...oldData];
      newData[neighborhoodIndex].edit = !newData[neighborhoodIndex].edit;
      return newData;
    });
  };

  useEffect(() => {
    neighborhoodArray.map((neighborhood) => (neighborhood.edit = false));
    setNeighborhoodArray(data ?? []);
    setForm(neighborhood);
    //eslint-disable-next-line
  }, []);

  const filterNeighByName = (neighbor) => {
    if (!searchs.name) return true;
    return neighbor.name
      .toLocaleLowerCase()
      .includes(searchs.name.toLocaleLowerCase());
  };

  const filterNeighByCity = (neighbor) => {
    if (!searchs.city) return true;
    return neighbor?.city.name
      .toLocaleLowerCase()
      .includes(searchs.city.toLocaleLowerCase());
  };

  const filteredData = useMemo(
    () =>
      neighborhoodArray?.filter(filterNeighByName).filter(filterNeighByCity),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, neighborhoodArray]
  );

  return (
    <>
      <ModalWrapper>
        <ModalTitle>{addEditNeighborhoods}</ModalTitle>
        <ModalBody noOverflow>
          <form>
            <NewNeighborhoodInputContent>
              <NewNeighborhoodInputContext>
                <MuiTextFieldStyled
                  name="neighborhood"
                  label= {newNeighborhoods}
                  variant="outlined"
                  margin="dense"
                  InputProps={register("Name", { required: true })}
                />
              </NewNeighborhoodInputContext>
              <NewNeighborhoodInputContext>
                <CurrencyMask
                  label={value}
                  value={priceTag}
                  placeholder="R$ 0,00"
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  name="price"
                  customInput={(p) => {
                    return (
                      <MuiTextFieldStyled
                        {...p}
                        margin="dense"
                        variant="outlined"
                        inputProps={register("Value", { required: true })}
                      />
                    );
                  }}
                  onValueChange={handleChangePriceNeighborhood}
                />
              </NewNeighborhoodInputContext>
              <NewNeighborhoodInputContext>
                <MuiAutoComplete
                  id="MustHave"
                  noOptionsText={noOptions}
                  options={cities}
                  getOptionLabel={(option) => option?.name || ""}
                  value={values.city}
                  getOptionSelected={(option, value) => {
                    if (value?.name === null) return false;
                    return option?.name === value?.name;
                  }}
                  renderInput={(params) => (
                    <MuiTextFieldStyled
                      {...params}
                      margin="dense"
                      label={City}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  onChange={handleAutoChange}
                  // onSelect={handleAutoChange}
                />
              </NewNeighborhoodInputContext>
              <ButtonAdd
                onClick={handleSubmit(handleAddOnTable)}
                className="button"
              />
            </NewNeighborhoodInputContent>
          </form>
          <NewNeighborhoodDivision />
          <ContainerFilter>
            {data && (
              <>
                <MuiAutoComplete
                  id="search"
                  options={data}
                  noOptionsText={noOptions}
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <InputSearch
                      {...params}
                      margin="dense"
                      label= {search}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  onChange={(e) => {
                    setSearchs({ ...searchs, name: e.target.value });
                  }}
                  onSelect={(e) => {
                    setSearchs({ ...searchs, name: e.target.value });
                  }}
                />

                <MuiAutoComplete
                  id="city"
                  options={cities}
                  noOptionsText={noOptions}
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <MuiTextFieldStyled
                      {...params}
                      margin="dense"
                      label= {City}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  onChange={(e) => {
                    setSearchs({
                      ...searchs,
                      city: e.target.value,
                    });
                  }}
                  onSelect={(e) => {
                    setSearchs({
                      ...searchs,
                      city: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </ContainerFilter>
          <ModalBodyScrollSection>
            <Table
              data={filteredData}
              setData={setNeighborhoodArray}
              titles={listTitles}
              colKeysAlign={colKeyAligns}
              colTitleKeysAlign={colTitleKeysAlign}
              keys={locationKeys}
              withStatus
              statusKey="active"
              onClickToogle={handleOnChangeActiveToggle}
              onClickEdit={handleOnClickEdit}
              withActions
              withCities
              onChangeInput={(data, event) => handleChangeTable(data, event)}
              objectKey="city"
            />
          </ModalBodyScrollSection>
        </ModalBody>
        <ModalFooter>
          <NewNeighborhoodButtonSave onClick={handleOnClickSave}>
            {saveEditions}
          </NewNeighborhoodButtonSave>
          <NewNeighborhoodButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            {back}
          </NewNeighborhoodButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </>
  );
};

export default Neighborhood;
