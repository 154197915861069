import styled, { css } from 'styled-components';
import { Paragraph16px300Light, Description12px600Semibold } from '../../styles/style-guide';
// import { IconError } from '../../styles/icons';

// const SelectCustomIconError = styled(IconError)`
//   display: ${({ haserror }) => (haserror === 'true' ? 'block' : 'none')};
//   position: absolute;
//   float: right;
//   top: 12px;
//   right: 15.5px;
// `;

const LabelContent = styled.div`
  position: absolute;
  min-width: 330px;
`;

const Label = styled.label`
  top: 2px;
  left: -13px; 
  right: 0;
  position: relative;
  z-index: 0;
  cursor: text;
  padding: 0 12px 8px 12px;
  transition: .2s ease-in-out;
  color: ${({ theme }) => theme.colors.textColor};
  ${() => Paragraph16px300Light}
`;

// const LabelArrowDown = styled.label`
//   height: 24px;
//   width: 24px;
//   top: 8px;
//   right: 8px;
//   float: right;
//   cursor: pointer;
//   position: relative;
//   z-index: 0;
//   color: ${({ theme }) => theme.colors.mainColor};

//   svg {
//     left: 7px;
//     top: 1px;
//     position: relative;
//   }

//   &:hover {
//     background-color: ${({ theme }) => theme.colors.mainColorLight};
//     border-radius: 2px;
//   }
// `;

const LabelRequired = styled.label`
  display: inline;
  opacity: 1;
  transition: .1s ease-in-out;
  color: ${({ theme }) => theme.colors.mainColor};
`;

// const SelectInputErrorMessage = styled.span`
//   display: ${({ haserror }) => (haserror === 'true' ? 'block' : 'none')};
//   position: absolute;
//   color: ${({ theme }) => theme.colors.statusColorDanger};
//   ${() => Hint9px400Regular};
//   width: 100%;
//   bottom: -12px;
//   left: 12px;
// `;

const SelectInput = styled.input`
  ${({ hasTag, width }) => hasTag && `width: ${width}px`};
  ${({ hasTag }) => hasTag === false && 'min-width: 300px'};
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.textColor};
  ${() => Paragraph16px300Light};

  &::placeholder {
    ${({ hasTag }) => hasTag && 'color: transparent'};
    opacity: 0;
    transition: .2s ease-in-out;
  }

  &:focus {
    &:empty {
      &::placeholder {
        width: 300px;
        opacity: 1;
        transition: .2s ease-in-out;
      }
    }
  }
`;

// const SelectContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   margin: 4px 0px 0px 10px;
// `;

const SelectInputContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-height: 40px;
  padding: 4px 0 8px 0;
  outline: none;
  box-sizing: border-box;
  padding: 8px 12px 8px 12px;
  width: 95%;
  color: ${({ theme }) => theme.colors.textColor};
  border-radius: 4px;
  font-size: 16px;
  ${() => Paragraph16px300Light};
`;

const SelectContentWithTag = css`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border: 2px solid ${({ theme, haserror }) => (haserror === 'true' ? theme.colors.statusColorDanger : theme.colors.mainColor)};

  ${SelectInputContent} ${SelectInput} ~ ${LabelContent} {
    ${Label} {
      top: -20px;
      left: -5px; 
      font-size: 12px;
      padding: 0 5px 0px 5px;
      pointer-events: none;
      color: ${({ theme }) => theme.colors.mainColor};
      background-color:  ${({ theme }) => theme.colors.mainColorContrast};
      ${Description12px600Semibold};
      transition: .2s ease-in-out;

      ${LabelRequired} {
        display: none;
        opacity: 0;
        transition: .1s ease-in-out;
      }
    }
  }
`;

const SelectContentWithoutTag = css`
  &:focus-within {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border: 2px solid ${({ theme, haserror }) => (haserror === 'true' ? theme.colors.statusColorDanger : theme.colors.mainColor)};
  }

  ${SelectInputContent} ${SelectInput}:valid ~ ${LabelContent}, 
  ${SelectInputContent} ${SelectInput}:focus ~ ${LabelContent} {
    min-width: 240px;

    ${Label} {
      top: -20px;
      left: -5px; 
      font-size: 12px;
      padding: 0 5px 0px 5px;
      pointer-events: none;
      color: ${({ theme }) => theme.colors.mainColor};
      background-color:  ${({ theme }) => theme.colors.mainColorContrast};
      ${Description12px600Semibold};
      transition: .2s ease-in-out;

      ${LabelRequired} {
        display: none;
        opacity: 0;
        transition: .1s ease-in-out;
      }
    }
  }
`;

const SelectContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  display: flex;
  padding: 0px 12px 0px 12px;
  width: 100%;
  min-height: 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme, haserror }) => (haserror === 'true' ? theme.colors.statusColorDanger : theme.colors.textColorDisabled)};
  ${({ hasTag }) => hasTag && SelectContentWithTag}
  ${({ hasTag }) => !hasTag && SelectContentWithoutTag}

  .react-select-container {
    width: 100%;
    height: 40px;
    border: 0px solid #E6E6E6;
    
    .react-select__control {
      border: 0px solid #E6E6E6;

      .react-select__indicators {
        flex: 0 1 0;
      }
    }
    .react-select__control--is-focused {
      border: 0px solid #E6E6E6;
    }
    .react-select__control--menu-is-open {
      border: 0px solid #E6E6E6;
    }
    .css-1pahdxg-control {
      box-shadow: none;
      border: 0px solid #FF5533;
    }
    .css-1pahdxg-control:hover {
      border: 0px solid #FF5533;
    }


    .react-select__option--is-focused{
      background-color: #FABBAE;
    }
    .react-select__option--is-selected{
      background-color: #F55533;
    }
  }
`;
// // 
// const SelectOptionsContent = styled.div`
//   position: relative;
//   top: 0;
// `;

// const SelectArrowContent = styled.div`
//   width: 5%;
// `;

const SelectWrapper = styled.label`
  z-index: 3;
  margin: 3px 0px 0px 5px;
  width: 100%;
`;

const Container = styled.div`

  .SelectInput {    
    ${({ hasTag, width }) => hasTag && `width: ${width}px`};
    ${({ hasTag }) => hasTag === false && 'min-width: 300px'};
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.textColor};
    ${() => Paragraph16px300Light};

    &::placeholder {
      ${({ hasTag }) => hasTag && 'color: transparent'};
      opacity: 0;
      transition: .2s ease-in-out;
    }

    &:focus {
      &:empty {
        &::placeholder {
          width: 300px;
          opacity: 1;
          transition: .2s ease-in-out;
        }
      }
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .css-at12u2-loadingIndicator {
    margin-right: 12px;
  }
`

export {
  Container,
  SelectContent,
  SelectWrapper,
  SelectInputContent,
  Label,
  LabelContent,
  LabelRequired

}