const CategoryForm = {
  name: {
    value: '',
    isRequired: true,
    isValid: false,
    placeholder: 'enterANameForTheCategory.message',
    errorsMsg: {
      required: 'thisFieldIsRequired.message',
    },
  },
  active: {
    value: true,
    isRequired: true,
    isValid: true,
  },
  from: {
    value: '',
  },
  file: {
    value: '',
    isRequired: false,
    isValid: true,
  },
  touched: false,
};

export default CategoryForm;
