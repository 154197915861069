import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { ReactComponent as Logo } from "../../../assets/logo_horizontal.svg";

const AsideContainer = styled.div`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  margin-right: 8px;
  z-index: 1;

  ${media.md`
    min-width: 250px;
    max-width: 250px;
  `};

  ${media.lg`
    min-width: 275px;
    max-width: 275px;
  `};
`;

const LogoContainer = styled.div`
  margin: 16px 0 40px 24px;
  justify-content: center;
  display: flex;
  height: 100px;
  svg {
    width: 100px;
    height: 40px;
  }
`;

const ImageLogo = styled(Logo)``;

const MenuIcon = styled.i`
  position: relative;
  margin-right: 30px;

  svg {
    path {
      fill: ${({ theme, isactive }) =>
        isactive === "true"
          ? theme.colors.mainColorContrast
          : theme.colors.mainColor};
    }
  }
  * {
    position: absolute;
    top: ${({ top }) => top || "0px"};
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  height: 100%;
  min-height: 60%;
`;

const SelectContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 12px;
`;

const SelectTittle = styled.h4`
  text-align: center;
  color: #ff558f;
  margin-bottom: 14px;
`;

const SelectSubTittle = styled.h5`
  text-align: center;
  color: #c0c0c0;
  margin-bottom: 15px;
  span {
    color: #000;
  }
  margin-bottom: 14px;
`;

const ContainerAdmin = styled.div`
  margin-bottom: 24px;
`;

export {
  AsideContainer,
  MenuContainer,
  LogoContainer,
  MenuIcon,
  ImageLogo,
  SelectContainer,
  SelectTittle,
  SelectSubTittle,
  ContainerAdmin,
};
