import React, { useEffect, useState } from "react";
import Mask from "react-input-mask";
import PropTypes from "prop-types";
import ModalMytm from "../../../../../components/modal-mytm/ModalMytm";
import ScheduleForm from "./scheduleForm";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import {
  NewSchedulesFooter,
  NewSchedulesButtonSave,
  NewSchedulesButtonGoBack,
  NewSchedulesForm,
  NewSchedulesWrapper,
  NewSchedulesInputGroupWithToggle,
  NewSchedulesInput,
  NewScheduleCheckBox,
  NewSchedulesInputGroup,
  NewSchedulesTitle,
  NewSchedulesInputTitle,
  NewSchedulesInputContent,
} from "./Styles";
import formUtils from "../../../../../utils/form-utils";
import restSchedules from "../../../../../api/schedules/rest-schedules";
import dayjs from "dayjs";
import TranslatorFunction from '../../../../../components/I18n/Translator'

const EditSchedules = (props) => {
  const language = TranslatorFunction({ path: 'language.message' });
  const sunday = TranslatorFunction({ path: 'sunday.message' });
  const monday = TranslatorFunction({ path: 'monday.message' });
  const tuesday = TranslatorFunction({ path: 'tuesday.message' });
  const wesnesday = TranslatorFunction({ path: 'wesnesday.message' });
  const thursday = TranslatorFunction({ path: 'thursday.message' });
  const friday = TranslatorFunction({ path: 'friday.message' });
  const saturday = TranslatorFunction({ path: 'saturday.message' });
  const to = TranslatorFunction({ path: 'to.message' });
  const from = TranslatorFunction({ path: 'from.message' });
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const time = TranslatorFunction({ path: 'zeit.message' });

  const days = [
    sunday,
    monday,
    tuesday,
    wesnesday,
    thursday,
    friday,
    saturday,
  ];
  const { open, onClose, schedule } = props;
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [isEditMode, setIsEditMode] = useState(false);
  const [form, setForm] = useState({ ...ScheduleForm });
  const disableSubmitButton = () => !formUtils.isFormValid(form);

  const putSchedules = (newForm) => {
    setIsLoading(true);
    onClose();
    restSchedules
      .putSchedule(schedule.id, newForm)
      .then((res) => handleResponseSuccess(res))
      .catch((err) => handleResponseError(err, "ERROR"));
  };

  const handleResponseSuccess = () => {

    setForm({ ...ScheduleForm });
    setOpenModal(false);
    setIsLoading(false);
    setIsEditMode(false);
  };

  const handleResponseError = () => {
    setForm({ ...ScheduleForm });
    setIsLoading(false);
    setOpenModal(false);
  };

  const formTransform = () => {
    let newForm = { ...form };
    newForm.hours.forEach((day) => {
      day.opened1 =
        parseInt(day.opened1.split(":")[0]) * 60 +
        parseInt(day.opened1.split(":")[1]);
      day.opened2 =
        parseInt(day.opened2.split(":")[0]) * 60 +
        parseInt(day.opened2.split(":")[1]);
      day.closed1 =
        parseInt(day.closed1.split(":")[0]) * 60 +
        parseInt(day.closed1.split(":")[1]);
      day.closed2 =
        parseInt(day.closed2.split(":")[0]) * 60 +
        parseInt(day.closed2.split(":")[1]);
    });
    return newForm;
  };

  const handleOnClickSave = () => {
    let newForm = formTransform();
    putSchedules(newForm);
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose();
    setForm({ ...ScheduleForm });
  };

  const handleOnChangeCheckBox = (event) => {
    const { name, value } = event;
    document.getElementById(name).disabled = !value;
    let newForm = { ...form };
    newForm.hours.map((day, index) =>
      index === parseInt(name) ? (day.active = value) : ""
    );
    setForm(newForm);
  };

  const handleOnChangeInput = (event) => {
    const { value, name, id } = event.target;
    let newForm = { ...form };

    newForm.hours[id][name] = value;

    setForm(newForm);
  };

  const initEditMode = () => {
    let newForm = { ...form };

    newForm.hours = schedule.hours;
    if (
      schedule?.hours.length > 0 &&
      typeof newForm?.hours[0]?.opened1 === "number"
    ) {
      newForm.hours.forEach((day, index) => {
        day.opened1 = dayjs()
          .hour(parseInt(schedule.hours[index].opened1 / 60))
          .minute(schedule.hours[index].opened1 % 60)
          .format("HH:mm");
        day.closed1 = dayjs()
          .hour(parseInt(schedule.hours[index].closed1 / 60))
          .minute(schedule.hours[index].closed1 % 60)
          .format("HH:mm");
        day.opened2 = dayjs()
          .hour(parseInt(schedule.hours[index].opened2 / 60))
          .minute(schedule.hours[index].opened2 % 60)
          .format("HH:mm");
        day.closed2 = dayjs()
          .hour(parseInt(schedule.hours[index].closed2 / 60))
          .minute(schedule.hours[index].closed2 % 60)
          .format("HH:mm");
      });
    }
    setForm(newForm);
  };

  const timeMask = (value) => {
    if (Number.isInteger(value)) {
      return "";
    }
    const chars = value.split("");
    const hours = [/[0-2]/, chars[0] === "2" ? /[0-3]/ : /[0-9]/];

    const minutes = [/[0-5]/, /[0-9]/];
    return hours.concat(":").concat(minutes);
  };

  useEffect(() => {
    setOpenModal(open);
    setForm({ ...ScheduleForm });
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (Object.keys(schedule).includes("nameOperation")) {
      setIsEditMode(true);
      initEditMode();
    }
    // eslint-disable-next-line
  }, [schedule]);

  useEffect(() => {
    if (!open) {
      setIsEditMode(false);
    }
  }, [open]);
// schedule.translatedName && schedule.translatedName.length > 0 ? schedule.translatedName[0][language] : schedule?.nameOperation;
  return (
    <ModalMytm open={openModal} isLoading={isLoading}>
      <NewSchedulesWrapper>
        {console.log('schedule',schedule)}
        <NewSchedulesTitle>{time} {schedule?.translatedName && schedule?.translatedName.length > 0 ? schedule?.translatedName[0][language] : schedule?.nameOperation}</NewSchedulesTitle>
        <NewSchedulesForm>
          {form.hours?.map((day, index) => (
            <NewSchedulesInputGroupWithToggle key={index}>
              <NewSchedulesInputGroup>
                <NewScheduleCheckBox>
                  <Checkbox
                    name={`${index}`}
                    value={day?.active}
                    onChange={handleOnChangeCheckBox}
                  >
                    {days[index]}
                  </Checkbox>
                </NewScheduleCheckBox>
                <NewSchedulesInputContent>
                  <NewSchedulesInputTitle>{from}</NewSchedulesInputTitle>
                  {day.active ? (
                    <Mask
                      mask={timeMask(day?.opened1)}
                      value={day.active ? day.opened1 : ""}
                      onChange={handleOnChangeInput}
                    >
                      <NewSchedulesInput
                        name="opened1"
                        id={index}
                        placeholder="00:00"
                      />
                    </Mask>
                  ) : (
                    <NewSchedulesInput
                      value={day.active ? day.opened1 : ""}
                      onChange={handleOnChangeInput}
                      name="opened1"
                      id={index}
                      disabled
                    />
                  )}
                </NewSchedulesInputContent>
                <NewSchedulesInputContent>
                  <NewSchedulesInputTitle>{to}</NewSchedulesInputTitle>
                  {day.active ? (
                    <Mask
                      mask={timeMask(day?.opened1)}
                      value={day.active ? day.closed1 : ""}
                      onChange={handleOnChangeInput}
                    >
                      <NewSchedulesInput
                        name="closed1"
                        id={index}
                        placeholder="00:00"
                      />
                    </Mask>
                  ) : (
                    <NewSchedulesInput
                      value={day.active ? day.closed1 : ""}
                      onChange={handleOnChangeInput}
                      name="closed1"
                      id={index}
                      disabled
                    />
                  )}
                </NewSchedulesInputContent>
                <NewSchedulesInputContent>
                  <NewSchedulesInputTitle>{from}</NewSchedulesInputTitle>
                  {day.active ? (
                    <Mask
                      mask={timeMask(day?.opened1)}
                      value={day.active ? day.opened2 : ""}
                      onChange={handleOnChangeInput}
                    >
                      <NewSchedulesInput
                        name="opened2"
                        id={index}
                        placeholder="00:00"
                      />
                    </Mask>
                  ) : (
                    <NewSchedulesInput
                      value={day.active ? day.opened2 : ""}
                      onChange={handleOnChangeInput}
                      name="opened2"
                      id={index}
                      disabled
                    />
                  )}
                </NewSchedulesInputContent>
                <NewSchedulesInputContent>
                  <NewSchedulesInputTitle>{to}</NewSchedulesInputTitle>
                  {day.active ? (
                    <Mask
                      mask={timeMask(day?.opened1)}
                      value={day.active ? day.closed2 : ""}
                      onChange={handleOnChangeInput}
                    >
                      <NewSchedulesInput
                        name="closed2"
                        id={index}
                        placeholder="00:00"
                      />
                    </Mask>
                  ) : (
                    <NewSchedulesInput
                      value={day.active ? day.closed2 : ""}
                      onChange={handleOnChangeInput}
                      name="closed2"
                      id={index}
                      disabled
                    />
                  )}
                </NewSchedulesInputContent>
              </NewSchedulesInputGroup>
            </NewSchedulesInputGroupWithToggle>
          ))}
          <NewSchedulesFooter>
            <NewSchedulesButtonSave
              type="button"
              disabled={disableSubmitButton()}
              onClick={handleOnClickSave}
            >
              {saveEditions}
            </NewSchedulesButtonSave>
            <NewSchedulesButtonGoBack
              type="button"
              btnType="tertiary"
              onClick={handleOnClickGoBack}
            >
              {back}
            </NewSchedulesButtonGoBack>
          </NewSchedulesFooter>
        </NewSchedulesForm>
      </NewSchedulesWrapper>
    </ModalMytm>
  );
};

EditSchedules.defaultProps = {
  schedule: {},
  open: false,
  onClose: () => {},
};

EditSchedules.propTypes = {
  schedule: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditSchedules;
