import styled from 'styled-components';
import { IconAdd } from '../../styles/icons';
import { Paragraph16px300Light } from '../../styles/style-guide';
import Button from '../button/Button';

const EmptyScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 155px);
`;

const EmptyScreenParagraph = styled.p`
  ${Paragraph16px300Light};
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.textColor};
`;

const EmptyScreenButtonIconAdd = styled(IconAdd)`
  background-color: #ffffff;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  bottom: 0;
  margin-right: 6px;
`;

const EmptyScreenButton = styled(Button)`
  padding: 0 14px;
`;

const EmptyScreenButtonContent = styled.div`
  margin-top: 24px;
`;

export {
  EmptyScreenWrapper,
  EmptyScreenParagraph,
  EmptyScreenButtonIconAdd,
  EmptyScreenButton,
  EmptyScreenButtonContent,
};
