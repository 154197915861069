import styled, { keyframes } from 'styled-components';

const AnimationSpin = keyframes`
  0% {
     transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

const LoaderContainer = styled.div`
  display: block;
  top: 12px;
  right: 15.5px;
`;

const LoaderCircle = styled.i`
  position: relative;
  height: 50px;
  width: 50px;
  display: inline-block;
  animation: ${AnimationSpin} 3s infinite;

  &::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 5px;
    border-color: ${({ theme }) => `${theme.colors.mainColor} ${theme.colors.mainColor} ${theme.colors.textColorDisabled} ${theme.colors.textColorDisabled}`};
    border-style: solid;
    border-radius: 50px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: ${AnimationSpin} 3s ease-in-out infinite;
  }

  &::before {
    animation: ${AnimationSpin} 3s ease-in-out infinite;
    background: transparent;
  }
`;

export {
  LoaderContainer,
  LoaderCircle,
};
