import HttpClient from "../http-client";
import { id } from "../helper-client";

const putSchedule = async (hoursId, data) => {
  var a = HttpClient.put(`officeHours/${hoursId}?userId=${id ?? ""}`, data);
  return a;
};

const fetchSchedules = async () =>
  HttpClient.get(`officeHours?userId=${id ?? ""}`);

export default {
  putSchedule,
  fetchSchedules,
};
