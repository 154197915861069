import React from "react";

import { TableCol } from "../../Styles";
import TranslatorFunction from '../../../I18n/Translator';


function TypeCol({ obj, isCoupon }) {
  
  const freeShipping = TranslatorFunction({ path: "freeShipping.message" });
  const discount = TranslatorFunction({ path: "discount.message" });

  return isCoupon ? (
    <TableCol>
      {obj?.type === "shipping" ? freeShipping.toUpperCase() : discount.toUpperCase() }
    </TableCol>
  ) : null;
}

export default TypeCol;
