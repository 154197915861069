import HttpClient from "../http-client";
import { id } from "../helper-client";

const postSubscription = async (data) =>
  HttpClient.post(`payment/subscriptions?userId=${id}`, data);

const getSubscriptions = async () =>
  HttpClient.get(`payment/subscriptions?userId=${id}`);

const deleteSubscription = async () =>
  HttpClient.delete(`payment/subscriptions?userId=${id}`);

const updateSubscription = async () =>
  HttpClient.put(`payment/subscriptions?userId=${id}`);

export default {
  postSubscription,
  getSubscriptions,
  deleteSubscription,
  updateSubscription,
};
