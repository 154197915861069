import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import InputSearch from "../../../../../components/inputs/mui-input-search/input-search";
import TableDnD from "../../../../../components/table/TableDnD";
import { ListCategoriesTableWrapper, SearchContainer } from "./Styles";
import restCategories from "../../../../../api/categories/rest-categories";
import {
  orderAlphabetical,
  orderByStatus,
} from "../../../../../utils/order-functions";
import user from "../../../../../utils/user";
import { useMemo } from "react";
import { Translator } from '../../../../../components/I18n';
import TranslatorFunction from '../../../../../components/I18n/Translator';

const ListCategories = ({ categories, onClickEditCategory }) => {
  const yes = TranslatorFunction({ path: "yes.message" });
  const no = TranslatorFunction({ path: "no.message" });
  const [data, setData] = useState(categories);
  const colKeyAligns = { name: "left" };
  const colTitleKeysAlign = ["left", "left"];
  const categoryKeys = ["name"];
  const listTitles = [<Translator path="image.message" />, <Translator path="nameUpper.message" />, <Translator path="status.message" />, <Translator path="action.message" />];
  const [searchs, setSearchs] = useState({ active: no });

  const disabledTableKeys = [
    {
      key: user.getHighlight(),
      tooltip: <Translator path="theHighlightsCategoryCannotBeEdited.message" />,
      action: "edit",
    },
  ];

  const orderTitles = [
    {
      colKey: "name",
      titleKey: "NOME",
      titleKeyIndex: 1,
      tooltipUpText: <Translator path="sortFromAZ.message" />,
      tooltipDownText: <Translator path="sortFromZA.message" />,
    },
    {
      colKey: "name",
      titleKey: "STATUS",
      titleKeyIndex: 2,
      tooltipUpText: <Translator path="sortByActive.message" />,
      tooltipDownText: <Translator path="sortByInactive.message" />,
    },
  ];

  const orderAlphabeticalData = (order) => {
    setData((oldData) => [...orderAlphabetical(oldData, "name", order)]);
  };

  const orderByActiveStatus = (status) => {
    setData((oldData) => [...orderByStatus(oldData, "active", status)]);
  };

  const handleOnClickOrderStatus = (arrow) => {
    if (arrow === "up") {
      orderByActiveStatus(true);
      return;
    }
    orderByActiveStatus(false);
  };

  const handleOnClickOrderName = (arrow) => {
    if (arrow === "up") {
      orderAlphabeticalData("ASC");
      return;
    }
    orderAlphabeticalData("DSC");
  };

  const handleOnClickTableOrder = (title, arrow) =>
    title === "NOME"
      ? handleOnClickOrderName(arrow)
      : handleOnClickOrderStatus(arrow);

  const putCategory = (category) => {
    restCategories
      .putCategory(category._id, { active: category.active })
      .then((res) => console.log(res));
  };

  const handleOnChangeActiveToggle = (selectedCategory) => {
    const categoryIndex = data.findIndex(
      (cat) => cat._id === selectedCategory._id
    );
    setData((oldData) => {
      const newData = [...oldData];
      newData[categoryIndex].active = !newData[categoryIndex].active;
      return newData;
    });
    putCategory(data[categoryIndex]);
  };

  const filteredCategoriesByActive = (category) => {
    const active = searchs.active === yes;
    if (!active) return true;
    return category.active === active;
  };

  const filteredCategories = useMemo(
    () => data.filter(filteredCategoriesByActive),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, data]
  );

  useEffect(() => {
    setData(categories);
  }, [categories]);

  return (
    <ListCategoriesTableWrapper>
      {data && (
        <SearchContainer>
          <MuiAutoComplete
            id="search"
            options={[yes, no]}
            renderInput={(params) => (
              <InputSearch
                {...params}
                margin="dense"
                label={<Translator path="listActiveOnly.message" />}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
            defaultValue={searchs.active}
            onInputChange={(e, value) => {
              setSearchs((state) => ({
                ...state,
                active: value,
              }));
            }}
            onSelect={(e) => {
              setSearchs((state) => ({
                ...state,
                active: e.target.value,
              }));
            }}
          />
        </SearchContainer>
      )}
      <TableDnD
        data={filteredCategories}
        setData={setData}
        titles={listTitles}
        colKeysAlign={colKeyAligns}
        colTitleKeysAlign={colTitleKeysAlign}
        keys={categoryKeys}
        withImage
        withStatus
        withStarCategory={false}
        statusKey="active"
        imageKey="imageURL"
        onClickEdit={onClickEditCategory}
        onClickToogle={handleOnChangeActiveToggle}
        orderTitles={orderTitles}
        onClickOrder={handleOnClickTableOrder}
        disabledKeys={disabledTableKeys}
        objectKey="_id"
        isCategory
      />
    </ListCategoriesTableWrapper>
  );
};

ListCategories.defaultProps = {
  categories: [],
  onClickEditCategory: () => { },
};

ListCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  onClickEditCategory: PropTypes.func,
};

export default ListCategories;
