import React from "react";
import dayjs from "dayjs";

import { TableCol } from "../../Styles";

function ValidateCol({ obj, isCoupon }) {
  return isCoupon ? (
    <TableCol>{dayjs(obj?.endendAt).format("DD/MM/YYYY")}</TableCol>
  ) : null;
}

export default ValidateCol;
