import styled from "styled-components";
import { IconMandatory } from "../../../../../styles/icons";
import { Paragraph16px300Light } from "../../../../../styles/style-guide";

const DetailsFormWrapper = styled.div``;

const DetailsFormContent = styled.form``;

const DetailsFormHeader = styled.header``;

const DetailsFormLeftLabel = styled.label`
  float: left;
`;

const DetailsFormRightLabel = styled.label`
  float: right;
`;

const DetailsFormIconMandatory = styled(IconMandatory)`
  margin-right: 3px;
  margin-bottom: 1px;
`;

const DetailsFormRow = styled.div`
  display: flex;
  width: 100%;
`;

const DetailsFormButtonsContent = styled.div`
  margin: 20px 0px 20px 8px;
`;

const DetailsFormColumn = styled.div`
  ${({
    // eslint-disable-next-line indent
    widthPercentual,
    marginRight,
    marginTop,
    marginBottom,
    // eslint-disable-next-line indent
  }) => `
    width: ${widthPercentual || 100}%;
    margin-right: ${marginRight || 0}px;
    margin-top:  ${marginTop || 12}px;
    margin-bottom: ${marginBottom || 12}px;
  `};
`;

const DetailsFormOperationsContent = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const DetailsFormAccessDataContent = styled.div`
  margin-top: 24px;
`;

const DetailsFormUserContent = styled.div`
  margin-top: 24px;
`;

const DetailsFormParagraph = styled.p`
  ${Paragraph16px300Light};
  margin: 8px 0px;
`;

export {
  DetailsFormWrapper,
  DetailsFormContent,
  DetailsFormHeader,
  DetailsFormLeftLabel,
  DetailsFormRightLabel,
  DetailsFormIconMandatory,
  DetailsFormRow,
  DetailsFormColumn,
  DetailsFormParagraph,
  DetailsFormButtonsContent,
  DetailsFormOperationsContent,
  DetailsFormAccessDataContent,
  DetailsFormUserContent,
};
