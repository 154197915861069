import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalGenericConfirmationWrapper,
  ModalGenericConfirmationContainer,
  ModalGenericConfirmationContent,
  ModalGenericConfirmationDataContent,
  ModalGenericConfirmationImageContent,
  ModalGenericConfirmationButtonsContent,
  ModalGenericConfirmationButtonLeftContent,
} from './Styles';
import { ModalTitle } from '../modal-mytm/Styles';
import { ReactComponent as ImageConfirmation } from '../../assets/confirmatio-desctructive.svg';
import Button from '../button/Button';

const ModalGenericConfirmation = (props) => {
  const {
    message, btnOneText, btnTwoText, open,
    btnThreeText, onClickBtnOne, onClickBtnTwo,
    onClickBtnThree, title,
  } = props;
  return (
    <ModalGenericConfirmationWrapper open={open}>
      <ModalGenericConfirmationContainer>
        <ModalGenericConfirmationContent>
          <ModalGenericConfirmationDataContent>
            <ModalTitle>
              { title }
            </ModalTitle>
            { message }
          </ModalGenericConfirmationDataContent>
          <ModalGenericConfirmationImageContent>
            <ImageConfirmation />
          </ModalGenericConfirmationImageContent>
        </ModalGenericConfirmationContent>
        <ModalGenericConfirmationButtonsContent>
          <ModalGenericConfirmationButtonLeftContent>
            <Button onClick={onClickBtnOne}>{ btnOneText }</Button>
            <Button onClick={onClickBtnTwo} btnType="secondary">{ btnTwoText }</Button>
          </ModalGenericConfirmationButtonLeftContent>
          <Button onClick={onClickBtnThree} btnType="tertiary">{ btnThreeText }</Button>
        </ModalGenericConfirmationButtonsContent>
      </ModalGenericConfirmationContainer>
    </ModalGenericConfirmationWrapper>
  );
};

ModalGenericConfirmation.defaultProps = {
  open: false,
  title: 'Tem certeza que deseja fazer isso?',
  message: '',
  btnOneText: 'SALVAR ALTERAÇÕES E SAIR',
  btnTwoText: 'SAIR SEM SALVAR',
  btnThreeText: 'VOLTAR',
  onClickBtnOne: () => {},
  onClickBtnTwo: () => {},
  onClickBtnThree: () => {},
};

ModalGenericConfirmation.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  btnOneText: PropTypes.string,
  btnTwoText: PropTypes.string,
  btnThreeText: PropTypes.string,
  onClickBtnOne: PropTypes.func,
  onClickBtnTwo: PropTypes.func,
  onClickBtnThree: PropTypes.func,
};

export default ModalGenericConfirmation;
