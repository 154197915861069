import HttpClient from "../http-client";
import { id } from "../helper-client";

const postCategory = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return HttpClient.post(`categories?userId=${id ?? ""}`, formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
};

const putCategory = async (catId, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return HttpClient.put(`categories/${catId}?userId=${id ?? ""}`, formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
};

const fetchCategories = async () =>
  HttpClient.get(`categories?userId=${id ?? ""}`);

const putCategoryOrder = async (data) => {
  return HttpClient.put(`orderCategory?userId=${id ?? ""}`, data);
};

export default {
  postCategory,
  putCategory,
  fetchCategories,
  putCategoryOrder,
};
