import styled from "styled-components";
import { Col, Container, Row } from "styled-bootstrap-grid";
import {
  Paragraph16px300Light,
  Hint9px400Regular,
  Description12px400Regular,
} from "../../../../../../styles/style-guide";
import CardSubtitle from "../../../../../../components/styled/CardSubtitle";
import MUITextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const LabelSubtitle = styled(CardSubtitle)`
  float: left;
`;

const LabelDescription = styled.label`
  ${() => Description12px400Regular};
  float: right;
`;

const LabelMainColor = styled.label`
  color: ${({ theme }) => theme.colors.mainColor};
`;

const FormContainer = styled.form`
  display: flex;
  height: 100%;
`;

const DataCardContent = styled.div`
  height: calc(150vh - 240px);
  height: auto;
`;

const DataContainer = styled.div`
  width: 70%;
`;

const PhotoContainer = styled.div`
  width: 30%;
`;

const DataContent = styled.div`
  ${({ marginBottom }) => marginBottom && "margin-bottom: 70px"};
`;

const InputNameContent = styled.div`
  width: 70%;
  margin-right: 24px;
`;

const InputDescriptionContent = styled.div`
  width: 100%;
  margin-right: 24px;
`;

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "#FF5533",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c6c6c6",
      },
      "&:hover fieldset": {
        borderColor: "#808080",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FF5533",
      },
    },
  },
})(MUITextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const InputValorContent = styled.div`
  margin-right: 10px;
  width: 20%;
`;

const ParagraphPhoto = styled.p`
  ${() => Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 16px;
`;

const PhotoCardContent = styled.div`
  height: calc(100vh - 240px);
  height: auto;
`;

const NewProductFormPhotoArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const NewProductFormPhotoAreaContent = styled.div`
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 486px;
`;

const NewProductFormBSContainer = styled(Container)`
  padding: 0px;
`;

const NewProductFormBSRow = styled(Row)`
  margin: 0;
  ${({ height }) => height && `min-height: ${height}`};
`;

const NewProductFormBsCol = styled(Col)`
  ${({ noPadding }) => noPadding && "padding: 0"};
  ${({ noPaddingRight }) => noPaddingRight && "padding-right: 0"};
  ${({ noPaddingLeft }) => noPaddingLeft && "padding-left: 0"};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
`;

const NewProductFormButtonsBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;
  * {
    margin-right: 16px;
  }
`;

const CheckBoxContainer = styled.div`
  width: 50%;
`;

const CheckBoxLine = styled.div`
  display: flex;
  width: 100%;

  vertical-align: text-top;
  margin: 0 auto;
  align-content: center;
  align-self: center;
`;

const ComponentsCardContent = styled.div`
  height: calc(100vh - 240px);
  min-height: 500px;
  width: 100%;
  overflow: auto;
`;

const ComponentsSearchBar = styled.div`
  display: flex;
  gap: 1%;

  div:nth-child(1) {
    width: 21%;
  }
  div:nth-child(2) {
    width: 21%;
  }
  div:nth-child(3) {
    width: 10%;
  }
  div:nth-child(4) {
    width: 21%;
  }
  div:nth-child(5) {
    width: 21%;
  }
`;

const ButtonBox = styled.div`
  position: relative;
  top: 35px;
`;

const InputSpanCounter = styled.span`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme, haserror }) =>
    haserror === "true"
      ? theme.colors.statusColorDanger
      : theme.colors.mainColor};
  margin-right: 10px;
  margin-top: -15px;
  ${() => Hint9px400Regular};
`;

export {
  LabelSubtitle,
  LabelDescription,
  LabelMainColor,
  FormContainer,
  DataContainer,
  CheckBoxContainer,
  DataCardContent,
  DataContent,
  InputNameContent,
  InputValorContent,
  InputDescriptionContent,
  PhotoContainer,
  ParagraphPhoto,
  PhotoCardContent,
  ComponentsCardContent,
  CheckBoxLine,
  ComponentsSearchBar,
  ButtonBox,
  NewProductFormBSContainer,
  NewProductFormBsCol,
  NewProductFormBSRow,
  NewProductFormPhotoArea,
  NewProductFormPhotoAreaContent,
  NewProductFormButtonsBar,
  InputSpanCounter,
  useStyles,
  CssTextField,
};
