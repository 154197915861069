import styled from 'styled-components';
import ModalMytm from '../../../../../../../components/modal-mytm/ModalMytm';
import { ReactComponent as CancelSubscriptionSVG } from '../../../../../../../assets/cancel-subscription.svg';
import { Paragraph16px300Light } from '../../../../../../../styles/style-guide';

const SignaturesPlanCancelModalWrapper = styled(ModalMytm)`
  .modal_mytm {
    width: 736px;
    height: 416px;
    padding: 24px;
  };
`;

const SignaturesPlanCancelModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SignaturesPlanCancelModalMainContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const SignaturesPlanCancelModalLeftContent = styled.div`
  width: 55%;
  height: 100%;
`;

const SignaturesPlanCancelModalRightContent = styled.div`
  width: 45%;
  height: 100%;
`;

const SignaturesPlanCancelModalButtonsContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SignaturesPlanCancelModalParagraph = styled.p`
  ${Paragraph16px300Light};
`;

const SignaturesPlanCancelModalImage = styled(CancelSubscriptionSVG)``;

export {
  SignaturesPlanCancelModalWrapper,
  SignaturesPlanCancelModalButtonsContent,
  SignaturesPlanCancelModalMainContent,
  SignaturesPlanCancelModalLeftContent,
  SignaturesPlanCancelModalRightContent,
  SignaturesPlanCancelModalImage,
  SignaturesPlanCancelModalParagraph,
  SignaturesPlanCancelModalContent,
};
