import styled from "styled-components";
import { Paragraph16px300Light } from "../../../styles/style-guide";

export const TableInput = styled.input`
  border: none;
  width: 100%;
  height: 50%;
  padding-left: 5px;
  border: 1px solid #ff5533;
  border-radius: 5px;
  outline: #ff5533;
  text-align: ${({ textAlign }) => textAlign || "center"};
  ${Paragraph16px300Light};

  background-color: transparent;

  &:nth-child(even) {
    background-color: rgba(230, 230, 230, 0.3);
  }
`;
