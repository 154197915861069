import React from 'react';
import PropTypes from 'prop-types';
import { TooltipContainer, TooltipMessage } from './Styles';

const Tooltip = ({
  message, children, changeCursor, position,
}) => (
  <TooltipContainer changeCursor={changeCursor}>
    <TooltipMessage position={position}>{message}</TooltipMessage>
    {children}
  </TooltipContainer>
);

Tooltip.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  changeCursor: PropTypes.bool,
  message: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element]).isRequired,
};

Tooltip.defaultProps = {
  position: 'top',
  changeCursor: true,
};

export default Tooltip;
