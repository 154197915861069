import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import restCategories from "../../../../api/categories/rest-categories";
import ListCategories from "./list-categories/ListCategories";
import NewCategory from "./new-category/NewCategory";
import EmptyScreen from "../../../../components/empty-screen/EmptyScreen";

const Categories = ({ openNewCategory, onHideNewCategory, onLoad }) => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openNewCategoryModal, setOpenNewCategoryModal] = useState(
    openNewCategory
  );

  const handleOnClickEditCategory = (selectedCategory) => {
    setCategory(selectedCategory);
    setOpenNewCategoryModal(true);
  };

  const handleOnLoad = (value) => {
    onLoad(value);
    setIsLoading(value);
  };

  const handleOnClickEmptyScreenButton = () => {
    setOpenNewCategoryModal(true);
  };

  const handleResponseSuccess = (response) => {
    setCategories(response);
    handleOnLoad(false);
  };

  const fetchCategories = () => {
    handleOnLoad(true);
    restCategories
      .fetchCategories()
      .then((res) => handleResponseSuccess(res.data.categories))
      .catch(() => handleOnLoad(false));
  };

  const handleOnModalClose = () => {
    setOpenNewCategoryModal(false);
    setCategory({});
    onHideNewCategory();
    fetchCategories();
  };

  useEffect(() => {
    fetchCategories();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOpenNewCategoryModal(openNewCategory);
  }, [openNewCategory]);

  const renderCategories = () =>
    !isLoading && categories.length === 0 ? (
      <EmptyScreen onClickButton={handleOnClickEmptyScreenButton} />
    ) : (
      <ListCategories
        categories={categories}
        onClickEditCategory={handleOnClickEditCategory}
      />
    );

  return (
    <>
      {renderCategories()}
      <NewCategory
        open={openNewCategoryModal}
        category={category}
        onClose={handleOnModalClose}
      />
    </>
  );
};

Categories.defaultProps = {
  openNewCategory: false,
  onHideNewCategory: () => {},
  onLoad: () => {},
};

Categories.propTypes = {
  openNewCategory: PropTypes.bool,
  onHideNewCategory: PropTypes.func,
  onLoad: PropTypes.func,
};

export default Categories;
