import styled from 'styled-components';
import ModalMytm from '../../../../components/modal-mytm/ModalMytm';
import { ReactComponent as OpenStoreSVG } from '../../../../assets/open-store.svg';
import { Paragraph16px300Light, Paragraph16px600Medium } from '../../../../styles/style-guide';

const ModalOnboardingOpenStoreImage = styled(OpenStoreSVG)``;

const ModalMytmOnboarding = styled(ModalMytm)`
  .modal_mytm {
    width: 736px;
    height: 416px;
    padding: 24px;
  }
`;

const ModalOnboardingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalOnboardingContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ModalOnboardingLeftContent = styled.div`
  width: 60%;
`;

const ModalOnboardingRightContent = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalOnboardingFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & :last-child {
    margin-right: 16px;
  }
`;

const ModalOnboardingParagraph = styled.p`
  ${Paragraph16px300Light};
  margin-top: 32px;
  width: 370px;
`;

const ModalOnboardingColor = styled.span`
  color: ${({ theme }) => theme.colors.mainColor};
  ${Paragraph16px600Medium};
`;

export {
  ModalMytmOnboarding,
  ModalOnboardingContainer,
  ModalOnboardingContent,
  ModalOnboardingLeftContent,
  ModalOnboardingRightContent,
  ModalOnboardingOpenStoreImage,
  ModalOnboardingFooter,
  ModalOnboardingParagraph,
  ModalOnboardingColor,
};
