export default {
  translations: {
    language: {
      message: "ja",
    },
    LoginFormTitle: {
      message: "ログイン"
    },
    LoginFormSubtitle: {
      message: "こんにちは,メールアドレスとパスワードを入力してログインしてください:"
    },
    LoginFormErroMessage: {
      message: "ログインできません。もう一度お試しください。"
    },
    LoginFormButton: {
      message: "ログイン"
    },
    LoginFormButtonForgotPassword: {
      message: "パスワードを忘れました"
    },
    password: {
      message: "パスワード"
    },
    confirmPassword: {
      message: "パスワードを認証する"
    },
    mail: {
      message: "Eメール"
    },
    myEstablishment: {
      message: "私の施設"
    },
    parameterizaton: {
      message: "パラメータ化"
    },
    itemRegistration: {
      message: "オブジェクトの登録"
    },
    orders: {
      message: "リクエスト"
    },
    discountCoupons: {
      message: "割引クーポン"
    },
    logout: {
      message: "外出"
    },
    establishmentDetails: {
      message: "exit プロパティの詳細"
    },
    subscriptions: {
      message: "サブスクリプション"
    },
    aboutTheEstablishment: {
      message: "設立について"
    },
    mandatoryFilling: {
      message: "必須記入"
    },
    tradeName: {
      message: "商号（事業所を知らせる名称）"
    },
    companyCorporateName: {
      message: "社会的理由"
    },
    informTheCompanyCorporateName: {
      message: "会社の社名を知らせる"
    },
    pixel: {
      message: "ピクセル"
    },
    informPixel: {
      message: "ピクセルを報告する"
    },
    invalidCnpj: {
      message: "無効な CNPJ"
    },
    invalidCep: {
      message: "無効な郵便番号"
    },
    state: {
      message: "州"
    },
    city: {
      message: "街"
    },
    cityUpper: {
      message: "街"
    },
    neighborhoods: {
      message: "近所"
    },
    street: {
      message: "道"
    },
    number: {
      message: "Número"
    },
    informTheNumber: {
      message: "番号を入力してください番号"
    },
    complement: {
      message: "補体"
    },
    complementUpper: {
      message: "補体"
    },
    URLToYourMenu: {
      message: "あなたのメニューへのURL"
    },
    emailToLoginToTheManager: {
      message: "マネージャーにログインするためのメール"
    },
    name: {
      message: "名前"
    },
    nameUpper: {
      message: "名前"
    },
    lastName: {
      message: "苗字"
    },
    contactEmail: {
      message: "連絡先メールアドレス"
    },
    cellphoneNumberWhatsApp: {
      message: "モバイル / WhatsApp"
    },
    thisFieldIsRequired: {
      message: "この項目は必須です。"
    },
    enterYourCreditCardDetails: {
      message: "クレジット カードの詳細を入力してください:"
    },
    thisIsTheCurrentPaymentMethod: {
      message: "これが現在の支払い方法です:"
    },
    paymentMethod: {
      message: "支払方法："
    },
    paymentMethod2: {
      message: "お支払い方法"
    },
    paymentMethod3: {
      message: "お支払い方法"
    },
    cardNumber: {
      message: "カード番号："
    },
    enterAValidCardNumber: {
      message: "有効なカード番号を入力してください。"
    },
    enterAValidDate: {
      message: "有効な日付を入力してください。"
    },
    cardholderName: {
      message: "クレジットカード名義人氏名。"
    },
    typeAsWrittenOnCard: {
      message: "クレジットカード名義人氏名。"
     },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: "カードに書かれているとおりに名前を入力してください。"
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: "支払いの詳細を保存することにより,MyTripMenu がプランの金額を毎月自動的に請求することを承認したことになります。 利用規約が利用可能です"
    },
    inThisLink: {
      message: "このリンクで。"
    },
    savePaymentData: {
      message: "支払いデータを保存する"
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: "クレジット カードを追加してプラン情報を表示し,サブスクリプションを確認してください。"
    },
    deliveryConfiguration: {
      message: "配信設定"
    },
    schedules: {
      message: "スケジュール"
    },
    Schedules: {
      message: "スケジュール"
    },
    add: {
      message: "追加"
    },
    onSiteServiceVisualization: {
      message: "オンサイトサービス | 視覚化"
    },
    deliveryWhatsappOrders: {
      message: "配信 | WhatsAppによるリクエスト"
    },
    takeAway: {
      message: "撤退"
    },
    catalog: {
      message: "カタログ"
    },
    typesOfService: {
      message: "サービスの種類"
    },
    mandatoryDataForDelivery: {
      message: "配達のための必須データ"
    },
    phone: {
      message: "電話"
    },
    phoneUpper: {
      message: "電話"
    },
    address: {
      message: "住所"
    },
    generalSettings: {
      message: "一般設定"
    },
    freeShippingForPurchasesAbove: {
      message: "上記購入で送料無料"
    },
    noFreeShipping: {
      message: "送料無料"
    },
    minimumValueForDeliveryOrders: {
      message: "配達注文の最小値"
    },
    minimumDeliveryTime: {
      message: "最短納期"
    },
    noForecast: {
      message: "予報なし"
    },
    maximumDeliveryTime: {
      message: "最大配達時間"
    },
    orderResponseTimeInMinutes: {
      message: "注文の応答時間は数分です"
    },
    serviceNumberViaWhats: {
      message: "WHATSAPP 経由のサービス番号"
    },
    servicePlaces: {
      message: "サービス場所"
    },
    action: {
      message: "行動"
    },
    addEditCities: {
      message: "都市の追加/編集"
    },
    newCity: {
      message: "新しい街"
    },
    search: {
      message: "探す"
    },
    addNew: {
      message: "新しく追加する"
    },
    saveEditions: {
      message: "エディションを保存"
    },
    exitWithoutSaving: {
      message: 'セーブせずに終了する'
    },
    saveEditionsAndExit: {
      message: '変更を保存して終了'
    },
    back: {
      message: "戻ってくる"
    },
    status: {
      message: "状態"
    },
    active: {
      message: "アクティブ"
    },
    inactive: {
      message: "非活性"
    },
    category: {
      message: "カテゴリー"
    },
    subcategory: {
      message: "サブカテゴリ"
    },
    categories: {
      message: "カテゴリー"
    },
    subcategories: {
      message: "サブカテゴリ"
    },
    categoriesUpper: {
      message: "カテゴリー"
    },
    subcategoriesUpper: {
      message: "サブカテゴリー"
    },
    complements: {
      message: "アドオン"
    },
    complementsGroup: {
      message: "アドオングループ"
    },
    complementsGroupUpper: {
      message: "補完グループ"
    },
    products: {
      message: "製品"
    },
    image: {
      message: "画像"
    },
    yes: {
      message: "はい"
    },
    no: {
      message: "いいえ"
    },
    theHighlightsCategoryCannotBeEdited: {
      message: "ハイライト カテゴリは編集できません"
    },
    sortFromAZ: {
      message: "A-Zから並べ替え"
    },
    sortFromZA: {
      message: "Z-Aから並べ替え"
    },
    sortByActive: {
      message: "アクティブで並べ替え"
    },
    sortByInactive: {
      message: "非アクティブで並べ替え"
    },
    listActiveOnly: {
      message: "アセットのみのリスト"
    },
    addEditCategory: {
      message: "カテゴリの追加/編集"
    },
    addEditSubcategory: {
      message: "サブカテゴリを追加/編集"
    },
    imageOptional: {
      message: "画像（任意）"
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: "カテゴリへのイラスト画像の追加は任意のアクションですが,メニューをより魅力的にするのに役立ちます."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: "このカテゴリを構成するアイテムを説明する写真を選択し,適切な照明があり,製品の実際のプレゼンテーションに関して現実的です. 一般的な写真,ストック写真,低品質の写真は使用しないでください。"
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: "理想的な解像度は 800x600 ピクセルで,画像は 1 MB を超えることはできません。"
    },
    selectPhoto: {
      message: "写真を選択"
    },
    selectCategory: {
      message: "カテゴリを選んでください"
    },
    saveAndAddAnother: {
      message: "保存して別のものを追加"
    },
    highlightsSubcategoryCannotBeEdited: {
      message: "ハイライト サブカテゴリは編集できません"
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: "サブカテゴリが表示されるカテゴリ"
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: "サブカテゴリへのイラスト画像の追加は任意のアクションですが,メニューをより魅力的にするのに役立ちます."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "このサブカテゴリを構成するアイテムを説明する写真を選択し,適切な照明があり,製品の実際のプレゼンテーションに関して現実的です. 一般的な写真,ストック写真,低品質の写真は使用しないでください。"
    },
    enterANameForTheSubcategory: {
      message: "サブカテゴリの名前を入力してください"
    },
    enterANameForTheCategory: {
      message: 'カテゴリの名前を入力してください'
    },
    descriptionOfComplement: {
      message: "アドオンの説明"
    },
    descriptionOfComplementUpper: {
      message: "説明してください"
    },
    addComplementGroup: {
      message: "補完グループを追加"
    },
    complementGroupName: {
      message: "アドオングループ名"
    },
    complementName: {
      message: "アドオン名"
    },
    checkAllFields: {
      message: "すべてのフィールドをチェックしてください。"
    },
    complementGroupIsInactive: {
      message: "アドオン グループは非アクティブです。"
    },
    value: {
      message: "価値"
    },
    valueUpper: {
      message: "価値"
    },
    addProduct: {
      message: "商品を追加"
    },
    images: {
      message: "画像"
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "この製品を構成するアイテムを説明する写真を選択し,適切な照明があり,実際のプレゼンテーションに関して現実的です。 一般的な写真,ストック写真,低品質の写真は使用しないでください。"
    },
    enableOn: {
      message: "有効にする"
    },
    categorysInWhichTheProductShouldAppear: {
      message: "製品が表示されるカテゴリ"
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: "製品が表示されるサブカテゴリ"
    },
    categorization: {
      message: "分類"
    },
    shortDescriptionShownInProductDetail: {
      message: "簡単な説明（商品詳細に表示）"
    },
    longDescriptionShownInProductDetail: {
      message: "詳細な説明 (製品の詳細に表示)"
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: "この製品を構成する材料,付属品,ポーション サイズを入力してください"
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: "おかずや分量などを入力してください"
    },
    principalInformation: {
      message: "主な情報"
    },
    id: {
      message: "ID"
    },
    type: {
      message: "タイプ"
    },
    validity: {
      message: "有効"
    },
    validityUpper: {
      message: "有効"
    },
    useAvailable: {
      message: "使用/利用可能"
    },
    filter: {
      message: "フィルター"
    },
    freeDelivery: {
      message: "無料配達"
    },
    discount: {
      message: "割引"
    },
    aCouponWithThisCodeAlreadyExists: {
      message: "そのコードのクーポンは既に存在します。"
    },
    dateTime: {
      message: "日付時刻"
    },
    change: {
      message: "もの"
    },
    formOfPayment: {
      message: "支払形式"
    },
    customer: {
      message: "クライアント"
    },
    uninformed: {
      message: "無知"
    },
    recordsFound: {
      message: "レコードが見つかりました"
    },
    QRCodeOfYourDigitalMenu: {
      message: "デジタルメニューのQRコード"
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: "QR コードをダウンロードし,施設や販促資料で利用できるようにして,顧客がデジタル メニューに簡単にアクセスできるようにします。"
    },
    areYouSureYouWantToDoThis: {
      message: "これを実行してもよろしいですか？"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: "アドオンを無効にすると,以前にリンクされたすべてのアドオン グループが製品からリンク解除されます。"
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: "製品カテゴリの取得中にエラーが発生しました"
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: "製品操作の取得中にエラーが発生しました"
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: "選択したカテゴリのサブカテゴリを取得中にエラーが発生しました"
    },
    productImage: {
      message: "商品イメージ"
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: "製品を無効にすると,オンライン メニューに表示されなくなります。"
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: "必要に応じて,別の機会に再度アクティブ化できることを忘れないでください。"
    },
    inactivateTheProduct: {
      message: "製品を無効にしてください"
    },
    keepActive: {
      message: "アクティブにしてください"
    },
    simpleEasyFastDigital: {
      message: "単純。 簡単。 速い。 デジタル。"
    },
    errorIdentification: {
      message: "エラー識別"
    },
    tryAgain: {
      message: "再試行"
    },
    huhLooksLikeSomethingWentWrong: {
      message: "は！ 何か問題が起きたようだ…"
    },
    unableToSaveCreditCardDetails: {
      message: "クレジット カードの詳細を保存できません。"
    },
    mmyy: {
      message: "月/年"
    },
    removePhoto: {
      message: "写真を削除"
    },
    addEditCoupon: {
      message: "クーポンの追加/編集"
    },
    couponType: {
      message: "クーポンタイプ"
    },
    freeShipping: {
      message: "送料無料"
    },
    discountByValue: {
      message: "値引き"
    },
    percentageDiscount: {
      message: "パーセンテージ割引"
    },
    generateCoupon: {
      message: "クーポンを生成する"
    },
    limitOfUse: {
      message: "使用制限"
    },
    couponCode: {
      message: "クーポンコード"
    },
    validFrom: {
      message: "の有効な"
    },
    validUntil: {
      message: "まで有効"
    },
    highlightsSubcategoryCannotBeCopied: {
      message: "ハイライトのサブカテゴリはコピーできません"
    },
    nothingHere: {
      message: "ここにはまだ何もないようだ。"
    },
    from: {
      message: "の"
    },
    to: {
      message: "まで"
    },
    sunday: {
      message: "日曜日"
    },
    monday: {
      message: "2番"
    },
    tuesday: {
      message: "火曜日"
    },
    wesnesday: {
      message: "第4"
    },
    thursday: {
      message: "5番目"
    },
    friday: {
      message: "金曜日"
    },
    saturday: {
      message: "土曜日"
    },
    accessData: {
      message: "アクセスデータ"
    },
    responsibleEstablishment: {
      message: "設立の責任者"
    },
    saveEditions2:{
      message: "エディションを保存"
    },
    zeit:{
      message: "時間"
    },
    cardsAccepted:{
      message: "あなたの施設で受け入れられているカードをマークしてください"
    },
    pixKey:{
      message: "ここで PIX キーを登録します"
    },
    noOptions:{
      message: "オプションなし"
    },
    neighborhoods2:{
      message: "近所"
    },
    addEditNeighborhoods:{
      message: "近隣の追加/編集"
    },
    newNeighborhoods:{
      message: "ニュー・ネイバーフッド"
    },
    qtyMinimum:{
      message: "数量 最小"
    },
    qtymaximum:{
      message: "数量 最大"
    },
    linkGroup:{
      message: "リンク用品グループ"
    },
    importantGroupComplements:{
      message: "補体のインポートグループ"
    },
    people:{
      message: "人々"
    },
    house:{
      message: "住宅"
    },
    subcategoryProducts:{
      message: "このサブカテゴリには関連製品がありません"
    },
    productNotAdd:{
      message: "この製品にはアドオンが追加されていません"
    },
    linkSupplements:{
      message: "リンクサプリメント"
    },
    PRICE:{
      message: "価格"
    },
    COMPLEMENTS:{
      message: "補足"
    },
    DESCRIPTION:{
      message: "説明"
    },
    action2:{
      message: "行動"
    },
    pixKey2:{
      message: "あなたの PIX キー"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn:{
      message: "変更を保存する前に宿泊施設の詳細ページから移動すると、入力済みの情報が失われます。"
    },
    couldNotLoadUserData:{
      message: "ユーザーデータを読み込めませんでした"
    },
    termsOfUseAanServices:{
      message: "利用規約とサービス"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou:{
      message: "やあ！ご興味をお持ちいただきありがとうございます。当社のサービスを利用する前に、時間をかけて利用規約とサービスを読み、お客様との関係を管理する規則について学んでください。"
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom:{
      message: "以下では、重要と思われるいくつかの点を明確にします。このドキュメントで説明されている点、または説明されていない点についてご不明な点がございましたら、hello@mytripmenu.com までお気軽にお問い合わせください"
    },
    definitions:{
      message: "1. 定義:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "本器では、以下の定義に従って、以下の式を理解します。"
    },
    plataform: {
      message: "プラットホーム"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "が提供する mytripmenu.com.br から利用できる Web システム"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " の所有権、運用および責任 "
    },
    users: {
      message: "ユーザー"
    },
    user: {
      message: "ユーザー"
    },
    where: {
      message: "、 どこ"
    },
    allThe: {
      message: "全ての"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "が提供するサービスを表示および使用できます。"
    },
    establishment: {
      message: "確率"
    },
    establishments: {
      message: "施設"
    },
    legalEntityRegisteredInThe: {
      message: "に登録された法人"
    },
    whichContractsTheServicesOfferedBy: {
      message: "が提供するサービスを契約する"
    },
    client: {
      message: "クライアント"
    },
    naturalPersonAccessingThe: {
      message: "にアクセスする自然人"
    },
    toViewTheMenu: {
      message: "メニューを見る "
    },
    dataProcessing: {
      message: "情報処理"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " 2018 年法律第 13,709 号の第 5 条第 X 項に従い、 "
    },
    withPersonalDataOf: {
      message: " の個人データで "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " 情報の収集、作成、受信、分類、使用、アクセス、複製、送信、配布、処理、アーカイブ、保存、削除、評価または管理、変更、通信、転送、流布または抽出に言及するものなど。 "
    },
    admission: {
      message: " 2. 入場: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " この楽器は、の使用条件を規制します "
    },
    being: {
      message: "であること"
    },
    aContractBetweenThe: {
      message: " 間の契約 "
    },
    andThe: {
      message: " そしてその "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " を通じて提供されるサービスの使用 "
    },
    fromThe: {
      message: "から "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " この文書に含まれるすべての条件および種に適用される法的規定に同意することを明示的に示します。 "
    },
    the: {
      message: "の "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " は、サービス契約モデルが、によって予算化および提供される前払いの月額サブスクリプション プランを通じて行われることを理解しています。 "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "ウェブサイトの計画エリアで入手可能な情報によると： "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "カスタマイズプランのご契約につきましては、 "
    },
    shouldContactThe: {
      message: "に連絡する必要があります "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "特定の予算の精緻化のために、そのチャネルを通じて。 "
    },
    youUnderstandAndAgreeThatThe: {
      message: "あなたは、 "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " 提供されたサービスの使用は、これらの条件および種に関連するその他すべての法的条項に同意したものとみなされます。"
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "ユーザーは、このインストルメントの条件に同意することにより、プラットフォームの機能のメンテナンスと良好なパフォーマンスを保証するために、データの処理を明示的に承認します。"
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "ユーザーは、この行為において、によって収集および処理されたデータの共有に完全な同意を表明します。 "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: "、この文書に従って、あなたの経済グループの一部である、またはあなたのサービスプロバイダーである他の私の旅行メニューと共に。"
    },

    caseYou: {
      message: "ケース・ユー "
    },
    doNotAgree: {
      message: "不賛成"
    },
    withTheProvisionsOfThisInstrument: {
      message: " この文書の規定により、 "
    },
    dont: {
      message: 'しないでください'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " からのページ、コンテンツ、情報、またはサービスへのアクセス、表示、ダウンロード、またはその他の方法での使用 "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "これらの条件は、いつでも読むことができます。 "
    },
    inMessage: {
      message: "の "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. 私たちは誰であり、私たちは何をしますか:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "は、SaaS (Software as a Service) 形式のシステムを開発してライセンス供与したサービス プロバイダーであり、特定の機能を備えています。"
    },
    and: {
      message: "と"
    },
    soThe: {
      message: "だから、"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "デジタルメニューを提供し、両方の効率と経験を向上させます"
    },
    asForThe: {
      message: "に関しては"
    },
    generalConditionsOfUse: {
      message: "4. 一般利用条件:"
    },
    onlyProvidesThe: {
      message: " のみを提供します "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " デジタルメニューサービスを提供 "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " その責任は、 "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "およびその機能は、この手段と、 "
    },
    notStoppingThe: {
      message: "、止まらない"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "デジタルメニューを介したコンテンツの作成、カスタマイズ、および配布については責任を負いません。"
    },
    justLicenseThe: {
      message: " ライセンスを取得するだけ "
    },
    toThe: {
      message: " に "
    },
    toThe2: {
      message: " に "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: "、およびこれらの当事者間に他の関係はありません。"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " 他人に生じた損害に対する責任 "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " または第三者に対して、以下に起因する行為 "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " の利用可能な機能を使用しながら "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "を理解し、受け入れることを宣言します。 "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "の商業ユニットで利用可能な物理的なメニューを置き換えるつもりはありません "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "間のコミュニケーションを容易にするための補完的なソリューションにすぎません。 "
    },
    atTheTimeOfRegistrationThe: {
      message: "登録時には、"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " で利用できるすべてのサービスを利用できます。 "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: "、これらの利用規約に含まれるすべての条項を読み、理解し、同意したことを宣言します。"
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "は、この手段を通じて、 "
    },
    theGarantingOfALicenseToUseThe: {
      message: " を使用するためのライセンスの付与 "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: "、譲渡不可であり、この用語の遵守が続く限り、国内または外国の領土での使用のためのサブライセンスは禁止されており、 "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " この文書の規定に同意しない。"
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " は、パスワードのセキュリティと登録の使用について全責任を負います。 "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: "したがって、そのような情報を第三者と共有しないことをお勧めします。また、この情報が何らかの理由で失われたり、紛失したりした場合は、 "
    },
    hacked: {
      message: "ハッキングされた"
    },
    mustImmediatelyInformThe: {
      message: " 直ちに通知しなければならない "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: "問題を解決するには、hello@mytripmenu.com からご連絡ください。"
    },
    itIsOnlyUpToThe: {
      message: "までです。 "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " 第三者、その他の者に生じた損害について責任を負いません。 "
    },
    orToItself: {
      message: " またはそれ自体に "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: "の機能を使用した結果、 "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " で利用可能なサービスを使用しないでください。 "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " 違法、中傷的、差別的、虐待的、攻撃的、ポルノ的、わいせつ、攻撃的、有害、不快、欺瞞的、中傷的、暴力的、下品、または嫌がらせ、脅迫、または偽の身元の使用、つまり、あらゆる怪しげな使用、目的、または手段害を及ぼす可能性があります "
    },
    others: {
      message: " その他"
    },
    orThirdParties: {
      message: " または第三者"
    },
    UnderNoCircumstancesWillThe: {
      message: "いかなる場合でも、 "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "によって生じた損害について責任を負うものとします。 "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " 一時的に利用できなくなる可能性があるため "
    },
    orOnyContentMadeAvailableByThe: {
      message: "またはによって利用可能になったコンテンツ "
    },
    onTheDigitalMenu: {
      message: "デジタルメニューで"
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " にアクセスするために必要なすべてのソフトウェアとハ​​ードウェアが必要です。 "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: "、インターネットにアクセスできるコンピューターまたはモバイル デバイスを含みますが、これらに限定されません。 "
    },
    onlyToProvideThe: {
      message: "、提供するためだけに "
    },
    to2: {
      message: " に "
    },
    underThisInstrumentToThe: {
      message: "この楽器の下。に "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "デジタルメニューにアクセスするには、QRコードを読み取れるデバイスが不可欠です。"
    },
    byThe: {
      message: " によって "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "事前の登録、およびこの文書に含まれる規定の遵守が条件となります。"
    },
    registration: {
      message: "5. 登録"
    },
    soThatThe: {
      message: "そのため、 "
    },
    registerInThe: {
      message: " に登録する"
    },
    mustProvideTo: {
      message: "提供しなければならない "
    },
    theFollowingData: {
      message: " 次のデータ "
    },
    corporateName: {
      message: "会社名;"
    },
    unitAddressOf: {
      message: " ユニットアドレス "
    },
    contactNumberInTheEventThatThe: {
      message: "連絡先番号。その場合、"
    },
    hireThe: {
      message: "を雇う"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "より多くのユニットについては、関心のある他のアドレスを登録する必要があります。"
    },
    forRegularUseOf: {
      message: "普段使いには "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " によって要求されたすべてのデータを記入して、登録する必要があります。 "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " 登録時に提供されるサービスプランを選択するだけでなく、 "
    },
    itIsTheResponsibilityOf: {
      message: " それはの責任です "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "契約プランで、その契約で企図されているユニット数を特定します。"
    },
    itIsTheSoleResponsibilityOf: {
      message: "それはの唯一の責任です"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "登録データの正確性を提供し、更新し、保証します。"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " によって提供された虚偽、不正確、または不完全なデータに起因するあらゆる種類の民事および刑事責任 "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " を識別するためにすべての有効かつ可能な手段を使用する権利を留保します。 "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " 報告されたデータを検証するために、関連すると見なされる追加のデータと文書を要求するだけでなく、.この場合、 "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " 最終的に要求されたドキュメントの送信に条件付けられます。"
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "登録に誤りまたは不正確なデータが含まれている疑いがあると見なされた場合、 "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " は、事前の通知なしに、一時的または永久的に、"
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " 登録を担当。中止の場合はご参加いただけません "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " 損失および損害、利益の損失または道徳的損害に対するあらゆる種類の補償または補償を受ける権利。"
    },
    inTheEventThatThe: {
      message: "その場合、"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "からの個人データの収集を実行する "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "収集された情報へのアクセスを許可し、 "
    },
    carriedOutBy: {
      message: " によって行われた "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: "、無料で、hello@mytripmenu.com にリクエストしてください"
    },
    orByRegisteringAt: {
      message: "またはで登録することによって "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " いつでも編集または削除できます。"
    },
    theCollectionOfDataFrom: {
      message: "からのデータの収集 "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: "、それが発生した場合、あなたを識別し、あなたが正しく使用できるようにすることを目的としています "
    },
    andWithThatThe: {
      message: "、そしてそれで、 "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " 認可されたサービスの品質を確保できます。"
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "この文書の条件に同意することにより、"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " あなたのデータの収集は、 "
    },
    authorizingFromNowOnThe: {
      message: "、承認、これから、 "
    },
    forThe: {
      message: " のために "
    },
    offersThePossibilityOf: {
      message: " の可能性を提供します "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "QRコードを通じてあなたのデジタルメニューをあなたの "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "ただし、登録の必要がなくても、登録がなくても、この文書の規則への違反が損なわれることはありません。 "
    },
    mustEnsureCompliance: {
      message: "コンプライアンスを確保する必要があります。"
    },
    willAccessYourRegistrationIn: {
      message: " であなたの登録にアクセスします "
    },
    through: {
      message: " 終えた "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " このデータを第三者に通知しないことを約束し、それらの使用について全責任を負います。"
    },
    undertakesToNotifyThe: {
      message: " に通知することを約束する "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " によって維持されている連絡チャネルを通じて、直ちに、 "
    },
    at: {
      message: " で "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: "、アカウントの不正使用に関して。の"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " アクセスはあなただけが知っているパスワードを使用することによってのみ可能になるため、あなたのアカウントで実行される操作については、当社が全責任を負います。"
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: "、他の人による不正行為の知識に関して"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " 自身に損害を与える可能性があるもの "
    },
    ofThe: {
      message: " の "
    },
    toThisOnTo: {
      message: " これに、に "
    },
    orToThirdParties: {
      message: " または第三者に。"
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "の機能を使用することを約束します"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " 誠意を持って、有効な法律、道徳および善良な慣習に従います。"
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "いかなる状況においても、譲渡、販売、賃貸、またはその他の形式の譲渡は行われません。"
    },
    atItsSoleDiscretion: {
      message: "独自の裁量で "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " は、事前の通知または補償なしに、登録を除外、無効化、サービスの使用に制限を設け、一時停止、ブロック、無期限に行うことができます。 "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " 攻撃的と見なされるもの、この文書の条件または有効な法律に違反するもの。"
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " の新規登録を許可しない権利を留保します。 "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " すでにキャンセル、無効化、ブロック、削除、または一時停止されている "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " ポリシーの違反により、元の登録がキャンセル、ブロック、無効化、除外、または一時停止された人々による新しい登録の作成。 "
    },
    orCurrentLegislation: {
      message: " または現在の法律。"
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " は、事前の通知、同意または補償なしに、一方的に、登録の要求を拒否する権利を留保します。 "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: "、および以前に受け入れた登録の使用をキャンセル、無効化、ブロック、削除、または一時停止します。"
    },
    byAgreeingToThisInstrumentThe: {
      message: "この文書に同意することにより、 "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " 古い、不正確な、または虚偽の情報の挿入によって引き起こされた損害は、 "
    },
    orThe: {
      message: "または"
    },
    functionalities: {
      message: "6. 機能:"
    },
    offersTheFollowingFeatures: {
      message: " 次の機能を提供します。"
    },
    aOnlineDigitalMenu: {
      message: "(a) オンライン デジタル メニュー。"
    },
    bViewingProductsWithPphotos: {
      message: "(b) 写真付き商品の閲覧"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) 利用可能な条件に応じて、多言語メニューを提供します。"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) リアルタイムでのアイテムのアクティブ化と非アクティブ化;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) QR コードをスキャンしてメニューにアクセスします。"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) プロモーション アクションのハイライトの作成。"
    },
    whenUsingTheServicesProvidedByThe: {
      message: " が提供するサービスを利用する場合 "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " のデジタル メニューを検索する電子アドレスを決定するために、URL (Uniform Resource Locator) を選択できます。 "
    },
    ToDefineTheURLThe: {
      message: "URL を定義するには、 "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "第三者の知的財産を尊重する必要があるため、混乱を招く表現や第三者のサービスをほのめかす表現を含めてはなりません。"
    },
    understandThatThe: {
      message: " 理解する "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " リソースは、混乱を引き起こすために使用されました "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "サービスを適切に識別しにくくし、誤解を招く可能性がある場合は、その URL を除外することができます。 "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "ケースに関連するその他の措置を講じる"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " に規定されているように、契約プランに従って利用可能な機能の範囲を変更する場合があります。 "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " 補償を必要とせずに、事前通知を送信することにより、既存の機能を編集および/または削除したり、新しい機能を "
    },
    translations: {
      message: "7. 翻訳:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "翻訳サービスを使用して、選択した言語でメニューを利用できるようにすることができます。この目的のために、サービスの料金が請求されます。 "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "翻訳サービスの実行については、"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "少なくとも 72 時間 (72 時間) の期間を要求します。翻訳する文章の長さにもよりますが、"
    },
    withPriorNoticeTo: {
      message: "に事前に通知して"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "当社は、当社の判断によりサービス実施期間を延長することができます。"
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "特定の文化や特定の地域を反映していることを考えると、特定の表現は翻訳できないことを理解し、受け入れることを宣言します。"
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " のデジタル メニューの不正確な翻訳に起因するいかなる損害についても責任を負いません。 "
    },
    howeverInTheEventThatThe: {
      message: ".ただし、"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "伝統に誤りや誤解がある場合は、 "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "できるだけ早く要求に応答する必要があります。"
    },
    suppertService: {
      message: "8. サポートサービス"
    },
    willSupportThe: {
      message: "をサポートします "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " 電子メールhello@mytripmenu.comで利用可能な連絡先チャネルを通じて。"
    },
    inSupportRequestsViaEmailTthe: {
      message: "メールによるサポート リクエストでは、 "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "返信には最大 72 営業時間かかります。"
    },
    paymentMethod4: {
      message: "9. 支払い方法:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "によって請求が行われる前に、 "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "のツールを無料で試用するために、07 (7) 日間の試用期間が提供されます。 "
    },
    ifThe: {
      message: "もし "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " サービスの契約を進めることを選択し、 "
    },
    makesAvailableTo: {
      message: "利用可能にします "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "次のいずれかの支払い方法: "
    },
    VISACreditCardOr: {
      message: "VISAクレジットカード;また、"
    },
    MASTERCARDCreditCardOr: {
      message: "マスターカードクレジットカード;また、"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "AMERICAN EXPRESSクレジットカード;また、"
    },
    DINNERSCLUBCreditCardOr: {
      message: "DINNERS CLUBクレジットカード;また、"
    },
    ELOCreditCardOr: {
      message: "ELO クレジット カード、または;"
    },
    DISCOVERCreditCard: {
      message: "ディスカバークレジットカード。"
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "クレジットカード決済を処理するには、"
    },
    registerOn: {
      message: "登録する "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "選択したクレジット カードの次の詳細:"
    },
    NameOfTheCreditCardHolder: {
      message: "クレジット カード所有者の名前。"
    },
    CreditCardNumber: {
      message: "クレジットカード番号;"
    },
    CreditCardFlag: {
      message: "クレジット カード フラグ;"
    },
    CreditCardExpirationMonthandYear: {
      message: "クレジット カードの有効期限 (月と年);"
    },
    CreditCardsecuritynumber: {
      message: "クレジット カードのセキュリティ番号。"
    },
    Paymentsforservicescontractedinthe: {
      message: "で契約したサービスの支払い "
    },
    willBe: {
      message: " になります"
    },
    processedthroughtheGETNETplatform: {
      message: " GETNET プラットフォームを通じて処理される"
    },
    beingnecessarythatall: {
      message: "すべてが必要である "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " また、GETNETプラットフォームの利用規約とサービスを読み、同意します"
    },
    viaemailaddress: {
      message: "メールアドレス経由： "
    },
    Whenhiringthe: {
      message: "を採用するときは、 "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: "、クレジットカードまたは銀行振込でお支払いの場合、"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "は、このツールに記載されているすべての条件、および GETNET プラットフォームの使用条件と特定のサービスを読み、同意したことを明示的に示しています。"
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "GETNET プラットフォームを通じて行われた支払いに関する紛争は、"
    },
    andGETNETnotfallingto: {
      message: " とGETNET、に落ちない "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " GETNET プラットフォームで行われた支払いを処理する責任。"
    },
    TheGETNETplatform: {
      message: "GETNET プラットフォーム"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "その使用に対して料金を請求する場合があります。 "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " GETNET によって請求される値に関する情報。 "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " によって提供された支払いデータを削除することのみを担当します。 "
    },
    fromitsowndatabasedeclaringthe: {
      message: "独自のデータベースから、"
    },
    beawarethatitisnotupto: {
      message: " までではないことに注意してください "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " GETNET データベースからの当該データの除外を促進します。"
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. プライバシーおよびデータ処理ポリシー:"
    },
    Duringtheuseof: {
      message: "使用中 "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "によって提供された情報を収集し、保存します。"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: "、この楽器の条件の下で、アクセスデバイスの特性、ブラウザ、アプリケーションアクセスログ（日付と時刻を含むIP）、アクセスされた情報、アクセスされた画面、位置情報データ、アプリケーションの履歴など、自動的に生成された情報とともに、とりわけ、の"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: "これはデータベースとブラウザの Cookie に保存されます。"
    },
    Thedatacollectedfrom: {
      message: "から収集したデータ "
    },
    throughtheuseof: {
      message: "を使用して"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: "、ナビゲーションを改善する目的で、サービスの適切な提供に使用されます"
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: "、および広告および統計目的のため。"
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "個人データを"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "2018 年法律第 13,709 号の第 5 条、項目 X の条件の下で行う必要があります。"
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "個人データが提供される場合 "
    },
    willbeconsideredconfidentialbythe: {
      message: "、によって機密と見なされます"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: "、およびそのようなデータの保管におけるシステムのセキュリティを維持するためにあらゆる努力を払うことを約束します。"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) データの不可侵性を確保するために、他の標準形式の暗号化に加えて、収集されたデータを暗号化するための業界標準の方法の使用。"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) Use of high-tech software to protect against unauthorized access to systems, which are considered controlled and security environments;"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) 機密保持契約への署名を含め、そのようなデータの機密性を約束する、以前に承認および認証された人物のみが、個人データの保管場所への制御されたアクセスを利用できること。"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) 治療の責任者を個別化できる記録へのアクセス、およびデータの使用の結果として収集されたデータへのアクセスのための認証メカニズムの適用"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) データの匿名化"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "提携していない第三者と共有する場合"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) Decree No. 8,771/2016 の第 13 条で決定された、接続およびアプリケーション アクセスの記録に基づく、時間、期間、従業員またはアクセスの責任者の身元、およびオブジェクト ファイルを示す目録の維持。"
    },
    Ifdatacollectionfromthe: {
      message: "からのデータ収集の場合"
    },
    throughtheuse: {
      message: "、使用を通じて"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "これらは、以下に挙げる場合に限り、第三者と共有することができます:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) の利益を保護するため"
    },
    incaseofconflictincludinglegalclaims: {
      message: "法的請求を含む紛争の場合。"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) を伴うお取引及び法人変更の場合"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: "、その場合、データの転送は、を通じて提供されるサービスの継続のために必要になります"
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) 裁判所の命令、またはその要求に対して法的権限を持つ行政当局の要求による。"
    },
    guaranteesto: {
      message: "を保証します"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: "、個人データの処理に関して、次の権利："
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) お客様の個人データの処理の存在の確認。"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) によって収集されたお客様のデータへのアクセス"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: "、あなた自身のログインを介して、またはhello@mytripmenu.comを要求することにより;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) データが不完全、不正確、または古くなっている場合のデータの修正。"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) 適用されるブラジルの法律に従って、不要、過剰、または不適合なデータをブロックまたは削除する。"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) 個人データの可搬性。"
    },
    viahellomytripmenucom: {
      message: "、hello@mytripmenu.com経由。"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) お客様の同意を得て処理された個人データの削除。"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) 公的機関または民間機関に関する情報を入手すること。"
    },
    sharedyourdataand: {
      message: "あなたのデータを共有しました。と、"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) 当事者の同意を提供しない場合の可能性および結果に関する情報"
    },
    youcansendemailto: {
      message: "あなたはに電子メールを送信することができます"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com、あなたの個人データに関連する疑問や要件を指摘してください。"
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "から収集した個人データを削除する場合があります"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) 収集された目的が達成された場合。または、これらの利用規約およびプライバシーポリシーに記載されている目的に従って、目的の範囲にもはや必要または関連性がなくなった場合。"
    },
    iiWhenthe: {
      message: "(ii) とき"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "必要に応じて同意を取り消し、"
    },
    viahellomytripmenucomor: {
      message: "hello@mytripmenu.com経由。また、"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) 所轄官庁が決定した場合。"
    },
    GENERALPROVISIONS: {
      message: "11. 一般規定:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "何らかの理由で裁判所または法廷によって無効または無効と見なされるこの文書の条項または条件は、これらの条件の他の条項の有効性に影響を与えません。最大限。"
    },
    Thefailureof: {
      message: "の失敗"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "これらの条件の権利または規定は放棄を構成しないと主張する場合、後者は法的な期限内に定期的に権利を行使することができます."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "すべての資料、特許、商標、登録、ドメイン、名前、特権、創造物、画像、および関連するすべての権利"
    },
    anddevelopedby: {
      message: "によって開発された"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: "、の唯一かつ排他的な財産であり、今後もそうあり続ける"
    },
    agreeingwiththe: {
      message: "、同意する"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "ここに記載されている権利を何らかの方法で害する行為または事実を実行しないこと、またはそれらに対する権利または特権を主張しないこと。"
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "は、当社のウェブサイトに改訂版を公開するだけで、いつでもこの楽器を変更することができます。このため、常に当サイトのこのセクションにアクセスし、定期的にお読みになることを強くお勧めします。ただし、良好な関係に貢献するために、これらの変更について通知するメールも送信します。"
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "この文書は、"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "また、ブラジルの法律に準拠し、サン レオポルド/RS 市の管轄権が、この文書から生じる問題を解決する唯一の管轄裁判所として選出され、他の管轄権を明示的に放棄します。"
    },

    recoverPassword:{
      message: "パスワード復旧"
    },
    receiveNewPassword:{
      message: "メールで新しいパスワードを受け取ります。迷惑メール フォルダも確認してください。"
    },
    dontWorry:{
      message: "心配しないでください、私たちはあなたがそれを取り戻すのを手伝います. ログイン用メールアドレスを入力してください"
    },
    enterEmail:{
      message: "メールアドレスを入力して"
    },
    notBeSent:{
      message: "メールを送信できませんでした。もう一度お試しください。"
    },
    pleasureToHaveYou:{
      message: "ご一緒できて光栄です！"
    },
    beforeStarting:{
      message: "製品の組み込みを開始してメニューが形になる前に、メンテナンス プランに加入する必要があります。"
    },
    BRL99:{
      message: "製品の組み込みを開始してメニューが形になる前に、メンテナンス プランに加入する必要があります。"
    },
    month:{
      message: "月。"
    },
    includesFacilities:{
      message: "次のような施設が含まれます。"
    },
    menuWithPhotos:{
      message: "写真付きのフルメニュー。"
    },
    priceUpdate:{
      message: "リアルタイムの価格更新;"
    },
    activateDeactivate:{
      message: "製品を迅速にアクティブ化および非アクティブ化します。"
    },
    categorizeFilteReorder:{
      message: "製品の分類、フィルタリング、再注文。"
    },
    newFeatures:{
      message: "毎週新機能追加！"
    },
    thePlan:{
      message: "計画に署名する"
    },
    company:{
      message: "会社"
    },
    fantasyName:{
      message: "ファンタジーネーム"
    },
    numberAccesses:{
      message: "アクセス数"
    },
    entryDate:{
      message: "申込日"
    },
    customers:{
      message: "顧客"
    },
    wantLeave:{
      message: "ここまで来ましたが、本当にここを離れますか?"
    },
    cancelYourSubscription:{
      message: "申し訳ありませんが、MyTripMenu プランのサブスクリプションをキャンセルしたいと考えています。"
    },
    beforeConfirming:{
      message: "確認する前に、退会した場合、顧客は施設のメニューにアクセスできなくなり、プランに再登録するまで URL と QR コードにアクセスできなくなることをお伝えしておく必要があります。"
    },
    goAhead:{
      message: "確信が持てるならどうぞ…"
    },
    unsubscribePlan:{
      message: "プランから退会する"
    },
    dontCancel:{
      message: "もうキャンセルしたくない "
    },
    parameterization:{
      message: "パラメータ化"
    },
    noChange:{
      message: "変化なし"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, Limited Company は、定期的に CNPJ に No. 34.177.633/0001-91、所在地は Rua Felipe Dos Santos、nº 77、1203 torre A、Padre Reus 地区、São Leopoldo/RS 市、CEP: 93.020-180"
    },
    Delivery: {
      message: "配達"
    },
    withdrawal: {
      message: "撤退"
    },
    Pickup: {
      message: "その場で受け取り"
    },
    Catalog: {
      message: "カタログ"
    },
    Enteremail: {
      message: "メールアドレスを入力してください"
    },
    emailinvalid: {
      message: "このメールは無効です"
    },
    Typepassword: {
      message: "パスワードを入力してください"
    },
    least6digits: {
      message: "このフィールドは 6 桁以上である必要があります"
    },
     Cashpayment: {
      message: "現金支払い方法は編集できません"
    },
    Checkpayment: {
      message: "小切手支払い方法は編集できません"
    },
    save: {
      message: "保存する"
    },
    logIn: {
      message: "ログインする"
    },
    logged: {
      message: "あなたはログインしています"
    },
    enterAShortEasilyIdentifiableName: {
      message: "短くて識別しやすい名前を入力してください"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "ハイライトのサブカテゴリはコピーできません"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "カードを読み込めません"
    },
    invalidUser: {
      message: "無効なユーザー"
    },
    invalidPassword: {
      message: "無効なパスワード"
    },
    inactiveUser: {
      message: "非アクティブなユーザー"
    }
  }
}
