import React from "react";

import Tag from "../../../tag/Tag";
import { TableCol, ColCategoriesWrapper } from "../../Styles";

const getTagsOfSubCategories = (key, subCategories) => {
  const arrSubCategoriesTags = subCategories.map((subCategory) => (
    <Tag key={`${key}-${subCategory._id}`} withButton={false} type="secondary">
      {subCategory.name.toUpperCase()}
    </Tag>
  ));
  return arrSubCategoriesTags;
};

function SubCategoriesCol({
  withSubCategories,
  obj,
  subCategoriesKey,
  objectKey,
}) {
  const renderSubCategories = (obj) => {
    const subCategories = obj[subCategoriesKey];
    const key = obj[objectKey];
    if (subCategories.length > 0) {
      return getTagsOfSubCategories(key, subCategories);
    }
    return "";
  };
  return (
    <>
      {withSubCategories ? (
        <TableCol colCategories>
          <ColCategoriesWrapper>
            {renderSubCategories(obj)}
          </ColCategoriesWrapper>
        </TableCol>
      ) : null}
    </>
  );
}

export default SubCategoriesCol;
