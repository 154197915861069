import HttpClient from "../http-client";
import { id } from "../helper-client";

const putComplement = async (data) => {
  return HttpClient.put(`complement?userId=${id ?? ""}`, data);
};

const postComplement = async (complementId, data) =>
  HttpClient.post(`/complement/${complementId}?userId=${id ?? ""}`, data);

const fetchComplement = async () =>
  HttpClient.get(`complement?userId=${id ?? ""}`);

const fetchComplementByGroup = async (complementGroupId) =>
  HttpClient.get(`complement/${complementGroupId}?userId=${id ?? ""}`);

export default {
  putComplement,
  fetchComplement,
  fetchComplementByGroup,
  postComplement,
};
