import styled from "styled-components";
import { ReactComponent as AvatarSVG } from "../assets/avatar.svg";
import { ReactComponent as ProductsSVG } from "../assets/icons/icon-products.svg";
import { ReactComponent as StructureSVG } from "../assets/icons/icon-structure.svg";
import { ReactComponent as CadastroSVG } from "../assets/icons/icon-cadastro.svg";
import { ReactComponent as CameraSVG } from "../assets/icons/icon-camera.svg";
import { ReactComponent as AddSVG } from "../assets/icons/icon-add.svg";
import { ReactComponent as ErrorSVG } from "../assets/icons/icon-error.svg";
import { ReactComponent as EditSVG } from "../assets/icons/icon-edit.svg";
import { ReactComponent as CopySVG } from "../assets/icons/icon-copy.svg";
import { ReactComponent as LogoutSVG } from "../assets/icons/icon-logout.svg";
import { ReactComponent as TriangleUpSVG } from "../assets/icons/triangle-up.svg";
import { ReactComponent as TriangleDownSVG } from "../assets/icons/triangle-down.svg";
import { ReactComponent as CloseSVG } from "../assets/icons/icon-close.svg";
import { ReactComponent as HouseSVG } from "../assets/icons/icon-house.svg";
import { ReactComponent as MandatorySVG } from "../assets/icons/icon-mandatory.svg";
import { ReactComponent as DownloadSVG } from "../assets/icons/icon-download.svg";
import { ReactComponent as MartercardSVG } from "../assets/icons/icon-mastercard.svg";
import { ReactComponent as DinersSVG } from "../assets/icons/icon-diners.svg";
import { ReactComponent as VisaSVG } from "../assets/icons/icon-visa.svg";
import { ReactComponent as AmexSVG } from "../assets/icons/icon-amex.svg";
import { ReactComponent as EloSVG } from "../assets/icons/icon-elo.svg";
import { ReactComponent as OrderSVG } from "../assets/icons/order-icon.svg";
import { ReactComponent as ClientSVG } from "../assets/icons/icon-client.svg";
import { ReactComponent as CadastrosSVG } from "../assets/icons/icon-cadastros.svg";
import { ReactComponent as MeuEstabelecimentoSVG } from "../assets/icons/icon-meu-estabelecimento.svg";
import { ReactComponent as ParameterizacaoSVG } from "../assets/icons/icon-parameterizacao.svg";
import { ReactComponent as PedidosSVG } from "../assets/icons/icon-pedidos.svg";
import { ReactComponent as CuponsSVG } from "../assets/icons/icon-cupons.svg";

const IconError = styled(ErrorSVG)``;

const IconAvatar = styled(AvatarSVG)`
  path:not(:first-of-type) {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

const IconProducs = styled(ProductsSVG)`
  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

const IconStructure = styled(StructureSVG)``;

const IconClient = styled(ClientSVG)``;

const IconCupons = styled(CuponsSVG)``;

const IconCadastros = styled(CadastrosSVG)``;

const IconMeuEstabelecimento = styled(MeuEstabelecimentoSVG)``;

const IconParameterizacao = styled(ParameterizacaoSVG)``;

const IconPedidos = styled(PedidosSVG)``;

const IconCadastro = styled(CadastroSVG)``;

const IconCamera = styled(CameraSVG)``;

const IconAdd = styled(AddSVG)`
  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
  height: 20px;
  width: 20px;
`;

const IconEdit = styled(EditSVG)`
  cursor: ${({ isdisabled }) =>
    isdisabled === "true" ? "not-allowed" : "pointer"};
  path:first-of-type {
    fill: ${({ theme, isdisabled }) =>
      isdisabled === "true"
        ? theme.colors.mainColorDisabled
        : theme.colors.mainColor};
  }
`;

const IconCopy = styled(CopySVG)`
  cursor: ${({ isdisabled }) =>
    isdisabled === "true" ? "not-allowed" : "pointer"};
  path:first-of-type {
    fill: ${({ theme, isdisabled }) =>
      isdisabled === "true" ? theme.colors.mainColorDisabled : "#709DE2"};
  }
`;

const IconLogout = styled(LogoutSVG)`
  width: 24px;
  height: 24px;
  padding: 4px 3px;
  border-radius: 2px;

  &:hover {
    background: ${({ theme }) => theme.colors.mainColorLight};
  }

  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

const IconArrowDown = styled(TriangleDownSVG)`
  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

const IconArrowUp = styled(TriangleUpSVG)`
  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

const IconClose = styled(CloseSVG)``;

const IconHouse = styled(HouseSVG)``;

const IconMandatory = styled(MandatorySVG)``;

const IconDownload = styled(DownloadSVG)``;

const IconMastercard = styled(MartercardSVG)`
  width: 20px;
  height: 20px;
`;
const IconDiners = styled(DinersSVG)`
  width: 20px;
  height: 20px;
`;
const IconVisa = styled(VisaSVG)`
  width: 20px;
  height: 20px;
`;
const IconAmex = styled(AmexSVG)`
  width: 20px;
  height: 20px;
`;
const IconElo = styled(EloSVG)`
  width: 20px;
  height: 20px;
`;

const IconOrder = styled(OrderSVG)`
  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

export {
  IconAvatar,
  IconClient,
  IconProducs,
  IconStructure,
  IconCamera,
  IconAdd,
  IconError,
  IconEdit,
  IconCopy,
  IconLogout,
  IconArrowDown,
  IconArrowUp,
  IconClose,
  IconHouse,
  IconMandatory,
  IconDownload,
  IconMastercard,
  IconDiners,
  IconVisa,
  IconAmex,
  IconElo,
  IconCadastro,
  IconOrder,
  IconCadastros,
  IconMeuEstabelecimento,
  IconParameterizacao,
  IconPedidos,
  IconCupons,
};
