import styled from "styled-components";

const InputGroup = styled.div`
  display: flex;
  flex: 0;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const InputGroupWithToggle = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const InputToggleContent = styled.div`
  /* margin-top: 16px; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { InputGroup, InputGroupWithToggle, InputToggleContent };
