export default {
  translations: {
    language: {
      message: "fr",
    },
    LoginFormTitle: {
      message: "connexion"
    },
    LoginFormSubtitle: {
      message: "Bonjour, entrez votre email et votre mot de passe pour vous connecter :"
    },
    LoginFormErroMessage: {
      message: "Impossible de se connecter, veuillez réessayer."
    },
    LoginFormButton: {
      message: "Chantez"
    },
    LoginFormButtonForgotPassword: {
      message:"Mot de passe oublié"
    },
    password: {
      message: "Mot de passe"
    },
    confirmPassword: {
      message:"Confirmez le mot de passe"
    },
    mail: {
      message: "Poster"
    },
    myEstablishment: {
      message: "Mon Etablissement"
    },
    parameterization: {
      message: "Paramétrage"
    },
    itemRegistration: {
      message: "Enregistrement de l'article"
    },
    orders: {
      message: "Demandes"
    },
    discountCoupons: {
      message: "Bons de réduction"
    },
    logout: {
      message: "Se déconnecter"
    },
    establishmentDetails: {
      message: "Détails de l'établissement"
    },
    subscriptions: {
      message: "Abonnements"
    },
    aboutTheEstablishment: {
      message: "A propos de l'établissement"
    },
    mandatoryFilling: {
      message: "remplissage obligatoire"
    },
    tradeName: {
      message: "Nom commercial (nom sous lequel votre établissement est connu)"
    },
    companyCorporateName: {
      message: "Nom de l'entreprise"
    },
    informTheCompanyCorporateName: {
      message: "Renseigner la raison sociale de l'entreprise"
    },
    pixel: {
      message: "Pixel"
    },
    informPixel: {
      message: "informer pixel"
    },
    invalidCnpj: {
      message: "CNPJ invalide"
    },
    invalidCep: {
      message: "CNPJ invalide"
    },
    state: {
      message: "État"
    },
    city: {
      message: "Ville"
    },
    cityUpper: {
      message: "VILLE"
    },
    neighborhoods: {
      message: "Quartiers"
    },
    street: {
      message: "Rue"
    },
    number: {
      message: "Nombre"
    },
    informTheNumber: {
      message: "renseigner le numéro"
    },
    complement: {
      message: "Complément"
    },
    complementUpper: {
      message: "COMPLÉMENT"
    },
    URLToYourMenu: {
      message: "URL de votre menu"
    },
    emailToLoginToTheManager: {
      message: "Email pour se connecter au manager"
    },
    name: {
      message: "Nom"
    },
    nameUpper: {
      message: "NOM"
    },
    lastName: {
      message: "Nom de famille"
    },
    contactEmail: {
      message: "Email du contact"
    },
    cellphoneNumberWhatsApp: {
      message: "téléphone portable/WhatsApp"
    },
    thisFieldIsRequired: {
      message: "Ce champ est obligatoire."
    },
    enterYourCreditCardDetails: {
      message: "Entrez les détails de votre carte de crédit :"
    },
    thisIsTheCurrentPaymentMethod: {
      message:"Voici le mode de paiement actuel :"
    },
    paymentMethod: {
      message: "Mode de paiement:"
    },
    paymentMethod2: {
      message: "Mode de paiement"
    },
    paymentMethod3: {
      message: "MÉTHODES DE PAYEMENT"
    },
    cardNumber: {
      message: "Numéro de carte."
    },
    enterAValidCardNumber: {
      message: "Entrez un numéro de carte valide."
    },
    enterAValidDate: {
      message: "Entrez une date valide."
    },
    cardholderName: {
      message: "Nom du titulaire."
    },
    typeAsWrittenOnCard: {
      message: "Tapez tel qu'écrit sur la carte."
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: "Entrez le nom tel qu'il est écrit sur la carte."
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: "En enregistrant les détails de paiement, vous autorisez MyTripMenu à débiter automatiquement le montant du forfait chaque mois jusqu'à ce que vous annuliez l'abonnement. Les termes et conditions sont disponibles "
    },
    inThisLink: {
      message:"dans ce lien."
    },
    savePaymentData: {
      message: "enregistrer les données de paiement"
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message:"Ajoutez une carte de crédit pour afficher les informations sur le forfait et confirmer votre abonnement."
    },
    deliveryConfiguration: {
      message: "configuration de livraison"
    },
    schedules: {
      message: "des horaires"
    },
    Schedules: {
      message: "DES HORAIRES"
    },
    add: {
      message: "ajouter"
    },
    onSiteServiceVisualization: {
      message:"Service sur site | visualisation"
    },
    deliveryWhatsappOrders: {
      message: "Livraison | commandes WhatsApp"
    },
    takeAway: {
      message: "Emporter"
    },
    catalog: {
      message: "Catalogue"
    },
    typesOfService: {
      message: "TYPES DE PRESTATIONS"
    },
    mandatoryDataForDelivery: {
      message: "DONNEES OBLIGATOIRES A LA LIVRAISON"
    },
    phone: {
      message: "Téléphone"
    },
    phoneUpper: {
      message: "TÉLÉPHONE"
    },
    address: {
      message: "Adresse"
    },
    generalSettings: {
      message: "RÉGLAGES GÉNÉRAUX"
    },
    freeShippingForPurchasesAbove: {
      message: "LIVRAISON GRATUITE POUR LES ACHATS CI-DESSUS"
    },
    noFreeShipping: {
      message: "Pas de livraison gratuite"
    },
    minimumValueForDeliveryOrders: {
      message: "VALEUR MINIMALE POUR LES COMMANDES DE LIVRAISON"
    },
    minimumDeliveryTime: {
      message: "DÉLAI DE LIVRAISON MINIMUM"
    },
    noForecast: {
      message: "Pas de prévision"
    },
    maximumDeliveryTime: {
      message: "DÉLAI DE LIVRAISON MAXIMAL"
    },
    orderResponseTimeInMinutes: {
      message: "TEMPS DE RÉPONSE À LA COMMANDE EN MINUTES"
    },
    serviceNumberViaWhats: {
      message: "NUMÉRO DE SERVICE VIA WHAT\"S"
    },
    servicePlaces: {
      message: "LIEUX DE SERVICES"
    },
    action: {
      message: "ACTION"
    },
    addEditCities: {
      message:"AJOUTER/MODIFIER DES VILLES"
    },
    newCity: {
      message: "Nouvelle ville"
    },
    search: {
      message: "Recherche"
    },
    addNew: {
      message: "Ajouter nouveau"
    },
    saveEditions: {
      message: "ENREGISTRER LES ÉDITIONS"
    },
    exitWithoutSaving: {
      message: "QUITTER SANS SAUVEGARDER"
    },
    saveEditionsAndExit: {
      message: "ENREGISTRER LES ÉDITIONS ET QUITTER"
    },
    back: {
      message: "DOS"
    },
    status: {
      message: "STATUT"
    },
    active: {
      message: "ACTIVE"
    },
    inactive: {
      message: "INACTIVE"
    },
    category: {
      message: "Catégorie"
    },
    subcategory: {
      message: "Sous-catégorie"
    },
    categories: {
      message: "Catégories"
    },
    subcategories: {
      message: "Sous-catégories"
    },
    categoriesUpper: {
      message: "CATÉGORIES"
    },
    subcategoriesUpper: {
      message: "SOUS-CATÉGORIES"
    },
    complements: {
      message: "Compléments"
    },
    complementsGroup: {
      message: " Groupe de complète"
    },
    complementsGroupUpper: {
      message: "GROUPE DE COMPLÉMENTS"
    },
    products: {
      message: "des produits"
    },
    image: {
      message: "IMAGE"
    },
    yes: {
      message: "Oui"
    },
    no: {
      message:"Non"
    },
    theHighlightsCategoryCannotBeEdited: {
      message: "La catégorie Highlights ne peut pas être modifiée"
    },
    sortFromAZ: {
      message: "trier de A à Z"
    },
    sortFromZA: {
      message: "trier de Z-A"
    },
    sortByActive: {
      message: "Trier par actif"
    },
    sortByInactive: {
      message : "Trier par inactif"
    },
    listActiveOnly: {
      message:"Liste active uniquement"
    },
    addEditCategory: {
      message:"Liste active uniquement"
    },
    addEditSubcategory: {
      message: "Ajouter/modifier une sous-catégorie"
    },
    imageOptional: {
      message: "Image (facultatif)"
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: "L'ajout d'une image illustrative à la catégorie est une action facultative, cependant, cela contribue à rendre votre menu plus attrayant."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message:"Choisissez une photo qui illustre les articles qui composent cette catégorie, ayez un bon éclairage et soyez réaliste quant à la présentation réelle des produits. Évitez d'utiliser des photos génériques, d'image de stock ou de mauvaise qualité."
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: "La résolution idéale est de 800 x 600 pixels et l'image ne doit pas dépasser 1 Mo."
    },
    selectPhoto: {
      message: "SÉLECTIONNER UNE PHOTO"
    },
    selectCategory: {
      message: "CHOISIR UNE CATÉGORIE"
    },
    saveAndAddAnother: {
      message: "SAUVEGARDER ET EN AJOUTER UN AUTRE"
    },
    highlightsSubcategoryCannotBeEdited: {
      message: "La sous-catégorie Highlights ne peut pas être modifiée"
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: "Catégorie dans laquelle la sous-catégorie doit apparaître"
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: "L'ajout d'une image illustrative à la sous-catégorie est une action facultative, mais contribue à rendre votre menu plus attrayant."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "Choisissez une photo illustrant les articles qui composent cette sous-catégorie, bien éclairée et réaliste par rapport à la présentation réelle des produits. Évitez d'utiliser des photos génériques, d'archives ou de mauvaise qualité."
    },
    enterANameForTheSubcategory: {
      message: "Entrez un nom pour la sous-catégorie"
    },
    enterANameForTheCategory: {
      message: "Entrez un nom pour la catégorie"
    },
    descriptionOfComplement: {
      message: "description du complément"
    },
    descriptionOfComplementUpper: {
      message: "DESCRIPTION DU COMPLÉMENT"
    },
    addComplementGroup: {
      message: "AJOUTER UN GROUPE COMPLÉMENTAIRE"
    },
    complementGroupName: {
      message: "Compléter le nom du groupe"
    },
    complementName: {
      message: "Compléter le nom"
    },
    checkAllFields: {
      message: "Vérifiez tous les champs."
    },
    complementGroupIsInactive: {
      message: "complementGroup est inactif."
    },
    value: {
      message: "Valeur"
    },
    valueUpper: {
      message: "VALEUR"
    },
    addProduct: {
      message: "AJOUTER PRODUIT"
    },
    images: {
      message: "Images"
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "Choisissez une photo illustrant les éléments qui composent ce produit, bien éclairée et réaliste par rapport à votre présentation réelle. Évitez d'utiliser des photos génériques, d'archives ou de mauvaise qualité."
    },
    enableOn: {
      message: "Activer sur"
    },
    categorysInWhichTheProductShouldAppear: {
      message: "Catégorie(s) dans laquelle le produit doit apparaître"
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: "Sous-catégorie(s) dans laquelle le produit doit apparaître"
    },
    categorization: {
      message: "Catégorisation"
    },
    shortDescriptionShownInProductDetail: {
      message: "Brève description (affichée dans les détails du produit)"
    },
    longDescriptionShownInProductDetail: {
      message: "Description longue (affichée dans les détails du produit)"
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: "Entrez les ingrédients qui composent ce produit, son(ses) accompagnement(s) et les tailles des portions"
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: "Entrez quelque chose comme les plats d'accompagnement ou la taille des portions"
    },
    principalInformation: {
      message: "Informations principales"
    },
    id: {
      message: "IDENTIFIANT"
    },
    type: {
      message: "TAPER"
    },
    validity: {
      message: "Validité"
    },
    validityUpper: {
      message: "VALIDITÉ"
    },
    useAvailable: {
      message: "UTILISATION/DISPONIBLE"
    },
    filter: {
      message: "Filtre"
    },
    freeDelivery: {
      message: "LIVRAISON GRATUITE"
    },
    discount: {
      message: "RABAIS"
    },
    aCouponWithThisCodeAlreadyExists: {
      message: "Un coupon avec ce code existe déjà."
    },
    dateTime: {
      message:"DATE/HEURE"
    },
    change: {
      message: "CHANGEMENT"
    },
    formOfPayment: {
      message: "FORME DE PAYEMENT"
    },
    customer: {
      message: "CLIENT"
    },
    uninformed: {
      message: "Non informé"
    },
    recordsFound: {
      message: "Dossiers trouvés"
    },
    QRCodeOfYourDigitalMenu: {
      message: "QR Code de votre menu digital"
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: "Téléchargez votre QR Code et mettez-le à disposition dans votre établissement ou dans des supports promotionnels pour faciliter l'accès des clients à votre menu digital"
    },
    areYouSureYouWantToDoThis: {
      message: "Es-tu sûr de vouloir faire ça?"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: "Lors de la désactivation du complément, tous les groupes de complément précédemment liés seront dissociés du produit."
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: "Une erreur s'est produite lors de la récupération des catégories de produits"
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: "Une erreur s'est produite lors de la récupération des opérations du produit"
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: "Une erreur s'est produite lors de la récupération des sous-catégories pour la catégorie sélectionnée"
    },
    productImage: {
      message: "Image du produit"
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: "Lorsque vous désactivez un produit, il n'apparaîtra plus dans le(s) menu(s) en ligne."
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: "N'oubliez pas que vous pourrez le réactiver à un autre moment si nécessaire."
    },
    inactivateTheProduct: {
      message: "INACTIVER LE PRODUIT"
    },
    keepActive: {
      message: "RESTE ACTIF"
    },
    simpleEasyFastDigital: {
      message: "Simple. Facile. Rapide. Numérique."
    },
    errorIdentification: {
      message: "Détection d'erreur"
    },
    tryAgain: {
      message: "ESSAYER À NOUVEAU"
    },
    huhLooksLikeSomethingWentWrong: {
      message: "Huh ! On dirait que quelque chose s'est mal passé..."
    },
    unableToSaveCreditCardDetails: {
      message: "Impossible d'enregistrer les détails de la carte de crédit."
    },
    mmyy: {
      message: "MM/AA"
    },
    removePhoto: {
      message: "Retirer photo"
    },
    addEditCoupon: {
      message: "AJOUTER/MODIFIER LE COUPON"
    },
    couponType: {
      message: "TYPE DE COUPON"
    },
    freeShipping: {
      message:"Livraison gratuite"
    },
    discountByValue: {
      message: "Remise par valeur"
    },
    percentageDiscount: {
      message:"Remise en pourcentage"
    },
    generateCoupon: {
      message: "GÉNÉRER UN COUPON"
    },
    limitOfUse: {
      message: "LIMITE D'UTILISATION"
    },
    couponCode: {
      message: "CODE PROMO"
    },
    validFrom: {
      message: "VALIDE À PARTIR DE"
    },
    validUntil: {
      message: "VALABLE JUSQUE"
    },
    highlightsSubcategoryCannotBeCopied: {
      message: "La sous-catégorie Highlights ne peut pas être copiée"
    },
    nothingHere: {
      message: "On dirait qu'il n'y a toujours rien ici."
    },
    from: {
      message: "DEPUIS"
    },
    to: {
      message: "POUR"
    },
    sunday: {
      message: "Dimanche"
    },
    monday: {
      message: "Lundi"
    },
    tuesday: {
      message: "Mardi"
    },
    wesnesday: {
      message: "Mercredi"
    },
    thursday: {
      message: "Jeudi"
    },
    friday: {
      message: "Vendredi"
    },
    saturday: {
      message: "Samedi"
    },
    accessData: {
      message: "Données d'accès"
    },
    responsibleEstablishment: {
      message: "Responsable de l'établissement"
    },
    saveEditions2: {
      message: "Enregistrer les éditions"
    },
    zeit: {
      message: "Temps"
    },
    cardsAccepted: {
      message: "Cochez les cartes acceptées par votre établissement"
    },
    pixKey: {
      message: "Enregistrez votre clé PIX ici"
    },
    noOptions: {
      message: "pas d'option"
    },
    neighborhoods2: {
      message: "QUARTIERS"
    },
    addEditNeighborhoods: {
      message: "AJOUTER/MODIFIER DES QUARTIERS"
    },
    newNeighborhoods: {
      message: "Nouveaux quartiers"
    },
    qtyMinimum: {
      message: "QTÉ MINIMALE"
    },
    qtymaximum: {
      message: "QTÉ maximum"
    },
    linkGroup: {
      message: "LINK SUPPLIES GROUP"
    },
    importantGroupComplements: {
      message: "GROUPE D'IMPORTATION DE COMPLÉMENTS"
    },
    people: {
      message: "Personnes"
    },
    house: {
      message: "Maisons"
    },
    subcategoryProducts: {
      message: "Cette sous-catégorie n'a pas de produits associés"
    },
    productNotAdd: {
      message: "Ce produit n'a aucun add-on ajouté"
    },
    linkSupplements: {
      message: "SUPPLÉMENTS DE LIEN"
    },
    PRICE: {
      message: "PRIX"
    },
    COMPLEMENTS: {
      message: "COMPLÉMENTS"
    },
    DESCRIPTION: {
      message: "DESCRIPTION"
    },
    action2: {
      message: "Action"
    },
    pixKey2: {
      message: "Votre clé PIX"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "Si vous quittez la page des détails de la propriété avant d'enregistrer vos modifications, vous perdrez toutes les informations que vous avez déjà renseignées."
    },
    couldNotLoadUserData: {
      message: "Impossible de charger les données utilisateur"
    },
    termsOfUseAanServices: {
      message: "CONDITIONS D'UTILISATION ET SERVICES"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou: {
      message: "Salut! Content de votre intérêt ! Avant d'utiliser nos services, veuillez prendre le temps de lire nos conditions d'utilisation et de service et de vous renseigner sur les règles qui régissent notre relation avec vous."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom: {
      message: "Ci-dessous, nous allons clarifier certains points que nous considérons comme importants. Si vous avez des doutes sur des points abordés ou non dans ce document, n'hésitez pas à nous contacter à hello@mytripmenu.com."
    },
    definitions: {
      message: "1. DÉFINITIONS:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "Dans cet instrument, nous entendons les expressions ci-dessous selon les définitions suivantes :"
    },
    plataform: {
      message: "PLATEFORME"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "système Web disponible via mytripmenu.com.br, offert par"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: "et la propriété, l'exploitation et la responsabilité de"
    },
    users: {
      message: "UTILISATEURS"
    },
    user: {
      message: "UTILISATEUR"
    },
    where: {
      message: ", où"
    },
    allThe: {
      message: "tous les"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "peut voir et utiliser les services offerts par"
    },
    establishment: {
      message: "ÉTABLISSEMENT"
    },
    establishments: {
      message: "ÉTABLISSEMENTS"
    },
    legalEntityRegisteredInThe: {
      message: "personne morale enregistrée au "
    },
    whichContractsTheServicesOfferedBy: {
      message: ", qui contracte les services offerts par"
    },
    client: {
      message: "CLIENT"
    },
    customers: {
      message: "CLIENTS"
    },
    naturalPersonAccessingThe: {
      message: "personne physique accédant au "
    },
    toViewTheMenu: {
      message: "pour afficher le menu "
    },
    dataProcessing: {
      message: "TRAITEMENT DE L'INFORMATION"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " Conformément à l'article 5, point X, de la loi 13.709 de 2018, toute transaction effectuée par "
    },
    withPersonalDataOf: {
      message: " avec les données personnelles de "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " , telles que celles se référant à la collecte, la production, la réception, la classification, l'utilisation, l'accès, la reproduction, la transmission, la distribution, le traitement, l'archivage, le stockage, l'élimination, l'évaluation ou le contrôle des informations, la modification, la communication, le transfert, la diffusion ou l'extraction. "
    },
    admission: {
      message: " 2. ENTRÉE : "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: "Cet instrument réglemente les conditions d'utilisation du "
    },
    being: {
      message: "être"
    },
    aContractBetweenThe: {
      message: " un contrat entre le "
    },
    andThe: {
      message: " et le "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " L'utilisation des services offerts par "
    },
    fromThe: {
      message: "du "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " indique expressément que vous êtes d'accord avec tous les termes et conditions contenus dans cet instrument et avec les dispositions légales applicables aux espèces. "
    },
    the: {
      message: "Le "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " comprend que le modèle de contrat de service se fera par le biais de plans d'abonnement mensuels prépayés budgétés et proposés par "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "selon les informations disponibles dans l'espace plans du site : "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "Pour la passation de plans personnalisés, le "
    },
    shouldContactThe: {
      message: "doit contacter le "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "par ses canaux, pour l'élaboration d'un budget spécifique. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "VOUS COMPRENEZ ET ACCEPTEZ QUE "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " L'UTILISATION DES SERVICES FOURNIS SERA CONSIDÉRÉE PAR VOTRE ACCEPTATION DE CES CONDITIONS ET DE TOUTES LES AUTRES DISPOSITIONS LÉGALES RELATIVES À L'ESPÈCE."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "L'UTILISATION DES SERVICES FOURNIS SERA CONSIDÉRÉE PAR VOTRE ACCEPTATION DE CES CONDITIONS ET DE TOUTES LES AUTRES DISPOSITIONS LÉGALES RELATIVES À L'ESPÈCE."
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "L'UTILISATEUR, DANS CET ACTE, MANIFESTE SON CONSENTEMENT COMPLET AU PARTAGE DES DONNÉES COLLECTÉES ET TRAITÉES PAR "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: ",EN VERTU DE CET INSTRUMENT, AVEC D'AUTRES MENUS MON VOYAGE QUI FONT PARTIE DE VOTRE GROUPE ECONOMIQUE, OU SONT VOS PRESTATAIRES DE SERVICES."
    },

    caseYou: {
      message: "Cas vous "
    },
    doNotAgree: {
      message: "NE PAS ÊTRE D'ACCORD"
    },
    withTheProvisionsOfThisInstrument: {
      message: " aux dispositions de cet instrument, "
    },
    dont: {
      message: "NE LE FAITES PAS"
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: "accéder, afficher, télécharger ou utiliser de toute autre manière toute page, contenu, information ou service de "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "Ces conditions sont consultables, à tout moment, sur "
    },
    inMessage: {
      message: "dans "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. QUI NOUS SOMMES ET CE QUE NOUS FAISONS :"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "est un fournisseur de services qui a développé et licencié un système sous forme de SaaS (Software as a Service), avec des fonctionnalités spécifiques pour aider"
    },
    and: {
      message: "et"
    },
    soThe: {
      message: "Alors le"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "offre un menu numérique, permettant une amélioration de l'efficacité et de l'expérience pour"
    },
    asForThe: {
      message: "as for the"
    },
    generalConditionsOfUse: {
      message: "4. CONDITIONS GÉNÉRALES D'UTILISATION :"
    },
    onlyProvidesThe: {
      message: " fournit uniquement le "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " qui propose des services de menus numériques pour le "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " sa responsabilité étant limitée au seul bon fonctionnement du "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "et ses fonctionnalités, selon cet instrument et le plan contracté par le "
    },
    notStoppingThe: {
      message: ", ne pas arrêter le "
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "aucune responsabilité pour la création, la personnalisation et la diffusion de contenu via le menu numérique."
    },
    justLicenseThe: {
      message: " il suffit de licencier le "
    },
    toThe: {
      message: " au "
    },
    toThe2: {
      message: " Au "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ",et il n'y a pas d'autre relation entre ces parties, de sorte qu'il n'est pas possible d'attribuer à la"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: "responsabilité pour tout dommage causé à autrui "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: "ou à des tiers, pour des actes résultant de "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " tout en utilisant les fonctions disponibles du "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "déclare comprendre et accepter que le "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "n'entend pas remplacer le menu physique disponible à l'unité commerciale du "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "n'étant qu'une solution complémentaire, afin de faciliter la communication entre "
    },
    atTheTimeOfRegistrationThe: {
      message: "Au moment de l'inscription, le "
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: "Vous pouvez utiliser tous les services disponibles sur le "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: ", déclarant avoir lu, compris et accepté toutes les dispositions contenues dans les présentes Conditions d'Utilisation."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "reconnaît expressément que, par le biais de cet instrument, il reçoit de "
    },
    theGarantingOfALicenseToUseThe: {
      message: "l'octroi d'une licence d'utilisation du "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", est incessible, et la sous-licence est interdite, pour une utilisation sur le territoire national ou étranger, tant que dure l'adhésion à ce terme, laissant l'usage du "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: "en désaccord avec les dispositions de cet instrument."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: "est seul responsable de la sécurité de votre mot de passe et de l'utilisation de votre inscription à "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: ", par conséquent, nous vous recommandons de ne pas partager ces informations avec des tiers et, si ces informations sont, pour une raison quelconque, perdues ou "
    },
    hacked: {
      message: "piraté"
    },
    mustImmediatelyInformThe: {
      message: "doit immédiatement informer le "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", via hello@mytripmenu.com, afin de résoudre le problème."
    },
    itIsOnlyUpToThe: {
      message: "C'est seulement jusqu'à la"
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: "responsable des dommages causés à des tiers, à d'autres "
    },
    orToItself: {
      message: "ou à lui-même "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", résultant de l'utilisation des fonctionnalités du "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: "ne doit pas utiliser les services disponibles sur le "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: "pour toute utilisation illégale, diffamatoire, discriminatoire, abusive, offensante, pornographique, obscène, agressive, injurieuse, vexatoire, trompeuse, calomnieuse, violente, vulgaire ou harcelante, menaçante ou d'utilisation d'une fausse identité, c'est-à-dire toute utilisation, fin ou moyen louche qui pourrait nuire au "
    },
    others: {
      message: "autres"
    },
    orThirdParties: {
      message: " ou des tiers"
    },
    UnderNoCircumstancesWillThe: {
      message: "En aucun cas le "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "sera responsable des dommages subis par le "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: "pour une éventuelle indisponibilité temporaire du "
    },
    orOnyContentMadeAvailableByThe: {
      message: "ou tout contenu mis à disposition par le "
    },
    onTheDigitalMenu: {
      message: "sur le menu numérique."
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " doit disposer de tous les logiciels et matériels nécessaires pour accéder au "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", y compris, mais sans s'y limiter, un ordinateur ou un appareil mobile avec accès à Internet, et le "
    },
    onlyToProvideThe: {
      message: ",uniquement pour fournir le "
    },
    to2: {
      message: " pour "
    },
    underThisInstrumentToThe: {
      message: "sous cet instrument. Au "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "accéder au menu digital, un appareil capable de lire le QR Code sera indispensable."
    },
    byThe: {
      message: "par le "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "est conditionnée à son enregistrement préalable, ainsi qu'au respect des dispositions contenues dans le présent acte."
    },
    registration: {
      message: "5. INSCRIPTION"
    },
    soThatThe: {
      message: "De sorte que la "
    },
    registerInThe: {
      message: " inscrivez-vous dans le "
    },
    mustProvideTo: {
      message: "doit fournir à "
    },
    theFollowingData: {
      message: " les données suivantes "
    },
    corporateName: {
      message: "nom de la SOCIETE;"
    },
    unitAddressOf: {
      message: "adresse d'unité de "
    },
    contactNumberInTheEventThatThe: {
      message: "Numéro de contact. Dans le cas où le "
    },
    hireThe: {
      message: "louer le"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "pour plus d'unités, vous devez enregistrer les autres adresses d'intérêt."
    },
    forRegularUseOf: {
      message: "Pour une utilisation régulière de "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: "doit s'inscrire, en remplissant toutes les données demandées par le "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: "au moment de l'inscription, ainsi que la sélection du plan de service offert par "
    },
    itIsTheResponsibilityOf: {
      message: "Il est de la responsabilité de "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "identifier dans le plan contracté le nombre d'unités envisagées dans son contrat. "
    },
    itIsTheSoleResponsibilityOf: {
      message: "Il est de la seule responsabilité de "
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "fournir, mettre à jour et garantir la véracité des données d'enregistrement, ne relevant pas du "
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " tout type de responsabilité civile et pénale résultant de données fausses, incorrectes ou incomplètes fournies par "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " se réserve le droit d'utiliser tous les moyens valables et possibles pour identifier le "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: "ainsi que de demander des données et des documents supplémentaires qu'il juge pertinents afin de vérifier les données déclarées. Dans ce cas, l'utilisation de "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message:" est conditionné à l'envoi des documents éventuellement demandés."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "Si un enregistrement est considéré comme suspect de contenir des données erronées ou mensongères, le "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " se réserve le droit de suspendre, temporairement ou définitivement, sans préavis, le "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: "responsable de l'inscription. En cas de suspension, vous ne participerez pas au "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: "droit à tout type d'indemnité ou de compensation pour pertes et dommages, manque à gagner ou préjudice moral "
    },
    inTheEventThatThe: {
      message: "Dans le cas où le "
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "procéder à la collecte des données personnelles auprès du "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "permettra l'accès aux informations collectées et sur le "
    },
    carriedOutBy: {
      message: " effectuée par "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", gratuitement, en demandant hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "ou en vous inscrivant sur "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: "pouvoir les modifier ou les supprimer à tout moment."
    },
    theCollectionOfDataFrom: {
      message: "La collecte des données de "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", lorsqu'il se produit, vise à vous identifier, ainsi qu'à vous permettre d'utiliser correctement le "
    },
    andWithThatThe: {
      message: ", et avec ça, le "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: "peut garantir la bonne qualité des services sous licence."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "En acceptant les termes de cet instrument, le "
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " déclare expressément être conscient que la collecte de vos données est indispensable au bon fonctionnement du "
    },
    authorizingFromNowOnThe: {
      message: ", autorisant, désormais, le "
    },
    forThe: {
      message: " pour le "
    },
    offersThePossibilityOf: {
      message: "offre la possibilité de "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "faites connaître votre menu numérique par QR Code à votre "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "sans nécessité d'enregistrement, cependant, l'absence d'enregistrement n'enlève rien au non-respect des règles de cet instrument, que le "
    },
    mustEnsureCompliance: {
      message: "doit assurer la conformité."
    },
    willAccessYourRegistrationIn: {
      message: " accédera à votre inscription dans "
    },
    through: {
      message: "à travers "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: "et mot de passe, s'engageant à ne pas informer des tiers de ces données, assumant l'entière responsabilité de l'utilisation qui en sera faite."
    },
    undertakesToNotifyThe: {
      message: " s'engage à notifier au "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: "immédiatement, via les canaux de contact entretenus par "
    },
    at: {
      message: " à "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ",concernant toute utilisation non autorisée de votre compte. Le"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " sera seul responsable des opérations effectuées sur votre compte, l'accès ne sera possible qu'à l'aide d'un mot de passe connu de vous exclusivement."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ",concernant toute connaissance d'actes répréhensibles commis par d'autres"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " qui peuvent causer des dommages à eux-mêmes "
    },
    ofThe: {
      message: "de la "
    },
    toThisOnTo: {
      message: " à celui-ci, à "
    },
    orToThirdParties: {
      message: " ou à des tiers."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "s'engage à utiliser les fonctionnalités du "
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: "de bonne foi, conformément à la législation en vigueur, à la morale et aux bonnes mœurs. "
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "En aucun cas, la cession, la vente, la location ou toute autre forme de transfert du "
    },
    atItsSoleDiscretion: {
      message: "À sa seule discrétion "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: "peut exclure, désactiver, créer des limites à l'utilisation du service, suspendre, bloquer, indéfiniment, sans préavis ni indemnité, les inscriptions o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: "qui sont considérés comme offensants, qui violent les termes de cet instrument ou la législation en vigueur."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " se réserve le droit de ne pas autoriser une nouvelle inscription de "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " qui ont déjà été annulés, désactivés, bloqués, supprimés ou suspendus de "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " La création de nouvelles inscriptions par des personnes dont les inscriptions originales ont été annulées, bloquées, désactivées, exclues ou suspendues en raison de violations des politiques du "
    },
    orCurrentLegislation: {
      message: " ou la législation en vigueur."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " se réserve le droit, unilatéralement, sans préavis, consentement ni indemnité, de refuser toute demande d'inscription d'un "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", ainsi que d'annuler, de désactiver, de bloquer, de supprimer ou de suspendre l'utilisation d'un enregistrement précédemment accepté."
    },
    byAgreeingToThisInstrumentThe: {
      message: "En acceptant cet instrument, le "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " déclare être conscient qu'il est seul responsable de son enregistrement, étant certain que tout dommage causé par l'insertion d'informations périmées, inexactes ou fausses ne pourra être imputé à "
    },
    orThe: {
      message: "Ou la"
    },
    functionalities: {
      message: "6. FONCTIONNALITÉS :"
    },
    offersTheFollowingFeatures: {
      message: " propose les fonctionnalités suivantes :"
    },
    aOnlineDigitalMenu: {
      message: "(a) Menu numérique en ligne ;"
    },
    bViewingProductsWithPphotos: {
      message: "(b) Visualiser des produits avec des photos ; "
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) Offrir un menu multilingue, sous réserve des conditions disponibles ;"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) Activation et désactivation d'éléments en temps réel ;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) Accès au menu en scannant un QR Code ;"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) Création de temps forts pour des actions promotionnelles."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " lors de l'utilisation des services fournis par le "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " pouvez sélectionner URL (Uniform Resource Locator), afin de déterminer l'adresse électronique qui localisera le menu numérique du "
    },
    ToDefineTheURLThe: {
      message: "Pour définir l'URL, le "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "doit respecter la propriété intellectuelle des tiers, par conséquent, il ne doit pas inclure d'expressions pouvant prêter à confusion ou faire allusion à des services tiers."
    },
    understandThatThe: {
      message: " comprendre que le "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " ressources ont été utilisées pour semer la confusion parmi les "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "rendant difficile l'identification correcte des services et les induisant en erreur, vous pouvez exclure l'URL du "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "ainsi que d'adopter d'autres mesures pertinentes à l'affaire"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " peut modifier l'éventail des fonctionnalités disponibles selon le plan du contrat, tel que prévu à l'article "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " peut à tout moment, moyennant l'envoi d'un préavis, sans qu'il soit besoin de dédommagement, modifier et/ou supprimer des fonctionnalités existantes, ainsi qu'ajouter de nouvelles fonctionnalités au "
    },
    translations: {
      message: "7. TRADUCTIONS :"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "Vous pouvez faire appel à des services de traduction afin de rendre vos menus disponibles dans les langues de votre choix. A cet effet, des frais seront facturés pour les services, comme prévu dans le "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "Pour l'exécution des services de traduction, le "
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "demander un délai minimum de 72 heures (soixante-douze heures). En fonction de la longueur du texte à traduire, le"
    },
    withPriorNoticeTo: {
      message: "avec préavis à"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "peut prolonger la période d'exécution du service à sa discrétion."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "déclare comprendre et accepter que certaines expressions ne puissent être traduites, étant donné qu'elles reflètent certaines cultures ou régions spécifiques."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " n'est pas responsable des dommages résultant de traductions inexactes dans le menu numérique du "
    },
    howeverInTheEventThatThe: {
      message: ". Cependant, dans le cas où le"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "identifier toute erreur ou malentendu dans la tradition, vous pouvez communiquer au "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "qui doit répondre à la demande dans les plus brefs délais."
    },
    suppertService: {
      message: "8. SERVICES D'ASSISTANCE"
    },
    willSupportThe: {
      message: "soutiendra le"
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: "via le canal de contact disponible par e-mail hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "Dans les demandes d'assistance par e-mail, le "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "aura jusqu'à 72 (soixante-douze) heures ouvrables pour répondre."
    },
    paymentMethod4: {
      message: "9. MÉTHODES DE PAIEMENT : "
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "Avant toute facturation par le "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: " se verra proposer une période d'essai de 07 (sept) jours, pour un essai gratuit des outils de "
    },
    ifThe: {
      message: "Si la "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " choisir de procéder à la passation de marchés de services, le "
    },
    makesAvailableTo: {
      message: "met à la disposition de"
    },
    oneOfTheFollowingPaymentMethods: {
      message: "l'un des modes de paiement suivants : "
    },
    VISACreditCardOr: {
      message: "Carte de crédit VISA ; ou,"
    },
    MASTERCARDCreditCardOr: {
      message: "Carte de crédit MASTERCARD ; ou,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "Carte de crédit AMERICAN EXPRESS ; ou,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "Carte de crédit DINERS CLUB ; ou," },
    ELOCreditCardOr: {
      message: "Carte de crédit ELO, ou ;"
    },
    DISCOVERCreditCard: {
      message: "DÉCOUVREZ la carte de crédit."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "Pour traiter les paiements par carte de crédit, il sera nécessaire pour le"
    },
    registerOn: {
      message: "inscrivez-vous sur "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "les détails suivants de la carte de crédit choisie :"
    },
    NameOfTheCreditCardHolder: {
      message: "Nom du titulaire de la carte de crédit ;"
    },
    CreditCardNumber: {
      message: "Numéro de Carte de Crédit;"
    },
    CreditCardFlag: {
      message: "Drapeau de carte de crédit ; "
    },
    CreditCardExpirationMonthandYear: {
      message: "Expiration de la carte de crédit (mois et année);"
    },
    CreditCardsecuritynumber: {
      message: "Numéro de sécurité de la carte de crédit."
    },
    Paymentsforservicescontractedinthe: {
      message: "Les paiements pour les services contractés dans le "
    },
    willBe: {
      message: " sera"
    },
    processedthroughtheGETNETplatform: {
      message: "traitées via la plateforme GETNET"
    },
    beingnecessarythatall: {
      message: "nécessaire que tout"
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " également lire et accepter les conditions d'utilisation et des services de la plateforme GETNET"
    },
    viaemailaddress: {
      message: "une adresse e-mail : "
    },
    Whenhiringthe: {
      message: "Lors de l'embauche du "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", avec paiement par carte de crédit ou virement bancaire, le"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "indique expressément que vous avez lu et accepté toutes les conditions présentes dans cet instrument et dans les conditions d'utilisation et services spécifiques de la plate-forme GETNET."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "Tout litige de paiements effectués via la plate-forme GETNET doit être résolu uniquement entre le "
    },
    andGETNETnotfallingto: {
      message : " et GETNET, ne tombant pas sur "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: "toute responsabilité pour le traitement des paiements effectués sur la plate-forme GETNET."
    },
    TheGETNETplatform: {
      message: "La plateforme GETNET"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "peut facturer des frais pour son utilisation, et ce n'est qu'à la "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " des informations sur les valeurs facturées par GETNET. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " est seul responsable de la suppression des données de paiement fournies par le "
    },
    fromitsowndatabasedeclaringthe: {
      message: "à partir de sa propre base de données, déclarant le"
    },
    beawarethatitisnotupto: {
      message:" être conscient que ce n'est pas à "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " favoriser l'exclusion desdites données de la base de données GETNET."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. POLITIQUE DE CONFIDENTIALITÉ ET DE TRAITEMENT DES DONNÉES :"
    },
    Duringtheuseof: {
      message: "Pendant l'utilisation de "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "collectera et stockera les informations fournies par le "
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", aux termes de cet instrument, ainsi que des informations générées automatiquement, telles que les caractéristiques du dispositif d'accès, le navigateur, les journaux d'accès aux applications (IP, avec date et heure), les informations consultées, les écrans consultés, les données de géolocalisation, l'historique des applications , entre autres, de"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", qui sera stocké dans la base de données et également dans le cookie du navigateur."
    },
    Thedatacollectedfrom: {
      message: "Les données collectées auprès de "
    },
    throughtheuseof: {
      message: ", par l'utilisation de"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", seront utilisés pour la fourniture adéquate de services, dans le but d'améliorer la navigation du "
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: ", ainsi qu'à des fins publicitaires et statistiques."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "déclare comprendre, accepter et consentir qu'en cas d'envoi de données personnelles à"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message:"doit le faire en vertu de l'article 5, point X, de la loi 13.709 de 2018."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "Dans le cas où des données personnelles sont fournies par le "
    },
    willbeconsideredconfidentialbythe: {
      message: ", seront considérées comme confidentielles par le"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ",et il s'engage à tout mettre en œuvre pour préserver la sécurité de ses systèmes dans le stockage de ces données, en respectant les normes de sécurité établies dans le décret n° 8 771/2016, telles que :"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) Utilisation de méthodes standard de l'industrie pour crypter les données collectées, en plus d'autres formes standard de cryptage, afin de garantir leur inviolabilité ; "
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) Utilisation de logiciels de haute technologie pour se protéger contre l'accès non autorisé aux systèmes, qui sont considérés comme des environnements contrôlés et sécurisés ; "
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) Disponibilité d'un accès contrôlé aux emplacements de stockage des données personnelles uniquement aux personnes préalablement autorisées et authentifiées, engagées dans le secret de ces données, y compris en signant un accord de confidentialité ; "
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) Application de mécanismes d'authentification pour l'accès aux enregistrements capables d'individualiser le responsable du traitement et l'accès aux données collectées à la suite de l'utilisation du "
    },
    vAnonymizationofdatafromthe: {
      message: "(v) Anonymisation des données de la"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "lorsqu'il est partagé avec des tiers non partenaires"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi)Tenue de l'inventaire indiquant l'heure, la durée, l'identité de l'employé ou de la personne responsable de l'accès et le fichier objet, sur la base des enregistrements de connexion et d'accès à l'application, tel que déterminé à l'article 13 du décret n° 8 771/2016. "
    },
    Ifdatacollectionfromthe: {
      message: "Si la collecte de données à partir du"
    },
    throughtheuse: {
      message: ",par l'utilisation"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "ceux-ci ne peuvent être partagés qu'avec des tiers, dans les cas énumérés ci-dessous :"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) Pour la protection des intérêts de"
    },
    incaseofconflictincludinglegalclaims: {
      message: "in cas de conflit, y compris les réclamations légales ; "
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) En cas de transactions et de changements d'entreprise impliquant le "
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", auquel cas le transfert de données sera nécessaire pour la continuité des services offerts par le "
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) Par décision de justice ou à la demande des autorités administratives qui sont juridiquement compétentes pour sa demande."
    },
    guaranteesto: {
      message: "garantit "
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", en ce qui concerne le traitement des données personnelles, les droits suivants :"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) Confirmation de l'existence d'un traitement de vos données personnelles ;"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) Accès à vos données collectées par"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ",via votre propre connexion ou en demandant hello@mytripmenu.com ; "
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) Correction de vos données si elles sont incomplètes, inexactes ou périmées ; "
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) bloquer ou supprimer les données inutiles, excessives ou non conformes à la législation brésilienne applicable ; "
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) La portabilité des données personnelles, pour vous ou pour un tiers, sur demande expresse faite par le "
    },
    viahellomytripmenucom: {
      message: ", via hello@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) Suppression des données personnelles traitées avec votre consentement, à condition qu'il n'y ait pas de détermination légale pour les garder enregistrées auprès du "
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) Obtenir des informations sur les entités publiques ou privées avec lesquelles le "
    },
    sharedyourdataand: {
      message: "partagé vos données ; et,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) Informations sur la possibilité et les conséquences de ne pas donner le consentement du "
    },
    youcansendemailto: {
      message: "vous pouvez envoyer un e-mail à"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, signalant des doutes et/ou des exigences concernant vos données personnelles."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "peut supprimer les données personnelles collectées à partir de "
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) Lorsque le but pour lequel elles ont été collectées est atteint ; ou, lorsqu'elles ne sont plus nécessaires ou pertinentes au regard de la finalité, selon les finalités décrites dans les présentes Conditions d'Utilisation et Politique de Confidentialité ;"
    },
    iiWhenthe: {
      message: "(ii) Quand le"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "révoquer votre consentement, dans les cas où cela est nécessaire, en demandant l'exclusion du "
    },
    viahellomytripmenucomor: {
      message: "via hello@mytripmenu.com ; ou,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) Si déterminé par l'autorité compétente."
    },
    GENERALPROVISIONS: {
      message: "11.DISPOSITIONS GÉNÉRALES:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "Toute clause ou condition de cet instrument qui, pour quelque raison que ce soit, sera considérée comme nulle ou inefficace par un tribunal ou un tribunal, n'affectera pas la validité des autres dispositions des présentes Conditions, qui resteront pleinement valides et contraignantes, générant des effets à leur dans toute la mesure."
    },
    Thefailureof: {
      message: "L'échec de "
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "revendiquer l'un quelconque des droits ou dispositions des présentes CGU ne constituera pas une renonciation, et ces derniers pourront régulièrement exercer leur droit, dans les délais légaux."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "Tous les matériaux, brevets, marques, enregistrements, domaines, noms, privilèges, créations, images et tous les droits connexes relatifs au "
    },
    anddevelopedby: {
      message: "et développé par"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", sont et resteront la propriété unique et exclusive de "
    },
    agreeingwiththe: {
      message: ", d'accord avec le "
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "de ne pas accomplir un acte ou un fait qui, de quelque manière que ce soit, porte atteinte aux droits énoncés dans les présentes, ni de revendiquer un droit ou un privilège sur ceux-ci. "
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "peut modifier cet instrument à tout moment, en publiant simplement une version révisée sur notre site Web. Pour cette raison, nous vous recommandons fortement de toujours visiter cette section de notre site et de la lire périodiquement. Mais, pour contribuer à la bonne relation, nous vous enverrons également un e-mail vous informant de ces changements."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "Cet instrument constitue l'entente intégrale entre le "
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "et est régi par les lois brésiliennes, la juridiction de la ville de São Leopoldo/RS étant élue comme le seul tribunal compétent pour résoudre les problèmes découlant de cet instrument, avec renonciation expresse à toute autre juridiction, aussi privilégiée soit-elle. "
    },
    recoverPassword: {
      message: "RÉCUPÉRER MOT DE PASSE"
    },
    receiveNewPassword: {
      message: "Vous recevrez un nouveau mot de passe dans votre e-mail, pensez à vérifier également votre dossier spam."
    },
    dontWorry: {
      message: "Ne vous inquiétez pas, nous vous aiderons à le récupérer. Renseignez votre email de connexion"
    },
    enterEmail: {
      message: "Entrez l'e-mail"
    },
    notBeSent: {
      message: "L'e-mail n'a pas pu être envoyé, veuillez réessayer."
    },
    pleasureToHaveYou: {
      message: "C'est un plaisir de vous avoir parmi nous !"
    },
    beforeStarting: {
      message: "Avant de commencer à inclure vos produits et de voir votre menu prendre forme, il est nécessaire de souscrire à un plan d'entretien d'un montant de"
    },
    BRL99: {
      message: "BRL 99.90"
    },
    month: {
      message: "un mois."
    },
    includesFacilities: {
      message: "Il comprend des installations telles que :"
    },
    menuWithPhotos: {
      message: "menu complet avec photos;"
    },
    priceUpdate: {
      message: "menu complet avec photos;"
    },
    activateDeactivate: {
      message: "activer et désactiver les produits rapidement ;"
    },
    categorizeFilteReorder: {
      message: "catégoriser, filtrer et réorganiser les produits ; "
    },
    newFeatures: {
      message: "de nouvelles fonctionnalités ajoutées chaque semaine !"
    },
    thePlan: {
      message: "signer le plan"
    },
    company: {
      message: "ENTREPRISE"
    },
    fantasyName: {
      message: "NOM FANTASTIQUE"
    },
    numberAccesses: {
      message: "NOMBRE D'ACCÈS"
    },
    entryDate: {
      message: "DATE D'ENTRÉE"
    },
    wantLeave: {
      message: "Maintenant que tu es arrivé jusqu'ici, es-tu sûr de vouloir partir ?"
    },
    cancelYourSubscription: {
      message: "Nous sommes désolés que vous souhaitiez annuler votre abonnement au plan MyTripMenu."
    },
    beforeConfirming: {
      message: "Avant de confirmer, je dois vous dire que si vous partez, vos clients n'auront plus accès au menu de votre établissement et l'accès à l'URL et au QRCode ne sera pas disponible jusqu'à ce que vous vous réinscriviez au forfait."
    },
    goAhead: {
      message: "Si tu en es sûr, vas-y..."
    },
    unsubscribePlan: {
      message: "se désinscrire du forfait"
    },
    dontCancel: {
      message: "Je ne veux plus annuler"
    },
    noChange: {
      message: "Pas de changement"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, société à responsabilité limitée, régulièrement enregistrée auprès du CNPJ sous le numéro 34.177.633/0001-91, dont le siège social est situé Rua Felipe Dos Santos, nº 77, 1203 torre A, quartier Padre Reus, dans la ville de São Leopoldo/RS, sous CEP : 93.020-180."
    },
    Delivery: {
      message: "Livraison"
    },
    withdrawal: {
      message: "retrait"
    },
    Pickup: {
      message: "A retirer sur place"
    },
    Catalog: {
      message: "Catalogue"
    },
    Enteremail: {
      message: "Entrez l'e-mail"
    },
    emailinvalid: {
      message: "Cette adresse email est invalide"
    },
    Typepassword: {
      message: "Tapez le mot de passe"
    },
    least6digits: {
      message: "Ce champ doit comporter au moins 6 chiffres"
    },
    Cashpayment: {
      message: "Le mode de paiement en espèces ne peut pas être modifié"
    },
    Checkpayment: {
      message: "Check payment method cannot be edited"
    },
    save: {
      message: "Sauver"
    },
    logIn: {
      message: "CONNEXION"
    },
    logged: {
      message: "VOUS ÊTES AUTHENTIFIÉ"
    },
    enterAShortEasilyIdentifiableName: {
      message: "Entrez un nom court et facilement identifiable"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "La sous-catégorie Highlights ne peut pas être copiée"
    },
    unableToLoadCards : {
      message: "Impossible de charger les cartes"
    },
    invalidUser: {
      message: "Utilisateur invalide"
    },
    invalidPassword: {
      message: "Mot de passe incorrect"
    },
    inactiveUser: {
      message: "Utilisateur inactif"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
  }
}
