import styled from "styled-components";

export const ListCategoriesTableWrapper = styled.div`
  height: calc(100vh - 155px);
  width: 100%;
  overflow: auto;
  table {
    margin-left: 16px;
  }
`;

export const SearchContainer = styled.div`
  width: 40%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 16px;
`;
