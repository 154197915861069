import currency from "currency.js";



const formatToCurrencyWithMonetaryValue = (value) =>
  currency(value, { symbol: "R$ ", decimal: ",", separator: "." }).format();

const formatNumberToString = (value) =>
  currency(value, { symbol: "", decimal: "," }).format();

const formatCategoriesToMultSelectOptions = (categories,language) => {
  return categories.map((category) => ({
    label: category.translatedName.length > 0 && category.translatedName[0].name[0][language] !== undefined ? category.translatedName[0].name[0][language] : category.name,
    key: category._id,
    isMultSelected: false,
  }));
}
const formatSubCategoriesToMultSelectOptions = (subCategories,language) => {
  return subCategories.map((subCategory) => ({
    label: subCategory.translatedName.length > 0 &&  subCategory.translatedName[0].name[0][language]  !== undefined? subCategory.translatedName[0].name[0][language] : subCategory.name,
    key: subCategory._id,
    selecionado: false,
    isMultSelected: false,
  }));
};

const formatProductsCategoriesToEdit = (products) =>
  products.subcategories.map((subCategory) => subCategory.category._id);

const formatProductsSubCategoriesToEdit = (products) =>
  products.subcategories.map((subCategory) => subCategory._id);

const formatProductsMultiSelectSubCategoriesToEdit = (products) =>
  products.subcategories.map((subCategory) => subCategory.key);

const formatId = (value) => ("0000" + value).slice(-4);

export default {
  formatToCurrencyWithMonetaryValue,
  formatNumberToString,
  formatCategoriesToMultSelectOptions,
  formatSubCategoriesToMultSelectOptions,
  formatProductsCategoriesToEdit,
  formatProductsSubCategoriesToEdit,
  formatProductsMultiSelectSubCategoriesToEdit,
  formatId,
};
