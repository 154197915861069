import React, { useEffect, useState } from "react";
import CurrencyMask from "react-currency-format";
import {
  DeliverySettingsContainer,
  DeliverySettingsContext,
  DeliverySettingsLabel,
  DeliveryInpuntContext,
  DeliveryInputTitle,
  DeliveryInpunt,
} from "./Styles";
import PropTypes from "prop-types";
import restDeliverySettings from "../../../../../api/deliverysettings/rest-delivery-settings";
import TranslatorFunction from "../../../../../components/I18n/Translator"

const DeliveryInput = (props) => {
  return <DeliveryInpunt {...props} />;
};

const DeliverySettings = ({ deliverySettings, getDeliverySettings }) => {

  const generalSettings = TranslatorFunction({ path: 'generalSettings.message' });
  const freeShippingForPurchasesAbove = TranslatorFunction({ path: 'freeShippingForPurchasesAbove.message' });
  const minimumValueForDeliveryOrders = TranslatorFunction({ path: 'minimumValueForDeliveryOrders.message' });
  const minimumDeliveryTime = TranslatorFunction({ path: 'minimumDeliveryTime.message' });
  const maximumDeliveryTime = TranslatorFunction({ path: 'maximumDeliveryTime.message' });
  const orderResponseTimeInMinutes = TranslatorFunction({ path: 'orderResponseTimeInMinutes.message' });
  const serviceNumberViaWhats = TranslatorFunction({ path: 'serviceNumberViaWhats.message' });
  const [form, setForm] = useState(deliverySettings);

  const putDeliverySettings = () => {
    restDeliverySettings.putDeliverySettings(form._id, form);
    // .then((res) => console.log(res))
    // .catch((err) => console.log(err));
  };

  const handleInputOnChange = (event) => {
    const { value, name } = event.target;
    let newForm = { ...form };
    let hold;

    if ((name === "freeShipping" || name === "minValue") && value !== null) {
      
      hold = value.slice(2, -3);
      newForm[name] = Number.isNaN(Number.parseInt(hold))
        ? null
        : Number.parseInt(hold);    
    } else if (name === "officePhone") {
      newForm[name] = value;
    } else {
      newForm[name] = Number.isNaN(Number.parseInt(value))
        ? null
        : Number.parseInt(value);
    }

    setForm(newForm);
  };

  const noFreeShipping = TranslatorFunction({ path: 'noFreeShipping.message' });
  const noForecast = TranslatorFunction({ path: 'noForecast.message' });

  useEffect(() => {
    setForm(deliverySettings);
  }, [deliverySettings]);

  return (
    <>
      <DeliverySettingsContainer>
        <DeliverySettingsLabel>{generalSettings}</DeliverySettingsLabel>
        <DeliverySettingsContext>
          <DeliveryInpuntContext>
            <DeliveryInputTitle>
            {freeShippingForPurchasesAbove}
            </DeliveryInputTitle>
            <CurrencyMask
              placeholder={noFreeShipping}
              prefix="R$ "
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              name="freeShipping"
              value={form?.freeShipping ?? ""}
              onChange={handleInputOnChange}
              customInput={DeliveryInput}
              onBlur={putDeliverySettings}
            />
          </DeliveryInpuntContext>
          <DeliveryInpuntContext>
            <DeliveryInputTitle>
            {minimumValueForDeliveryOrders}
            </DeliveryInputTitle>
            <CurrencyMask
              placeholder="R$ 0,00"
              prefix="R$ "
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              name="minValue"
              value={form?.minValue ?? ""}
              onChange={handleInputOnChange}
              customInput={DeliveryInput}
              onBlur={putDeliverySettings}
            />
          </DeliveryInpuntContext>
          <DeliveryInpuntContext>
            <DeliveryInputTitle>{minimumDeliveryTime}</DeliveryInputTitle>
            <CurrencyMask
              placeholder={noForecast}
              format="### min"
              name="minDeliveryTime"
              value={form?.minDeliveryTime ?? ""}
              onChange={handleInputOnChange}
              customInput={DeliveryInput}
              onBlur={putDeliverySettings}
            />
          </DeliveryInpuntContext>
          <DeliveryInpuntContext>
            <DeliveryInputTitle>{maximumDeliveryTime}</DeliveryInputTitle>

            <CurrencyMask
              placeholder={noForecast}
              format="### min"
              name="maxDeliveryTime"
              value={form?.maxDeliveryTime ?? ""}
              onChange={handleInputOnChange}
              customInput={DeliveryInput}
              onBlur={putDeliverySettings}
            />
          </DeliveryInpuntContext>
          <DeliveryInpuntContext>
            <DeliveryInputTitle>
            {orderResponseTimeInMinutes}
            </DeliveryInputTitle>
            <CurrencyMask
              placeholder={noForecast}
              format="### min"
              name="responseTime"
              value={form?.responseTime ?? ""}
              onChange={handleInputOnChange}
              customInput={DeliveryInput}
              onBlur={putDeliverySettings}
            />
          </DeliveryInpuntContext>
          <DeliveryInpuntContext>
            <DeliveryInputTitle>
            {serviceNumberViaWhats}
            </DeliveryInputTitle>
            <CurrencyMask
              placeholder="+55 (XX) XXXXX-XXXXX"
              format="+55 (##) #####-####"
              name="officePhone"
              value={form?.officePhone ?? ""}
              onChange={handleInputOnChange}
              customInput={DeliveryInput}
              onBlur={putDeliverySettings}
            />
          </DeliveryInpuntContext>
        </DeliverySettingsContext>
        {/* <DeliverySettingsLine /> */}
      </DeliverySettingsContainer>
    </>
  );
};

DeliverySettings.defaultProps = {
  deliverySettings: {},
  getDeliverySettings: () => {},
};

DeliverySettings.propTypes = {
  deliverySettings: PropTypes.object,
  getDeliverySettings: PropTypes.func,
};

export default DeliverySettings;
