import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IconCamera } from "../../styles/icons";
import {
  ImageCropInput,
  ImageCropWrapper,
  ImageCropEmptyContent,
  ImageCropEmptytWrapper,
  ImageCropOverlay,
  ImageCropLabelButton,
  ImageCropIconAdd,
  ImageCropContent,
  ImageCropContainer,
  CropperStyled,
  ImageCropSaveButton,
  ImageCropPreviewImg,
  ImageCropLoaderContent,
  ImageCropLoaderOverlay,
} from "./Styles";
import generateRandomIdHash from "../../utils/IdGenerator";
import { getCroppedImg } from "../../utils/crop-utils";
import Loader from "../loader/Loader";
import TranslatorFunction from "../I18n/Translator"


const ImageCrop = (props) => {
  const {
    value,
    id,
    className,
    buttonHtmlFor,
    buttonText,
    withIconAdd,
    onChange,
    isDefaultImage,
    minHeight,
    isProduct,
  } = props;

  const [inputId] = useState(id !== "" ? id : generateRandomIdHash());
  const [file, setFile] = useState();
  const [fileURL, setFileURL] = useState(value);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isCropActive, setIsCropActive] = useState(false);
  const [zoom, setZoom] = useState(0.8);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const save = TranslatorFunction({ path: 'save.message' });

  const readFileURL = async (fileFromInput) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(fileFromInput);
    });

  const handleOnChangeInputFile = async (event) => {
    event.preventDefault();
    const tmpFile = event.target.files[0];
    if (!tmpFile) {
      return;
    }
    setIsLoading(true);
    const tmpFileURL = await readFileURL(tmpFile);
    setFile(tmpFile);
    setFileURL(tmpFileURL);
    setIsCropActive(true);
    // onChange({ file: tmpFile, fileURL: tmpFileURL.toString() });
    setIsLoading(false);
  };

  const handleOnSaveCrop = async () => {
    setIsLoading(true);
    const croppedFile = await getCroppedImg(fileURL, croppedAreaPixels, 0);
    const croppedFileUrl = URL.createObjectURL(croppedFile);
    setFile(isProduct ? null : croppedFile);
    setFileURL(isProduct ? null : croppedFileUrl);
    setIsCropActive(false);
    onChange({
      file: croppedFile,
      fileURL: croppedFileUrl.toString(),
      fileContent: file,
    });
    setIsLoading(false);
  };

  const handleOnCropComplete = useCallback(
    (croppedArea, paramCroppedAreaPixels) => {
      setCroppedAreaPixels(paramCroppedAreaPixels);
    },
    []
  );

  useEffect(() => {
    setFileURL(value);
    // eslint-disable-next-line
  }, [value]);

  const ImageCropEmpty = () => {
    return (
      <ImageCropEmptytWrapper>
        <ImageCropEmptyContent>
          <ImageCropLoaderContent isLoading={isLoading}>
            <ImageCropLoaderOverlay />
            <Loader />
          </ImageCropLoaderContent>
          <IconCamera />
        </ImageCropEmptyContent>
      </ImageCropEmptytWrapper>
    )
  };

  const ImageCropPreviewComponent = () => {
    return (
      <ImageCropContent>
        <ImageCropLoaderContent isLoading={isLoading}>
          <ImageCropLoaderOverlay />
          <Loader />
        </ImageCropLoaderContent>
        <ImageCropOverlay isLoading={isLoading} />
        <ImageCropContainer>
          <ImageCropPreviewImg
            src={fileURL}
            isDefaultImage={isDefaultImage}
            minHeight={minHeight}
          />
        </ImageCropContainer>
      </ImageCropContent>
    )
  };

  const ImagePreviewOrEmptyContent = () => {
    return fileURL !== "" ? <ImageCropPreviewComponent /> : <ImageCropEmpty />;
  }

  return (
    <ImageCropWrapper className={className}>
      <ImageCropLabelButton
        isCropActive={isCropActive}
        isLoading={isLoading}
        htmlFor={buttonHtmlFor || inputId}
      >
        {withIconAdd && <ImageCropIconAdd />}
        {buttonText}
      </ImageCropLabelButton>
      {isCropActive ? (
        <ImageCropContent>
          <ImageCropLoaderContent isLoading={isLoading}>
            <ImageCropLoaderOverlay />
            <Loader />
          </ImageCropLoaderContent>
          <ImageCropContainer>
            <ImageCropSaveButton
              disabled={isLoading}
              onClick={handleOnSaveCrop}
            >
              {save}
            </ImageCropSaveButton>
            <CropperStyled
              image={fileURL}
              crop={crop}
              zoom={zoom}
              restrictPosition={false}
              onCropChange={setCrop}
              onCropComplete={handleOnCropComplete}
              onZoomChange={setZoom}
            />
          </ImageCropContainer>
        </ImageCropContent>
      ) : (
        <ImagePreviewOrEmptyContent />
      )}
      <ImageCropInput
        id={inputId}
        type="file"
        onChange={handleOnChangeInputFile}
      />
    </ImageCropWrapper>
  );
};

ImageCrop.defaultProps = {
  value: "",
  id: "",
  withIconAdd: false,
  className: "",
  buttonHtmlFor: "",
  buttonText: "",
  onChange: () => { },
  isDefaultImage: false,
  minHeight: 326,
  isProduct: false,
};

ImageCrop.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  withIconAdd: PropTypes.bool,
  buttonHtmlFor: PropTypes.string,
  buttonText: PropTypes.string,
  onChange: PropTypes.func,
  isDefaultImage: PropTypes.bool,
  minHeight: PropTypes.number,
  isProduct: PropTypes.bool,
};

export default ImageCrop;
