import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import User from "../../../api/user/rest-user";
import InputSearch from "../../../components/inputs/mui-input-search/input-search";
import MenuItem from "../../../components/menu-item/MenuItem";
import {
  IconCadastros,
  IconClient,
  IconCupons,
  IconMeuEstabelecimento,
  IconParameterizacao,
  IconPedidos,
} from "../../../styles/icons";
import storage from "../../../utils/storage";
import PageSideMenuFooter from "../page-side-menu-footer/PageSideMenuFooter";
import {
  AsideContainer,
  ContainerAdmin,
  ImageLogo,
  LogoContainer,
  MenuContainer,
  MenuIcon,
  SelectContainer,
  SelectSubTittle,
  SelectTittle,
} from "./Styles";

import  { Translator } from '../../../components/I18n'

const Aside = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const [menus, setMenus] = useState([
    {
      id: 2,
      route: "/meu-estabelecimento?tab=company",
      text: <Translator path="myEstablishment.message" />,
      pageTitle: <Translator path="myEstablishment.message" />,
      name: "meu-estabelecimento",
      active: false,
      icon: <IconMeuEstabelecimento />,
    },
    {
      id: 3,
      route: "/parametrizacao?tab=delivery-conf",
      text: <Translator path="parameterization.message" />,
      pageTitle: <Translator path="parameterization.message" />,
      name: "parametrizacao",
      active: false,
      icon: <IconParameterizacao />,
    },
    {
      id: 4,
      route: "/cadastro-itens?tab=categories",
      text: <Translator path="itemRegistration.message" />,
      pageTitle: <Translator path="itemRegistration.message" />,
      name: "cadastro-itens",
      active: false,
      icon: <IconCadastros />,
    },
    {
      id: 5,
      route: "/pedidos?page=1",
      text: <Translator path="orders.message" />,
      pageTitle: <Translator path="orders.message" />,
      name: "pedidos",
      active: false,
      icon: <IconPedidos />,
    },
    {
      id: 6,
      route: "/cupom?page=1",
      text: <Translator path="discountCoupons.message" />,
      pageTitle: <Translator path="discountCoupons.message" />,
      name: "cupom",
      active: false,
      icon: <IconCupons />,
    },
  ]);
  const selectedUserStorage = storage.getAnotherUser();
  const userAdmin = storage.getAdmin();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ name: "", value: "" });
  const history = useHistory();

  const handleSuccess = (data) => {
    let newData = [];

    // eslint-disable-next-line
    data.map((user) => {
      newData.push({ name: user.companyName, value: user._id });
    });

    setUsers(newData);
    setLoading(false);
  };

  const fetchUsers = () => {
    User.getAllUser().then((res) => handleSuccess(res.data));
  };

  const isMenuActive = (name) => location.pathname.includes(name);

  const menusMap = (menu) => (
    <MenuItem
      key={menu.id}
      isActive={isMenuActive(menu.name)}
      name={menu.name}
      to={`${url}${menu.route}`}
    >
      <MenuIcon isactive={isMenuActive(menu.name).toString()}>
        {menu.icon}
      </MenuIcon>
      {menu.text}
    </MenuItem>
  );

  const handleOnBlurSearch = (event, value) => {
    if (value) {
      storage.setAnotherUser(value);
      setSelectedUser({ name: value.name, value: value.value });
    }

    if (!value) {
      storage.dropAnotherUser();
      setSelectedUser({ value: "", name: "" });
    }

    history.go(0);
  };

  useEffect(() => {
    fetchUsers();
    if (selectedUserStorage) {
      setSelectedUser(selectedUserStorage);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storage.getAdmin()) {
      let newMenus = [...menus];
      newMenus.unshift({
        id: 1,
        route: "/clientes",
        text: <Translator path="customers.message" />,
        pageTitle: <Translator path="customers.message" />,
        name: "clientes",
        active: false,
        icon: <IconClient />,
      });

      setMenus(newMenus);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AsideContainer>
      <LogoContainer>
        <ImageLogo />
      </LogoContainer>
      {!loading && userAdmin && (
        <ContainerAdmin>
          <SelectTittle><Translator path="logIn.message" /></SelectTittle>
          <SelectContainer>
            <MuiAutoComplete
              id="user"
              freeSolo
              name="user"
              options={users}
              getOptionLabel={(option) => option.name}
              value={selectedUser}
              renderInput={(params) => (
                <InputSearch
                  {...params}
                  margin="dense"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onChange={handleOnBlurSearch}
            />
          </SelectContainer>

          <SelectSubTittle>
          <Translator path="logged.message" /> <span>{selectedUser.name?.toUpperCase()}</span>
          </SelectSubTittle>
        </ContainerAdmin>
      )}

      <MenuContainer>{menus.map(menusMap)}</MenuContainer>
      <PageSideMenuFooter />
    </AsideContainer>
  );
};

export default React.memo(Aside);
