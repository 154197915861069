import HttpClient from "../http-client";
import { id } from "../helper-client";

const putCoupons = async (id, data) => {
  return HttpClient.put(`coupon/${id}`, data);
};

const postCoupons = async (data) => HttpClient.post("coupon", data);

const fetchCoupons = async (page, name, type) =>
  HttpClient.get(
    `coupon?page=${page ?? 1}&name=${name ?? ""}&filter=${
      type ?? ""
    }&userId=${id}`
  );

export default {
  putCoupons,
  fetchCoupons,
  postCoupons,
};
