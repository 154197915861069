import styled from 'styled-components';

const Container = styled.div`
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

export {
  Container,
  Content,
};
