import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputText from '../../../components/inputs/input-text/InputText';
import FormUtils from '../../../utils/form-utils';
import {
  LoginFormContent,
  LoginFormRow,
  LoginFormTitle,
  LoginFormSubtitle,
  LoginFormTextBox,
  LoginFormButtonBox,
  LoginFormErrorBox,
  LoginFormErroMessage,
  LoginFormButton,
  LoginFormButtonForgotPassword,
} from "./Styles";
import AuthForm from "./AuthForm";
import restAuth from "../../../api/auth/rest-auth";
import TranslatorFunction from "../../../components/I18n/Translator"
import I18n, { Translator } from '../../../components/I18n'
import { useBlockLoadingContext } from "../../../contexts/BlockLoaderContext";
import User from "../../../utils/user";

const defaultForm = { ...AuthForm };



const LoginForm = () => {
  const mail = TranslatorFunction({ path: 'mail.message' });
  const password = TranslatorFunction({ path: 'password.message' });
  const LoginFormButtonForgotPassword1 = TranslatorFunction({ path: 'LoginFormButtonForgotPassword.message' });
  const LoginFormErroMessage1 = TranslatorFunction({ path: 'LoginFormErroMessage.message' });
  const invalidUser = TranslatorFunction({ path: 'invalidUser.message' });
  const invalidPassword = TranslatorFunction({ path: 'invalidPassword.message' });
  const inactiveUser = TranslatorFunction({ path: 'inactiveUser.message' });
  const emailinvalid = TranslatorFunction({ path: 'emailinvalid.message' });
  const Typepassword = TranslatorFunction({ path: 'Typepassword.message' });
  const least6digits = TranslatorFunction({ path: 'least6digits.message' });
  const enterEmail = TranslatorFunction({ path: 'enterEmail.message' });
  const thisFieldIsRequired = TranslatorFunction({ path: 'thisFieldIsRequired.message' });
  const LoginFormButton1 = TranslatorFunction({ path: 'LoginFormButton.message' });
 
  defaultForm.email.placeholder = enterEmail;
  defaultForm.email.errorsMsg.email = emailinvalid;
  defaultForm.email.errorsMsg.required = thisFieldIsRequired;
 
  defaultForm.password.placeholder = Typepassword;
  defaultForm.password.errorsMsg.minLength = least6digits;
  defaultForm.password.errorsMsg.required = thisFieldIsRequired;
 
  const [form, setForm] = useState(defaultForm);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(LoginFormErroMessage1);
  const { setIsLoading } = useBlockLoadingContext();
  const history = useHistory();

  useEffect(() => {
    clearFields(['email','password'])
  },[]);

  const handleSuccessResponse = async (response) => {
    setIsLoading(false);
    try {
      User.setUserData(response.data);

      if (User.getIsFirstLogin()) {
        history.push("/home/meu-estabelecimento?tab=subscriptions");
      } else {
        history.push('/home/cadastro-itens?tab=subcategories');
      }
    } catch (error) {
      User.clearUserSession();
      setShowErrorMessage(true);
    }
    // history.push(`/${response.data.user.subDomain}`);
  };

  const handleOnClickRecoveryPassword = () => history.push('/recovery');

  const handleResponseError = (err) => {
    const responseJson = err.response.data.message;
    const responseJsonParsed = JSON.parse(responseJson);
    setIsLoading(false);
    setShowErrorMessage(true);
    if (responseJsonParsed.code === 1) setErrorMessage(invalidUser);

    if (responseJsonParsed.code === 2) setErrorMessage(invalidPassword);

    if (responseJsonParsed.code === 3) setErrorMessage(inactiveUser);
  };

  const postAuth = () => {
    setIsLoading(true);
    restAuth.postAuth(FormUtils.getFormRawValue(form))
      .then(handleSuccessResponse)
      .catch(handleResponseError);
  };

  const clearFields = (names) => {
    names.forEach((name) =>{
      setForm((oldForm) => {
        const newForm = { ...oldForm };
        newForm[name].value = '';
        return FormUtils.updateFormValidationWithValidators(newForm);
      });
    })
  };

  const handleOnChangeInput = (name, value) => {
    setForm((oldForm) => {
      const newForm = { ...oldForm };
      newForm[name].value = value;
      return FormUtils.updateFormValidationWithValidators(newForm);
    });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    setShowErrorMessage(false);
    setIsSubmitted(true);
    if (FormUtils.isFormValid(form)) {
      postAuth();
    } else {
      setForm((oldForm) => FormUtils.updateFormValidationWithValidators(oldForm));
    }
  };

  const handleErrorShow = (field) => {
    const keys = Object.keys(field.hasErrors);
    const errors = keys.filter((key) => field.hasErrors[key] === true);
    return isSubmitted && errors.length > 0;
  };

  const getErrorMessage = (field) => {
    const keys = Object.keys(field.hasErrors);
    const errorsKeys = keys.filter((key) => field.hasErrors[key] === true);
    const errorsMessages = errorsKeys.map((key) => field.errorsMsg[key]);
    return errorsMessages;
  };

  return (
    <LoginFormContent onSubmit={handleOnSubmit} noValidate>
      <LoginFormTextBox><I18n />
        <LoginFormTitle><Translator path="LoginFormTitle.message" /></LoginFormTitle>
        <LoginFormSubtitle><Translator path="LoginFormSubtitle.message" /></LoginFormSubtitle>
      </LoginFormTextBox>
      <LoginFormRow>
        <InputText
          label={mail}
          name="email"
          value={form.email.value}
          onChange={handleOnChangeInput}
          placeholder={form.email.placeholder}
          error={handleErrorShow(form.email)}
          errorMessage={getErrorMessage(form.email)[0] || ''}
          dataTestId="email"
        />
      </LoginFormRow>
      <LoginFormRow paddingBottom="16">
        <InputText
          inputType="password"
          name="password"
          label={password}
          value={form.password.value}
          onChange={handleOnChangeInput}
          placeholder={form.password.placeholder}
          error={handleErrorShow(form.password)}
          errorMessage={getErrorMessage(form.password)[0] || ''}
          dataTestId="password"
        />
      </LoginFormRow>
      <LoginFormButtonForgotPassword
        btnType="tertiary"
        type="button"
        onClick={handleOnClickRecoveryPassword}
      >
        {LoginFormButtonForgotPassword1}
      </LoginFormButtonForgotPassword>
      <LoginFormErrorBox>
        {
          showErrorMessage ? (
            <LoginFormErroMessage>
              {errorMessage}
            </LoginFormErroMessage>
          ) : null
        }
      </LoginFormErrorBox>
      <LoginFormButtonBox>
        <LoginFormButton type="submit">{LoginFormButton1}</LoginFormButton>
      </LoginFormButtonBox>
    </LoginFormContent>
  );
};

export default LoginForm;
