import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalCustom } from './Styles';
import BlockLoader from '../block-loader/BlockLoader';

const ModalMytm = (props) => {
  const {
    open, className, isLoading, children,
  } = props;
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => setIsOpen(open), [open]);

  return (
    <ModalCustom
      className={className}
      style={ModalCustom}
      isOpen={isOpen}
    >
      <BlockLoader blocking={isLoading}>
        { children }
      </BlockLoader>
    </ModalCustom>
  );
};

ModalMytm.defaultProps = {
  className: '',
  open: false,
  isLoading: false,
  children: <></>,
};

ModalMytm.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any,
  ]),
};

export default ModalMytm;
