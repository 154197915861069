import styled from 'styled-components';
import { ReactComponent as SavingsSVG } from '../../../../../../../assets/savings.svg';
import {
  Description12px300Light, Description12px600Semibold, Paragraph16px300Light,
  Subtitle16px600SemiboldUppercase, Title24px700BoldUppercase,
} from '../../../../../../../styles/style-guide';

const SignaturesPlanSignInContent = styled.div`
  background: ${({ theme }) => theme.colors.mainColorDisabled};
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  margin: 24px 0;
  display: flex;
  flex-direction: column;
`;

const SignaturesPlanSignInValuesWrapper = styled.div`
  position: relative;
`;

const SignaturesPlanSignInValuesContent = styled.div`
  position: absolute;
  right: -8px;
  top: 8px;
  width: 150px;
  height: 80px;
`;

const SignaturesPlanSignInContenteCenter = styled.div`
  display: flex;
  margin: 0 185px 0 150px;
  min-height: 80px;
`;

const SignaturesPlanSignInButtonOrStatusContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  
  @media (max-width: 1430px) {
    width: 100%;
  }
`;

const SignaturesPlanSignInStatusWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
`;

const SignaturesPlanSignInStatusContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SignaturesPlanSignInStatusLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const SignaturesPlanSignInStatusLabelMainColor = styled.label`
  color: ${({ theme }) => theme.colors.mainColor};
  ${Subtitle16px600SemiboldUppercase};
`;

const SignaturesPlanSignInStatusLabel = styled.label`
${Paragraph16px300Light};
`;

const SignaturesPlanSignInSubtitle = styled.label`
  ${Subtitle16px600SemiboldUppercase};
`;

const SignaturesPlanSignInPromoMonthLabel = styled.label`
  ${Subtitle16px600SemiboldUppercase};
  margin-left: 2px;
`;

const SignaturesPlanSignInOldPlanValueLabel = styled.label`
  color: ${({ theme }) => theme.colors.mainColorLight};
  ${Subtitle16px600SemiboldUppercase};
  text-decoration: line-through;
`;
const SignaturesPlanSignInPromoPlanValueLabel = styled.label`
  ${Title24px700BoldUppercase};
`;

const SignaturesPlanSignInPromoValuesContent = styled.div`
  margin-top: 5px;
`;

const SignaturesPlanSignInParagraphContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;

  @media (max-width: 1430px) {
    display: none;
  }
`;

const SignaturesPlanSignInParagraphBottomContent = styled.div`
  display: none;
  @media (max-width: 1430px) {
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

`;

const SignaturesPlanSignInImage = styled(SavingsSVG)`
  position: absolute;
  left: 0;
  bottom: -26px;
`;

const SignaturesPlanSignInParagraph = styled.p`
  ${Description12px300Light};
  text-align: right;
  margin-bottom: 10px;
  margin-right: 5px;
`;

const SignaturesPlanSignInLink = styled.a`
  ${Description12px600Semibold}
  color: ${({ theme }) => theme.colors.mainColor};
  cursor: pointer;
`;

const SignaturesPlanSignCancelButtonContent = styled.div`
  display: ${({ hasPlan }) => (hasPlan ? 'block' : 'none')};
  margin-left: auto;
`;

const SignaturesPlanSignCancelButtonBottomContent = styled.div`
  display: ${({ hasPlan }) => (hasPlan ? 'block' : 'none')};
`;

const SignaturesPlanSignInParagraphBottom = styled.p`
  ${Description12px300Light};
  text-align: right;
`;

const SignaturesPlanSignInImageWrapper = styled.div`
  position: relative;
`;

export {
  SignaturesPlanSignInContent,
  SignaturesPlanSignInContenteCenter,
  SignaturesPlanSignInValuesContent,
  SignaturesPlanSignInValuesWrapper,
  SignaturesPlanSignInPromoMonthLabel,
  SignaturesPlanSignInOldPlanValueLabel,
  SignaturesPlanSignInPromoPlanValueLabel,
  SignaturesPlanSignInPromoValuesContent,
  SignaturesPlanSignInSubtitle,
  SignaturesPlanSignInButtonOrStatusContent,
  SignaturesPlanSignInStatusContent,
  SignaturesPlanSignInStatusWrapper,
  SignaturesPlanSignInStatusLabelContent,
  SignaturesPlanSignInStatusLabelMainColor,
  SignaturesPlanSignInStatusLabel,
  SignaturesPlanSignInParagraphContent,
  SignaturesPlanSignInParagraph,
  SignaturesPlanSignInLink,
  SignaturesPlanSignInImage,
  SignaturesPlanSignCancelButtonContent,
  SignaturesPlanSignInParagraphBottomContent,
  SignaturesPlanSignInParagraphBottom,
  SignaturesPlanSignCancelButtonBottomContent,
  SignaturesPlanSignInImageWrapper,
};
