import React from "react";
import PropTypes from "prop-types";
import { TabLiContent, TabLiLabel } from "./Styles";

const TabItem = (props) => {
  const { text, selected, disabled, onClick } = props;

  const handleOnClick = (event) => {
    if (!disabled) {
      onClick(event);
    }
  };

  return (
    <>
      <TabLiContent onClick={handleOnClick}>
        <TabLiLabel isSelected={selected} disabled={disabled}>
          {text}
        </TabLiLabel>
      </TabLiContent>
    </>
  );
};

TabItem.defaultProps = {
  onClick: () => {},
  selected: false,
  disabled: false,
  text: "",
};

TabItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

export default TabItem;
