import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <Modal
    className={modalClassName}
    portalClassName={className}
    {...props}
  />
);

ReactModalAdapter.defaultProps = {
  className: '',
  modalClassName: '',
};

ReactModalAdapter.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ReactModalAdapter;
