import styled, { css } from "styled-components";
import {
  Title24px400Regular,
  Paragraph16px400Regular,
  Paragraph16px300Light,
} from "../../styles/style-guide";
import ReactModalAdapter from "./ModalAdapter";

const ModalMytmCss = css`
  .modal_mytm {
    &--after-open {
      position: relative;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: ${({ theme }) => theme.colors.mainColorContrast};
      overflow: auto;
      border-radius: 25px;
      outline: none;
      padding: 20px;
      max-height: 85vh;
    }

    &--before-close {
    }
  }
`;

const ModalMytmOverlayCss = css`
  .modal_mytm_overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    &--after-open {
      /* RGBa with 0.6 opacity */
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
      /* For IE 5.5 - 7*/
      /* filter: blur(50px); */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
      /* For IE 8*/
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
    }

    &--before-close {
      /* RGBa with 0.6 opacity */
      background-color: rgba(0, 0, 0, 0.6);
      /* For IE 5.5 - 7*/
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
      /* For IE 8*/
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
      /* background-color: rgba(0, 0, 0, 0.6); */
    }
  }
`;

const ModalCustom = styled(ReactModalAdapter).attrs({
  overlayClassName: {
    base: "modal_mytm_overlay",
    afterOpen: "modal_mytm_overlay--after-open",
    beforeClose: "modal_mytm_overlay--before-close",
  },
  modalClassName: {
    base: "modal_mytm",
    afterOpen: "modal_mytm--after-open",
    beforeClose: "modal_mytm--before-close",
  },
})`
  ${ModalMytmCss};
  ${ModalMytmOverlayCss};
`;

const ModalTitle = styled.h4`
  ${Title24px400Regular};
  margin-bottom: 22px;
  color: ${({ cColor }) => cColor || "#777"};
  text-align: ${({ cAlign }) => cAlign || "center"};
  font-weight: bold;
`;

const ModalSubTitle = styled.h5`
  ${Paragraph16px400Regular};
  visibility: hidden;
`;

const ModalParagraph = styled.p`
  ${Paragraph16px300Light};
  margin: 15px 0px;
`;

const ModalWrapper = styled.div`
  width: 65vw;
  height: 72vh;
  min-width: 40vw;
  padding: 4px;
`;
const ModalHeader = styled.div`
  padding-top: 2px;
  width: 44%;
  margin-bottom: 16px;
  height: 20%;
`;
const ModalBody = styled.div`
  height: 77%;
  width: 100%;
  display: flex;
  flex-direction: column;

  overflow-y: ${({ noOverflow }) => (noOverflow ? "hidden" : "auto")};

  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
    outline: 0px;
  }
`;

const ModalBodyScrollSection = styled.div`
  /* max-height: 300px; */
  /* width: 100%; */
  /* border: 1px solid #aaa; */
  /* border-radius: 15px; */
  /* margin: 0px 26px 0px 26px; */
  overflow: auto;

  height: ${({ cHeigth }) => cHeigth || "100%"};

  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
  }
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
    outline: 0px;
  }
`;

const ModalFooter = styled.div`
  height: 13%;
  bottom: 0px;
  width: 95%;
  position: absolute;
`;

export {
  ModalCustom,
  ModalTitle,
  ModalSubTitle,
  ModalParagraph,
  ModalWrapper,
  ModalHeader,
  ModalBody,
  ModalBodyScrollSection,
  ModalFooter,
};
