import React, { useState } from "react";
import { mask } from "remask";
import CurrencyMask from "react-currency-format";
import ModalMytm from "../../../../components/modal-mytm/ModalMytm";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../../../components/modal-mytm/Styles";
import {
  ModalContainerFields,
  ModalContainerType,
  TitleType,
  ContainerSelects,
  ContentSelects,
  SubtitleType,
  ContentDiscount,
  ButtonGoBack,
  ButtonSave,
} from "./styles";
import { inputSizeLabel } from "../../../../styles/style-guide"
import { MuiTextFieldStyled } from "../../../../components/inputs/mui-inputs/Styles";
import RadioButton from "../../../../components/radio-button/RadioButton";
import InputDate from "../../../../components/inputs/input-date/InputDate";
import {
  percentFormatter,
  removeCharactersAndSpecialCharacter,
} from "../../../../utils/masks";
import TranslatorFunction from "../../../../components/I18n/Translator"

function PercentMuiTextFieldStyled(props) {
  return <MuiTextFieldStyled {...props} margin="dense" variant="outlined" />;
}

function ValueMuiTextFieldStyled(props) {
  return <MuiTextFieldStyled {...props} margin="dense" variant="outlined" />;
}

function NewCoupons({
  open,
  onClose,
  createCoupon,
  form,
  setForm,
  edit,
  updateCoupon,
}) {
  const [errorInitDate, setErrorInitDate] = useState(false);
  const [errorLastDate, setErrorLastDate] = useState(false);
  const addEditCoupon = TranslatorFunction({ path: "addEditCoupon.message" });
  const freeShipping = TranslatorFunction({ path: "freeShipping.message" });
  const discountByValue = TranslatorFunction({ path: "discountByValue.message" });
  const percentageDiscount = TranslatorFunction({ path: "percentageDiscount.message" });
  const generateCoupon = TranslatorFunction({ path: "generateCoupon.message" });
  const back = TranslatorFunction({ path: "back.message" });
  const limitOfUse = TranslatorFunction({ path: "limitOfUse.message" });
  const couponCode = TranslatorFunction({ path: "couponCode.message" });
  const validFrom = TranslatorFunction({ path: "validFrom.message" });
  const validUntil = TranslatorFunction({ path: "validUntil.message" });
  const couponType = TranslatorFunction({ path: "couponType.message" });
  const language = TranslatorFunction({ path: "language.message" });
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    const oldValues = { ...form };

    if (value.trim() === oldValues[name]) return;

    oldValues[name] =
      name === "useLimite" ? mask(value, ["9999"]) : value.trim().toUpperCase();

    setForm(oldValues);
  };

  const onChangeValue = (event) => {
    const { name, value } = event.target
    const oldValues = { ...form };
    oldValues[name] = value;
    setForm(oldValues);
  };

  const onBlurValue = () => {
    let name = "discountValue";
    const oldValues = { ...form };

    if (oldValues[name].trim() === oldValues[name]) return;

    oldValues[name] =
      name === "useLimite" ? mask(oldValues[name], ["9999"]) : oldValues[name].trim().toUpperCase();

    setForm(oldValues);
  };

  const onChangePercent = (event) => {
    const { name, value } = event.target
    const oldValues = { ...form };
    oldValues[name] = value;
    setForm(oldValues);
  };

  const onBlurPercent = () => {
    const name = "discountPercent";
    const oldValues = { ...form };
    const valueWithoutCaracters = removeCharactersAndSpecialCharacter(oldValues[name]);
    if (!isNaN(valueWithoutCaracters) && valueWithoutCaracters > 10000)
      oldValues[name] = percentFormatter("10000");
    setForm(oldValues);
  };

  const handleChangeRadioButton = (event) => {
    const { value } = event.target;

    setForm({ ...form, type: value });
  };

  const onClickSave = async () => {
    const formattedValues = { ...form };

    if (formattedValues.type === "shipping") {
      formattedValues.discountValue = null;
      formattedValues.discountPercent = null;
    }

    if (formattedValues.type === "discountValue") {
      formattedValues.discountPercent = null;
      formattedValues.discountValue =
        typeof formattedValues.discountValue === "string"
          ? parseFloat(
            removeCharactersAndSpecialCharacter(
              formattedValues.discountValue
            ) / 100
          )
          : formattedValues.discountValue;
    }

    if (formattedValues.type === "discountPercent") {
      formattedValues.discountValue = null;
      formattedValues.discountPercent =
        typeof formattedValues.discountPercent === "string"
          ? parseFloat(
            removeCharactersAndSpecialCharacter(
              formattedValues.discountPercent
            ) / 100
          )
          : formattedValues.discountPercent;
    }

    let newEndDateValue = new Date(formattedValues.endendAt);

    newEndDateValue.setHours(23);
    newEndDateValue.setMinutes(59);
    newEndDateValue.setSeconds(59);
    formattedValues.endendAt = newEndDateValue.toISOString();
    
    let newStartDateValue = new Date(formattedValues.startedAt);

    newStartDateValue.setHours(0);
    newStartDateValue.setMinutes(0);
    newStartDateValue.setSeconds(0);
    formattedValues.startedAt = newStartDateValue.toISOString();

    edit
      ? await updateCoupon(form._id, formattedValues)
      : await createCoupon(formattedValues);
    onClose();
  };

  const gettingErrorInitDate = (err) => {
    setErrorInitDate(err && err.length > 0 ? true : false);
  };

  const gettingErrorLastDate = (err) => {
    setErrorLastDate(err && err.length > 0 ? true : false);
  };

  const values = [form.couponCod, form.useLimite];
  const basicValuesValid = values.every((v) => v !== "");
  let discountValues = true;

  if (form.type === "discountValue") {
    discountValues = form.discountValue !== "" && form.discountValue !== null;
  }

  if (form.type === "discountPercent") {
    discountValues =
      form.discountPercent !== "" && form.discountPercent !== null;
  }
  const verify = (
      !errorInitDate &&
      !errorLastDate &&
      basicValuesValid &&
      form.type !== "" &&
      discountValues
    );

  return (
    <ModalMytm isLoading={false} open={open} onClose={onClose}>
      <ModalWrapper>
        <ModalTitle>{addEditCoupon}</ModalTitle>
        <ModalBody>
          <ModalContainerFields>
            <MuiTextFieldStyled
              variant="outlined"
              margin="dense"
              label={couponCode}
              name="couponCod"
              value={form.couponCod}
              onChange={handleChange}
            />

            <InputDate
              label={validFrom}
              name="startedAt"
              value={form.startedAt}
              onChange={(date) => setForm({ ...form, startedAt: date })}
              onError={gettingErrorInitDate}
            />

            <InputDate
              label={validUntil}
              name="endendAt"
              value={form.endendAt}
              onChange={(date) => setForm({ ...form, endendAt: date })}
              onError={gettingErrorLastDate}
            />

            <MuiTextFieldStyled
              variant="outlined"
              margin="dense"
              label={limitOfUse}
              InputLabelProps={{ style: inputSizeLabel[language] }} // font size of input label
              name="useLimite"
              key="useLimite"
              value={form.useLimite}
              onChange={handleChange}
            />
          </ModalContainerFields>
          <ModalContainerType>
            <TitleType>{couponType}</TitleType>
            <ContainerSelects>
              <ContentSelects>
                <RadioButton
                  name="shipping"
                  value="shipping"
                  checked={form.type === "shipping"}
                  onChange={handleChangeRadioButton}
                />
                <SubtitleType>{freeShipping}</SubtitleType>
              </ContentSelects>
              <ContentSelects>
                <RadioButton
                  name="discountValue"
                  value="discountValue"
                  checked={form.type === "discountValue"}
                  onChange={handleChangeRadioButton}
                />
                <ContentDiscount>
                  <SubtitleType>{discountByValue}</SubtitleType>
                  <CurrencyMask
                    value={form.discountValue ?? ""}
                    placeholder="R$"
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    name="discountValue"
                    customInput={ValueMuiTextFieldStyled}
                    disabled={form.type !== "discountValue"}
                    onBlur={onBlurValue}
                    onChange={onChangeValue}
                  />
                </ContentDiscount>
              </ContentSelects>
              <ContentSelects>
                <RadioButton
                  name="discountPercent"
                  value="discountPercent"
                  checked={form.type === "discountPercent"}
                  onChange={handleChangeRadioButton}
                />
                <ContentDiscount>
                  <SubtitleType>{percentageDiscount}</SubtitleType>
                  <CurrencyMask
                    value={form.discountPercent ?? ""}
                    placeholder="%"
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    name="discountPercent"
                    suffix={"%"}
                    disabled={form.type !== "discountPercent"}
                    onChange={onChangePercent}
                    onBlur={() => {
                      onBlurPercent()
                    }}
                    customInput={PercentMuiTextFieldStyled}
                  />
                </ContentDiscount>
              </ContentSelects>
            </ContainerSelects>
          </ModalContainerType>
        </ModalBody>
        <ModalFooter>
          <ButtonSave onClick={onClickSave} disabled={!verify}>
            {generateCoupon}
          </ButtonSave>
          <ButtonGoBack onClick={onClose} type="button" btnType="tertiary">
            {back}
          </ButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </ModalMytm>
  );
}

export default NewCoupons;
