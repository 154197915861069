import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalTitle } from '../../../../../../../components/modal-mytm/Styles';
import {
  SignaturesPlanSignInModalLeftContent, SignaturesPlanSignInModalRightContent,
  SignaturesPlanSignInModalWrapper, SignaturesPlanSignInModalContent,
  SignaturesPlanSignInModalImage, SignaturesPlanSignInModalParagraph,
  SignaturesPlanSignInModalSpanMainColor, SignaturesPlanSignInModalCardNumberText,
  SignaturesPlanSignInModalCardFlagIcon, SignaturesPlanSignInModalCardContainer,
} from './Styles';
import Button from '../../../../../../../components/button/Button';
import InputText from '../../../../../../../components/inputs/input-text/InputText';
import SignaturesPlanSignInModalForm from './signatures-plan-sign-in-modal-form';
import { CvvOptions } from '../../../../../../../utils/input-options';
import formUtils from '../../../../../../../utils/form-utils';
import {
  IconAmex, IconDiners, IconElo, IconMastercard, IconVisa,
} from '../../../../../../../styles/icons';

const defaultCardIcons = {
  mastercard: <IconMastercard />,
  visa: <IconVisa />,
  amex: <IconAmex />,
  elo: <IconElo />,
  diners: <IconDiners />,
  unknown: '',
};

const defaultForm = SignaturesPlanSignInModalForm;

const SignaturesPlanSignInModal = (props) => {
  const {
    open, card, isLoading, onClickSignIn,
  } = props;

  const [form, setForm] = useState(defaultForm);
  const [isDisabledButton, setIsDisableButton] = useState(true);

  const fieldHasErrors = (field) => {
    const keys = Object.keys(field.hasErrors);
    return keys.filter((key) => field.hasErrors[key] === true).length > 0;
  };

  const handleErrorShow = (field) => (
    fieldHasErrors(field) && field.touched
  );

  const getErrorMessage = (field) => {
    const keys = Object.keys(field.hasErrors);
    const errorsKeys = keys.filter((key) => field.hasErrors[key] === true);
    const errorsMessages = errorsKeys.map((key) => field.errorsMsg[key]);
    return errorsMessages || '';
  };

  const handleInputChange = (name, value) => {
    setForm((oldForm) => {
      let newForm = { ...oldForm };
      newForm[name].value = value;
      newForm = formUtils.updateFieldValidationWithValidators(newForm, name);
      if (newForm.isValid) {
        setIsDisableButton(false);
      } else {
        setIsDisableButton(true);
      }
      return newForm;
    });
  };

  const handleOnSubmit = () => {
    if (form.isValid) {
      onClickSignIn(form.securityCode.value);
    }
  };

  const handleOnBlur = (event) => {
    const { name } = event.target;
    const newForm = { ...form };
    newForm[name].touched = true;
    setForm(newForm);
  };

  return (
    <SignaturesPlanSignInModalWrapper open={open} isLoading={isLoading}>
      <SignaturesPlanSignInModalContent>
        <SignaturesPlanSignInModalLeftContent>
          <ModalTitle>
            Você está prestes a descomplicar a gestão do seu cardápio!
          </ModalTitle>
          <SignaturesPlanSignInModalParagraph>
            Essa é só uma confirmação para esclarecermos tudinho antes de começar.
            Ao assinar o plano MyTripMenu:
            <br />
            <br />
            <SignaturesPlanSignInModalSpanMainColor>✓</SignaturesPlanSignInModalSpanMainColor>
            você aceita a cobrança mensal e automática do valor do plano em seu cartão
            de crédito cadastrado;
            <br />
            <SignaturesPlanSignInModalSpanMainColor>✓</SignaturesPlanSignInModalSpanMainColor>
            está ciente que um possível cancelamento do plano deve ser solicitado manualmente
            por você através da sua página de assinaturas;
            <br />
            <SignaturesPlanSignInModalSpanMainColor>✓</SignaturesPlanSignInModalSpanMainColor>
            concorda com os termos e condições.
            <br />
          </SignaturesPlanSignInModalParagraph>
          <SignaturesPlanSignInModalCardContainer>
            <SignaturesPlanSignInModalCardNumberText>
              <SignaturesPlanSignInModalCardFlagIcon>
                {defaultCardIcons[card.brand]}
              </SignaturesPlanSignInModalCardFlagIcon>
              •••• •••• ••••
              {` ${card.lastFourDigits}`}
            </SignaturesPlanSignInModalCardNumberText>
            <InputText
              label={form.securityCode.label}
              isRequired={form.securityCode.isRequired}
              placeholder={form.securityCode.placeholder}
              error={handleErrorShow(form.securityCode)}
              errorMessage={getErrorMessage(form.securityCode)[0] || ''}
              value={form.securityCode.value}
              options={CvvOptions}
              onChange={handleInputChange}
              onBlur={handleOnBlur}
              name="securityCode"
              dataTestId="securityCode"
            />
          </SignaturesPlanSignInModalCardContainer>
          <Button onClick={handleOnSubmit} disabled={isDisabledButton}>assinar o plano</Button>
        </SignaturesPlanSignInModalLeftContent>
        <SignaturesPlanSignInModalRightContent>
          <SignaturesPlanSignInModalImage />
        </SignaturesPlanSignInModalRightContent>
      </SignaturesPlanSignInModalContent>
    </SignaturesPlanSignInModalWrapper>
  );
};

SignaturesPlanSignInModal.defaultProps = {
  open: false,
  isLoading: false,
  onClickSignIn: () => { },
};

SignaturesPlanSignInModal.propTypes = {
  card: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  onClickSignIn: PropTypes.func,
  open: PropTypes.bool,
};

export default SignaturesPlanSignInModal;
