import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../../components/styled/Forms";
import Button from "../../../../../../components/button/Button";
import {
  Paragraph16px300Light,
  Title24px400Regular,
} from "../../../../../../styles/style-guide";

export const NewCityWrapper = styled.div`
  height: 100%;
  width: 60vw;
  border-radius: 30px;
`;

export const NewCityTitle = styled.h4`
  ${Title24px400Regular};
  margin-bottom: 22px;
  text-align: center;
  color: #9a9a9a;
`;

export const NewCitySubTitle = styled.h4`
  /* margin-bottom: 22px; */
  text-align: center;
  font-weight: 400;
  color: #6a6a6a;
`;

export const NewCityInputTitle = styled.label`
  position: absolute;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  top: -6px;
  left: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #ff5533;
`;
export const NewCityInput = styled.input`
  border-width: 1px;
  border-radius: 10px;
  border-color: #9a9a9a;
  width: 82vw;
  padding-left: 10px;
  /* text-align: center; */

  :focus {
    border-radius: 10px;
    outline: 0;
  }
`;
export const ContainerFilter = styled.div`
  padding-top: 15px;
  width: 80%;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  margin: 0px 26px 16px 12px;
`;
export const NewCityInputContent = styled.div`
  height: 36px;
  position: relative;
  margin: 15px 26px 15px 12px;
  display: flex;
  align-items: center;
`;

export const NewCityTableWrapper = styled.div`
  max-height: 300px;
  /* width: 100%; */
  border: 1px solid #aaa;
  border-radius: 15px;
  margin: 0px 26px 0px 26px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
  }
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
    outline: 0px;
  }
`;

export const NewCityCheckBox = styled.div`
  min-width: 100px;
`;

export const NewCityGrid = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px;
`;

export const NewCityForm = styled.form`
  display: flex;
  /* flex-direction: column; */
  .button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NewCityPhotoContent = styled.div`
  margin-top: 40px;
  display: flex;
`;

export const NewCityPhotoTextContent = styled.div`
  width: 40%;
  height: 320px;
`;

export const NewCityPhotoArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 320px;
`;

export const NewCityPhotoAreaContent = styled.div`
  margin-left: 35px;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  max-width: 426px;
`;

export const NewCityPhotoParagraph = styled.p`
  margin-top: 16px;
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
`;

export const NewCityInputGroupWithToggle = styled(InputGroupWithToggle)`
  margin-bottom: 11px;
`;

export const NewCityButtonGoBack = styled(Button)`
  float: right;
`;

export const NewCityButtonSave = styled(Button)`
  float: left;
`;

export const NewCityFooter = styled.footer`
  bottom: 20px;
  width: 90%;
  margin: 32px auto;
`;
export const NewCityInputGroup = styled.div`
  display: flex;
  flex: 0;
`;

export const NewCityDivision = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #e6e6e6;
`;
