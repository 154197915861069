import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
  ModalBodyScrollSection,
} from "../../../../../../components/modal-mytm/Styles";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import InputSearch from "../../../../../../components/inputs/mui-input-search/input-search";
import restCity from "../../../../../../api/city/rest-city";
import ButtonAdd from "../../../../../../components/button-add/button-add";
import Table from "../../../../../../components/table/Table";
import {
  ContainerFilter,
  NewCityButtonGoBack,
  NewCityButtonSave,
  NewCityDivision,
  NewCityInputContent,
} from "./Styles";
import  { Translator } from '../../../../../../components/I18n';

const City = (props) => {
  const { city, onClose } = props;
  const colTitleKeysAlign = ["left", "left"];
  const locationKeys = ["name"];
  const listTitles = [<Translator path='cityUpper.message' />, <Translator path='status.message' />, <Translator path='action.message' />];
  const colKeyAligns = { name: "left" };
  const data = city;

  const { register, handleSubmit, reset } = useForm();
  const [searchs, setSearchs] = useState({
    name: "",
  });

  //form que vem do backend + front
  const [form, setForm] = useState(data);

  // array de todos os complementos
  const [cityArray, setCityArray] = useState([]);

  //novos valores antes de mandar pro form
  const [oldForm, setOldForm] = useState([]);

  //valor do complement from
  const [values, setValues] = useState({
    name: "",
    id: 0,
    active: true,
    edit: false,
  }); //valor do form

  //editando ou criando

  const handleOnClickGoBack = () => {
    onClose();
  };

  const putAndPostCities = async () => {
    try {
      if (oldForm.length > 0) {
        await Promise.all(
          oldForm.map(async (city) => await restCity.postCity(city))
        );
      }
      if (form.length > 0) {
        await form.map(async (city) => await restCity.putCity(city._id, city));
      }
    } catch (error) {}
    onClose();
  };

  const handleOnClickSave = () => putAndPostCities();

  const verify = (finalForm) => {
    if (finalForm.name === "") return false;

    return true;
  };

  const handleAddOnTable = (finalForm) => {
    if (verify(finalForm)) {
      let oldForm_ = [...cityArray];
      let beforeForm = [...oldForm];

      let newValues = { ...values };
      newValues.name = finalForm.Name;

      beforeForm.push(newValues);
      oldForm_.push(newValues);
      setCityArray(oldForm_);
      setOldForm(beforeForm);
      setValues({
        name: "",
        id: oldForm.length + 1,
        active: true,
        edit: false,
      });
      reset({
        Name: ""
      });
    }
  };

  const handleChangeTable = (data, event) => {
    const isNew = data._id ? false : true;
    const { value, name } = event.target;
    const valuesInArray = [...cityArray];

    const findedIndexInArray = cityArray.findIndex((city) => city === data);

    if (isNew) {
      const values = [...oldForm];

      const findedIndexComplement = oldForm.findIndex(
        (compl) => compl.id === data.id
      );

      values[findedIndexComplement][name] = value;

      valuesInArray[findedIndexInArray] = values[findedIndexComplement];

      setOldForm(values);
      setCityArray(valuesInArray);
      return;
    }

    const findedIndexComplement = form.findIndex(
      (compl) => compl._id === data._id
    );

    const values = [...form];

    values[findedIndexComplement][name] = value;

    valuesInArray[findedIndexInArray] = values[findedIndexComplement];

    setForm(values);
    setCityArray(valuesInArray);

    return;
  };
  const handleOnChangeActiveToggle = (selectedCity) => {
    const cityIndex = cityArray.findIndex(
      (city) => city._id === selectedCity._id
    );
    setCityArray((oldData) => {
      const newData = [...oldData];
      newData[cityIndex].active = !newData[cityIndex].active;
      return newData;
    });
  };

  const handleOnClickEdit = (selectedCity) => {
    const cityIndex = cityArray.findIndex(
      (city) => city._id === selectedCity._id
    );

    setCityArray((oldData) => {
      const newData = [...oldData];
      newData[cityIndex].edit = !newData[cityIndex].edit;
      return newData;
    });
  };

  useEffect(() => {
    cityArray.map((city) => (city.edit = false));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCityArray(data ?? []);
    //eslint-disable-next-line
  }, [form]);

  const filterCityByName = (city) => {
    if (!searchs.name) return true;
    return city.name
      .toLocaleLowerCase()
      .includes(searchs.name.toLocaleLowerCase());
  };

  const filteredData = useMemo(
    () => cityArray?.filter(filterCityByName),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, cityArray]
  );

  return (
    <>
      <ModalWrapper>
        <ModalTitle><Translator path="addEditCities.message" /></ModalTitle>
        <ModalBody noOverflow>
          <form onSubmit={handleSubmit(handleAddOnTable)}>
            <NewCityInputContent>
              <InputSearch
                name="city"
                label={<Translator path="newCity.message" />}
                variant="outlined"
                margin="dense"
                InputProps={register("Name", { required: true })}
              />
              <ButtonAdd
                onClick={handleSubmit(handleAddOnTable)}
                className="button"
              />
            </NewCityInputContent>
          </form>
          <NewCityDivision />
          <ContainerFilter>
            {data && (
              <>
                <MuiAutoComplete
                  id="search"
                  options={data}
                  noOptionsText = {<Translator path='noOptions.message' />}
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <InputSearch
                      {...params}
                      margin="dense"
                      label={<Translator path="search.message" />}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  onChange={(e) => {
                    setSearchs({ ...searchs, name: e.target.value });
                  }}
                  onSelect={(e) => {
                    setSearchs({ ...searchs, name: e.target.value });
                  }}
                />
              </>
            )}
          </ContainerFilter>
          <ModalBodyScrollSection>
            <Table
              data={filteredData}
              setData={setCityArray}
              titles={listTitles}
              colKeysAlign={colKeyAligns}
              keys={locationKeys}
              colTitleKeysAlign={colTitleKeysAlign}
              withStatus
              objectKey="_id"
              statusKey="active"
              onClickToogle={handleOnChangeActiveToggle}
              withActions
              onClickEdit={handleOnClickEdit}
              onChangeInput={(data, event) => handleChangeTable(data, event)}
            />
          </ModalBodyScrollSection>
        </ModalBody>
        <ModalFooter>
          <NewCityButtonSave onClick={handleOnClickSave}>
            <Translator path="saveEditions.message" />
          </NewCityButtonSave>
          <NewCityButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            <Translator path="back.message" />
          </NewCityButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </>
  );
};

export default City;
