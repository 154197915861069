import React from "react";
import { ButtonAddIcon, Button } from "./Styles";

const ButtonAdd = ({ onClick, className }) => {
  return (
    <Button className={`${className}`}>
      <ButtonAddIcon onClick={onClick} />
    </Button>
  );
};

export default ButtonAdd;
