import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  InsideTableColTitle,
  TableRow,
  InsideTableCol,
  TableCustom,
  TableOrderButtons,
  TableInput,
  TableDrag,
  Empty,
} from "./Styles";
import Toggle from "../../toggle/Toggle";
import formatters from "../../../utils/formatters";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragAndDrop from "../../drag-and-drop/Drag-and-drop";
import CurrencyMask from "react-currency-format";
import { Translator } from '../../../components/I18n';

const TableInsideDnD = (props) => {
  const {
    data,
    setData,
    // group,
    dataIndex,
    keys,
    titles,
    withStatus,
    statusKey,
    colKeysAlign,
    colKeysCurrency,
    colTitleKeysAlign,
    colEditableField,
    colKeysMaxWidth,
    orderTitles,
    onClickOrder,
    // withCustomButton,
    // buttonText,
    // onClickButton,
    onDrag,
    // objectKey,
  } = props;
  const [currentOrderActive, setCurrentOrderActive] = useState("");
  const [currentOrderArrowActive, setCurrentArrowActive] = useState("");
  const [refresh, setRefresh] = useState(true);

  const formatColValue = (value, key) => {
    const isFormatCurrencyField = colKeysCurrency.includes(key);
    return isFormatCurrencyField
      ? formatters.formatToCurrencyWithMonetaryValue(value)
      : value;
  };

  const TableInp = (props) => {
    return <TableInput {...props} />;
  };

  const handleOnClickToggle = (obj) => {
    const index = data.findIndex((cat) => cat._id === obj._id);

    const newData = data;
    newData[index].active = !newData[index].active;
    setRefresh(!refresh);
  };

  const handleOnClickOrder = (headerTitle, arrow) => {
    setCurrentOrderActive(headerTitle);
    setCurrentArrowActive(arrow);
    onClickOrder(headerTitle, arrow);
    setRefresh(!refresh);
  };

  const onChangeInput = (obj, index, event) => {
    let newForm = data;

    const { floatValue } = event;
    const complementIndex = data.findIndex((cat) => cat._id === obj._id);

    newForm[complementIndex].price = Number.isNaN(floatValue) ? 0 : floatValue;

    // setData(newForm, null, group);
  };

  const renderStatusCol = (obj) =>
    withStatus ? (
      <InsideTableCol Column={"c-3"} colStatus>
        <Toggle
          value={obj[statusKey]}
          onChange={() => handleOnClickToggle(obj)}
          textLeft={<Translator path="active.message" />}
          textRight={<Translator path="inactive.message" />}
        />
      </InsideTableCol>
    ) : null;

  const renderTableHeadColumns = () =>
    titles.map((title, index) => {
      const keyValue = `th-${index}`;
      const textAlign = colTitleKeysAlign[index];
      const currentOrderTitle = orderTitles.find(
        (obj) => obj.titleKeyIndex === index
      );

      return (
        <InsideTableColTitle
          key={keyValue}
          Column={keyValue}
          textAlign={textAlign}
        >
          {title}
          {currentOrderTitle !== undefined &&
          currentOrderTitle.titleKey === title ? (
            <TableOrderButtons
              isUpActive={
                title === currentOrderActive && currentOrderArrowActive === "up"
              }
              isDownActive={
                title === currentOrderActive &&
                currentOrderArrowActive === "down"
              }
              tooltipUpText={currentOrderTitle.tooltipUpText}
              tooltipDownText={currentOrderTitle.tooltipDownText}
              onClickUp={() => handleOnClickOrder(title, "up")}
              onClickDown={() => handleOnClickOrder(title, "down")}
            />
          ) : null}
        </InsideTableColTitle>
      );
    });

  const renderTableBodyColumns = (dataObject) =>
    keys.map((objKey, index) => {
      const keyValue = `c-${index}`;
      const textAlign = colKeysAlign[objKey];
      const maxWidth = colKeysMaxWidth[objKey];
      const fomattedValue = formatColValue(dataObject[objKey], objKey);

      return (
        <InsideTableCol
          key={keyValue}
          Column={keyValue}
          maxWidth={maxWidth}
          textAlign={textAlign}
        >
          <TableDrag>
            {index === 0 ? <DragAndDrop /> : ""}
            {index === 0 ? <Empty /> : ""}
            {colEditableField[index] ? (
              <CurrencyMask
                placeholder="R$ 0,00"
                prefix="R$ "
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                value={fomattedValue}
                onValueChange={(event) =>
                  onChangeInput(dataObject, index, event)
                }
                customInput={TableInp}
              />
            ) : (
              fomattedValue
            )}
          </TableDrag>
        </InsideTableCol>
      );
    });

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData(items, dataIndex);
  };

  const renderTableBodyRows = () =>
    data.map((obj, index) => {
      const keyValue = `d-${index}`;
      return (
        <Draggable key={keyValue} draggableId={keyValue} index={index}>
          {(provided) => (
            <TableRow
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {renderTableBodyColumns(obj)}
              {renderStatusCol(obj)}
            </TableRow>
          )}
        </Draggable>
      );
    });

  const renderTableHead = () => (
    <>
      <thead>
        <TableRow>{renderTableHeadColumns()}</TableRow>
      </thead>
    </>
  );

  const renderTableBody = (provided) => (
    <>
      <tbody {...provided.droppableProps} ref={provided.innerRef}>
        {renderTableBodyRows()}
      </tbody>
    </>
  );

  return (
    <>
      <TableCustom>
        {renderTableHead()}
        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={onDrag}>
          <Droppable droppableId="table">
            {(provided) => renderTableBody(provided)}
          </Droppable>
        </DragDropContext>
      </TableCustom>
    </>
  );
};

TableInsideDnD.defaultProps = {
  withActions: true,
  withStatus: false,
  withImage: false,
  withStarCategory: true,
  withCategories: false,
  withCustomButton: false,
  imageKey: "",
  categoriesKey: "",
  subCategoriesKey: "",
  statusKey: "",
  objectKey: "",
  buttonText: "",
  colKeysAlign: {},
  colKeysMaxWidth: {},
  colTitleKeysAlign: [],
  colEditableField: [],
  colKeysCurrency: [],
  onClickEdit: () => {},
  onClickToogle: () => {},
  onClickStar: () => {},
  handleStarShow: () => {},
  onClickOrder: () => {},
  onClickButton: () => {},
  onDrag: () => {},
  orderTitles: [],
  disabledKeys: [],
  isProduct: false,
  isCategory: false,
  isSubCategory: false,
};

TableInsideDnD.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  colKeysAlign: PropTypes.objectOf(PropTypes.string),
  colKeysMaxWidth: PropTypes.objectOf(PropTypes.string),
  colTitleKeysAlign: PropTypes.arrayOf(PropTypes.string),
  colEditableField: PropTypes.arrayOf(PropTypes.bool),
  colKeysCurrency: PropTypes.arrayOf(PropTypes.string),
  orderTitles: PropTypes.arrayOf(
    PropTypes.shape({
      colKey: PropTypes.string,
      titleKey: PropTypes.string,
      titleKeyIndex: PropTypes.number,
      tooltipUpText: PropTypes.string,
      tooltipDownText: PropTypes.string,
    })
  ),
  withActions: PropTypes.bool,
  withImage: PropTypes.bool,
  withStatus: PropTypes.bool,
  withCategories: PropTypes.bool,
  withStarCategory: PropTypes.bool,
  categoriesKey: PropTypes.string,
  subCategoriesKey: PropTypes.string,
  imageKey: PropTypes.string,
  statusKey: PropTypes.string,
  objectKey: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickToogle: PropTypes.func,
  onClickStar: PropTypes.func,
  onClickOrder: PropTypes.func,
  handleStarShow: PropTypes.func,
  disabledKeys: PropTypes.arrayOf(PropTypes.object),
  isProduct: PropTypes.bool,
  isCategory: PropTypes.bool,
  isSubCategory: PropTypes.bool,
};

export default TableInsideDnD;
