import HttpClient from "../http-client";
import { id } from "../helper-client";

const postCity = async (data) => {
  return HttpClient.post(`city?userId=${id ?? ""}`, data);
};

const putCity = async (cityId, data) => {
  return HttpClient.put(`city/${cityId}?userId=${id ?? ""}`, data);
};

const fetchCities = async () => HttpClient.get(`city?userId=${id ?? ""}`);

export default {
  postCity,
  putCity,
  fetchCities,
};
