import React, { useState, createContext } from "react";

const initialState = { name: "", value: "" };

export const UserContext = createContext();

const UserProvider = (props) => {
  const [userContext, setUserContext] = useState(initialState);

  return (
    <UserContext.Provider value={[userContext, setUserContext]}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
