const getUser = () => JSON.parse(sessionStorage.getItem("UMYTM"));

const getAdmin = () => JSON.parse(sessionStorage.getItem("ADMINMYTM"));

const getDomain = () => sessionStorage.getItem("DOMMYTM");

const getToken = () => sessionStorage.getItem("TKMYTM");

const getHighlight = () => sessionStorage.getItem("HIGHLIGHTMYTM");

const getHighLightSubCat = () => sessionStorage.getItem("HIGHLIGHTMYTMSUB");

const getStatus = () => JSON.parse(sessionStorage.getItem("SMYTM"));

const getIsFirstLogin = () => JSON.parse(sessionStorage.getItem("IFLMYTM"));

const getAlreadyOnboarding = () =>
  JSON.parse(sessionStorage.getItem("ALREADONBOARDMYTM"));

const setDomain = (value) => {
  sessionStorage.setItem("DOMMYTM", value);
};

const setUser = (value) => {
  sessionStorage.setItem("UMYTM", JSON.stringify(value));
};

const setAdmin = (value) => {
  sessionStorage.setItem("ADMINMYTM", JSON.stringify(value));
};

const setToken = (value) => {
  sessionStorage.setItem("TKMYTM", value);
};

const setStatus = (value) => {
  sessionStorage.setItem("SMYTM", value);
};

const setHightlight = (value) => {
  sessionStorage.setItem("HIGHLIGHTMYTM", value);
};

const setHighLightSubCat = (value) => {
  sessionStorage.setItem("HIGHLIGHTMYTMSUB", value);
};

const setAlreadyOnBoarding = (value) => {
  sessionStorage.setItem("ALREADONBOARDMYTM", JSON.stringify(value));
};

const setIsFirstLogin = (value) => {
  sessionStorage.setItem("IFLMYTM", value);
};

const setAnotherUser = (value) => {
  sessionStorage.setItem("ANTHUSERMYTM", JSON.stringify(value));
};

const getAnotherUser = () => JSON.parse(sessionStorage.getItem("ANTHUSERMYTM"));

const dropAnotherUser = () => {
  sessionStorage.removeItem("ANTHUSERMYTM");
};

const dropStorage = () => {
  sessionStorage.clear();
};

export default {
  getUser,
  getAdmin,
  getDomain,
  getToken,
  getHighlight,
  getHighLightSubCat,
  getStatus,
  getIsFirstLogin,
  getAlreadyOnboarding,
  setUser,
  setAdmin,
  setDomain,
  setToken,
  setHightlight,
  setHighLightSubCat,
  setStatus,
  setAlreadyOnBoarding,
  dropStorage,
  setIsFirstLogin,
  setAnotherUser,
  dropAnotherUser,
  getAnotherUser,
};
