import React from "react";

import PrinterIcon from "../../../assets/icons/printer-icon.svg";
import NoAllowed from "../../../assets/icons/no-allowed-icon.svg";

import formatters from "../../../utils/formatters";

import { Container, TableColTitle, TableRow, TableCol, Table } from "./styles";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import TranslatorFunction from '../../../components/I18n/Translator';

export const TableOrders = ({ headerTitles, data, handleOnClickImp }) => {
  const refs = useRef(null);
  const language = TranslatorFunction({ path: 'language.message' });
  const uninformed = TranslatorFunction({ path: 'uninformed.message' });
  const noChange = TranslatorFunction({ path: 'noChange.message' });
  const Delivery = TranslatorFunction({ path: 'Delivery.message' });
  const withdrawal = TranslatorFunction({ path: 'withdrawal.message' });
  const Catalog = TranslatorFunction({ path: 'Catalog.message' });

  const handlePrint = useReactToPrint({
    content: () => refs.current,
  });

  const handleOnClick = (event, indc) => {
    refs.current = event.target.parentElement.querySelector(`.imp-${indc}`);
    refs.current.focus();

    handlePrint();
  };

  function typeTranslated(type){
    switch (type) {
      case 'Entrega':
        return Delivery;
      case 'Retirada':
        return withdrawal;
      case 'Catálogo':
        return Catalog;
      default:
        return uninformed;
    }
  
}

  return (
    <Container>
      <Table>
        <thead>
          <TableRow>
            {headerTitles.map((key, indc) => (
              <TableColTitle key={indc}>{key.toUpperCase()}</TableColTitle>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {data.map((order, indc) => (
            <TableRow key={indc}>
              <TableCol>{formatters.formatId(order.id)}</TableCol>
              <TableCol>{order.clientName || uninformed}</TableCol>
              <TableCol>{order.phone || uninformed}</TableCol>
              <TableCol>{ typeTranslated(order.type) || uninformed}</TableCol>
              <TableCol>
                {formatters.formatToCurrencyWithMonetaryValue(
                  order.payment.total
                ) || uninformed}
              </TableCol>
              <TableCol>
                { order.payment.translatedName ? order.payment.translatedName[0][language] : order.payment.paymentName || uninformed}
              </TableCol>
              <TableCol>
                {order.payment.payback ? (
                  formatters.formatToCurrencyWithMonetaryValue(
                    order.payment.payback
                  )
                ) : (
                  <img src={NoAllowed} alt={noChange} />
                )}
              </TableCol>
              <TableCol>
                <h1>{order.date}</h1>
                <h1>{order.hours}</h1>
              </TableCol>
              <TableCol>
                <img
                  src={PrinterIcon}
                  alt="Impressora"
                  className="printer"
                  onClick={(e) => handleOnClick(e, indc)}
                />
                <div style={{ display: "none" }}>
                  <div
                    ref={refs}
                    style={{ whiteSpace: "pre-line", marginLeft: "15px" }}
                    className={`imp-${indc}`}
                  >
                    {handleOnClickImp(order)}
                  </div>
                </div>
              </TableCol>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TableOrders;
