import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LabelToggle, InputToggle, Slider, LabelLeftText, LabelRightText,
} from './Styles';
import generateRandomIdHash from '../../utils/IdGenerator';
import TranslatorFunction from '../I18n/Translator';

const Toggle = (props) => {
  const {
    textLeft, textRight, onChange, value, id, name
  } = props;
  const language = TranslatorFunction({ path: "language.message" });
  const [inputValue, setInputValue] = useState(value);
  const [inputId] = useState(id !== '' ? id : generateRandomIdHash());

  const hanldeOnChange = () => {
    onChange({ target: { name, value: !inputValue } });
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <LabelToggle language={language} htmlFor={inputId}>
      <InputToggle
        id={inputId}
        name={name}
        type="checkbox"
        checked={inputValue}
        onChange={hanldeOnChange}
      />
      <Slider language={language} >
        <LabelLeftText language={language} htmlFor={inputId}>{textLeft}</LabelLeftText>
        <LabelRightText language={language} htmlFor={inputId}>{textRight}</LabelRightText>
      </Slider>
    </LabelToggle>
  );
};

Toggle.defaultProps = {
  id: '',
  onChange: () => { },
  name: '',
  value: false,
};

Toggle.propTypes = {
  textLeft: PropTypes.string.isRequired,
  textRight: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default Toggle;
