import styled from "styled-components";

export const ListSubCategoriesTableWrapper = styled.div`
  height: calc(100vh - 155px);
  width: 100%;
  overflow: auto;
  table {
    margin-left: 16px;
  }
`;

export const ContainerFilter = styled.div`
  padding-top: 5px;
  width: 80%;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 16px;
  margin-left: 16px;
`;
