import React from "react";
import { IconHouse, IconStructure } from "../../../styles/icons";
import Storage from "../../../utils/storage";
import  { Translator } from '../../../components/I18n'
const MenusJSON = Storage.getAdmin()
  ? [
      {
        id: 1,
        route: "/clientes",
        text: <Translator path="customers.message" />,
        pageTitle: <Translator path="customers.message" />,
        name: "clientes",
        active: false,
        icon: <IconHouse />,
      },
      {
        id: 2,
        route: "/meu-estabelecimento?tab=company",
        text: <Translator path="myEstablishment.message" />,
        pageTitle: <Translator path="myEstablishment.message" />,
        name: "meu-estabelecimento",
        active: false,
        icon: <IconHouse />,
      },
      {
        id: 3,
        route: "/parametrizacao?tab=delivery-conf",
        text: <Translator path="parameterization.message" />,
        pageTitle: <Translator path="parameterization.message" />,
        name: "parametrizacao",
        active: false,
        icon: <IconStructure />,
      },
      {
        id: 4,
        route: "/cadastro-itens?tab=categories",
        text: <Translator path="itemRegistration.message" />,
        pageTitle: <Translator path="itemRegistration.message" />,
        name: "cadastro-itens",
        active: false,
        icon: <IconStructure />,
      },
      {
        id: 5,
        route: "/pedidos?page=1",
        text: <Translator path="orders.message" />,
        pageTitle: <Translator path="orders.message" />,
        name: "pedidos",
        active: false,
        icon: <IconStructure />,
      },
      {
        id: 6,
        route: "/cupom?page=1",
        text: <Translator path="discountCoupons.message" />,
        pageTitle: <Translator path="discountCoupons.message" />,
        name: "cupom",
        active: false,
        icon: <IconStructure />,
      },
    ]
  : [
      {
        id: 1,
        route: "/meu-estabelecimento?tab=company",
        text: <Translator path="myEstablishment.message" />,
        pageTitle: <Translator path="myEstablishment.message" />,
        name: "meu-estabelecimento",
        active: false,
        icon: <IconHouse />,
      },
      {
        id: 2,
        route: "/parametrizacao?tab=delivery-conf",
        text: <Translator path="parameterization.message" />,
        pageTitle: <Translator path="parameterization.message" />,
        name: "parametrizacao",
        active: false,
        icon: <IconStructure />,
      },
      {
        id: 3,
        route: "/cadastro-itens?tab=categories",
        text: <Translator path="itemRegistration.message" />,
        pageTitle: <Translator path="itemRegistration.message" />,
        name: "cadastro-itens",
        active: false,
        icon: <IconStructure />,
      },
      {
        id: 4,
        route: "/pedidos?page=1",
        text: <Translator path="orders.message" />,
        pageTitle: <Translator path="orders.message" />,
        name: "pedidos",
        active: false,
        icon: <IconStructure />,
      },
      {
        id: 5,
        route: "/cupom?page=1",
        text: <Translator path="discountCoupons.message" />,
        pageTitle: <Translator path="discountCoupons.message" />,
        name: "cupom",
        active: false,
        icon: <IconStructure />,
      },
    ];

export default MenusJSON;
