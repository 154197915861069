import axios from "axios";
import User from "../utils/user";

const HttpClient = axios.create({ baseURL: process.env.REACT_APP_API });

HttpClient.interceptors.request.use(async (config) => {
  const token = User.getUserToken();
  const newConfig = { ...config };
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
});

export default HttpClient;
