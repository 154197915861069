import styled from 'styled-components';
import {
  Paragraph16px300Light,
} from '../../../../../../styles/style-guide';

const SignaturesPlanListParagraph = styled.p`
  ${Paragraph16px300Light};
`;

const SignaturesPlanListContent = styled.div`
  height: calc(100vh - 240px);
  min-height: 420px;
`;

export {
  SignaturesPlanListParagraph,
  SignaturesPlanListContent,
};
