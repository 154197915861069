import styled, { css } from "styled-components";
import {
  Subtitle16px600SemiboldUppercase,
  Subtitle16px400RegularUppercase,
} from "../../styles/style-guide";

const TabDisabledCss = css`
  cursor: not-allowed;
  color: ${({ theme }) => theme.colors.textColorDisabled};
`;

const TabLiSelectedCss = css`
  ${Subtitle16px600SemiboldUppercase};
  color: ${({ theme }) => theme.colors.textColor};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mainColor};
`;

const TabLiContent = styled.li`
  margin: 16px;
`;

const TabLiLabel = styled.label`
  cursor: pointer;
  border-bottom: none;
  color: #b4b4b4;
  ${Subtitle16px400RegularUppercase};
  ${({ disabled }) => disabled && TabDisabledCss};
  ${({ isSelected }) => isSelected && TabLiSelectedCss};
`;

export { TabLiContent, TabLiLabel };
