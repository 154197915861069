import React from "react";
import Table from "../../../../../components/table/Table";
import PropTypes from "prop-types";
import ListDeliveryAreaTableWrapper from "./Styles";
import TranslatorFunction from "../../../../../components/I18n/Translator"

const ListDeliveryArea = ({ location, onClickEditLocation }) => {
  const servicePlaces = TranslatorFunction({ path: 'servicePlaces.message' });
  const action = TranslatorFunction({ path: 'action.message' });
  const colKeyAligns = { name: "left" };
  const colTitleKeysAlign = ["left", "center"];
  const regionsKeys = ["name"];
  const listTitles = [servicePlaces,action];

  return (
    <>
      <ListDeliveryAreaTableWrapper>
        <Table
          data={location}
          titles={listTitles}
          colKeysAlign={colKeyAligns}
          colTitleKeysAlign={colTitleKeysAlign}
          keys={regionsKeys}
          onClickEdit={onClickEditLocation}
          objectKey="id"
          withActions
        />
      </ListDeliveryAreaTableWrapper>
    </>
  );
};

ListDeliveryArea.defaultProps = {
  location: [],
  onClickEditLocation: () => {},
};

ListDeliveryArea.propTypes = {
  location: PropTypes.arrayOf(PropTypes.object),
  onClickEditLocation: PropTypes.func,
};

export default ListDeliveryArea;
