import formValidators from '../../../utils/form-validators';

const ResetPasswordForm = {
  email: {
    value: '',
    isRequired: true,
    isValid: false,
    touched: false,
    label: 'E-mail',
    placeholder: 'Digite o e-mail',
    isLoading: false,
    errorsMsg: {
      required: 'Este campo é obrigatório.',
      email: 'Este e-mail é inválido',
      unavailable: 'Esse e-mail não está cadastrado em nossa plataforma.',
    },
    hasErrors: {
      required: false,
      email: false,
      unavailable: false,
      httpError: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      email: formValidators.validateEmail,
    },
  },
  isValid: false,
};

export default ResetPasswordForm;
