import styled from 'styled-components';

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
  border: 1px solid ${({ theme }) => theme.colors.mainColorContrast};
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  margin: 8px;
  padding: 16px;
  ${({ width }) => width && `width: ${width}`};
`;

export default Card;
