import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableColTitle,
  TableRow,
  TableCol,
  TableCustom,
  TableOrderButtons,
  TableInput,
  TableDrag,
  Empty,
  DoubleRow,
  DoubleRowCol,
  TableColContentButton,
  TableButtonOpenShow,
} from "./Styles";
import Toggle from "../../toggle/Toggle";
import Button from "../../button/Button";
import formatters from "../../../utils/formatters";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragAndDrop from "../../drag-and-drop/Drag-and-drop";
import CurrencyMask from "react-currency-format";
import Collapsable from "react-collapsible";
import TableInsideDnD from "./TableInsideDnD";
import { Translator } from "../../../components/I18n";
import TranslatorFunction from "../../../components/I18n/Translator";

const TableDoubleDnD = (props) => {
  const {
    data,
    setData,
    keys,
    titles,
    withStatus,
    statusKey,
    onClickToogle,
    onInnerToogle,
    colKeysAlign,
    colKeysCurrency,
    colTitleKeysAlign,
    colEditableField,
    colKeysMaxWidth,
    orderTitles,
    onClickOrder,
    withCustomButton,
    onClickShowOpen,
    buttonText,
    onClickButton,
    onDrag,
  } = props;
  const [currentOrderActive, setCurrentOrderActive] = useState("");
  const [currentOrderArrowActive, setCurrentArrowActive] = useState("");

  //Inside Variables
  const status = TranslatorFunction({ path: "status.message" });
  const DESCRIPTION = TranslatorFunction({ path: "DESCRIPTION.message" });
  const PRICE = TranslatorFunction({ path: "PRICE.message" });
  const COMPLEMENTS = TranslatorFunction({ path: "COMPLEMENTS.message" });
  const language = TranslatorFunction({ path: "language.message" }); //translatedComplementGroup?.toUpperCase();
  const insColTitleKeysAlign = ["left", "left", "left", "left"];
  const insColKeyAligns = { name: "left", description: "left", price: "left" };
  const insComplementKeys = ["name", "description", "price"];
  const insColEditable = [false, false, true];
  const insListTitles = [COMPLEMENTS, DESCRIPTION, PRICE, status];
  const insOrderTitles = [
    {
      colKey: "name",
      titleKey: "NOME",
      titleKeyIndex: 1,
      tooltipUpText: "Ordenar de A-Z",
      tooltipDownText: "Ordenar de Z-A",
    },
    {
      colKey: "name",
      titleKey: "STATUS",
      titleKeyIndex: 2,
      tooltipUpText: "Ordenar por Inclusos",
      tooltipDownText: "Ordenar por Não-Inclusos",
    },
  ];

  //InsideVariables

  const setComplements = (complementos, index, group) => {
    let newData = data;

    if (group) {
      let inx = newData.findIndex((x) => x._id === group._id);
      newData[inx].complements = complementos;
    } else {
      newData[index].complements = complementos;
    }

    setData(newData, false);
  };

  const formatColValue = (value, key) => {
    const isFormatCurrencyField = colKeysCurrency.includes(key);
    if (key === "translatedComplementGroup") {
      return Array.isArray(value) && value.length > 0
        ? value[0].name[0][language]?.toUpperCase()
        : value.toUpperCase();
    }

    return isFormatCurrencyField
      ? formatters.formatToCurrencyWithMonetaryValue(value)
      : value;
  };

  const TableInp = (props) => {
    return <TableInput {...props} />;
  };

  const handleOnClickToggle = (obj) => {
    onClickToogle(obj);
  };

  const handleOnClickOrder = (headerTitle, arrow) => {
    setCurrentOrderActive(headerTitle);
    setCurrentArrowActive(arrow);
    onClickOrder(headerTitle, arrow);
  };

  const onChangeInput = (obj, index, event) => {
    const { floatValue } = event;
    const value = isNaN(floatValue) ? 0 : floatValue;
    let newForm = data;
    const complementIndex = data.findIndex((cat) => cat._id === obj._id);
    if (index === 1) {
      newForm[complementIndex].min = value;
    } else if (index === 2) {
      newForm[complementIndex].max = value;
    }

    // setData(newForm);
  };

  const renderButtonCol = (obj) =>
    withCustomButton ? (
      <TableCol Column={"c-4"} colActions textAlign="left">
        <TableColContentButton>
          <Button
            onClick={() => {
              onClickButton(obj);
            }}
            large
            cWidth={"70%"}
            cFontSize={"11px"}
          >
            {buttonText}
          </Button>
          <TableButtonOpenShow
            type="button"
            onClick={() => {
              onClickShowOpen(obj);
            }}
          />
        </TableColContentButton>
      </TableCol>
    ) : null;

  const renderStatusCol = (obj) =>
    withStatus ? (
      <TableCol Column={"c-3"} colStatus>
        <Toggle
          value={obj[statusKey]}
          onChange={() => handleOnClickToggle(obj)}
          textLeft={<Translator path="active.message" />}
          textRight={<Translator path="inactive.message" />}
        />
      </TableCol>
    ) : null;

  const renderTableHeadColumns = () =>
    titles.map((title, index) => {
      const keyValue = `th-${index}`;
      const textAlign = colTitleKeysAlign[index];
      const currentOrderTitle = orderTitles.find(
        (obj) => obj.titleKeyIndex === index
      );
      return (
        <TableColTitle key={keyValue} Column={keyValue} textAlign={textAlign}>
          {title}
          {currentOrderTitle !== undefined &&
          currentOrderTitle.titleKey === title ? (
            <TableOrderButtons
              isUpActive={
                title === currentOrderActive && currentOrderArrowActive === "up"
              }
              isDownActive={
                title === currentOrderActive &&
                currentOrderArrowActive === "down"
              }
              tooltipUpText={currentOrderTitle.tooltipUpText}
              tooltipDownText={currentOrderTitle.tooltipDownText}
              onClickUp={() => handleOnClickOrder(title, "up")}
              onClickDown={() => handleOnClickOrder(title, "down")}
            />
          ) : null}
        </TableColTitle>
      );
    });
  const renderTableBodyColumns = (dataObject) =>
    keys.map((objKey, index) => {
      const keyValue = `c-${index}`;
      const textAlign = colKeysAlign[objKey];
      const maxWidth = colKeysMaxWidth[objKey];
      const value = dataObject[objKey].length > 0 ? dataObject[objKey] : dataObject.name;
      const fomattedValue = formatColValue(value, objKey);
      return (
        <TableCol
          key={keyValue}
          Column={keyValue}
          maxWidth={maxWidth}
          textAlign={textAlign}
        >
          <TableDrag>
            {index === 0 ? <DragAndDrop /> : ""}
            {index === 0 ? <Empty /> : ""}
            {colEditableField[index] ? (
              <CurrencyMask
                // name={keyValue}
                placeholder="0"
                mask="xxx"
                value={fomattedValue ?? 0}
                onValueChange={(event) =>
                  onChangeInput(dataObject, index, event)
                }
                customInput={TableInp}
              />
            ) : (
              fomattedValue
            )}
          </TableDrag>
        </TableCol>
      );
    });

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData(items, true);
  };

  const renderSecondTable = (obj, index) => {
    return (
      <TableInsideDnD
        data={obj?.complements}
        dataIndex={index}
        group={obj}
        setData={setComplements}
        titles={insListTitles}
        colKeysAlign={insColKeyAligns}
        colTitleKeysAlign={insColTitleKeysAlign}
        keys={insComplementKeys}
        withStatus
        withActions={false}
        statusKey="active"
        onClickToogle={onInnerToogle}
        orderTitles={insOrderTitles}
        // onClickOrder={handleOnClickTableOrder}
        objectKey="_id"
        colEditableField={insColEditable}
      />
    );
  };

  const renderTableBodyRows = () =>
    data.map((obj, index) => {
      const keyValue = `d-${index}`;
      return (
        <Draggable key={keyValue} draggableId={keyValue} index={index}>
          {(provided) => (
            <React.Fragment>
              <TableRow
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {renderTableBodyColumns(obj)}
                {renderStatusCol(obj)}
                {renderButtonCol(obj)}
              </TableRow>
              <DoubleRow>
                <DoubleRowCol colSpan={5}>
                  <Collapsable
                    open={obj.showComp}
                    contentHiddenWhenClosed={!obj.showComp}
                  >
                    {obj.showComp ? renderSecondTable(obj, index) : ""}
                  </Collapsable>
                </DoubleRowCol>
              </DoubleRow>
            </React.Fragment>
          )}
        </Draggable>
      );
    });

  const renderTableHead = () => (
    <>
      <thead>
        <TableRow>{renderTableHeadColumns()}</TableRow>
      </thead>
    </>
  );

  const renderTableBody = (provided) => (
    <>
      <tbody {...provided.droppableProps} ref={provided.innerRef}>
        {renderTableBodyRows()}
      </tbody>
    </>
  );

  return (
    <>
      <TableCustom>
        {renderTableHead()}
        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={onDrag}>
          <Droppable droppableId="table">
            {(provided) => renderTableBody(provided)}
          </Droppable>
        </DragDropContext>
      </TableCustom>
    </>
  );
};

TableDoubleDnD.defaultProps = {
  withActions: true,
  withStatus: false,
  withImage: false,
  withStarCategory: true,
  withCategories: false,
  withCustomButton: false,
  imageKey: "",
  categoriesKey: "",
  subCategoriesKey: "",
  statusKey: "",
  objectKey: "",
  buttonText: "",
  colKeysAlign: {},
  colKeysMaxWidth: {},
  colTitleKeysAlign: [],
  colEditableField: [],
  colKeysCurrency: [],
  onClickEdit: () => {},
  onClickToogle: () => {},
  onClickStar: () => {},
  handleStarShow: () => {},
  onClickOrder: () => {},
  onClickButton: () => {},
  onDrag: () => {},
  orderTitles: [],
  disabledKeys: [],
  isProduct: false,
  isCategory: false,
  isSubCategory: false,
};

TableDoubleDnD.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  colKeysAlign: PropTypes.objectOf(PropTypes.string),
  colKeysMaxWidth: PropTypes.objectOf(PropTypes.string),
  colTitleKeysAlign: PropTypes.arrayOf(PropTypes.string),
  colEditableField: PropTypes.arrayOf(PropTypes.bool),
  colKeysCurrency: PropTypes.arrayOf(PropTypes.string),
  orderTitles: PropTypes.arrayOf(
    PropTypes.shape({
      colKey: PropTypes.string,
      titleKey: PropTypes.string,
      titleKeyIndex: PropTypes.number,
      tooltipUpText: PropTypes.string,
      tooltipDownText: PropTypes.string,
    })
  ),
  withActions: PropTypes.bool,
  withImage: PropTypes.bool,
  withStatus: PropTypes.bool,
  withCategories: PropTypes.bool,
  withStarCategory: PropTypes.bool,
  categoriesKey: PropTypes.string,
  subCategoriesKey: PropTypes.string,
  imageKey: PropTypes.string,
  statusKey: PropTypes.string,
  objectKey: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickToogle: PropTypes.func,
  onClickStar: PropTypes.func,
  onClickOrder: PropTypes.func,
  handleStarShow: PropTypes.func,
  disabledKeys: PropTypes.arrayOf(PropTypes.object),
  isProduct: PropTypes.bool,
  isCategory: PropTypes.bool,
  isSubCategory: PropTypes.bool,
};

export default TableDoubleDnD;
