import PropTypes from 'prop-types';
import React, { useState } from 'react';
import restValidate from '../../../api/validate/rest-validate';
import Button from '../../../components/button/Button';
import InputText from '../../../components/inputs/input-text/InputText';
import formUtils from '../../../utils/form-utils';
import ResetPasswordForm from './ResetPasswordForm';
import {
  ResetPasswordContent,
  ResetPasswordTitle,
  ResetPasswordParagraph,
  ResetPasswordEmailContent,
  ResetPasswordAdviceContent,
  ResetPasswordFooterContent,
  ResetPasswordParagraphError,
} from './Styles';
import { useDebounce } from '../../../hooks/custom-hooks';
import restUser from '../../../api/user/rest-user';
import { useBlockLoadingContext } from '../../../contexts/BlockLoaderContext';
import TranslatorFunction from "../../../components/I18n/Translator"

const TYPING_TIMEOUT = 1000;
const defaultForm = ResetPasswordForm;

console.log('defaultForm',defaultForm)
const ResetPassword = ({ onRecovery }) => {
  const LoginFormButtonForgotPassword = TranslatorFunction({ path: 'LoginFormButtonForgotPassword.message' });
  const recoverPassword = TranslatorFunction({ path: 'recoverPassword.message' });
  const receiveNewPassword = TranslatorFunction({ path: 'receiveNewPassword.message' });
  const dontWorry = TranslatorFunction({ path: 'dontWorry.message' });
  const mail = TranslatorFunction({ path: 'mail.message' });
  const thisFieldIsRequired = TranslatorFunction({ path: 'thisFieldIsRequired.message' });
  const emailinvalid = TranslatorFunction({ path: 'emailinvalid.message' });
  const enterEmail = TranslatorFunction({ path: 'enterEmail.message' });
  const notBeSent = TranslatorFunction({ path: 'notBeSent.message' });
  defaultForm.email.errorsMsg.required = thisFieldIsRequired;
  defaultForm.email.errorsMsg.email = emailinvalid;
  const [form, setForm] = useState({ ...defaultForm });
  const [showErrorOnSendEmail, setShowErrorOnSendEmail] = useState(false);
  const [disabledButton, setDisableButton] = useState(true);
  const { setIsLoading } = useBlockLoadingContext();

  const resetEmailField = () => {
    const newForm = { ...form };
    newForm.isValid = false;
    newForm.email.isValid = false;
    newForm.email.value = '';
    newForm.email.touched = false;
    newForm.email = formUtils.resetSpecificKeysOfFieldErrors(newForm.email, ['required', 'email, unavailable', 'httpError']);
    setForm(newForm);
  };

  const handlePutResponseSuccess = () => {
    setIsLoading(false);
    resetEmailField();
    onRecovery();
  };

  const handlePutResponseError = () => {
    setIsLoading(false);
    setShowErrorOnSendEmail(true);
  };

  const putRecoveryPassword = () => {
    setIsLoading(true);
    setShowErrorOnSendEmail(false);
    restUser.putRecoveryPassword(form.email.value)
      .then(handlePutResponseSuccess)
      .catch(handlePutResponseError);
  };

  const setFormFieldLoading = (value) => {
    setForm((oldForm) => formUtils.updateFormFieldIsLoading('email', oldForm, value));
  };

  const handleValidateResponseSuccess = (response) => {
    const { canBeUsed } = response.data;
    const newForm = { ...form };
    newForm.email.hasErrors.httpError = false;
    newForm.email.isLoading = false;
    newForm.email.hasErrors.unavailable = canBeUsed;
    if (!canBeUsed) {
      newForm.isValid = true;
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setForm(newForm);
  };

  const handleValidateResponseError = () => {
    const newForm = { ...form };
    newForm.email.isLoading = false;
    newForm.email.hasErrors.httpError = true;
    newForm.email = formUtils.resetSpecificKeysOfFieldErrors(newForm.email, ['unavailable']);
    setShowErrorOnSendEmail(true);
    setDisableButton(true);
    setForm(newForm);
  };

  const canValidate = () => {
    const newForm = { ...form };
    const errors = formUtils.getFieldErrorsKeys(newForm.email).filter((key) => key !== 'unavailable' && key !== 'httpError');
    return errors.length === 0 && newForm.email.value.length > 0;
  };

  const validateCompanyEmail = () => {
    setFormFieldLoading(true);
    setShowErrorOnSendEmail(false);
    restValidate.validateCompanyEmail(form.email.value)
      .then((response) => handleValidateResponseSuccess(response))
      .catch(() => handleValidateResponseError());
  };

  const saveInputChange = (obj) => {
    const { name, value } = obj;
    let newForm = { ...form };
    newForm.email.value = value;
    newForm = formUtils.updateFieldValidationWithValidators(newForm, name);
    setForm(newForm);
    return newForm;
  };

  const debouncedSave = useDebounce((nextForm) => validateCompanyEmail(nextForm), TYPING_TIMEOUT);

  const handleOnChangeInputEmail = (name, value) => {
    saveInputChange({ name, value });
    if (canValidate()) {
      debouncedSave();
    } else {
      setDisableButton(true);
    }
  };

  const handleOnBlurInputEmail = () => {
    setForm((oldForm) => {
      const newForm = { ...oldForm };
      if (newForm.email.isRequired && !newForm.email.isValid) {
        newForm.email.touched = true;
        const isValid = newForm.email.validators.required(newForm.email);
        newForm.email.isValid = isValid;
        newForm.email.hasErrors.required = !isValid;
      }
      return newForm;
    });
  };

  const handleErrorShow = (field) => {
    const keys = Object.keys(field.hasErrors);
    const errors = keys.filter((key) => field.hasErrors[key] === true);
    return errors.length > 0;
  };

  const getErrorMessage = (field) => {
    const keys = Object.keys(field.hasErrors);
    const errorsKeys = keys.filter((key) => field.hasErrors[key] === true);
    const errorsMessages = errorsKeys.map((key) => field.errorsMsg[key]);
    return errorsMessages || '';
  };

  return (
    <ResetPasswordContent>
      <ResetPasswordTitle>
        {LoginFormButtonForgotPassword}
      </ResetPasswordTitle>
      <ResetPasswordParagraph>
        {dontWorry}:
      </ResetPasswordParagraph>
      <ResetPasswordEmailContent>
        <InputText
          label={mail}
          name="email"
          value={form.email.value}
          placeholder={enterEmail}
          error={handleErrorShow(form.email)}
          errorMessage={getErrorMessage(form.email)[0] || ''}
          dataTestId="email"
          onChange={handleOnChangeInputEmail}
          onBlur={handleOnBlurInputEmail}
          isLoading={form.email.isLoading}
        />
      </ResetPasswordEmailContent>
      <ResetPasswordAdviceContent>
        <ResetPasswordParagraphError showErrorOnSendEmail={showErrorOnSendEmail}>
          {notBeSent}
        </ResetPasswordParagraphError>
      </ResetPasswordAdviceContent>
      <ResetPasswordFooterContent>
        <ResetPasswordParagraph>
          {receiveNewPassword}
        </ResetPasswordParagraph>
        <Button type="button" onClick={putRecoveryPassword} disabled={disabledButton}>
          {recoverPassword}
        </Button>
      </ResetPasswordFooterContent>
    </ResetPasswordContent>
  );
};

ResetPassword.propTypes = {
  onRecovery: PropTypes.func.isRequired,
};

export default ResetPassword;
