import styled from 'styled-components';
import {
  Description12px400RegularArray,
  PosistionInactiveButtonLeft,
  PosistionActiveButtonLeft,
  PosistionInactiveButtonRight,
  PosistionActiveButtonRight
} from '../../styles/style-guide';

const LabelLeftText = styled.label`
  ${(props) => Description12px400RegularArray[props.language]};
  cursor: pointer;
`;

const LabelRightText = styled.label`
  ${(props) => Description12px400RegularArray[props.language]};
  cursor: pointer;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 6px;
   /* inativo */
  background-color: ${({ theme }) => theme.colors.statusColorDanger};

  &::before {
    position: absolute;
    content: '';
    height: 40px;
    width: 80px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background-color: ${({ theme }) => theme.colors.textColorDisabled};
    -webkit-transition: .4s;
    transition: .4s;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  ${LabelLeftText} {
    display: block;
    margin: 0;
    ${(props) => PosistionInactiveButtonLeft[props.language]};
    position: absolute;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColorLight};
  }

  ${LabelRightText} {
    display: block;
    margin: 0;
    ${(props) => PosistionInactiveButtonRight[props.language]};
    position: absolute;
    color: ${({ theme }) => theme.colors.mainColorContrast};
  }
`;

const InputToggle = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const LabelToggle = styled.label`
  position: relative;
  display: inline-block;
  width: 160px;
  height: 40px;

  ${InputToggle} {
    &:checked + ${Slider} {
      /* ativo */
      background-color: ${({ theme }) => theme.colors.statusColorSuccess};

      ${LabelLeftText} {
        display: block;
        margin: 0;
        ${(props) => PosistionActiveButtonLeft[props.language]};
        position: absolute;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.mainColorContrast};
      }

      ${LabelRightText} {
        display: block;
        margin: 0;
        ${(props) => PosistionActiveButtonRight[props.language]};
        position: absolute;
        color: ${({ theme }) => theme.colors.textColorLight};
      }
    }

    &:checked + ${Slider} {
      &::before {
        -webkit-transform: translateX(80px);
        -ms-transform: translateX(80px);
        transform: translateX(80px);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        width: 80px;
      }
    }
  }
`;

export {
  LabelLeftText,
  LabelRightText,
  LabelToggle,
  InputToggle,
  Slider,
};
