import storage from './storage';

const setAlreadyOnbording = (value) => {
  storage.setAlreadyOnBoarding(value);
};

const getAlreadyOnboarding = () => (
  storage.getAlreadyOnboarding()
);

const canOpenOnboardingModal = () => !getAlreadyOnboarding();

export default {
  setAlreadyOnbording,
  getAlreadyOnboarding,
  canOpenOnboardingModal,
};
