import styled from 'styled-components';
import { Title24px400Regular, Paragraph16px300Light } from '../../../styles/style-guide';
import { ReactComponent as PasswordRecoverySVG } from '../../../assets/password-recovery.svg';

const ResetPasswordSuccessContent = styled.form`
  height: 100%;
  padding: 32px 24px;
  text-align: center;
`;

const ResetPasswordSuccessTitle = styled.h3`
  ${Title24px400Regular};
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 28px;
`;

const ResetPasswordSuccessImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ResetPasswordSuccessImage = styled(PasswordRecoverySVG)``;

const ResetPasswordSuccessParagraph = styled.p`
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
  margin-top: 8px;
`;

const ResetPasswordSuccessFooterContent = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
`;

export {
  ResetPasswordSuccessContent,
  ResetPasswordSuccessTitle,
  ResetPasswordSuccessImageContent,
  ResetPasswordSuccessImage,
  ResetPasswordSuccessParagraph,
  ResetPasswordSuccessFooterContent,
};
