import styled, { css } from "styled-components";
import { ReactComponent as StarFilledSVG } from "../../assets/icons/icon-star-filled.svg";
import { ReactComponent as StarOutlineSVG } from "../../assets/icons/icon-star-outline.svg";
import { ReactComponent as StarDisabledSVG } from "../../assets/icons/icon-star-disabled.svg";

const IconStarFilled = styled(StarFilledSVG)``;

const IconStarOutline = styled(StarOutlineSVG)``;

const IconStarDisabled = styled(StarDisabledSVG)``;

const CustomIconStarFilled = styled(IconStarFilled)`
  display: none;
`;

const CustomIconStarOutline = styled(IconStarOutline)`
  display: block;
`;

const ButtonInputTrueCss = css`
  ${CustomIconStarFilled} {
    display: block;
  }

  ${CustomIconStarOutline} {
    display: none;
  }
`;

const ButtonInputFalseCss = css`
  ${CustomIconStarFilled} {
    display: none;
  }

  ${CustomIconStarOutline} {
    display: block;
  }

  &:hover {
    ${CustomIconStarFilled} {
      display: block;
    }

    ${CustomIconStarOutline} {
      display: none;
    }
  }
`;

const ButtonStartContent = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  ${({ inputValue }) =>
    inputValue ? ButtonInputTrueCss : ButtonInputFalseCss};
`;

export {
  CustomIconStarFilled,
  CustomIconStarOutline,
  IconStarDisabled,
  ButtonStartContent,
};
