import styled from "styled-components";
import { ReactComponent as IconClose } from "../../assets/icons/icon-close-dots.svg";

export const Container = styled.div`
  .slick-slide img {
    margin: auto;
  }
  .slick-dots li {
    width: 50px;
    height: 50px;
  }
  .slick-dots {
    bottom: -70px;
  }

  .slick-dots.slick-thumb {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  margin-bottom: 100px;
`;

export const ContainerThumb = styled.div`
  position: relative;
`;

export const ImageContainer = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`;

export const CloseContainer = styled(IconClose)`
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -14px;
  fill: red;
  color: red;
  display: ${({ showIcon }) => (showIcon ? "initial" : "none")};
  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;
