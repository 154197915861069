import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CategoryForm from "./CategoryForm";
import ModalMytm from "../../../../../components/modal-mytm/ModalMytm";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
  ModalSubTitle,
} from "../../../../../components/modal-mytm/Styles";
import restCategories from "../../../../../api/categories/rest-categories";
import {
  NewCategoryForm,
  NewCategoryButtonSave,
  NewCategoryButtonGoBack,
  NewCategoryPhotoContent,
  NewCategoryPhotoTextContent,
  NewCategoryPhotoParagraph,
  NewCategoryPhotoArea,
  NewCategoryInputGroupWithToggle,
  NewCategoryPhotoAreaContent,
} from "./Styles";
import InputText from "../../../../../components/inputs/input-text/InputText";
import Toggle from "../../../../../components/toggle/Toggle";
import formUtils from "../../../../../utils/form-utils";
import {
  InputGroup,
  InputToggleContent,
} from "../../../../../components/styled/Forms";
import defaultNoProductPhoto from "../../../../../assets/productDefaultImg.png";
import User from "../../../../../utils/user";
import ImageCrop from "../../../../../components/image-crop/ImageCrop";
import TranslatorFunction from "../../../../../components/I18n/Translator"

const NewCategory = (props) => {

  CategoryForm.name.placeholder = TranslatorFunction({ path: CategoryForm.name.placeholder });
  CategoryForm.name.errorsMsg.required = TranslatorFunction({ path: CategoryForm.name.errorsMsg.required });
  const addEditCategory = TranslatorFunction({ path: 'addEditCategory.message' });
  const name = TranslatorFunction({ path: 'name.message' });
  const active = TranslatorFunction({ path: 'active.message' });
  const inactive = TranslatorFunction({ path: 'inactive.message' });
  const imageOptional = TranslatorFunction({ path: 'imageOptional.message' });
  const selectPhoto = TranslatorFunction({ path: 'selectPhoto.message' });
  const addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive = TranslatorFunction({ path: 'addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive.message' });
  const chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality = TranslatorFunction({ path: 'chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality.message' });
  const theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB = TranslatorFunction({ path: 'theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB.message' });
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const saveAndAddAnother = TranslatorFunction({ path: 'saveAndAddAnother.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const language = TranslatorFunction({ path: 'language.message' });

  CategoryForm.from.value = language;

  const { open, onClose, category } = props;
  const [form, setForm] = useState({ ...CategoryForm });
  const [isEditMode, setIsEditMode] = useState(false);
  const [fileURL, setFileURL] = useState("");
  const [openModal,    setOpenModal] = useState(open);
  const [isLoading, setIsLoading] = useState(false);
  const highlight = User.getHighlight();

  const handleResponseError = () => {
    setForm(formUtils.resetForm(form));
    setIsLoading(false);
    setOpenModal(false);
    onClose();
    setOpenModal(open);
  };

  const handleResponseSuccess = (res, next) => {
    if (next) {
      setForm(formUtils.resetForm(form));
      setFileURL("");
      setIsLoading(false);
      setIsEditMode(false);
      
    } else {
      setForm(formUtils.resetForm(form));
      setOpenModal(false);
      setIsLoading(false);
      setIsEditMode(false);
      onClose();
    }
  };

  const putCategory = (next) => {
    setIsLoading(true);
    const formRaw = formUtils.getFormRawValue(form);
    restCategories
      .putCategory(category._id, formRaw)
      .then((res) => handleResponseSuccess(res, next))
      .catch((err) => handleResponseError(err, "ERROR"));
  };

  const postCategory = (next) => {
    setIsLoading(true);
    const formRaw = formUtils.getFormRawValue(form);
    restCategories
      .postCategory(formRaw)
      .then((res) => handleResponseSuccess(res, next))
      .catch((err) => handleResponseError(err, "ERROR"));
  };

  const handleOnFileChange = (eventFile) => {
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform.file.value = eventFile.file;
      newform = formUtils.updateFormValidation(newform);
      newform.touched = true;
      setFileURL(URL.createObjectURL(eventFile.file));
      return newform;
    });
  };

  const handleInputOnChange = (name, value) => {
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform[name].value = value;
      newform = formUtils.updateFormValidation(newform);
      newform.touched = true;
      return newform;
    });
  };

  const handleInputOnChangeToggle = (event) => {
    const { name, value } = event.target;
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform[name].value = value;
      newform = formUtils.updateFormValidation(newform);
      newform.touched = true;
      return newform;
    });
  }

  const sendForm = (next) =>{
    isEditMode ? putCategory(next) : postCategory(next);
  }
    

  const handleOnClickSave = (next) => {
    if (formUtils.isFormValid(form)) {
      sendForm(next);
    }
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose();
  };

  const disableNameInput = () => isEditMode && category._id === highlight;

  const disableSubmitButton = () => !formUtils.isFormValid(form);

  const initEditMode = () => {
    setForm((oldForm) => {
      const newForm = { ...oldForm };
      newForm.name.value = Array.isArray(category.translatedName) && category.translatedName.length > 0  && category.translatedName[0].name[0][language] !==undefined? category.translatedName[0].name[0][language] : category.name;
      newForm.name.isValid = true;
      newForm.active.value = category.active;
      newForm.active.isValid = true;
      return newForm;
    });
    if (category.imageURL) {
      setFileURL(category.imageURL);
    } else {
      setFileURL("");
    }
  };

  useEffect(() => {
    setOpenModal(open);
    setForm(formUtils.resetForm(form));
    setFileURL("");
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (Object.keys(category).includes("name")) {
      setIsEditMode(true);
      initEditMode();
    }
    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    if (!open) {
      setIsEditMode(false);
    }
  }, [open]);

  const hasErrorOnField = (field) => !field.isValid && form.touched;
  return (
    <ModalMytm open={openModal} isLoading={isLoading}>
      <ModalWrapper>
        <ModalTitle>{addEditCategory}</ModalTitle>
        <ModalBody>
          <NewCategoryForm noValidate onSubmit={(e) => e.preventDefault()}>
            <InputGroup>
              <NewCategoryInputGroupWithToggle>
                <InputText
                  onChange={handleInputOnChange}
                  name="name"
                  value={form.name.value}
                  label={name}
                  error={hasErrorOnField(form.name)}
                  required={form.name.isRequired}
                  placeholder={form.name.placeholder}
                  errorMessage={form.name.errorsMsg.required}
                  disabled={disableNameInput()}
                  maxLength="40"
                />
              </NewCategoryInputGroupWithToggle>
              <InputToggleContent>
                <Toggle
                  value={form.active.value}
                  name="active"
                  textLeft={active}
                  textRight={inactive}
                  onChange={handleInputOnChangeToggle}
                />
              </InputToggleContent>
            </InputGroup>
            <NewCategoryPhotoContent>
              <NewCategoryPhotoTextContent>
                <ModalSubTitle>{imageOptional}</ModalSubTitle>
                <NewCategoryPhotoParagraph>
                  {addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive}
                  <br />
                  <br />
                 {chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality}
                  <br />
                  <br />
                  {theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB}
                </NewCategoryPhotoParagraph>
              </NewCategoryPhotoTextContent>
              <NewCategoryPhotoArea>
                <NewCategoryPhotoAreaContent>
                  <ImageCrop
                    value={fileURL}
                    onChange={handleOnFileChange}
                    buttonText={selectPhoto}
                    isDefaultImage={fileURL === defaultNoProductPhoto}
                    withIconAdd
                  />
                </NewCategoryPhotoAreaContent>
              </NewCategoryPhotoArea>
            </NewCategoryPhotoContent>
          </NewCategoryForm>
        </ModalBody>
        <ModalFooter>
          <NewCategoryButtonSave
            type="button"
            disabled={disableSubmitButton()}
            onClick={() => handleOnClickSave(false)}
          >
            {saveEditions}
          </NewCategoryButtonSave>
          <NewCategoryButtonSave
            type="button"
            disabled={disableSubmitButton()}
            onClick={() => handleOnClickSave(true)}
          >
            {saveAndAddAnother}
          </NewCategoryButtonSave>
          <NewCategoryButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            {back}
          </NewCategoryButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </ModalMytm>
  );
};
NewCategory.defaultProps = {
  category: {},
  open: false,
  onClose: () => { },
};

NewCategory.propTypes = {
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NewCategory;
