import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from '../../utils/auth';

const ProtectedRoute = (props) => {
  const { path, children } = props;
  return Auth.isAuthenticated()
    ? (
      <Route path={path}>
        { children}
      </Route>
    )
    : (<Redirect to="/login" />);
};

ProtectedRoute.defaultProps = {
  children: <></>,
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.instanceOf(React.Component),
  ]),
  path: PropTypes.string.isRequired,
};

export default ProtectedRoute;
