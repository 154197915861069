export default {
  translations: {
    language: {
      message: "ar",
    },
    LoginFormTitle: {
      message: "تسجيل الدخول"
    },
    LoginFormSubtitle: {
      message: "مرحبًا , أدخل بريدك الإلكتروني وكلمة المرور لتسجيل الدخول: "
    },
    LoginFormErroMessage: {
      message: "تعذر تسجيل الدخول , يرجى المحاولة مرة أخرى "
    },
    LoginFormButton: {
      message: "تسجيل الدخول"
    },
    LoginFormButtonForgotPassword: {
      message: "لقد نسيت كلمة المرور"
    },
    password: {
      message: "كلمة المرور"
    },
    confirmPassword: {
      message: "تأكيد كلمة المرور"
    },
    mail: {
      message: "البريد الإلكتروني"
    },
    myEstablishment: {
      message: "مؤسستي "
    },
    parameterization: {
      message: "معلمة"
    },
    itemRegistration: {
      message: "تسجيل الكائن"
    },
    orders: {
      message: "الطلبات"
    },
    discountCoupons: {
      message: "كوبونات خصم"
    },
    logout: {
      message: "اذهب للخارج"
    },
    establishmentDetails: {
      message: "تفاصيل اوضح"
    },
    subscriptions: {
      message: "الاشتراكات"
    },
    aboutTheEstablishment: {
      message: "عن المؤسسة "
    },
    mandatoryFilling: {
      message: "ملء إلزامي "
    },
    tradeName: {
      message: "الاسم التجاري (الاسم الذي تعرف به مؤسستك) "
    },
    companyCorporateName: {
      message: "سبب اجتماعي "
    },
    informTheCompanyCorporateName: {
      message: "إبلاغ اسم الشركة الشركة "
    },
    pixel: {
      message: "بكسل"
    },
    informPixel: {
      message: "تقرير بكسل "
    },
    invalidCnpj: {
      message: "CNPJ غير صالح "
    },
    invalidCep: {
      message: "الرمز البريدي غير صالح"
    },
    state: {
      message: "Estحالةado"
    },
    city: {
      message: "مدينة"
    },
    cityUpper: {
      message: "مدينة"
    },
    neighborhoods: {
      message: "حيّ"
    },
    street: {
      message: "طريق"
    },
    number: {
      message: "رقم"
    },
    informTheNumber: {
      message: "أدخل numberNumber "
    },
    complement: {
      message: "مديح"
    },
    complementUpper: {
      message: "مديح"
    },
    URLToYourMenu: {
      message: "URL لقائمتك "
    },
    emailToLoginToTheManager: {
      message: "بريد إلكتروني لتسجيل الدخول إلى المدير "
    },
    name: {
      message: "اسم"
    },
    nameUpper: {
      message: "اسم"
    },
    lastName: {
      message: "اللقب"
    },
    contactEmail: {
      message: "تواصل بالبريد الاكتروني"
    },
    cellphoneNumberWhatsApp: {
      message: "موبايل/واتس اب "
    },
    thisFieldIsRequired: {
      message: "هذه الخانة مطلوبه."
    },
    enterYourCreditCardDetails: {
      message: "أدخل تفاصيل بطاقة الائتمان الخاصة بك: "
    },
    thisIsTheCurrentPaymentMethod: {
      message: "هذه هي طريقة الدفع الحالية: "
    },
    paymentMethod: {
      message: "طريقة الدفع او السداد:"
    },
    paymentMethod2: {
      message: "طرق الدفع "
    },
    paymentMethod3: {
      message: "طرق الدفع "
    },
    cardNumber: {
      message: "رقم البطاقة:"
    },
    enterAValidCardNumber: {
      message: "الرجاء إدخال رقم بطاقة سارية المفعول."
    },
    enterAValidDate: {
      message: "ارجوك ادخل تاريخ صحيح."
    },
    cardholderName: {
      message: "إسم صاحب البطاقة."
    },
    typeAsWrittenOnCard: {
      message: "اكتب كما هو مكتوب على البطاقة "
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: "أدخل الاسم بنفس طريقة كتابته على البطاقة "
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: "من خلال حفظ تفاصيل الدفع الخاصة بك , فإنك تفوض MyTripMenu بشحن مبلغ الخطة تلقائيًا كل شهر حتى تقوم بإلغاء الاشتراك. الشروط والأحكام متوفرة "
    },
    inThisLink: {
      message: "على هذا الرابط."
    },
    savePaymentData: {
      message: "حفظ بيانات الدفع"
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: "أضف بطاقة ائتمان لعرض معلومات الخطة وتأكيد اشتراكك "
    },
    deliveryConfiguration: {
      message: "تكوين التسليم"
    },
    schedules: {
      message: "جداول"
    },
    Schedules: {
      message: "جداول"
    },
    add: {
      message: "يضيف"
    },
    onSiteServiceVisualization: {
      message: "الخدمة في الموقع | التصور "
    },
    deliveryWhatsappOrders: {
      message: "توصيل | طلبات WhatsApp "
    },
    takeAway: {
      message: "انسحاب"
    },
    catalog: {
      message: "فهرس"
    },
    typesOfService: {
      message: "أنواع الخدمة"
    },
    mandatoryDataForDelivery: {
      message: "بيانات إلزامية للتسليم"
    },
    phone: {
      message: "هاتف"
    },
    phoneUpper: {
      message: "هاتف"
    },
    address: {
      message: "تبوك"
    },
    generalSettings: {
      message: "الاعدادات العامة"
    },
    freeShippingForPurchasesAbove: {
      message: "شحن مجاني للمشتريات أعلاه"
    },
    noFreeShipping: {
      message: "لا يوجد شحن مجاني "
    },
    minimumValueForDeliveryOrders: {
      message: "أدنى قيمة لأوامر التسليم "
    },
    minimumDeliveryTime: {
      message: "الحد الأدنى من وقت التسليم"
    },
    noForecast: {
      message: "لا توقعات"
    },
    maximumDeliveryTime: {
      message: "أقصى وقت للتسليم"
    },
    orderResponseTimeInMinutes: {
      message: "اطلب وقت الاستجابة في غضون دقائق"
    },
    serviceNumberViaWhats: {
      message: "رقم الخدمة عبر الواتس اب"
    },
    servicePlaces: {
      message: "أماكن تقديم الخدمة"
    },
    action: {
      message: "أجراءات"
    },
    addEditCities: {
      message: "إضافة / تعديل المدن "
    },
    newCity: {
      message: "مدينة جديدة"
    },
    search: {
      message: "يبحث"
    },
    addNew: {
      message: "اضف جديد"
    },
    saveEditions: {
      message: "إصدارات الحفظ"
    },
    exitWithoutSaving: {
      message: 'الخروج من دون حفظ'
    },
    saveEditionsAndExit: {
      message: 'حفظ التغييرات و الخروج'
    },
    back: {
      message: "عد"
    },
    status: {
      message: "الحالة"
    },
    active: {
      message: "نشيط"
    },
    inactive: {
      message: "غير نشط"
    },
    category: {
      message: "فئة"
    },
    subcategory: {
      message: "تصنيف فرعي"
    },
    categories: {
      message: "فئات"
    },
    subcategories: {
      message: "الفئات الفرعية"
    },
    categoriesUpper: {
      message: "التصنيفات"
    },
    subcategoriesUpper: {
      message: "الفئات الفرعية"
    },
    complements: {
      message: "الوظائف الإضافية"
    },
    complementsGroup: {
      message: "مجموعة المكملات"
    },
    complementsGroupUpper: {
      message: "مجموعة المكملات"
    },
    products: {
      message: "منتجات"
    },
    image: {
      message: "صورة"
    },
    yes: {
      message: "نَعَم"
    },
    no: {
      message: "رقم"
    },
    theHighlightsCategoryCannotBeEdited: {
      message: "لا يمكن تعديل فئة الإضاءات "
    },
    sortFromAZ: {
      message: "فرز من الألف إلى الياء "
    },
    sortFromZA: {
      message: "فرز من Z-A "
    },
    sortByActive: {
      message: "فرز حسب النشط "
    },
    sortByInactive: {
      message: "فرز حسب غير نشط"
    },
    listActiveOnly: {
      message: "قائمة الأصول فقط "
    },
    addEditCategory: {
      message: "إضافة / تحرير فئة "
    },
    addEditSubcategory: {
      message: "إضافة / تحرير فئة فرعية "
    },
    imageOptional: {
      message: "صورة (اختيارية) "
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: "تعد إضافة صورة توضيحية إلى الفئة إجراءً اختياريًا , ولكنها تساعد في جعل قائمتك أكثر جاذبية "
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: "اختر صورة توضح العناصر التي تتكون منها هذه الفئة , وتتميز بإضاءة جيدة وواقعية بالنسبة للعرض التقديمي الفعلي للمنتجات. تجنب استخدام صور عامة أو مخزنة أو منخفضة الجودة "
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: "تبلغ الدقة المثالية 800 × 600 بكسل ولا يمكن أن تكون الصورة أكبر من 1 ميجابايت "
    },
    selectPhoto: {
      message: "اختر الصور"
    },
    selectCategory: {
      message: "اختر الفئة"
    },
    saveAndAddAnother: {
      message: "حفظ وإضافة آخر "
    },
    highlightsSubcategoryCannotBeEdited: {
      message: "لا يمكن تعديل الفئة الفرعية للمميزات "
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: "الفئة التي يجب أن تظهر فيها الفئة الفرعية "
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: "تعد إضافة صورة توضيحية إلى الفئة الفرعية إجراءً اختياريًا , ومع ذلك , فهي تساعد في جعل قائمتك أكثر جاذبية "
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "اختر صورة توضح العناصر التي تتكون منها هذه الفئة الفرعية , وتتميز بإضاءة جيدة , وواقعية فيما يتعلق بالعرض التقديمي الفعلي للمنتجات. تجنب استخدام صور عامة أو مخزنة أو منخفضة الجودة "
    },
    enterANameForTheSubcategory: {
      message: "أدخل اسمًا للفئة الفرعية "
    },
    enterANameForTheCategory: {
      message: 'أدخل اسمًا للفئة'
    },
    descriptionOfComplement: {
      message: "وصف الوظيفة الإضافية "
    },
    descriptionOfComplementUpper: {
      message: "وصف كامل "
    },
    addComplementGroup: {
      message: "إضافة مجموعة كاملة "
    },
    complementGroupName: {
      message: "اسم مجموعة الوظيفة الإضافية "
    },
    complementName: {
      message: "اسم الوظيفة الإضافية "
    },
    checkAllFields: {
      message: "تحقق من جميع الحقول "
    },
    complementGroupIsInactive: {
      message: "مجموعة الوظائف الإضافية غير نشطة. "
    },
    value: {
      message: "القيمة"
    },
    valueUpper: {
      message: "القيمة"
    },
    addProduct: {
      message: "أضف منتج"
    },
    images: {
      message: "الصور "
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "اختر صورة توضح العناصر التي يتكون منها هذا المنتج , وتتميز بإضاءة جيدة , وواقعية بالنسبة لعرضك التقديمي الفعلي. تجنب استخدام صور عامة أو مخزنة أو منخفضة الجودة "
    },
    enableOn: {
      message: "تمكين في "
    },
    categorysInWhichTheProductShouldAppear: {
      message: "الفئة (الفئات) التي يجب أن يظهر فيها المنتج "
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: "الفئة (الفئات) الفرعية التي يجب أن يظهر فيها المنتج "
    },
    categorization: {
      message: "التصنيف "
    },
    shortDescriptionShownInProductDetail: {
      message: " وصف موجز (معروض في تفاصيل المنتج) "
    },
    longDescriptionShownInProductDetail: {
      message: "وصف طويل (معروض في تفاصيل المنتج) "
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: "أدخل المكونات التي يتكون منها هذا المنتج ومرافقته (مرافقاته) وأحجام الأجزاء "
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: "أدخل شيئًا مثل الأطباق الجانبية أو حجم الجزء"
    },
    principalInformation: {
      message: "المعلومات الرئيسية"
    },
    id: {
      message: "بطاقة تعريف"
    },
    type: {
      message: "يكتب"
    },
    validity: {
      message: "صلاحية"
    },
    validityUpper: {
      message: "صلاحية"
    },
    useAvailable: {
      message: "الاستخدام / متوفر"
    },
    filter: {
      message: "منقي"
    },
    freeDelivery: {
      message: "توصيل مجاني"
    },
    discount: {
      message: "تخفيض"
    },
    aCouponWithThisCodeAlreadyExists: {
      message: "قسيمة بهذا الرمز موجودة بالفعل."
    },
    dateTime: {
      message: "التاريخ / الوقت"
    },
    change: {
      message: "شيء"
    },
    formOfPayment: {
      message: "شكل الدفع"
    },
    customer: {
      message: "عميل"
    },
    uninformed: {
      message: "جهل"
    },
    recordsFound: {
      message: "تم العثور على سجلات "
    },
    QRCodeOfYourDigitalMenu: {
      message: "رمز الاستجابة السريعة لقائمتك الرقمية "
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: "قم بتنزيل رمز الاستجابة السريعة الخاص بك واجعله متاحًا في مؤسستك أو في مواد ترويجية لتسهيل وصول العملاء إلى قائمتك الرقمية "
    },
    areYouSureYouWantToDoThis: {
      message: "هل انت متأكد من أنك تريد أن تفعل هذا؟"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: "من خلال تعطيل الوظيفة الإضافية , سيتم إلغاء ربط جميع مجموعات الوظائف الإضافية المرتبطة سابقًا بالمنتج. "
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: "حدث خطأ أثناء جلب فئات المنتجات "
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: "حدث خطأ أثناء إحضار عمليات المنتج "
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: "حدث خطأ أثناء جلب الفئات الفرعية للفئة المحددة "
    },
    productImage: {
      message: "صورة المنتج"
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: "عندما تقوم بإلغاء تنشيط أحد المنتجات , فلن يظهر بعد ذلك في القائمة (القوائم) عبر الإنترنت "
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: "تذكر أنه يمكنك تنشيطه مرة أخرى في وقت آخر إذا لزم الأمر. "
    },
    inactivateTheProduct: {
      message: "قم بتعطيل المنتج"
    },
    keepActive: {
      message: "ابقا نشطا"
    },
    simpleEasyFastDigital: {
      message: "بسيط. سهل. سريع. رقمي."
    },
    errorIdentification: {
      message: "تحديد الخطأ"
    },
    tryAgain: {
      message: "حاول مرة أخرى"
    },
    huhLooksLikeSomethingWentWrong: {
      message: "هاه! يبدو أنه حدث خطأ ما ... "
    },
    unableToSaveCreditCardDetails: {
      message: "تعذر حفظ تفاصيل بطاقة الائتمان "
    },
    mmyy: {
      message: "MM / YY"
    },
    removePhoto: {
      message: "إزالة الصورة"
    },
    addEditCoupon: {
      message: "إضافة / تحرير القسيمة"
    },
    couponType: {
      message: "نوع القسيمة"
    },
    freeShipping: {
      message: "الشحن مجانا"
    },
    discountByValue: {
      message: "الخصم بالقيمة"
    },
    percentageDiscount: {
      message: "نسبة الخصم"
    },
    generateCoupon: {
      message: "إنشاء القسيمة "
    },
    limitOfUse: {
      message: "حد الاستخدام "
    },
    couponCode: {
      message: "رمز الكوبون"
    },
    validFrom: {
      message: "صالح من تاريخ"
    },
    validUntil: {
      message: "صالح حتى"
    },
    highlightsSubcategoryCannotBeCopied: {
      message: "لا يمكن نسخ الفئة الفرعية المميزة"
    },
    nothingHere: {
      message: "يبدو أنه لا يوجد شيء هنا."
    },
    from: {
      message: "في"
    },
    to: {
      message: "حتى"
    },
    sunday: {
      message: "الأحد"
    },
    monday: {
      message: "ثانيا"
    },
    tuesday: {
      message: "يوم الثلاثاء"
    },
    wesnesday: {
      message: "الرابعة"
    },
    thursday: {
      message: "الخامس"
    },
    friday: {
      message: "جمعة"
    },
    saturday: {
      message: "السبت"
    },
    accessData: {
      message: "بيانات الدخول"
    },
    responsibleEstablishment: {
      message: "مسؤول عن المؤسسة"
    },
    saveEditions2: {
      message: "طبعات حفظ"
    },
    zeit: {
      message: "زمن"
    },
    cardsAccepted: {
      message: "ضع علامة على البطاقات التي تقبلها مؤسستك"
    },
    pixKey: {
      message: " سجل مفتاح PIX هنا"
    },
    noOptions: {
      message: "لا يوجد خيارات"
    },
    neighborhoods2: {
      message: "أحياء"
    },
    addEditNeighborhoods: {
      message: "إضافة / تعديل الأحياء"
    },
    newNeighborhoods: {
      message: "حي جديد"
    },
    qtyMinimum: {
      message: "الكمية الحد الأدنى"
    },
    qtymaximum: {
      message: "الكمية أقصى"
    },
    linkGroup: {
      message: "مجموعة مستلزمات الارتباط"
    },
    importantGroupComplements: {
      message: "مجموعة استيراد المكملات"
    },
    people: {
      message: "الناس"
    },
    house: {
      message: "كازا ، لار"
    },
    subcategoryProducts: {
      message: "لا تحتوي هذه الفئة الفرعية على منتجات مقترنة"
    },
    productNotAdd: {
      message: "هذا المنتج ليس لديه أي إضافات مضافة"
    },
    linkSupplements: {
      message: "مكملات الارتباط"
    },
    PRICE: {
      message: "سعر"
    },
    COMPLEMENTS: {
      message: "مكملات"
    },
    DESCRIPTION: {
      message: "وصف"
    },
    action2: {
      message: "أجراءات"
    },
    pixKey2: {
      message: "مفتاح PIX الخاص بك"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "إذا غادرت صفحة تفاصيل العقار قبل حفظ التغييرات ، فستفقد أي معلومات قمت بملئها بالفعل."
    },
    couldNotLoadUserData: {
      message: "تعذر تحميل بيانات المستخدم"
    },
    termsOfUseAanServices: {
      message: "شروط الاستخدام والخدمات"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou: {
      message: "أهلاً! سعيد لاهتمامك! قبل استخدام خدماتنا ، يرجى قضاء بعض الوقت في قراءة شروط الاستخدام والخدمة الخاصة بنا والتعرف على القواعد التي تحكم علاقتنا معك."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom: {
      message: "سنقوم أدناه بتوضيح بعض النقاط التي نعتبرها مهمة. إذا كانت لديك أي شكوك حول أي نقاط تمت مناقشتها أو لم تتم مناقشتها في هذا المستند ، فيرجى عدم التردد في الاتصال بنا على hello@mytripmenu.com."
    },
    definitions: {
      message: "1. تعاريف:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "في هذه الأداة ، نفهم التعبيرات أدناه وفقًا للتعريفات التالية:"
    },
    plataform: {
      message: "برنامج"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "نظام الويب متاح من خلال mytripmenu.com.br ، والذي يقدمه"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " والملكية والتشغيل والمسؤولية "
    },
    users: {
      message: "المستخدمون"
    },
    user: {
      message: "المستعمل"
    },
    where: {
      message: "، أين"
    },
    allThe: {
      message: "كل ال"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "يمكن عرض واستخدام الخدمات التي تقدمها"
    },
    establishment: {
      message: "مؤسسة"
    },
    establishments: {
      message: "المنشآت"
    },
    legalEntityRegisteredInThe: {
      message: "كيان قانوني مسجل في"
    },
    whichContractsTheServicesOfferedBy: {
      message: "، التي تتعاقد مع الخدمات التي تقدمها"
    },
    client: {
      message: "عميل"
    },
    naturalPersonAccessingThe: {
      message: " شخص طبيعي يقوم بالوصول إلى"
    },
    toViewTheMenu: {
      message: "لعرض القائمة"
    },
    dataProcessing: {
      message: "معالجة البيانات"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: "بموجب المادة 5 البند X من القانون 13709 لسنة 2018 ، يتم تنفيذ كل معاملة"
    },
    withPersonalDataOf: {
      message: "مع البيانات الشخصية لـ "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " ، مثل تلك التي تشير إلى الجمع أو الإنتاج أو الاستقبال أو التصنيف أو الاستخدام أو الوصول أو النسخ أو الإرسال أو التوزيع أو المعالجة أو الأرشفة أو التخزين أو الحذف أو التقييم أو التحكم في المعلومات أو التعديل أو الاتصال أو النقل أو النشر أو الاستخراج. "
    },
    admission: {
      message: " 2. القبول: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " ينظم هذا الصك شروط استخدام "
    },
    being: {
      message: "كون"
    },
    aContractBetweenThe: {
      message: " عقد بين "
    },
    andThe: {
      message: " و ال "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " استخدام الخدمات المقدمة من خلال "
    },
    fromThe: {
      message: "من "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " يشير صراحةً إلى موافقتك على جميع الشروط والأحكام الواردة في هذا الصك ومع الأحكام القانونية السارية على الأنواع. "
    },
    the: {
      message: "ال "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " تدرك أن نموذج تعاقد الخدمة سيكون من خلال خطط اشتراك شهرية مدفوعة مسبقًا مدرجة في الميزانية ومقدمة من "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "حسب المعلومات المتوفرة في منطقة المخططات بالموقع:"
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "للتعاقد على الخطط المخصصة ، فإن"
    },
    shouldContactThe: {
      message: "يجب الاتصال ب "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "من خلال قنواتها ، لوضع ميزانية محددة. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "أنت تدرك وتوافق على أن "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " سيؤخذ استخدام الخدمات المقدمة في الاعتبار قبولك لهذه الشروط وجميع الأحكام القانونية الأخرى ذات الصلة بالأنواع."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "بقبول شروط هذه الأداة ، يصرح المستخدم صراحةً بمعالجة بياناته ، من أجل ضمان الصيانة والأداء الجيد لوظائف المنصة"
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "يُظهر المستخدم ، في هذا القانون ، موافقته الكاملة على مشاركة البيانات التي تم جمعها ومعالجتها بواسطة "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: "وفقًا لهذه الأداة ، مع قوائم رحلاتي الأخرى التي تعد جزءًا من مجموعتك الاقتصادية ، أو من مزودي الخدمة لديك."
    },
    caseYou: {
      message: "حالة لك "
    },
    doNotAgree: {
      message: "لا توافق"
    },
    withTheProvisionsOfThisInstrument: {
      message: " مع أحكام هذا الصك ، "
    },
    dont: {
      message: 'لا'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " الوصول إلى أو عرض أو تنزيل أو استخدام أي صفحة أو محتوى أو معلومات أو خدمة من "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "هذه الشروط متاحة للقراءة ، في أي وقت ، في "
    },
    inMessage: {
      message: "في "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. من نحن وماذا نفعل:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "هو مزود خدمة قام بتطوير وترخيص نظام في شكل SaaS (برنامج كخدمة) ، مع ميزات محددة للمساعدة"
    },
    and: {
      message: "و"
    },
    soThe: {
      message: "لذلك"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "يقدم قائمة رقمية ، مما يسمح بتحسين الكفاءة والخبرة لكل من"
    },
    asForThe: {
      message: "أما بالنسبة لل"
    },
    generalConditionsOfUse: {
      message: "4. شروط الاستخدام العامة:"
    },
    onlyProvidesThe: {
      message: " يوفر فقط "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " الذي يقدم خدمات القائمة الرقمية لـ "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " تقتصر مسؤوليتها فقط على حسن سير العمل "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "ووظائفها ، وفقًا لهذه الأداة والخطة المتعاقد عليها مع "
    },
    notStoppingThe: {
      message: "، وعدم إيقاف"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "لا توجد مسؤولية عن إنشاء المحتوى وتخصيصه ونشره من خلال القائمة الرقمية."
    },
    justLicenseThe: {
      message: " مجرد ترخيص "
    },
    toThe: {
      message: " الى "
    },
    toThe2: {
      message: " الى "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: "، وليس هناك علاقة أخرى بين هذه الأطراف ، بحيث لا يمكن أن ينسب إلى"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " المسؤولية عن أي ضرر يلحق بالآخرين "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " أو لأطراف ثالثة ، بالنسبة للأفعال الناشئة عن "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " أثناء استخدام الميزات المتوفرة في "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "يصرح بأنه يتفهم ويقبل أن "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "لا تنوي استبدال القائمة المادية المتاحة في الوحدة التجارية في "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "كونه حلاً مكملاً فقط ، من أجل تسهيل التواصل بين "
    },
    atTheTimeOfRegistrationThe: {
      message: "في وقت التسجيل ،"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " يمكنك استخدام جميع الخدمات المتوفرة على "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: "، معلنين أنهم قد قرأوا وفهموا وقبلوا جميع الأحكام الواردة في شروط الاستخدام هذه."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "يقر صراحة أنه ، من خلال هذا الصك ، يتلقى من "
    },
    theGarantingOfALicenseToUseThe: {
      message: " منح ترخيص لاستخدام "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: "، غير قابل للتحويل ، ويحظر الترخيص من الباطن ، للاستخدام في الأراضي الوطنية أو الأجنبية ، طالما استمر الالتزام بهذا المصطلح ، مع ترك استخدام "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " بما يتعارض مع أحكام هذا الصك."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " هو المسؤول الوحيد عن أمان كلمة المرور الخاصة بك وعن استخدام تسجيلك في "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: "لذلك ، نوصيك بعدم مشاركة هذه المعلومات مع أطراف ثالثة ، وإذا كانت هذه المعلومات ، لأي سبب من الأسباب ، مفقودة أو "
    },
    hacked: {
      message: "اخترق"
    },
    mustImmediatelyInformThe: {
      message: " يجب إبلاغ "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: "يجب على الفور ، من خلال hello@mytripmenu.com ، من أجل حل المشكلة"
    },
    itIsOnlyUpToThe: {
      message: "الأمر متروك فقط ل "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " مسؤول عن أي أضرار تلحق بأطراف ثالثة ، للآخرين "
    },
    orToItself: {
      message: " أو لنفسها "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: "، الناتجة عن استخدام ميزات "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " يجب ألا تستخدم الخدمات المتوفرة على "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " عن أي غير قانوني أو تشهيري أو تمييزي أو مسيء أو مسيء أو إباحي أو فاحش أو عدواني أو مؤذ أو كيد أو خادع أو افتراء أو عنيف أو مبتذل أو مضايقة أو تهديد أو استخدام هوية مزيفة ، أي أي استخدام مشبوه أو أغراض أو وسائل يمكن أن تض "
    },
    others: {
      message: " الآخرين"
    },
    orThirdParties: {
      message: " أو أطراف ثالثة"
    },
    UnderNoCircumstancesWillThe: {
      message: "تحت أي ظرف من الظروف سوف "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "سيكون مسؤولاً عن أي أضرار يتكبدها "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " لاحتمال عدم التوفر المؤقت لـ "
    },
    orOnyContentMadeAvailableByThe: {
      message: "أو أي محتوى توفره "
    },
    onTheDigitalMenu: {
      message: "في القائمة الرقمية."
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " يجب أن يكون لديك جميع البرامج والأجهزة اللازمة للوصول إلى "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: "، بما في ذلك ، على سبيل المثال لا الحصر ، جهاز كمبيوتر أو جهاز محمول متصل بالإنترنت ، و "
    },
    onlyToProvideThe: {
      message: "، فقط لتوفير "
    },
    to2: {
      message: " إلى "
    },
    underThisInstrumentToThe: {
      message: "تحت هذا الصك. الى "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "الوصول إلى القائمة الرقمية ، سيكون الجهاز القادر على قراءة رمز الاستجابة السريعة أمرًا لا غنى عنه."
    },
    byThe: {
      message: " بواسطة "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "مشروط بتسجيله المسبق ، وكذلك الامتثال للأحكام الواردة في هذا الصك."
    },
    registration: {
      message: "5. التسجيل"
    },
    soThatThe: {
      message: "بحيث أن "
    },
    registerInThe: {
      message: " سجل في "
    },
    mustProvideTo: {
      message: "يجب أن توفر ل "
    },
    theFollowingData: {
      message: " البيانات التالية "
    },
    corporateName: {
      message: "اسم الشركة"
    },
    unitAddressOf: {
      message: " عنوان الوحدة "
    },
    contactNumberInTheEventThatThe: {
      message: "رقم الاتصال. في حالة عدم وجود ملف"
    },
    hireThe: {
      message: "استئجار"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "لمزيد من الوحدات ، يجب عليك تسجيل عناوين الاهتمام الأخرى."
    },
    forRegularUseOf: {
      message: "للاستخدام المنتظم لـ "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " يجب التسجيل وملء جميع البيانات المطلوبة من قبل "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " في وقت التسجيل ، وكذلك اختيار خطة الخدمة المقدمة من قبل "
    },
    itIsTheResponsibilityOf: {
      message: " إنها مسؤولية "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "تحدد في الخطة المتعاقد عليها عدد الوحدات المتوخاة في التعاقد معها."
    },
    itIsTheSoleResponsibilityOf: {
      message: "إنها مسؤولية"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "توفير وتحديث وضمان صحة بيانات التسجيل ، وعدم الوقوع في نطاق"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " أي نوع من المسؤولية المدنية والجنائية الناتجة عن بيانات غير صحيحة أو غير صحيحة أو غير كاملة مقدمة من "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " تحتفظ لنفسها بالحق في استخدام جميع الوسائل الصالحة والممكنة لتحديد "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " وكذلك طلب البيانات والوثائق الإضافية التي تراها ذات صلة من أجل التحقق من البيانات المبلغ عنها. في هذه الحالة ، فإن استخدام "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " مشروط بإرسال المستندات المطلوبة في النهاية."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "إذا تم اعتبار التسجيل مشتبهًا في احتوائه على بيانات خاطئة أو غير صحيحة ، فإن "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " تحتفظ لنفسها بالحق في تعليق ، بشكل مؤقت أو دائم ، دون إشعار مسبق ، "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " مسؤول عن التسجيل. في حالة التعليق ، لن تحضر "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " الحق في أي نوع من أنواع التعويض أو التعويض عن الخسائر والأضرار أو خسارة الأرباح أو الأضرار المعنوية."
    },
    inTheEventThatThe: {
      message: "في حالة عدم وجود ملف"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "القيام بجمع البيانات الشخصية من "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "سيسمح بالوصول إلى المعلومات التي تم جمعها وحول "
    },
    carriedOutBy: {
      message: " يقوم بها "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: "، مجانًا ، عن طريق طلب hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "أو عن طريق التسجيل في "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " القدرة على تعديلها أو حذفها في أي وقت."
    },
    theCollectionOfDataFrom: {
      message: "جمع البيانات من "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: "، عند حدوثه ، يهدف إلى التعرف عليك ، بالإضافة إلى تمكينك من استخدام ملف "
    },
    andWithThatThe: {
      message: "، ومع ذلك ، فإن "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " يمكن أن تضمن الجودة الجيدة للخدمات المرخصة."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "بالموافقة على شروط هذا الصك ، فإن"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " يصرح صراحةً أنه يدرك أن جمع بياناتك أمر ضروري لسير عمل "
    },
    authorizingFromNowOnThe: {
      message: "، يأذن ، من الآن فصاعدا ، "
    },
    forThe: {
      message: " ل "
    },
    offersThePossibilityOf: {
      message: " يوفر إمكانية "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "انشر قائمتك الرقمية من خلال رمز الاستجابة السريعة إلى ملف "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "دون الحاجة إلى التسجيل ، ومع ذلك ، فإن عدم التسجيل لا ينتقص من عدم الامتثال لقواعد هذا الصك ، والذي "
    },
    mustEnsureCompliance: {
      message: "يجب أن تضمن الامتثال."
    },
    willAccessYourRegistrationIn: {
      message: " سيصل إلى تسجيلك في "
    },
    through: {
      message: " عبر "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " وكلمة المرور ، مع التعهد بعدم إبلاغ الأطراف الثالثة بهذه البيانات ، وتحمل المسؤولية الكاملة عن استخدامها."
    },
    undertakesToNotifyThe: {
      message: " يتعهد بإخطار "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " على الفور ، من خلال قنوات الاتصال التي تحتفظ بها "
    },
    at: {
      message: " في "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: "، فيما يتعلق بأي استخدام غير مصرح به لحسابك. ال"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " سيكون المسؤول الوحيد عن العمليات التي يتم تنفيذها على حسابك ، حيث لن يكون الوصول ممكنًا إلا من خلال استخدام كلمة مرور معروفة لك حصريًا."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: "، فيما يتعلق بأي علم بارتكاب مخالفات من قبل الآخرين"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " التي قد تتسبب في ضرر لأنفسهم "
    },
    ofThe: {
      message: " التابع "
    },
    toThisOnTo: {
      message: " إلى هذا الشخص "
    },
    orToThirdParties: {
      message: " أو لأطراف ثالثة."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "يتعهد باستخدام ميزات"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " بحسن نية وفقا للتشريعات النافذة والأخلاق والعادات الحميدة."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "لن يتم بأي حال من الأحوال التنازل أو البيع أو الإيجار أو أي شكل آخر من أشكال النقل الخاص بـ"
    },
    atItsSoleDiscretion: {
      message: "حسب تقديرها وحدها "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " يجوز استبعاد أو تعطيل أو وضع قيود على استخدام الخدمة أو تعليقها أو حظرها ، إلى أجل غير مسمى ، دون إشعار مسبق أو تعويض ، التسجيلات أو "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " التي تعتبر مسيئة أو تنتهك شروط هذا الصك أو التشريع المعمول به."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " تحتفظ لنفسها بالحق في عدم السماح بتسجيل جديد لـ "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " التي تم إلغاؤها أو تعطيلها أو حظرها أو حذفها أو تعليقها بالفعل "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " إنشاء تسجيلات جديدة من قبل الأشخاص الذين تم إلغاء تسجيلاتهم الأصلية أو حظرها أو تعطيلها أو استبعادها أو تعليقها بسبب انتهاكات سياسات "
    },
    orCurrentLegislation: {
      message: " أو التشريع الحالي."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " تحتفظ لنفسها بالحق ، من جانب واحد ، دون إشعار مسبق أو موافقة أو تعويض ، في رفض أي طلب لتسجيل ملف "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: "، بالإضافة إلى إلغاء أو تعطيل أو حظر أو حذف أو تعليق استخدام التسجيل الذي تم قبوله مسبقًا."
    },
    byAgreeingToThisInstrumentThe: {
      message: "بالموافقة على هذا الصك ، فإن "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " يصرح بأنه على علم بأنه المسؤول الوحيد عن تسجيله ، مع التأكد من أن أي ضرر ناتج عن إدخال معلومات قديمة أو غير دقيقة أو غير صحيحة لا يمكن أن يُنسب إلى "
    },
    orThe: {
      message: "أو ال"
    },
    functionalities: {
      message: "6. الوظائف:"
    },
    offersTheFollowingFeatures: {
      message: " يقدم الميزات التالية:"
    },
    aOnlineDigitalMenu: {
      message: "(أ) قائمة رقمية على الإنترنت ؛"
    },
    bViewingProductsWithPphotos: {
      message: "(ب) عرض المنتجات بالصور ؛"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(ج) تقديم قائمة متعددة اللغات ، رهنا بالشروط المتاحة ؛"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(د) تنشيط العناصر وإلغاء تنشيطها في الوقت الفعلي ؛"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(هـ) الوصول إلى القائمة عن طريق مسح رمز الاستجابة السريعة ؛"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(و) إنشاء النقاط البارزة للإجراءات الترويجية."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " عند استخدام الخدمات التي تقدمها "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " يمكن تحديد URL (محدد موقع المعلومات) ، من أجل تحديد العنوان الإلكتروني الذي سيحدد موقع القائمة الرقمية لـ "
    },
    ToDefineTheURLThe: {
      message: "لتحديد عنوان URL ، يجب أن يكون ملف "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "يجب أن تحترم الملكية الفكرية لأطراف ثالثة ، وبالتالي ، يجب ألا تتضمن عبارات قد تسبب التباسًا أو تلمح إلى خدمات الجهات الخارجية."
    },
    understandThatThe: {
      message: " نفهم أن "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " تم استخدام الموارد لإحداث ارتباك بين "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "مما يجعل من الصعب تحديد الخدمات بشكل صحيح وتضليلها ، يمكنك استبعاد عنوان URL الخاص بـ "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "فضلا عن اتخاذ تدابير أخرى ذات صلة بالقضية"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " قد يغير نطاق الميزات المتاحة وفقًا لخطة العقد ، على النحو المنصوص عليه في "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " يجوز في أي وقت ، عن طريق إرسال إشعار مسبق ، دون الحاجة إلى تعويض ، تحرير و / أو حذف الميزات الموجودة ، وكذلك تضمين ميزات جديدة إلى "
    },
    translations: {
      message: "7. الترجمات:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "يمكنك استخدام خدمات الترجمة لإتاحة قوائمك باللغات التي تختارها. لهذا الغرض ، سيتم فرض رسوم على الخدمات ، على النحو المنصوص عليه في "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "لتنفيذ خدمات الترجمة ، فإن"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "اطلب ما لا يقل عن 72 ساعة (اثنان وسبعون ساعة). اعتمادًا على طول النص المراد ترجمته ، فإن ملف"
    },
    withPriorNoticeTo: {
      message: "مع إشعار مسبق ل"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "يجوز تمديد فترة تنفيذ الخدمة حسب تقديرها."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "يصرح بأنه يتفهم ويقبل أن بعض التعبيرات لا يمكن ترجمتها ، لأنها تعكس ثقافات معينة أو مناطق معينة."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " ليست مسؤولة عن أي ضرر ناتج عن الترجمات غير الدقيقة في القائمة الرقمية لـ "
    },
    howeverInTheEventThatThe: {
      message: ". ومع ذلك ، في حالة عدم وجود ملف"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "تحديد أي خطأ أو سوء فهم في التقليد ، يمكنك التواصل مع "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "والتي يجب أن تستجيب للطلب في أسرع وقت ممكن."
    },
    suppertService: {
      message: "8. خدمة الدعم"
    },
    willSupportThe: {
      message: "سيدعم "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " من خلال قناة الاتصال المتاحة عبر البريد الإلكتروني hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "في طلبات الدعم عبر البريد الإلكتروني ، فإن "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "ما يصل إلى 72 (اثنان وسبعون) ساعة عمل للرد."
    },
    paymentMethod4: {
      message: "9. طرق الدفع:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "قبل أي تهمة يتم إجراؤها من قبل "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "سيتم تقديم فترة تجريبية مدتها 07 (سبعة) أيام ، للحصول على نسخة تجريبية مجانية من أدوات "
    },
    ifThe: {
      message: "إذا كان "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " اختر المضي قدما في التعاقد على الخدمات ، و "
    },
    makesAvailableTo: {
      message: "يجعله متاحًا لـ "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "إحدى طرق الدفع التالية: "
    },
    VISACreditCardOr: {
      message: "بطاقة فيزا الائتمانية ؛ أو،"
    },
    MASTERCARDCreditCardOr: {
      message: "بطاقة ائتمان ماستركارد ؛ أو،"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "بطاقة ائتمان AMERICAN EXPRESS ؛ أو،"
    },
    DINNERSCLUBCreditCardOr: {
      message: "بطاقة ائتمان DINNERS CLUB ؛ أو،"
    },
    ELOCreditCardOr: {
      message: "بطاقة ائتمان ELO ، أو ؛"
    },
    DISCOVERCreditCard: {
      message: "اكتشف بطاقة الائتمان."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "لمعالجة مدفوعات بطاقات الائتمان ، سيكون من الضروري لـ"
    },
    registerOn: {
      message: "سجل في "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "التفاصيل التالية لبطاقة الائتمان المختارة:"
    },
    NameOfTheCreditCardHolder: {
      message: "اسم صاحب بطاقة الائتمان ؛"
    },
    CreditCardNumber: {
      message: "رقم بطاقة الائتمان؛"
    },
    CreditCardFlag: {
      message: "علم بطاقة الائتمان ؛"
    },
    CreditCardExpirationMonthandYear: {
      message: "انتهاء صلاحية بطاقة الائتمان (شهر وسنة) ؛"
    },
    CreditCardsecuritynumber: {
      message: "رقم تأمين بطاقة الائتمان."
    },
    Paymentsforservicescontractedinthe: {
      message: "المدفوعات مقابل الخدمات المتعاقد عليها في "
    },
    willBe: {
      message: " سوف يكون"
    },
    processedthroughtheGETNETplatform: {
      message: " تتم معالجتها من خلال منصة GETNET"
    },
    beingnecessarythatall: {
      message: "كونها ضرورية أن كل شيء "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " اقرأ أيضًا شروط الاستخدام والخدمات الخاصة بمنصة GETNET وقبولها"
    },
    viaemailaddress: {
      message: "عبر عنوان البريد الإلكتروني: "
    },
    Whenhiringthe: {
      message: "عند التعاقد مع "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: "، مع الدفع عن طريق بطاقة الائتمان أو قسيمة بنكية ، فإن"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "يشير صراحةً إلى أنك قد قرأت وقبلت جميع الشروط الواردة في هذه الأداة وفي شروط الاستخدام والخدمات المحددة لمنصة GETNET."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "يجب حل أي نزاع حول المدفوعات التي تتم من خلال منصة GETNET فقط بين"
    },
    andGETNETnotfallingto: {
      message: " و GETNET ، لا تقع في "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " أي مسؤولية عن معالجة المدفوعات التي تتم على منصة GETNET."
    },
    TheGETNETplatform: {
      message: "منصة GETNET"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "قد تتقاضى رسومًا مقابل استخدامها ، والأمر متروك فقط لـ "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " معلومات حول القيم التي تفرضها GETNET. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " مسؤول فقط عن حذف بيانات الدفع المقدمة من "
    },
    fromitsowndatabasedeclaringthe: {
      message: "من قاعدة البيانات الخاصة بها ، معلنة أن"
    },
    beawarethatitisnotupto: {
      message: " كن على علم بأن الأمر لا يصل إلى "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " تعزيز استبعاد البيانات المذكورة من قاعدة بيانات GETNET."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. سياسة الخصوصية ومعالجة البيانات:"
    },
    Duringtheuseof: {
      message: "أثناء استخدام "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "سيقوم بجمع وتخزين المعلومات المقدمة من قبل"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: "، بموجب شروط هذه الأداة ، جنبًا إلى جنب مع المعلومات التي يتم إنشاؤها تلقائيًا ، مثل خصائص جهاز الوصول ، والمتصفح ، وسجلات الوصول إلى التطبيق (IP ، مع التاريخ والوقت) ، والمعلومات التي تم الوصول إليها ، والشاشات التي تم الوصول إليها ، وبيانات تحديد الموقع الجغرافي ، وسجل التطبيقات ، من بين أمور أخرى ، من"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: "، والتي سيتم تخزينها في قاعدة البيانات وأيضًا في ملف تعريف ارتباط المتصفح."
    },
    Thedatacollectedfrom: {
      message: "البيانات التي تم جمعها من "
    },
    throughtheuseof: {
      message: "، من خلال استخدام"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: "، من أجل توفير الخدمات المناسبة ، لغرض تحسين الملاحة في"
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: "وكذلك لأغراض الدعاية والإحصاء."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "يصرح بفهم وقبول والموافقة على ذلك في حالة إرسال بيانات شخصية إلى"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "يجب القيام بذلك بموجب أحكام المادة 5 ، البند X ، من القانون 13709 لعام 2018."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "في حالة تقديم البيانات الشخصية من قبل "
    },
    willbeconsideredconfidentialbythe: {
      message: "، ستعتبر سرية من قبل"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: "وتلتزم ببذل كل جهد للحفاظ على أمن أنظمتها في تخزين مثل هذه البيانات ، مستوفية للمعايير الأمنية المنصوص عليها في المرسوم رقم 8771/2016 ، مثل:"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(ط) استخدام طرق الصناعة المعيارية لتشفير البيانات المجمعة ، بالإضافة إلى أشكال التشفير القياسية الأخرى ، لضمان حرمتها ؛"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(2) استخدام برمجيات عالية التقنية للحماية من الوصول غير المصرح به إلى الأنظمة التي تعتبر بيئات خاضعة للرقابة والأمان ؛"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(3) إتاحة الوصول الخاضع للرقابة إلى مواقع تخزين البيانات الشخصية فقط للأشخاص المصرح لهم والمصدق عليهم مسبقًا ، والملتزمين بسرية هذه البيانات ، بما في ذلك عن طريق التوقيع على اتفاقية السرية ؛"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(4) تطبيق آليات المصادقة للوصول إلى السجلات القادرة على إضفاء الطابع الفردي على الشخص المسؤول عن العلاج والوصول إلى البيانات التي تم جمعها نتيجة لاستخدام"
    },
    vAnonymizationofdatafromthe: {
      message: "(5) إخفاء هوية البيانات من"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "عند مشاركتها مع جهات خارجية ليست شركاء معها"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(6) الحفاظ على الجرد مع بيان الوقت والمدة وهوية الموظف أو الشخص المسؤول عن الوصول وملف الكائن ، بناءً على سجلات الاتصال والوصول إلى التطبيق ، على النحو المحدد في المادة 13 من المرسوم رقم 8771/2016."
    },
    Ifdatacollectionfromthe: {
      message: "إذا كان جمع البيانات من"
    },
    throughtheuse: {
      message: "، من خلال استخدام"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "لا يجوز مشاركتها إلا مع جهات خارجية ، في الحالات المذكورة أدناه:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(1) لحماية مصالح"
    },
    incaseofconflictincludinglegalclaims: {
      message: "في حالة التعارض ، بما في ذلك المطالبات القانونية ؛"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(2) في حالة المعاملات والتغييرات في الشركات التي تنطوي على"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", in which case the transfer of data will be necessary for the continuity of the services offered through the"
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(3) بأمر من المحكمة أو بطلب من السلطات الإدارية المختصة قانونًا بطلبها."
    },
    guaranteesto: {
      message: "ضمانات ل"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: "فيما يتعلق بمعالجة البيانات الشخصية ، الحقوق التالية:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(1) تأكيد وجود معالجة لبياناتك الشخصية ؛"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(2) الوصول إلى البيانات الخاصة بك التي تم جمعها بواسطة"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: "، من خلال تسجيل الدخول الخاص بك أو عن طريق طلب hello@mytripmenu.com ؛"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(3) تصحيح بياناتك إذا كانت غير كاملة أو غير دقيقة أو قديمة ؛"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(4) حظر أو حذف البيانات غير الضرورية أو الزائدة أو غير المطابقة للتشريعات البرازيلية المعمول بها ؛"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(5) إمكانية نقل البيانات الشخصية ، لك أو لطرف ثالث ، بناءً على طلب صريح من"
    },
    viahellomytripmenucom: {
      message: "، عبر hello@mytripmenu.com ؛"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(6) حذف البيانات الشخصية التي تمت معالجتها بموافقتك ، بشرط عدم وجود قرار قانوني للاحتفاظ بها مسجلة لدى"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(7) الحصول على معلومات حول الكيانات العامة أو الخاصة التي معها"
    },
    sharedyourdataand: {
      message: "شارك بياناتك ؛ و،"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(8) معلومات عن إمكانية وعواقب عدم تقديم موافقة"
    },
    youcansendemailto: {
      message: "يمكنك إرسال بريد إلكتروني إلى"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com ، للإشارة إلى الشكوك و / أو المتطلبات المتعلقة ببياناتك الشخصية."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "قد تحذف البيانات الشخصية التي تم جمعها من"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(1) عندما يتحقق الغرض الذي جمعت من أجله ؛ أو ، عندما لم تعد ضرورية أو ذات صلة بنطاق الغرض ، وفقًا للأغراض الموضحة في شروط الاستخدام وسياسة الخصوصية هذه ؛"
    },
    iiWhenthe: {
      message: "(2) عندما"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "إلغاء موافقتك ، في الحالات التي يكون فيها ذلك ضروريًا ، طلب الاستبعاد إلى"
    },
    viahellomytripmenucomor: {
      message: "عبر hello@mytripmenu.com ؛ أو،"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(3) إذا حددته السلطة المختصة."
    },
    GENERALPROVISIONS: {
      message: "11. أحكام عامة:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "أي بند أو شرط في هذا الصك ، لأي سبب من الأسباب ، سيتم اعتباره باطلاً أو غير فعال من قبل أي محكمة أو محكمة ، لن يؤثر على صلاحية الأحكام الأخرى لهذه الشروط ، والتي ستظل سارية تمامًا وملزمة ، مما يؤدي إلى إحداث آثار لها أقصى حد."
    },
    Thefailureof: {
      message: "فشل"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "في المطالبة بأي حقوق أو أحكام من هذه الشروط لن يشكل تنازلاً ، وقد يمارس الأخير حقه بانتظام ، في غضون المواعيد النهائية القانونية."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "جميع المواد وبراءات الاختراع والعلامات التجارية والتسجيلات والمجالات والأسماء والامتيازات والإبداعات والصور وجميع الحقوق ذات الصلة المتعلقة بـ"
    },
    anddevelopedby: {
      message: "وطورها"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: "، هي وستظل الملكية الوحيدة والحصرية لـ"
    },
    agreeingwiththe: {
      message: "، بالاتفاق مع"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "عدم القيام بعمل أو حقيقة تضر بأي شكل من الأشكال بالحقوق المنصوص عليها في هذه الوثيقة ، أو المطالبة بأي حق أو امتياز عليها."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "قد تقوم بتغيير هذه الأداة في أي وقت ، وذلك ببساطة عن طريق نشر نسخة منقحة على موقعنا. لهذا السبب ، نوصي بشدة أن تقوم دائمًا بزيارة هذا القسم من موقعنا وقراءته بشكل دوري. ولكن للمساهمة في العلاقة الجيدة ، سنرسل أيضًا بريدًا إلكترونيًا لإعلامك بهذه التغييرات."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "تشكل هذه الأداة التفاهم المتكامل بين"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "وتخضع للقوانين البرازيلية ، حيث يتم انتخاب الولاية القضائية لمدينة ساو ليوبولدو / جمهورية صربسكا باعتبارها المحكمة المختصة الوحيدة لحل المشكلات الناشئة عن هذا الصك ، مع التنازل الصريح عن أي ولاية قضائية أخرى ، مهما كانت مميزة."
    },
    recoverPassword: {
      message: "إستعادة كلمة المرور"
    },
    receiveNewPassword: {
      message: "Vستتلقى كلمة مرور جديدة في بريدك الإلكتروني ، تذكر أيضًا التحقق من مجلد البريد العشوائي."
    },
    dontWorry: {
      message: "لا تقلق ، سنساعدك في استعادتها. أدخل بريدك الإلكتروني لتسجيل الدخول"
    },
    enterEmail: {
      message: "أدخل البريد الإلكتروني"
    },
    notBeSent: {
      message: "تعذر إرسال البريد الإلكتروني ، يرجى المحاولة مرة أخرى."
    },
    pleasureToHaveYou: {
      message: "إنه لمن دواعي سروري أن تكون معنا هنا!"
    },
    beforeStarting: {
      message: "قبل البدء في تضمين منتجاتك والاطلاع على قائمتك  تتشكل ، فمن الضروري تنفيذ توقيع  قيمة الصيانة"
    },
    BRL99: {
      message: "99.90 ريال برازيلي"
    },
    month: {
      message: "شهر."
    },
    includesFacilities: {
      message: "تشمل مرافق مثل:"
    },
    menuWithPhotos: {
      message: "قائمة كاملة مع الصور ؛"
    },
    priceUpdate: {
      message: "تحديث السعر في الوقت الحقيقي ؛"
    },
    activateDeactivate: {
      message: "تنشيط المنتجات وإلغاء تنشيطها بسرعة ؛"
    },
    categorizeFilteReorder: {
      message: "تصنيف المنتجات وتصفيتها وإعادة ترتيبها ؛"
    },
    newFeatures: {
      message: "ميزات جديدة تضاف كل أسبوع!"
    },
    thePlan: {
      message: "وقع الخطة"
    },
    company: {
      message: "شركة"
    },
    fantasyName: {
      message: "اسم خيالي"
    },
    numberAccesses: {
      message: "عدد عمليات الوصول"
    },
    entryDate: {
      message: "موعد الدخول"
    },
    customers: {
      message: "عملاء"
    },
    wantLeave: {
      message: "الآن بعد أن وصلت إلى هذا الحد ، هل أنت متأكد من أنك تريد المغادرة؟"
    },
    cancelYourSubscription: {
      message: "نأسف لرغبتك في إلغاء اشتراكك في خطة MyTripMenu."
    },
    beforeConfirming: {
      message: "قبل التأكيد ، أحتاج أن أخبرك أنه إذا غادرت ، فلن يتمكن عملاؤك من الوصول إلى قائمة مؤسستك ولن يكون الوصول إلى عنوان URL و QRCode متاحًا حتى تقوم بإعادة الاشتراك في الخطة."
    },
    goAhead: {
      message: "إذا كنت متأكدًا من ذلك ، فابدأ ..."
    },
    unsubscribePlan: {
      message: "إلغاء الاشتراك من الخطة"
    },
    dontCancel: {
      message: "لا أريد الإلغاء بعد الآن"
    },
    noChange:{
      message: "لا تغيير"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA ، شركة محدودة ، مسجلة بانتظام لدى CNPJ تحت رقم. 34.177.633 / 0001-91 ، ومقرها في Rua Felipe Dos Santos، nº 77، 1203 torre A، Padre Reus، in the city of São Leopoldo / RS، under CEP: 93.020-180."
    },
    Delivery: {
      message: "توصيل"
    },
    withdrawal: {
      message: "انسحاب"
    },
    Pickup: {
      message: "التقط على الفور"
    },
    Catalog: {
      message: "فهرس"
    },
    Enteremail: {
      message: "أدخل البريد الإلكتروني"
    },
    emailinvalid: {
      message: "هذا البريد الإلكتروني غير صالح"
    },
    Typepassword: {
      message: "اكتب كلمة المرور"
    },
    least6digits: {
      message: "يجب أن يحتوي هذا الحقل على 6 أرقام على الأقل"
    },
     Cashpayment: {
      message: "لا يمكن تعديل طريقة الدفع النقدي"
    },
    Checkpayment: {
      message: "لا يمكن تحرير طريقة الدفع بالشيك"
    },
    save: {
      message: "للحفظ"
    },
    logIn: {
      message: "تسجيل الدخول"
    },
    logged: {
      message: "لقد سجلت الدخول"
    },
    enterAShortEasilyIdentifiableName: {
      message: "أدخل اسمًا قصيرًا يسهل التعرف عليه"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "لا يمكن نسخ الفئة الفرعية المميزة"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "تعذر تحميل البطاقات"
    },
    invalidUser: {
      message: "مستخدم غير صالح"
    },
    invalidPassword: {
      message: "رمز مرور خاطئ"
    },
    inactiveUser: {
      message: "مستخدم غير نشط"
    }
  }
}
