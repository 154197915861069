import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import PageHeader from '../page-header/PageHeader';
import PageSideMenu from '../page-side-menu/PageSideMenu';
import PageContainer from '../page-container/PageContainer';
import restTheme from '../../../api/theme/rest-theme';
import { Container, Content } from './Styles';

const MytmTheme = (props) => {
  const { children } = props;
  const [theme, setTheme] = useState(restTheme.getDefaultTheme);
  useEffect(() => {
    restTheme.getTheme(theme).then(setTheme);
  }, [theme]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container>
          <PageHeader />
          <Content>
            <PageSideMenu />
            <PageContainer>
              {children}
            </PageContainer>
          </Content>
        </Container>
      </ThemeProvider>
    </ >
  );
};

MytmTheme.defaultProps = {
  children: '',
};

MytmTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ]),
};

export default MytmTheme;
