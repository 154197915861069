import formValidators from "./form-validators";

const formatValueToFloat = (val) => {
  if (val.includes("R$")) {
    return parseFloat(val.replace(".", "").replace(",", ".").split("R$")[1]);
  }
  return parseFloat(val.replace(".", "").replace(",", "."));
};

export const formatPriceWithSeparato = (price) => {
  return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
    price
  );
};

const getFormRawValue = (form) => {
  const keys = Object.keys(form);
  const rawForm = {};
  keys.forEach((key) => {
    if (!Array.isArray(form[key])) {
      if (key === "value") {
        rawForm[key] = formatValueToFloat(
          !form[key].value ? form[key] : form[key].value
        );
      } else if (Array.isArray(form[key].value)) {
        rawForm[key] = JSON.stringify(form[key].value);
      } else {
        rawForm[key] = form[key].value;
      }
    } else {
      rawForm[key] = form[key];
    }
  });
  if (
    Object.getOwnPropertyNames(rawForm).includes("file") &&
    rawForm.file === ""
  ) {
    delete rawForm.file;
  }
  if (
    Object.getOwnPropertyNames(rawForm).includes("removeFile") &&
    rawForm.removeFile === ""
  ) {
    delete rawForm.removeFile;
  }
  if (Object.getOwnPropertyNames(rawForm).includes("touched")) {
    delete rawForm.touched;
  }
  if (Object.getOwnPropertyNames(rawForm).includes("isValid")) {
    delete rawForm.isValid;
  }
  return rawForm;
};

const updateFormValidation = (form) => {
  const keys = Object.keys(form);
  const testForm = form;
  keys.forEach((key) => {
    if (testForm[key].isRequired) {
      testForm[key].isValid = formValidators.validateInputRequired(
        testForm[key]
      );
    }
  });
  return testForm;
};

const isFormValid = (form) => {
  const keys = Object.keys(form);
  if (keys.length > 0) {
    const validationStatus = keys.map((k) => form[k].isValid);
    return !validationStatus.includes(false);
  }
  return false;
};

const updateFieldValidationWithValidators = (form, fieldKey) => {
  const newForm = { ...form };
  if (!Array.isArray(form[fieldKey])) {
    const newField = { ...form[fieldKey] };
    const { validators } = newField;
    const validationsKeys = Object.keys(newField.validators);
    validationsKeys.forEach((key) => {
      const validationResult = validators[key](newField);
      newField.hasErrors[key] = !validationResult;
      if (validationResult) {
        newField.isValid = false;
        return;
      }
      newField.isValid = true;
    });
  }

  const formKeys = Object.keys(newForm);
  const formErrors = [];
  formKeys.forEach((key) => {
    if (key === "isValid") {
      return;
    }

    if (Array.isArray(newForm[key])) {
      return;
    }
    const errorsKeys = Object.keys(newForm[key].hasErrors);
    errorsKeys.forEach((errorKey) => {
      formErrors.push(newForm[key].hasErrors[errorKey]);
    });
  });
  newForm.isValid = !formErrors.includes(true);
  return newForm;
};

const updateFormFieldIsLoading = (name, form, value) => {
  const newForm = { ...form };
  newForm[name].isLoading = value;
  return newForm;
};

const updateFormValidationWithValidators = (form) => {
  const newForm = { ...form };
  
  const keys = Object.keys(newForm);
  keys.forEach((key) => {
    if (Array.isArray(newForm[key])) return;
    if (typeof newForm[key] === "boolean") return;
    if (typeof newForm[key] === "string") return;
    if (key === 'email' && newForm[key].value) newForm[key].value = newForm[key].value.trim()
    const { validators } = newForm[key];
    const validationKeys = Object.keys(validators);
    validationKeys.forEach((validationKey) => {
      const validationResult = validators[validationKey](newForm[key]);
      newForm[key].hasErrors[validationKey] = !validationResult;
      newForm[key].isValid = validationResult;
    });
  });
  return newForm;
};

const resetForm = (form) => {
  const keys = Object.keys(form);
  const testForm = form;
  keys.forEach((key) => {
    if (key === "touched") {
      testForm.touched = false;
      return;
    }

    if (testForm[key].isRequired) {
      testForm[key].isValid = false;
    } else {
      testForm[key].isValid = true;
    }

    if (typeof testForm[key].value === "string") {
      testForm[key].value = "";
      if (typeof testForm[key].name === "string") {
        testForm[key].name = "";
      }
      return;
    }

    if (typeof testForm[key].value === "boolean") {
      testForm[key].value = true;
      return;
    }

    if (typeof testForm[key].value === "object") {
      testForm[key].value = "";
      return;
    }

    if (Array.isArray(testForm[key].value)) {
      testForm[key].value = [];
    }
  });
  return testForm;
};

const getFieldErrorsKeys = (field) => {
  const errorsKeys = Object.keys(field.hasErrors);
  return errorsKeys.filter((key) => field.hasErrors[key]);
};

const resetFieldErrors = (field) => {
  const newField = { ...field };
  const errorsKeys = Object.keys(newField.hasErrors);
  errorsKeys.forEach((key) => {
    newField.hasErrors[key] = false;
  });
  return newField;
};

const resetSpecificKeysOfFieldErrors = (field, arrKeys) => {
  const newField = { ...field };
  const errorsKeys = Object.keys(newField.hasErrors);
  errorsKeys.forEach((key) => {
    if (arrKeys.includes(key)) {
      newField.hasErrors[key] = false;
    }
  });
  return newField;
};

const isFormTouched = (form) => Object.keys(form).length > 0;

export default {
  getFormRawValue,
  isFormValid,
  isFormTouched,
  resetForm,
  updateFormValidation,
  updateFormValidationWithValidators,
  updateFieldValidationWithValidators,
  updateFormFieldIsLoading,
  getFieldErrorsKeys,
  resetFieldErrors,
  resetSpecificKeysOfFieldErrors,
};
