import HttpClient from "../http-client";
import { id } from "../helper-client";

const postNeighborhood = async (data) => {
  return HttpClient.post(`neighborhood?userId=${id ?? ""}`, data);
};

const putNeighborhood = async (data) => {
  return HttpClient.put(`neighborhood?userId=${id ?? ""}`, data);
};

const fetchNeighborhoods = async () =>
  HttpClient.get(`neighborhood?userId=${id ?? ""}`);

export default {
  postNeighborhood,
  putNeighborhood,
  fetchNeighborhoods,
};
