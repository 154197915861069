import styled from "styled-components";

const ListSchedulesTableWrapper = styled.div`
  height: calc(100vh - 155px);
  width: 100%;
  overflow: auto;
  margin-left: 16px;
`;

export const ContainerFilter = styled.div`
  padding-top: 2px;
  width: 80%;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 16px;
`;
export default ListSchedulesTableWrapper;
