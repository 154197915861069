import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Subtitle,
  NewProducsContainer,
  ContentContainer,
  PageSubtitleContent,
} from "./Styles";
import NewProductForm from "./new-products-form/NewProductForm";
import FormUtils from "../../../../../utils/form-utils";
import RestProducts from "../../../../../api/products/rest-products";
import { useBlockLoadingContext } from "../../../../../contexts/BlockLoaderContext";
import { useAPIErrorContext } from "../../../../../contexts/APIErrorContext";
import apiErrorHandler from "../../../../../utils/api-error-handler";
import TranslatorFunction from "../../../../../components/I18n/Translator"
const NewProducts = () => {
  const addProduct = TranslatorFunction({ path: 'addProduct.message' });
  const [form, setForm] = useState({});
  const [product, setProduct] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [clearForm, setClearForm] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { setIsLoading } = useBlockLoadingContext();
  const apiErrorModal = useAPIErrorContext();

  useEffect(() => {
    if (location.state && location.state.product) {
      setIsEditMode(true);
      setProduct(location.state.product);
    }
  }, [location]);

  const redirectToProducts = () => {
    history.goBack();
  };

  const restartPage = () => {
    history.replace({ ...history.location, state: {} });
    history.go(0);
  };

  const handleOnErrorClickGoBack = () => {
    apiErrorModal.setOpen(false);
  };

  const setApiErrorModal = (error, message) => {
    apiErrorModal.setMessage(message);
    apiErrorModal.setError(apiErrorHandler.handleError(error));
    apiErrorModal.setOnClickGoBackFunc(() => handleOnErrorClickGoBack());
    return apiErrorModal;
  };

  const handleResponseSuccess = async (res, next, newForm) => {
    setIsLoading(false);
    setIsEditMode(false);
    setForm({});
    setProduct({});
    next ? restartPage() : redirectToProducts();
  };

  const onClickTryAgainPost = () => {
    apiErrorModal.setOpen(false);
    setIsLoading(true);
    const rawForm = FormUtils.getFormRawValue(form);
    RestProducts.postProduct(rawForm)
      .then((res) => handleResponseSuccess(res))
      .catch((err) => {
        apiErrorModal.setOpen(false);
        setIsLoading(false);
        setApiErrorModal(err, "Ocorreu um erro ao tentar criar o produto");
        apiErrorModal.setOnClickTryAgainFunc(() => onClickTryAgainPost());
        apiErrorModal.setOpen(true);
      });
  };

  const onClickTryAgainPut = () => {
    setIsLoading(true);
    apiErrorModal.setOpen(false);
    const rawForm = FormUtils.getFormRawValue(form);

    RestProducts.putProduct(product._id, rawForm)
      .then((res) => handleResponseSuccess(res))
      .catch((err) => {
        apiErrorModal.setOpen(false);
        setIsLoading(false);
        setApiErrorModal(err, "Ocorreu um erro ao tentar salvar o produto");
        apiErrorModal.setOnClickTryAgainFunc(() => onClickTryAgainPut());
        apiErrorModal.setOpen(true);
      });
  };

  const handleResponsePostError = (err) => {
    setApiErrorModal(err, "Ocorreu um erro ao tentar criar o produto");
    apiErrorModal.setOnClickTryAgainFunc(() => onClickTryAgainPost());
    apiErrorModal.setOpen(true);
  };

  const handleResponsePutError = (err) => {
    setApiErrorModal(err, "Ocorreu um erro ao tentar salvar o produto");
    apiErrorModal.setOnClickTryAgainFunc(() => onClickTryAgainPut());
    apiErrorModal.setOpen(true);
  };

  const handleResponseError = (err, method) => {
    setIsLoading(false);
    if (method === "POST") {
      handleResponsePostError(err);
      return;
    }
    handleResponsePutError(err);
  };

  const postProduct = (newForm, next) => {
    RestProducts.postProduct(newForm)
      .then((res) => handleResponseSuccess(res, next, newForm))
      .catch((err) => handleResponseError(err, "POST"));
  };

  const putProduct = (newForm, next) => {
    RestProducts.putProduct(product._id, newForm)
      .then((res) => handleResponseSuccess(res, next, newForm))
      .catch((err) => handleResponseError(err, "PUT"));
  };

  const sendForm = (newForm, next) =>
    isEditMode ? putProduct(newForm, next) : postProduct(newForm, next);

  const handleSubmit = (newForm, next) => {
    setIsLoading(true);
    setSubmitted(true);
    setClearForm(false);
    if (FormUtils.isFormValid(newForm)) {
      sendForm(newForm, next);
    }
  };

  return (
    <NewProducsContainer>
      <PageSubtitleContent>
        <Subtitle>{addProduct}</Subtitle>
      </PageSubtitleContent>
      <ContentContainer>
        <NewProductForm
          product={product}
          onChange={(e) => setForm(e)}
          onSubmit={handleSubmit}
          onRedirectToProducts={redirectToProducts}
          submitted={submitted}
          clearForm={clearForm}
        />
      </ContentContainer>
    </NewProducsContainer>
  );
};

export default NewProducts;
