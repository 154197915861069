const ProductForm = {
  name: "",
  value: "",
  from: "",
  active: true,
  shortDescription: "",
  longDescription: "",
  categoryId: "",
  category: "",
  subcategories: [],
  removeFile: "",
  attendance: {
    local: false,
    delivery: false,
  },
  enableIn: [],
  complementGroupActive: false,
  complementGroup: [],
};

export default ProductForm;
