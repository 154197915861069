import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Container,
  CloseContainer,
  ContainerThumb,
  ImageContainer,
} from "./styles";
import TranslatorFunction from '../../components/I18n/Translator';

function CarrouselPictures({ slides, thumbnails, handleRemove }) {
  const [showClose, setShowClose] = useState([]);
  const removePhoto = TranslatorFunction({ path: "removePhoto.message" });
  const handleSetShowClose = (index) => {
    const oldShows = [...showClose];
    oldShows[index] = !oldShows[index];
    setShowClose(oldShows);
  };

  const handleRemoveImage = (index) => {
    handleRemove(thumbnails[index]);
  };

  useEffect(() => {
    if (thumbnails) {
      const falseIcons = thumbnails.map((item) => false);
      setShowClose(falseIcons);
    }
  }, [thumbnails]);

  const settings = {
    customPaging: (index) => {
      return typeof thumbnails[index] === "string" ? (
        <ContainerThumb
          key={thumbnails[index]}
          onMouseEnter={() => handleSetShowClose(index)}
          onMouseLeave={() => handleSetShowClose(index)}
        >
          <CloseContainer
            alt={removePhoto}
            showIcon={showClose[index]}
            onClick={() => handleRemoveImage(index)}
          />
          <ImageContainer src={thumbnails[index]} />
        </ContainerThumb>
      ) : (
        <ContainerThumb key={thumbnails[index]}>
          {thumbnails[index]}
        </ContainerThumb>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Container>
      <Slider {...settings}>{slides.map((image) => image)}</Slider>
    </Container>
  );
}

export default CarrouselPictures;
