import React from "react";
import PropTypes from "prop-types";
import {
  SignaturesPlanSignInContent,
  SignaturesPlanSignInContenteCenter,
  SignaturesPlanSignInValuesWrapper,
  SignaturesPlanSignInValuesContent,
  SignaturesPlanSignInOldPlanValueLabel,
  SignaturesPlanSignInPromoMonthLabel,
  SignaturesPlanSignInPromoPlanValueLabel,
  SignaturesPlanSignInPromoValuesContent,
  SignaturesPlanSignInSubtitle,
  SignaturesPlanSignInButtonOrStatusContent,
  SignaturesPlanSignInStatusContent,
  SignaturesPlanSignInStatusLabelContent,
  SignaturesPlanSignInStatusLabelMainColor,
  SignaturesPlanSignInStatusLabel,
  SignaturesPlanSignInStatusWrapper,
  SignaturesPlanSignInParagraphContent,
  SignaturesPlanSignInParagraph,
  SignaturesPlanSignInLink,
  SignaturesPlanSignCancelButtonContent,
  SignaturesPlanSignInParagraphBottomContent,
  SignaturesPlanSignInParagraphBottom,
  SignaturesPlanSignCancelButtonBottomContent,
  SignaturesPlanSignInImageWrapper,
  SignaturesPlanSignInImage,
} from "./Styles";
import Button from "../../../../../../../components/button/Button";

const SignaturesPlanSignIn = ({
  isActive,
  planExpDate,
  onSignInPlan,
  onCancelPlan,
  onClickedTerms,
}) => {
  const renderButtonsOrStatus = () =>
    isActive && planExpDate ? (
      <SignaturesPlanSignInStatusWrapper>
        <SignaturesPlanSignInStatusContent>
          <SignaturesPlanSignInStatusLabelContent>
            <SignaturesPlanSignInStatusLabelMainColor>
              STATUS
            </SignaturesPlanSignInStatusLabelMainColor>
            <SignaturesPlanSignInStatusLabel>
              {isActive ? "ATIVO" : "INATIVO"}
            </SignaturesPlanSignInStatusLabel>
          </SignaturesPlanSignInStatusLabelContent>
          <SignaturesPlanSignInStatusLabelContent>
            <SignaturesPlanSignInStatusLabelMainColor>
              VENCIMENTO
            </SignaturesPlanSignInStatusLabelMainColor>
            <SignaturesPlanSignInStatusLabel>
              {planExpDate}
            </SignaturesPlanSignInStatusLabel>
          </SignaturesPlanSignInStatusLabelContent>
        </SignaturesPlanSignInStatusContent>
      </SignaturesPlanSignInStatusWrapper>
    ) : (
      <div>
        <Button onClick={onSignInPlan}>Assinar Plano</Button>
      </div>
    );

  return (
    <SignaturesPlanSignInContent>
      <SignaturesPlanSignInContenteCenter>
        <SignaturesPlanSignInValuesWrapper>
          <SignaturesPlanSignInValuesContent>
            <SignaturesPlanSignInSubtitle>
              plano mytripmenu
            </SignaturesPlanSignInSubtitle>
            <SignaturesPlanSignInPromoValuesContent>
              <SignaturesPlanSignInOldPlanValueLabel>
                R$ 159,00
              </SignaturesPlanSignInOldPlanValueLabel>
              <div>
                <SignaturesPlanSignInPromoPlanValueLabel>
                  R$ 99,00
                </SignaturesPlanSignInPromoPlanValueLabel>
                <SignaturesPlanSignInPromoMonthLabel>
                  /mês
                </SignaturesPlanSignInPromoMonthLabel>
              </div>
            </SignaturesPlanSignInPromoValuesContent>
          </SignaturesPlanSignInValuesContent>
        </SignaturesPlanSignInValuesWrapper>
        <SignaturesPlanSignInButtonOrStatusContent>
          {renderButtonsOrStatus()}
        </SignaturesPlanSignInButtonOrStatusContent>
        <SignaturesPlanSignInParagraphContent>
          <SignaturesPlanSignInParagraph>
            Os termos e condições estão disponíveis neste
            <SignaturesPlanSignInLink onClick={onClickedTerms}>
              {" "}
              link
            </SignaturesPlanSignInLink>
            .
          </SignaturesPlanSignInParagraph>
          <SignaturesPlanSignCancelButtonContent hasPlan={isActive}>
            <Button btnType="tertiary" onClick={onCancelPlan}>
              cancelar plano
            </Button>
          </SignaturesPlanSignCancelButtonContent>
        </SignaturesPlanSignInParagraphContent>
        <SignaturesPlanSignInImageWrapper>
          <SignaturesPlanSignInImage />
        </SignaturesPlanSignInImageWrapper>
      </SignaturesPlanSignInContenteCenter>
      <SignaturesPlanSignInParagraphBottomContent>
        <SignaturesPlanSignInParagraphBottom>
          Os termos e condições estão disponíveis neste
          <SignaturesPlanSignInLink onClick={onClickedTerms}>
            {" "}
            link
          </SignaturesPlanSignInLink>
          .
        </SignaturesPlanSignInParagraphBottom>
        <SignaturesPlanSignCancelButtonBottomContent hasPlan={isActive}>
          <Button btnType="tertiary" onClick={onCancelPlan}>
            cancelar plano
          </Button>
        </SignaturesPlanSignCancelButtonBottomContent>
      </SignaturesPlanSignInParagraphBottomContent>
    </SignaturesPlanSignInContent>
  );
};

SignaturesPlanSignIn.defaultProps = {
  isActive: false,
  planExpDate: "todo dia 14",
  onSignInPlan: () => {},
  onCancelPlan: () => {},
};

SignaturesPlanSignIn.propTypes = {
  isActive: PropTypes.bool,
  planExpDate: PropTypes.string,
  onSignInPlan: PropTypes.func,
  onCancelPlan: PropTypes.func,
  onClickedTerms: PropTypes.func.isRequired,
};

export default SignaturesPlanSignIn;
