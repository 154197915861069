import styled from "styled-components";

const DeliverySettingsContainer = styled.div`
  /* width: 100px; */
  margin-left: 30px;
  margin-right: 50px;
  margin-top: 30px;
`;

const DeliverySettingsLine = styled.div`
  border-top: 1px;
  border-color: #cccccc;
  border-width: 1px;
  border-top-style: solid;
  margin-right: auto;
  margin-left: auto;
  width: calc(80vw - 100px);
  margin-top: 30px;
`;

const DeliveryInpuntContext = styled.div`
  height: 28px;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
`;

const DeliveryInpuntContente = styled.div`
  display: flex;
`;

const DeliveryInpunt = styled.input`
  margin-right: 12px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #9a9a9a;
  width: calc(100% - 150px);
  height: 35px;
  text-align: center;
  :focus {
    border-radius: 10px;
    outline: 0;
  }
`;

const DeliveryInputTitle = styled.label`
  position: absolute;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  top: -6px;
  left: 12px;
  font-size: 8px;
  font-weight: 600;
  color: #ff5533;
`;

const DeliverySettingsContext = styled.div`
  display: grid;
  grid-template-columns: 26vw 26vw 26vw;
`;

const DeliverySettingsCheckBox = styled.div`
  margin-right: 110px;
`;

const DeliverySettingsLabel = styled.h4`
  margin-bottom: 30px;
  color: #000000;
`;

export {
  DeliverySettingsContainer,
  DeliverySettingsContext,
  DeliverySettingsCheckBox,
  DeliverySettingsLabel,
  DeliverySettingsLine,
  DeliveryInpuntContente,
  DeliveryInpuntContext,
  DeliveryInputTitle,
  DeliveryInpunt,
};
