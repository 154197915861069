import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../../../utils/auth';
import User from '../../../utils/user';

import Tooltip from '../../../components/tooltip/Tooltip';
import {
  Header,
  BreadcrumbsContent,
  Breadcrumb,
  UserContent,
  UserName,
  UserEmail,
  UserTextInfo,
} from './Styles';
import { IconLogout } from '../../../styles/icons';
import { usePageContext } from '../../../contexts/PageContext';
import  { Translator } from '../../../components/I18n'
const PageHeader = () => {
  const { pageTitle } = usePageContext();
  const [user, setUser] = useState({});
  const history = useHistory();

  const handleOnClickLogout = () => {
    Auth.logout();
    history.push('/login');
  };

  useEffect(() => {
    setUser(User.getUser());
  }, []);

  return (
    <Header>
      <BreadcrumbsContent>
        <Breadcrumb>{pageTitle}</Breadcrumb>
      </BreadcrumbsContent>
      <UserContent>
        <UserTextInfo>
          <UserName>{`${user.userFirstName} ${user.userLastName}`}</UserName>
          <br />
          <UserEmail>{user.companyEmail}</UserEmail>
        </UserTextInfo>
        <Tooltip message={Translator({ path: "logout.message" })}position="left">
          <IconLogout onClick={handleOnClickLogout} />
        </Tooltip>
      </UserContent>
    </Header>
  );
};

export default PageHeader;
