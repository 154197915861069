import formValidators from '../../../utils/form-validators';

const AuthForm = {
  email: {
    value: '',
    isRequired: true,
    isValid: false,
    placeholder: 'Digite o e-mail',
    errorsMsg: {
      email: 'Este e-mail é inválido',
      required: 'Este campo é obrigatório.',
    },
    hasErrors: {
      required: false,
      email: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      email: formValidators.validateEmail,
    },
  },
  password: {
    value: '',
    isRequired: true,
    isValid: false,
    minLength: 6,
    maxLength: 15,
    placeholder: 'Digite a senha',
    errorsMsg: {
      minLength: 'Este campo deve ter no mínimo 6 digitos',
      required: 'Este campo é obrigatório.',
    },
    hasErrors: {
      required: false,
      minLength: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      minLength: formValidators.validateMinLength,
    },
  },
};

export default AuthForm;
