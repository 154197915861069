import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Container, SelectContent, SelectWrapper } from "./Styles";
import Select from "react-select";

const UniSelect = (props) => {
  const { options, onChange, placeholder, value } = props;
  const SelectWrapperRef = useRef();
  const formOptions = options?.map((opt) => ({
    label: opt.label,
    value: opt.key,
  }));

  return (
    <SelectWrapper>
      <Container ref={SelectWrapperRef}>
        <SelectContent>
          <Select
            options={formOptions}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={onChange}
            placeholder={placeholder}
            value={value || undefined}
          />
        </SelectContent>
      </Container>
    </SelectWrapper>
  );
};

UniSelect.defaultProps = {
  options: "",
  placeholder: "",
  onChange: () => {},
};

UniSelect.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.any,
};

export default UniSelect;
