import React, { useState } from "react";
import PropTypes from "prop-types";
import restCards from "../../../../../../api/cards/rest-cards";
import restSubscriptions from "../../../../../../api/subscriptions/rest-subscriptions";

// import InputCreditCard from "../../../../../../components/inputs/input-credit-card/InputCreditCard";
import { useAPIErrorContext } from "../../../../../../contexts/APIErrorContext";
import apiErrorHandler from "../../../../../../utils/api-error-handler";
import formatFormRaw from "../../../../../../utils/credit-card-form-utils";
import formUtils from "../../../../../../utils/form-utils";
import DefaultCreditCardForm from "./credit-card-form";
import {
  CreditCardFormContainerForm,
  CreditCardFormFooterContent,
  CreditCardFormLink,
  CreditCardFormLoaderContent,
  CreditCardFormRow,
  CreditCardFormSmallParagraph,
} from "./Styles";
import InputText from "../../../../../../components/inputs/input-text/InputText";
import {
  defaultOptionsUpperCase,
  ExpDateOptions,
} from "../../../../../../utils/input-options";
import Button from "../../../../../../components/button/Button";
import Loader from "../../../../../../components/loader/Loader";
import { mask } from "remask";
import TranslatorFunction from '../../../../../../components/I18n/Translator';
const defaultForm = DefaultCreditCardForm;

const CreditCardForm = ({
  onSuccessCreateCard,
  onClickedTerms,
  onChangeCard,
}) => {
  const huhLooksLikeSomethingWentWrong = TranslatorFunction({ path: 'huhLooksLikeSomethingWentWrong.message' });
  const unableToSaveCreditCardDetails = TranslatorFunction({ path: 'unableToSaveCreditCardDetails.message' });
  const bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable = TranslatorFunction({ path: 'bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable.message' });
  const inThisLink = TranslatorFunction({ path: 'inThisLink.message' });
  const savePaymentData = TranslatorFunction({ path: 'savePaymentData.message' });
  const [form, setForm] = useState(defaultForm);
  const [isDisabledButton, setIsDisableButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const modalContext = useAPIErrorContext();
  const creditCardOptions = {
    numericOnly: true,
    blocks: [4, 4, 4, 4],
    delimiters: [" ", " ", " "],
  };

  const fieldHasErrors = (field) => {
    const keys = Object.keys(field.hasErrors);
    return keys.filter((key) => field.hasErrors[key] === true).length > 0;
  };

  const handleErrorShow = (field) => fieldHasErrors(field) && field.touched;

  const getErrorMessage = (field) => {
    const keys = Object.keys(field.hasErrors);
    const errorsKeys = keys.filter((key) => field.hasErrors[key] === true);
    const errorsMessages = errorsKeys.map((key) => field.errorsMsg[key]);
    return errorsMessages || "";
  };

  const handleOnGoBack = () => {
    setIsLoading(false);
    setIsDisableButton(false);
    modalContext.setOpen(false);
  };

  const handleResponseSuccess = () => {
    if (onChangeCard) {
      restSubscriptions
        .updateSubscription()
        .then(() => {
          setIsLoading(false);
          onSuccessCreateCard();
        })
        .catch(handleResponseError);
    }

    setIsLoading(false);
    onSuccessCreateCard();
  };

  const handleModalOnTryAgain = () => {
    setIsLoading(true);
    const raw = formatFormRaw(form);
    modalContext.setOpen(false);
    restCards
      .postCard(raw)
      .then(handleResponseSuccess)
      .catch((err) => {
        modalContext.setTitle(
          huhLooksLikeSomethingWentWrong
        );
        modalContext.setMessage(
          unableToSaveCreditCardDetails
        );
        modalContext.setError(apiErrorHandler.handleError(err));
        modalContext.setOnClickGoBackFunc(() => handleOnGoBack());
        modalContext.setOnClickTryAgainFunc(() => handleModalOnTryAgain());
        modalContext.setOpen(true);
      });
  };

  const handleResponseError = (err) => {
    setIsLoading(false);
    modalContext.setTitle(
      huhLooksLikeSomethingWentWrong
    );
    modalContext.setMessage(
      unableToSaveCreditCardDetails
    );
    modalContext.setError(apiErrorHandler.handleError(err));
    modalContext.setOnClickGoBackFunc(() => handleOnGoBack());
    modalContext.setOnClickTryAgainFunc(() => handleModalOnTryAgain());
    modalContext.setOpen(true);
  };

  const sendCard = () => {
    setIsLoading(true);
    const raw = formatFormRaw(form);

    restCards
      .postCard(raw)
      .then(handleResponseSuccess)
      .catch(handleResponseError);
  };

  const handleOnMaskChange = (name, value) => {
    setForm((oldForm) => {
      let newForm = { ...oldForm };
      newForm[name].value = value;
      newForm = formUtils.updateFieldValidationWithValidators(newForm, name);
      if (newForm.isValid) {
        setIsDisableButton(false);
      } else {
        setIsDisableButton(true);
      }
      return newForm
    });
  };

  const handleOnSubmit = () => {
    if (form.isValid) {
      sendCard();
      setIsDisableButton(true);
    }
  };

  const handleOnBlur = (event) => {
    const { name } = event.target;
    const newForm = { ...form };
    newForm[name].touched = true;
    setForm(newForm);
  };
  return (
    <CreditCardFormContainerForm>
      <CreditCardFormRow noMarginTop>
        <InputText
          label={TranslatorFunction({ path: form.cardNumber.label })}
          isRequired={form.cardNumber.isRequired}
          placeholder={TranslatorFunction({ path: form.cardNumber.placeholder })}
          error={handleErrorShow(form.cardNumber)}
          errorMessage={getErrorMessage(form.cardNumber)[0] || ""}
          value={mask(form.cardNumber.value, ["9999 9999 9999 9999"])}
          onBlur={handleOnBlur}
          name="cardNumber"
          dataTestId="cardNumber"
          options={creditCardOptions}
          onChange={(name, value) => handleOnMaskChange(name, value)}
          maxLength="19"
          maxLengthShow={false}
          replace={(value) => value.replace(/\D/g, "").replace(/(\d{4})(\d)/, "$1 $2").replace(/(\d{4})(\d)/, "$1 $2").replace(/(\d{4})(\d)/, "$1 $2") }
        />
      </CreditCardFormRow>
      <CreditCardFormRow>
        <InputText
          label={TranslatorFunction({ path: form.expirationDate.label })}
          isRequired={form.expirationDate.isRequired}
          placeholder={TranslatorFunction({ path: form.expirationDate.placeholder })}
          error={handleErrorShow(form.expirationDate)}
          errorMessage={getErrorMessage(form.expirationDate)[0] || ""}
          value={mask(form.expirationDate.value, ["99/99"])}
          onChange={(name, value) => handleOnMaskChange(name, value)}
          onBlur={handleOnBlur}
          options={ExpDateOptions}
          name="expirationDate"
          dataTestId="expirationDate"
          maxLength="5"
          maxLengthShow={false}
          replace={value => value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1/$2")}
        />
      </CreditCardFormRow>
      <CreditCardFormRow>
        <InputText
          label={TranslatorFunction({ path: form.cardholderName.label })}
          isRequired={form.cardholderName.isRequired}
          placeholder={TranslatorFunction({ path: form.cardholderName.placeholder })}
          error={handleErrorShow(form.cardholderName)}
          errorMessage={getErrorMessage(form.cardholderName)[0] || ""}
          value={form.cardholderName.value}
          onChange={(name, value) => handleOnMaskChange(name, value)}
          options={defaultOptionsUpperCase}
          onBlur={handleOnBlur}
          name="cardholderName"
          dataTestId="cardholderName"
          replace={value => value.replace(/[^a-zA-Z\u00C0-\u017F\s]/g, "")}
        />
      </CreditCardFormRow>
      <CreditCardFormFooterContent>
        <CreditCardFormSmallParagraph>
          {bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable}
          <CreditCardFormLink onClick={onClickedTerms}>
            {inThisLink}
          </CreditCardFormLink>
        </CreditCardFormSmallParagraph>
        <Button onClick={handleOnSubmit} disabled={isDisabledButton}>
          {savePaymentData}
        </Button>
        <CreditCardFormLoaderContent isLoading={isLoading}>
          <Loader />
        </CreditCardFormLoaderContent>
      </CreditCardFormFooterContent>
    </CreditCardFormContainerForm>
  );
};

CreditCardForm.defaultProps = {
  onSuccessCreateCard: () => { },
};

CreditCardForm.propTypes = {
  onSuccessCreateCard: PropTypes.func,
  onClickedTerms: PropTypes.func.isRequired,
};

export default CreditCardForm;
