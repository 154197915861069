import styled from 'styled-components';
import { Paragraph16px300Light } from '../../../../../styles/style-guide';

const SignaturesCreditCardContent = styled.div`
  height: calc(100vh - 240px);
  min-height: 480px;
`;

const SignaturesCreditCardParagraph = styled.p`
  ${Paragraph16px300Light};
`;

export {
  SignaturesCreditCardContent,
  SignaturesCreditCardParagraph,
};
